import React, { useState, useEffect } from "react";
import "tea-component/dist/tea.css";
import { Tabs, TabPanel, Layout, Card, List, Radio, Button, Modal } from "tea-component";
import './index.scss'
const { Header, Body, Sider, Content: LayoutContent } = Layout;
const color = ['#006eff', '#0abf5b', '#ff9d00', '#e54545', '#262626', '#bbbbbb', '#f1ccb9', '#b9f2ec', '#d8b9f2', '#a9c186']


export default function ProductExhibition() {
    useEffect(() => {
        // window.open('https://drive.weixin.qq.com/s?k=AJEAIQdfAAofhSAYs9')
    }, [])
    return (
        <>
            <LayoutContent>
                <LayoutContent.Header title='演示说明' />
                <div className="presentation">
                    <div className="presentation_box">
                        <iframe src="https://drive.weixin.qq.com/s?k=AJEAIQdfAAofhSAYs9" width="100%" height="100%" frameborder="0"></iframe>
                    </div>
                </div>
            </LayoutContent>

        </>
    );
}
