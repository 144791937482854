import { useState, useRef, useCallback } from 'react'
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  ReactFlowInstance,
  Connection,
  Edge,
  Node,
  useNodesState,
  useEdgesState,
  Controls,
  MarkerType
} from 'react-flow-renderer'

import Sidebar from './sidebar'
import MockNode from './mockNode'
import UpdateNode from './nodeContent'
import GroupA from './groupA'
import KafkaNode from './kafkaNode'
import EdgeContent from './edgeContent'
import DataSetNode from './dataSetNode.jsx'
import JsonModel from './jsonModel.jsx'
import { mockData } from './defaultNode'

import './index.scss'

const nodeTypes = {
  Mock: MockNode,
  GroupA: GroupA,
  Kafka: KafkaNode,
  DataSet: DataSetNode
}

let id = 0
const getId = () => `dndnode_${id++}`

const DnDFlow = () => {
  const initialNodes = [
    {
      id: 'GroupA1',
      type: 'GroupA',
      data: {
        label: '数据组件',
        onHide: e => {
          hideNodes(e)
        },
        hidden: false
      },
      position: { x: 100, y: 100 },
      className: 'light',
      dragHandle: '.tea-accordion__header',
      selectable: false
    }
  ]
  const jsonModelRef = useRef(null)
  const reactFlowWrapper = useRef(null)
  const [reactFlowInstance, setReactFlowInstance] = useState()
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes)
  const [edges, setEdges, onEdgesChange] = useEdgesState([])

  const [nodeInfo, setNodeInfo] = useState(null)
  const [edgeInfo, setEdgeInfo] = useState(null)

  const onConnect = params => {
    //禁止互相连接
    const connectedEdges = edges.filter(
      edge => edge.source === params.target && edge.target === params.source
    )
    if (connectedEdges.length > 0) {
      console.log('禁止连接已经相连的节点')
      return
    }
    const { source, target } = params
    const sourceNode = nodes.find(n => n.id === source)
    const targetNode = nodes.find(n => n.id === target)

    const DataNodes = ['DataSet', 'Mock']

    if (
      DataNodes.includes(sourceNode.type) &&
      !DataNodes.includes(targetNode.type)
    ) {
      console.log('禁止数据组件连接其他组件')
      return
    }

    if (
      DataNodes.includes(targetNode.type) &&
      !DataNodes.includes(sourceNode.type)
    ) {
      console.log('禁止数据组件连接其他组件')
      return
    }

    let label = ''
    if (DataNodes.includes(sourceNode.type)) {
      label = '依赖'
    }
    setEdges(es =>
      addEdge(
        {
          ...params,
          zIndex: 1,
          label,
          markerEnd: { type: MarkerType.ArrowClosed, width: 20, height: 20 }
        },
        es
      )
    )
  }
  const onInit = rfi => setReactFlowInstance(rfi)

  const onDragOver = useCallback(event => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
  }, [])

  const onDrop = useCallback(
    event => {
      event.preventDefault()
      if (reactFlowInstance) {
        const type = event.dataTransfer.getData('application/reactflow')
        if (typeof type === 'undefined' || !type) {
          return
        }
        let position = null
        let newNode = {
          id: `${type}_${getId()}`,
          type,
          data: { label: `${type}`, deleteNode: onChange }
        }
        if (type === 'Mock' || type === 'DataSet') {
          // 父组件折叠起来的时候不能新增
          const groupA = nodes.find(n => n.id === 'GroupA1')
          if (groupA.data.hidden) {
            return
          }

          const groupADom = document
            .getElementById('groupA1')
            .getBoundingClientRect()
          const zoom = reactFlowInstance.getZoom()
          let x = (event.clientX - groupADom.left) / zoom - 60

          if (x < 10) {
            x = 10
          } else if (x + 120 > groupADom.width / zoom) {
            x = groupADom.width / zoom - 140
          }
          let y = (event.clientY - groupADom.top) / zoom - 20
          if (y < 50) {
            y = 50
          } else if (y + 50 > groupADom.height / zoom) {
            y = groupADom.height / zoom - 60
          }
          position = { x, y }
          newNode.parentNode = 'GroupA1'
          newNode.extent = 'parent'
          newNode.data.table = { ...mockData, id: newNode.id }
        } else {
          const reactFlowBounds =
            reactFlowWrapper.current.getBoundingClientRect()
          position = reactFlowInstance.project({
            x: event.clientX - reactFlowBounds.left - 60,
            y: event.clientY - reactFlowBounds.top - 15
          })
        }

        newNode.position = position

        setNodes(nds => nds.concat(newNode))
      }
    },
    [reactFlowInstance]
  )

  const hideNodes = hidden => {
    //隐藏nodes
    setNodes(nds =>
      nds.map(item => {
        if (item.id === 'GroupA1') {
          item.data.hidden = hidden
        } else if (item.parentNode === 'GroupA1') {
          item.hidden = hidden
          return item
        }
        return item
      })
    )
    //隐藏edges
    setEdges(es =>
      es.map(item => {
        if (
          item.source.indexOf('Mock') > -1 ||
          item.target.indexOf('Mock') > -1
        ) {
          item.hidden = hidden
          return item
        }
        return item
      })
    )
  }

  // 改变节点内容
  const changeNode = val => {
    setNodes(nds =>
      nds.map(item => {
        if (item.id === val.id) {
          item.data = {
            ...item.data,
            table: val,
            label: val.name
          }
        }
        return item
      })
    )
  }

  //修改连接内容
  const changeEdge = val => {
    setEdges(es =>
      es.map(item => {
        if (item.id === val.id) {
          item.data = {
            data: val
          }
        }
        return item
      })
    )
  }

  // 删除节点
  const onChange = nodeId => {
    // console.log(id)
    setNodes(nds => nds.filter(item => item.id !== nodeId))
  }

  // 点击节点
  const onNodeClick = (e, node) => {
    console.log(node)
    if (node.type !== 'Mock') {
      return
    }
    setNodeInfo({
      ...node.data.table,
      id: node.id
    })
  }

  //点击连接线
  const onEdgeClick = (e, edge) => {
    console.log(edge)
    if (edge.label !== '依赖') {
      return
    }
    const edgeInfo = JSON.parse(JSON.stringify(edge))
    const sourceData = nodes.find(item => item.id === edge.source).data
    const targetData = nodes.find(item => item.id === edge.target).data
    edgeInfo.sourceData = sourceData.table || { name: sourceData.label }
    edgeInfo.targetData = targetData.table || { name: targetData.label }
    setEdgeInfo({ ...edgeInfo })
  }

  //导出json
  const exportJson = () => {
    const tableConfs = nodes
      .map(item => {
        if (item.type === 'Mock') {
          return { ...item.data.table, nodePosition: item.position }
        }
      })
      .filter(item => !!item)

    const edgeConfs = edges.map(item => {
      const { id, source, target } = item
      return { id, source, target }
    })
    const jsonData = JSON.stringify({ tableConfs, edgeConfs }, null, 2)
    jsonModelRef.current.open(jsonData)
  }

  return (
    <div className='dndflow'>
      <ReactFlowProvider initialNodes={initialNodes} initialEdges={[]}>
        <Sidebar exportjson={exportJson} />
        <JsonModel ref={jsonModelRef} />
        <div className='wrapper' ref={reactFlowWrapper}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            onEdgesChange={onEdgesChange}
            onNodesChange={onNodesChange}
            onConnect={onConnect}
            onInit={onInit}
            onDrop={onDrop}
            onDragOver={onDragOver}
            onNodeClick={onNodeClick}
            onEdgeClick={onEdgeClick}
            deleteKeyCode={['Delete', 'Backspace']}
          >
            <UpdateNode info={nodeInfo} onChange={changeNode} />
            <EdgeContent info={edgeInfo} onChange={changeEdge} />
            <Controls />
          </ReactFlow>
        </div>
      </ReactFlowProvider>
    </div>
  )
}

export default DnDFlow
