import axios from 'axios'

// 双活状态
export function getSystemInfo() {
    return axios.get(`/sys/info`)
}

// 双活切换获取架构图
export function getSwitch(destZone) {
    return axios.get(`/api/az/switch/${destZone}`)
}

// 双活切换任务进度
export function getTaskResult(currentTaskId) {
    return axios.get(`/api/task/${currentTaskId}`)
}

// 双活复原获取架构图
export function getReset(){
    return axios.get(`/api/az/reset`)
}

// 流量调度获取架构图
export function getSchedule(data) {
    return axios.get(`/api/traffic/schedule?az3TrafficRatio=${data.az3TrafficRatio}&az4TrafficRatio=${data.az4TrafficRatio}`)
}

export function getBiPage(projectId,pageId,scope,expireTime){
    return axios.get(`/sys/queryBiToken?projectId=`+projectId+'&pageId='+pageId+'&scope='+scope+'&expireTime='+expireTime)
}

//操作GPU
export function operateGpu(sc){
    return axios.get(`/gpu/createJob?scene=`+sc)
}
//删除GPU
export function deleteGpu(sc){
    return axios.get(`/gpu/deleteJob`)
}

export function queryGpuNode(){
    return axios.get(`/gpu/getNodes`)
}
//查询job下的pods
export function queryGpuJob(){
    return axios.get(`/gpu/getPods`)
}

export function queryEvents(){
    return axios.get(`/gpu/getEvent`)
}

export function queryLogs(){
    return axios.get(`/gpu/getLog`)
}

//查询GPU AI Nodes
export function queryGpuAiNodes(data){
    return axios.get(`/gpu/getAiNodes`)
}
//查询GPU AI GC Nodes
export function queryGpuAiGcNode(){
    return axios.get(`/gpu/getAiGcNodes`)
}
//查询GPU AI GC 下的pods
export function queryGpuAiGcPod(){
    return axios.get(`/gpu/getAiGcPods`)
}
//查询GPU AI GC 事件
export function queryAiGcEvents(){
    return axios.get(`/gpu/getAiGcEvent`)
}

//查询GPU AI pods
export function queryGpuAiPods(data){
    return axios.get(`/gpu/getAiPods?scene=`+data)
}
export function queryAiGcLogs(){
    return axios.get(`/gpu/getAiGcLog`)
}
//操作 AI pods
export function createGpuAiPods(data){
    return axios.get(`/gpu/createAiJob?scene=`+data)
}

//操作 AI pods
export function deleteGpuAi(){
    return axios.get(`/gpu/deleteAiJob`)
}

//操作 AI pods
export function queryAiEvent(scene,name){
    return axios.get(`/gpu/getAiEvent?scene=`+scene+'&eventName='+name)
}


//dlc相关方法 dlc查询数据
export function queryDlcDataList(taskEngine){
    return axios.get(`/dlc/queryDlcData?taskEngine=`+taskEngine)
}
//dlc场景复原
export function updateDataEngineDlc(scene){
    return axios.get(`/dlc/updateDataEngine?scene=`+scene)
}

//dlc开启ETL作业
export function startEtl(scence){
    return axios.get(`/dlc/startEtl?scence=`+scence)
}

//dlc开启ADHOC作业
export function startAdhoc(scence){
    return axios.get(`/dlc/startAdhoc?scence=`+scence)
}
//dlc状态
export function queryDlcStatus(){
    return axios.get(`/dlc/getDataEngineStatus`)
}
//dlc 信息
export function queryDlcInfo(){
    return axios.get(`/dlc/queryDlcInfo`)
}

//查看 dlc日志
export function queryDlcLog(scence){
    return axios.get(`/dlc/queryDescribeDataEngineEvents?scence=`+scence);
}


//查看 acrossCloudDoubleLive
export function queryAcrossCloudDoubleLive(){
    return axios.get(`/api/acrossCloudDoubleLive/getList`);
}

//跨云双活  场景3下单
export function purchaseAcrossCloud(userId,status,books){
    return axios.get(`/api/acrossCloudDoubleLive/purchase?userId=`+userId+'&status='+status+'&books='+books);
}

//跨云双活  场景4 故障模拟
export function updateDNSPod(type){
    return axios.get(`/api/acrossCloudDoubleLive/updateDnsPod?type=`+type);
}

//跨云双活  场景4 双活切换
export function updateKong(regionType,kongStatus){
    return axios.get(`/api/acrossCloudDoubleLive/updateKongEnabled?regionType=`+regionType+'&kongStatus='+kongStatus);
}

//跨云双活  查看日志
export function queryAcrossDoubleLiveLog(regionType,books){
    return axios.get(`/api/acrossCloudDoubleLive/getLog?region=`+regionType+'&books='+books);
}

//跨云双活  查看kongCls日志
export function queryAcrossDoubleLiveKongClsLog(books){
    return axios.get(`/api/acrossCloudDoubleLive/getClsKongLog?books=`+books);
}

//跨云双活  查看mysql数据
export function queryAcrossDoubleLiveMysqlDataLogTable(){
    return axios.get(`/api/acrossCloudDoubleLive/getMysqlDataLogTable`);
}

//跨云双活  查看mysql数据日志
export function queryAcrossDoubleLiveMysqlDataLog(books){
    return axios.get(`/api/acrossCloudDoubleLive/getMysqlDataLog?books=`+books);
}

//跨云双活  查看Cls数据日志
export function queryClsLogData(books){
    return axios.get(`/api/acrossCloudDoubleLive/getClsLog?books=`+books);
}

//跨云双活  写入mysql 广州或者上海数据库表
export function insertMysqlData(regionType,scene){
    return axios.get(`/api/acrossCloudDoubleLive/insertMysqlData?regionType=`+regionType+"&scene="+scene);
}

//获取系统配置
export function querySysConfig(sType,fType){
    return axios.get(`/sys/queryConsoleConfig?scenceType=`+sType+"&functionType="+fType);
}

//获取nacos服务列表
export function queryNacosServices(){
    return axios.get(`/api/tem/services/info`);
}

//tem pts
export function startTemPts(){
    return axios.get(`/api/tem/pts`);
}

//tem pts
export function stopTemPts(){
    return axios.get(`/api/tem/pts/stop`);
}

//沙盒系统场景预约
export function addReserveScene(sceneId,startTime,endTime){
    return axios.get(`/api/addReserveScene?sceneId=`+sceneId+"&startTime="+startTime+"&endTime="+endTime);
}

//查看场景预约
export function listReserveScene(pageIndex,pageSize){
    return axios.get(`/api/querySceneList?pageIndex=`+pageIndex+"&pageSize="+pageSize);
}

//取消场景预约
export function updateUserReserveSceneState(id){
    return axios.get(`/api/updateUserReserveSceneState?id=`+id);
}

//查看场景预约
export function listReserveSceneMenu(){
    return axios.get(`/api/querySceneMenuList`);
}

//查看登录用户
export function getUserName(){
    return axios.get(`/api/getUsername`);
}

//新增用户
export function addUser(userName,password,rePassword,role){
    return axios.get(`/api/addUser?userName=`+userName+"&password="+password+"&rePassword="+rePassword+"&role="+role)
}
//查询用户登录日志
export function queryLoginLogList(pageIndex,pageSize){
    return axios.get(`/api/queryLoginLog?pageIndex=`+pageIndex+"&pageSize="+pageSize)
}
//查讯k8s pod数据
export function queryPodsInfo(){
    return axios.get(`/api/k8s/info`);
}
//查讯北极星数据
export function queryPolarisInfo(){
    return axios.get(`/api/polaris/services`);
}

export function createNewService(){
    return axios.get(`/api/k8s/create`);
}

export function createBadService(){
    return axios.get(`/api/k8s/create/bad`);
}

export function restartGateway(){
    return axios.get(`/api/k8s/restart/gateway`);
}

export function recoveryService(){
    return axios.get(`/api/k8s/recovery/service`);
}

export function queryMenuList(){
    return axios.get(`/api/queryMenuList`);
}

// 获取所有的场景配置
let SCENE_CACHE_DATA
export async function getScene() {
    if(SCENE_CACHE_DATA && SCENE_CACHE_DATA.length > 0) return SCENE_CACHE_DATA
    const resp = await  axios.get(`/api/queryMenuList`)
    const sceneData = (resp.data?.scene || []).map(item => ({
        ...item,
        entry: JSON.parse(item.entry)
    }))
    SCENE_CACHE_DATA = sceneData
    return sceneData
}

export function queryPolarisKong(){
    return axios.get(`/api/polaris/queryKongSwitch`);
}

export function polarisSwitchKong(kongSwitch){
    return axios.get(`/api/polaris/kongSwitch?kongSwitch=`+kongSwitch);
}

//根据target跳转页面
export function iframeToUrl(target){
    return axios.get(`/api/txCloud/loginUrl?target=`+target);
}

export  function  getGameDataInfo(){
    return axios.get(`/api/getClickHouseInfo`)
}

//获取系统配置
export function querySceneConfig(sType,fType){
    return axios.get(`/sys/querySceneConfig?scenceType=`+sType+"&functionType="+fType);
}

export  function  getAiOffLineMixingClusterInfo(){
    return axios.get(`/api/aiOffLineMixing/getClusterInfo`)
}

//开始任务
export  function  startAiOffLineMixing(scene){
    return axios.get(`/api/aiOffLineMixing/startScene?scene=`+scene)
}
export  function  startAiOffLineMixingScene2(cpu,memory){
    return axios.get(`/api/aiOffLineMixing/updatePod?cpu=`+cpu+"&memory="+memory)
}

export  function  createAiOffLineTask(){
    return axios.get(`/api/aiOffLineMixing/createDeployment`)
}

export  function  deleteAiOffLineTask(){
    return axios.get(`/api/aiOffLineMixing/deleteDeployment`)
}



//questionAnswer
export  function  addQuestionAnswerText(value1){
    return axios.post("/api/questionAnswer/addTextData", {
        // 在这里填写您的请求参数
        text: value1
    });
}
//questionAnswer
export  function  addQuestionAnswerJsonText(value1){
    return axios.post("/api/questionAnswer/addJsonTextData", {
        // 在这里填写您的请求参数
        text: value1
    });
}

//恢复数据
export  function  deleteQuestionAnswer(){
    return axios.get(`/api/questionAnswer/deleteData`)
}

export  function  queryQuestionAnswerTextData(text){
    return axios.get(`/api/questionAnswer/queryTextData?similarity=`+text)
}

export  function  queryQuestionAnswerJsonData(id,filterName,type){
    return axios.get(`/api/questionAnswer/queryJsonData?id=`+id+"&filterName="+filterName+"&type="+type)
}

//测试压力数据
export  function  testPressureData(type){
    return axios.get(`/api/questionAnswer/pressureTest?testType=`+type)
}


