import { useState, useEffect, useRef } from 'react'
import {
  Button,
  Drawer,
  Form,
  Input,
  InputNumber,
  Select,
  DatePicker
} from 'tea-component'
import { useForm, Controller } from 'react-hook-form'
import moment from 'moment'

const NodeContent = ({ info, onChange }) => {
  const [visible, setVisible] = useState(false)
  const { control, handleSubmit, formState, reset } = useForm({ mode: 'all' })
  const [title, setTitle] = useState('')

  const defaultInfo = {
    name: 'mock',
    out_format: 'only_v',
    output: 'file',
    partitionNums: 100,
    partitionStart: moment(Date.now),
    partitionUnit: 'now',
    totalLines: 100000,
    bucketNums: 1,
    col_kv_split: '=',
    col_null_value: 'null',
    col_split: '`',
    output_kafka_topic: '',
    columns: []
  }

  useEffect(() => {
    const resetInfo = { ...defaultInfo, ...info }
    if (info) {
      const { sourceData, targetData } = info
      setTitle(sourceData.name + ' -> ' + targetData.name)

      setVisible(true)

      reset(resetInfo)
    }
  }, [info])

  async function onSubmit(values) {
    const dataset = { ...values, id: info?.id }

    onChange(dataset)
    console.log('dataset', dataset)
    setVisible(false)
  }

  function getStatus(meta) {
    if (!meta.isDirty && !formState.isSubmitted) {
      return null
    }
    return meta.invalid ? 'error' : 'success'
  }

  return (
    <>
      <Drawer
        visible={visible}
        title={title}
        onClose={() => setVisible(false)}
        className='nodeContent'
        outerClickClosable={false}
        style={{
          width: 600
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Form>
            <Controller
              name='name'
              control={control}
              rules={{
                required: '请输入表名'
              }}
              render={({ field, fieldState }) => (
                <Form.Item
                  label='表名'
                  status={getStatus(fieldState)}
                  message={fieldState.error?.message}
                >
                  <Input
                    {...field}
                    autoComplete='off'
                    maxLength={20}
                    placeholder='请输入表名'
                  />
                </Form.Item>
              )}
            />
          </Form>
          <Form.Action>
            <Button
              type='primary'
              htmlType='submit'
              loading={formState.isSubmitting}
            >
              保存
            </Button>
          </Form.Action>
        </form>
      </Drawer>
    </>
  )
}
export default NodeContent
