import axios from 'axios'

// 
export function getBookList(){
    return axios.get(`/api/bookstorage/admin/list`)
}

// 
export function modifyBookstorage(data){
    return axios.get(`/api/bookstorage/updateStorage?isbn=`+data.isbn+'&storageNum='+data.storageNum+'&startTime='+data.startTime+'&endTime='+data.endTime, data)
}

export function increasePodNumF(){
    return axios.get(`/api/increaseDeployment`)
}

export function reducePodNumF(){
    return axios.get(`/api/reduceDeployment`)
}

export function getK8sDeployment(){
    return axios.get(`/api/getK8sDeployment`)
}

export function modifyPodNum(data){
    return axios.get(`/api/increaseDeployment?frontNum=`+data.frontNum+'&orderNum='+data.orderNum+'&gateWayNum='+data.gateWayNum+'&secKillNum='+data.secKillNum+'&storeNum='+data.storeNum, data)
}

// 双活切换任务进度
export function getDeploymentTaskResult(currentTaskId) {
    return axios.get(`/api/getDeploymentTask/${currentTaskId}`)
}

export function modifyClbBand(clbBandWithout){
    return axios.get(`/sys/modifyClbBand?clbBandWithout=`+clbBandWithout)
}

export function getClbBand(){
    return axios.get(`/sys/queryClbBand`)
}

export function switchJmeter(switchFlag){
    return axios.get(`/sys/connectLinuxCommand?switchFlag=`+switchFlag)
}

export function getSwitchJmeter(){
    return axios.get(`/sys/queryConnectLinuxCommand`)
}

export function switchKong(switchFlag){
    return axios.get(`/sys/kongSwitch?kongSwitch=`+switchFlag)
}

export function updateKong(){
    return axios.get(`/sys/updateKong`)
}

export function getSwitchKong(){
    return axios.get(`/sys/queryKongSwitch`)
}

export function getResourceInfo(){
    return axios.get(`/sys/queryResourceInfo`)
}

export function seckillRecover(){
    return axios.get(`/seckill/seckillRecover`)
}
export function seckillStart(){
    return axios.get(`/seckill/ptsStart`)
}

export function seckillList(){
    return axios.get(`/seckill/ptsList`)
}

export function getIframeResUrl(target){
    return axios.get(`/api/txCloud/loginUrl?target=`+target)
}
