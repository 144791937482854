import React, { useEffect, useState } from "react";
import "tea-component/dist/tea.css";
import { Icon, Layout, Menu } from "tea-component";
import TopMenu from "../NavMenu";
// eslint-disable-next-line import/no-extraneous-dependencies
import { HashRouter, Link, Redirect, Route, Switch, withRouter } from "react-router-dom";

import BaseInfo from "../Routes/DoubleLive/BaseInfo";
import Function from '../Routes/DoubleLive/Function';
import ProductExhibition from '../Routes/DoubleLive/ProductExhibition';
import BookStorage from '../Routes/SecKill/BookStorage'
import BigPromoteFunction from '../Routes/SecKill/Function'
import BigPromoteBaseInfo from '../Routes/SecKill/BaseInfo'
import DemoDesc from '../Routes/SecKill/ProductExhibition'
import RealTimeAnalysisBaseInfo from '../Routes/RealTimeAnalysis/BaseInfo'
import RealTimeAnalysisFunction from '../Routes/RealTimeAnalysis/Function'
import RealTimeAnalysisExhibition from '../Routes/RealTimeAnalysis/ProductExhibition'
import GpuOptimizeBaseInfo from '../Routes/GpuOptimize/BaseInfo'
import GpuOptimizeFunction from '../Routes/GpuOptimize/Function'
import GpuOptimizeProductExhibition from '../Routes/GpuOptimize/ProductExhibition'
import AiFunction from '../Routes/GpuOptimize/AiFunction'


import StoreCalcSeparatedBaseInfo from '../Routes/StoreCalcSeparated/BaseInfo'
import StoreCalcSeparatedFunction from '../Routes/StoreCalcSeparated/Function'
import StoreCalcSeparatedExhibition from '../Routes/StoreCalcSeparated/ProductExhibition'


import AcrossCloudDoubleLiveBaseInfo from '../Routes/AcrossCloudDoubleLive/BaseInfo'
import AcrossCloudDoubleLiveFunction from '../Routes/AcrossCloudDoubleLive/Function'
import AcrossCloudDoubleLiveExhibition from '../Routes/AcrossCloudDoubleLive/ProductExhibition'


import MicroServiceContainerization from '../Routes/Tem/MicroServiceContainerization'
import MicroServiceContainerizationBaseInfo from '../Routes/Tem/BaseInfo'
import MicroServiceContainerizationExhibition from '../Routes/Tem/ProductExhibition'

import ReserveSceneFunction from '../Routes/ReserveScene/Function'
import AddUserFunction from '../Routes/ReserveScene/AddUserFunction'
import LoginFunction from '../Routes/ReserveScene/LoginFunction'

import { listReserveSceneMenu, getScene } from "../services/function";
import PolarisService from "../Routes/Polaris/Service";
import PolarisExhibition from "../Routes/Polaris/ProductExhibition";
import PolarisBaseInfo from "../Routes/Polaris/BaseInfo";

import AiGcFunction from '../Routes/AiGc/AiGcFunction'
import AiGcExhibition from '../Routes/AiGc/ProductExhibition'
import AiGcBaseInfo from '../Routes/AiGc/BaseInfo'

import GameDataFunction from "../Routes/GameDataAnalysis/GameDataFunction";
import GameExhibition from "../Routes/GameDataAnalysis/ProductExhibition";
import GameBaseInfo from "../Routes/GameDataAnalysis/BaseInfo";
import './menuIndex.scss'

import OffLineMixingBaseInfo from '../Routes/OffLineMixing/BaseInfo'
import OffLineMixingFunction from '../Routes/OffLineMixing/Function'
import OffLineMixingExhibition from '../Routes/OffLineMixing/ProductExhibition'


import QuestionAnswerBaseInfo from '../Routes/QuestionAnswer/BaseInfo'
import QuestionAnswerFunction from '../Routes/QuestionAnswer/Function'
import QuestionAnswerExhibition from '../Routes/QuestionAnswer/ProductExhibition'

import Flow from "../Routes/Flow";

const { Header, Body, Sider } = Layout;

const MenuWithRouter = withRouter(function MenuWithRouter({ location }) {
    const [menuList, setMenuList] = useState([])
    const [sceneState, setSceneState] = useState("")
    useEffect(() => {
        (async () => {
            const sceneData = await getScene()
            const menuData = sceneData
                .filter(item => !!parseInt(item.enabled))
                .map(item => {
                    item.children = item.entry.map(subItem => {
                        subItem.link = `${subItem.link}`
                        return subItem
                    })
                    return item
                })
            setMenuList(menuData)
            const res = await listReserveSceneMenu();
            if (res.data.success) {
                setSceneState(res.data.data)
            }
            if (res.data && res.data > 200 && res.data.indexOf("Please sign in") !== -1) {
                window.location.href = "/login"
            }

        })()
    }, [])
    const pathname = location && location.pathname;
    return (
        <Menu theme="dark" collapsable title="沙盒演示 控制器" style={{ height: '100%', zIndex: '999' }}>
            {menuList.length > 0 &&
                menuList.map((data, index) => {
                    // console.log("sceneState[`scene${data.key}`]"+sceneState[`scene${data.key}` ])
                    if (sceneState[`scene${data.key}`]) {
                        return (
                            <Menu.SubMenu title={`场景${data.key}：${data.title}`} key={index} id={`scene${data.key}`}>
                                {
                                    data.children.map((v, i) => {
                                        return (
                                            <Menu.Item
                                                title={v.title}
                                                selected={pathname.startsWith(v.link)}
                                                onClick={() => v.link}
                                                key={`${index}-${i}`}
                                                render={children => <Link to={v.link}><Icon type={v.icon} style={{
                                                    verticalAlign: 'text-top',
                                                    marginTop: '5px',
                                                    marginRight: '8px'
                                                }} />{children}</Link>}
                                            />
                                        )
                                    })
                                }
                            </Menu.SubMenu>
                        )
                    }
                })
            }
            <Menu.Item
                title='FLOW'
                selected={pathname.startsWith('/flow')}
                onClick={() => '/flow'}
                key='FLOW-0'
                render={children => <Link to='/flow'><Icon type='flow' style={{
                    verticalAlign: 'text-top',
                    marginTop: '5px',
                    marginRight: '8px'
                }} />{children}</Link>}
            />
        </Menu>
    );
});
// function Content({match}) {
//     const path = match && match.path;
//     return (
//         <LayoutContent>
//             <LayoutContent.Header title={path}/>
//             <LayoutContent.Body>
//                 <Card>
//                     <Card.Body>{path}</Card.Body>
//                 </Card>
//             </LayoutContent.Body>
//         </LayoutContent>
//
//     );
// }

export default function MenuWithRouterExample() {
    return (
        <HashRouter>

            {/* <Route path='/' exact component={Login} /> */}

            <Layout className="demo-layout-l" style={{ height: '100vh' }}>
                <Header>
                    <TopMenu />
                </Header>
                <Body>
                    <Sider>
                        <MenuWithRouter />
                    </Sider>
                    <Switch>
                        <Route path="/function" exact component={Function} />
                        <Route path="/baseInfo" component={BaseInfo} />
                        <Route path="/productExhibition" component={ProductExhibition} />
                        <Route path="/bookStorage" component={BookStorage} />
                        <Route path="/bigPromoteFunction" component={BigPromoteFunction} />
                        <Route path="/bigPromoteBaseInfo" component={BigPromoteBaseInfo} />
                        <Route path="/demoDesc" component={DemoDesc} />
                        <Route path="/realTimeAnalysisBaseInfo" component={RealTimeAnalysisBaseInfo} />
                        <Route path="/realTimeAnalysisFunction" component={RealTimeAnalysisFunction} />
                        <Route path="/realTimeAnalysisExhibition" component={RealTimeAnalysisExhibition} />
                        <Route path="/gpuOptimizeBaseInfo" component={GpuOptimizeBaseInfo} />
                        <Route path="/gpuOptimizeFunction" component={GpuOptimizeFunction} />
                        <Route path="/gpuOptimizeProductExhibition" component={GpuOptimizeProductExhibition} />
                        <Route path="/aiFunction" component={AiFunction} />

                        <Route path="/storeCalcSeparatedBaseInfo" component={StoreCalcSeparatedBaseInfo} />
                        <Route path="/storeCalcSeparatedFunction" component={StoreCalcSeparatedFunction} />
                        <Route path="/storeCalcSeparatedExhibition" component={StoreCalcSeparatedExhibition} />

                        <Route path="/acrossCloudDoubleLiveBaseInfo" component={AcrossCloudDoubleLiveBaseInfo} />
                        <Route path="/acrossCloudDoubleLiveFunction" component={AcrossCloudDoubleLiveFunction} />
                        <Route path="/acrossCloudDoubleLiveExhibition" component={AcrossCloudDoubleLiveExhibition} />

                        <Route path="/microServiceContainerization" component={MicroServiceContainerization} />
                        <Route path="/microServiceContainerizationBaseInfo" component={MicroServiceContainerizationBaseInfo} />
                        <Route path="/microServiceContainerizationExhibition" component={MicroServiceContainerizationExhibition} />

                        <Route path="/polarisService" component={PolarisService} />
                        <Route path="/polarisExhibition" component={PolarisExhibition} />
                        <Route path="/PolarisBaseInfo" component={PolarisBaseInfo} />

                        <Route path="/reserveSceneFunction" component={ReserveSceneFunction} />
                        <Route path="/addUserFunction" component={AddUserFunction} />
                        <Route path="/loginFunction" component={LoginFunction} />


                        <Route path="/aiGcFunction" component={AiGcFunction} />
                        <Route path="/aiGcExhibition" component={AiGcExhibition} />
                        <Route path="/aiGcBaseInfo" component={AiGcBaseInfo} />

                        <Route path="/gameDataFunction" component={GameDataFunction} />
                        <Route path="/gameDataExhibition" component={GameExhibition} />
                        <Route path="/gameDataBaseInfo" component={GameBaseInfo} />


                        <Route path="/offLineMixingBaseInfo" component={OffLineMixingBaseInfo} />
                        <Route path="/offLineMixingFunction" component={OffLineMixingFunction} />
                        <Route path="/offLineMixingExhibition" component={OffLineMixingExhibition} />

                        <Route path="/questionAnswerBaseInfo" component={QuestionAnswerBaseInfo} />
                        <Route path="/questionAnswerFunction" component={QuestionAnswerFunction} />
                        <Route path="/questionAnswerExhibition" component={QuestionAnswerExhibition} />

                        <Route path="/flow" component={Flow} />

                        <Redirect to="/function" />
                    </Switch>
                </Body>
            </Layout>

        </HashRouter>
    );
}
