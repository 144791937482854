import { Button } from 'tea-component'

const onDragStart = (event, nodeType) => {
  event.dataTransfer.setData('application/reactflow', nodeType)
  event.dataTransfer.effectAllowed = 'move'
}

const Sidebar = ({ importjson, exportjson }) => {
  return (
    <aside className='aside'>
      <div
        className='asideitem'
        onDragStart={event => onDragStart(event, 'DataSet')}
        draggable
      >
        DataSet
      </div>
      <div
        className='asideitem'
        onDragStart={event => onDragStart(event, 'Mock')}
        draggable
      >
        Mock
      </div>
      <div
        className='asideitem'
        onDragStart={event => onDragStart(event, 'Kafka')}
        draggable
      >
        Kafka
      </div>
      <div className='asideitem-right'>
        <Button className='itembtn' onClick={importjson}>
          导入json
        </Button>
        <Button className='itembtn' onClick={exportjson} type='primary'>
          生成json
        </Button>
      </div>
    </aside>
  )
}

export default Sidebar
