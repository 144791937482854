import React, {useEffect, useState} from "react";
import "tea-component/dist/tea.css";
import {Button, Card, Input, Layout, message, Radio, TabPanel, Tabs, TextArea} from "tea-component";
import './questionAnswer.scss'
import {
    addQuestionAnswerJsonText,
    addQuestionAnswerText,
    deleteQuestionAnswer,
    queryQuestionAnswerJsonData,
    queryQuestionAnswerTextData, querySceneConfig,
    testPressureData
} from "../../../services/function"

const {Content: LayoutContent} = Layout;
export default function QuestionAnswerFunction() {
    //场景2
    const disabled = false;
    const readonly = false;
    const [text, setText] = useState("");
    const [textRight, setTextRight] = useState("");
    const [rightFilterReq, setRightFilterReq] = useState("");
    const [rightIdReq, setRightIdReq] = useState("");
    const [commonFilterReq, setCommonFilterReq] = useState("");
    const [commonIdReq, setCommonIdReq] = useState("");
    const [commonContentReq, setCommonContentReq] = useState("");
    const [textResult, setTextResult] = useState("");
    const [autoText, setAutoText] = useState("");
    const [textResultCommonContent, setTextResultCommonContent] = useState("");
    const [textResultCommonId, setTextResultCommonId] = useState("");
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [loadingAddText, setLoadingAddText] = useState(false)
    const [loadingAddJson, setLoadingAddJson] = useState(false)
    const [loadingTestPressure, setLoadingTestPressure] = useState(false)
    const [testData, setTestData] = useState("1");
    const [consolePage, setConsolePage] = useState("https://dms.cloud.tencent.com/#/login?dbType=vector&region=ap-guangzhou&instanceId=vdb-fvirjd5s");
    const [codePage, setCodePage] = useState("https://cloud.tencent.com/document/product/1709/100556");
    const [testPressurePage, setTestPressurePage] = useState("https://console.cloud.tencent.com/vdb/instance/monitor?region=ap-beijing&InstanceId=vdb-b4kzc0uf");
    // const [testPressurePage95, setTestPressurePage95] = useState("https://console.cloud.tencent.com/vdb/instance/monitor?region=ap-beijing&InstanceId=vdb-12jzg3sf");
    const [testPressurePage95, setTestPressurePage95] = useState("https://console.cloud.tencent.com/vdb/instance/monitor?region=ap-beijing&InstanceId=vdb-b4kzc0uf");
    const milVusUrl = "https://grafana-489dx21o.grafana.tencent-cloud.com/d/uLf5cJ3Gz/milvus2-0";


    const tabs = [
        // {id: "scene1", label: "场景1"},
        // {id: "scene2", label: "场景2"},
        // {id: "scene3", label: "场景3"},
        // {id: "scene4", label: "场景4"},

        {id: "scene2", label: "场景1"},
        {id: "scene3", label: "场景2"},
        // {id: "scene1", label: "场景3"},
        // {id: "scene4", label: "场景4"},

    ];

    const tabs1 = [
        {id: "scene2Text1", label: "Text文本"},
        {id: "scene2Text2", label: "Json文本"},

    ];

    const tabs2 = [
        {id: "scene2QueryText1", label: "精确查询"},
        {id: "scene2QueryText2", label: "相似度查询"},
    ];

    const tabs3 = [
        {id: "scene2QueryCommonText1", label: "文本检索"},
        {id: "scene2QueryCommonText2", label: "文档主键ID"},
    ];
    const scene2Text1 = "腾讯云向量数据库是一款全托管的自研企业级分布式数据库服务，专用于存储、检索、分析多维向量数据。该数据库支持多种索引类型和相似度计算方法，单索引支持10亿级向量规模，可支持百万级 OPS 及毫秒级查询延迟。腾讯云向量数据库不仅能为大模型提供外部知识库，提高大模型回答的准确性，还可广泛应用于推荐系统、NLP 服务、计算机视觉、智能客服等 AI 领域。";
    const scene2Text2 = "[\n" +
        "  {\n" +
        "    \"id\": \"0001\",\n" +
        "    \"segment\": \"富贵功名，前缘分定，为人切莫欺心。\",\n" +
        "    \"bookName\": \"西游记\",\n" +
        "    \"author\": \"吴承恩\",\n" +
        "    \"text\": \"富贵功名，前缘分定，为人切莫欺心。\",\n" +
        "    \"page\": 21\n" +
        "  },\n" +
        "  {\n" +
        "    \"id\": \"0002\",\n" +
        "    \"author\": \"吴承恩\",\n" +
        "    \"bookName\": \"西游记\",\n" +
        "    \"segment\": \"正大光明，忠良善果弥深。些些狂妄天加谴，眼前不遇待时临。\",\n" +
        "    \"page\": 22,\n" +
        "    \"text\": \"正大光明，忠良善果弥深。些些狂妄天加谴，眼前不遇待时临。\"\n" +
        "  },\n" +
        "  {\n" +
        "    \"id\": \"0003\",\n" +
        "    \"text\": \"细作探知这个消息，飞报吕布。\",\n" +
        "    \"segment\": \"细作探知这个消息，飞报吕布。\",\n" +
        "    \"author\": \"罗贯中\",\n" +
        "    \"bookName\": \"三国演义\",\n" +
        "    \"page\": 23\n" +
        "  },\n" +
        "  {\n" +
        "    \"id\": \"0004\",\n" +
        "    \"bookName\": \"三国演义\",\n" +
        "    \"text\": \"富贵功名，前缘分定，为人切莫欺心。\",\n" +
        "    \"author\": \"罗贯中\",\n" +
        "    \"page\": 24,\n" +
        "    \"segment\": \"富贵功名，前缘分定，为人切莫欺心。\"\n" +
        "  },\n" +
        "  {\n" +
        "    \"id\": \"0005\",\n" +
        "    \"text\": \"布大惊，与陈宫商议。宫曰：“闻刘玄德新领徐州，可往投之。\",\n" +
        "    \"author\": \"罗贯中\",\n" +
        "    \"page\": 25,\n" +
        "    \"bookName\": \"三国演义\",\n" +
        "    \"segment\": \"布大惊，与陈宫商议。宫曰：“闻刘玄德新领徐州，可往投之。\"\n" +
        "  }\n" +
        "]";



    //新增场景2 文本
    const addText = async (textStr) => {
        setLoadingAddText(true)
        const res = await addQuestionAnswerText(textStr);
        if (res.data.success) {
            message.success({
                content: res.data.msg
            })
        } else {
            message.error({
                content: res.data.msg
            })
        }
        setLoadingAddText(false)
    }

    //新增场景2 Json文本
    const addJsonText = async (textStr) => {
        setLoadingAddJson(true)
        const res = await addQuestionAnswerJsonText(textStr);
        if (res.data.success) {
            message.success({
                content: res.data.msg
            })
        } else {
            message.error({
                content: res.data.msg
            })
        }
        setLoadingAddJson(false)
    }

    //数据恢复
    const delTextData = async () => {
        setLoadingDelete(true);
        const res = await deleteQuestionAnswer();
        if (res.data.success) {
            message.success({
                content: res.data.msg
            })
        } else {
            message.error({
                content: res.data.msg
            })
        }
        setLoadingDelete(false);
    }


    const testPressure = async (type) => {
        setLoadingTestPressure(true);
         //alert("type="+type);
        if(type==='1'){
            message.error({
                content: "请选择要压测的数据集！"
            })
            setLoadingTestPressure(false);
            return;
        }
        const res = await testPressureData(type);
        if (res.data.success) {
            message.success({
                content: res.data.msg
            })
            setTimeout(() => {
                // 睡眠3秒再跳转
                const w = window.open('_black') //这里是打开新窗口
                if(type==='test1'||type==='test3'){
                    w.location.href = testPressurePage95 //这样就可以跳转了
                }else {
                    w.location.href = testPressurePage
                }
            }, 3000);

        } else {
            message.error({
                content: res.data.msg
            })
        }
        setLoadingTestPressure(false);
    }


    //新增场景2 文本
    const queryData = async (textStr) => {

        const res = await queryQuestionAnswerTextData(textStr);
        if (res.data.success) {
            message.success({
                content: res.data.msg
            })
            const textValue = res.data.data;
            if (res.data.data.length > 0) {
                setTextResult(textValue)
            } else {
                setTextResult("")
            }

        } else {
            message.error({
                content: res.data.msg
            })
        }

    }

    //新增场景2 文本
    const queryJsonData = async (id, filterName, type) => {

        const res = await queryQuestionAnswerJsonData(id, filterName, type);
        if (res.data.success) {
            message.success({
                content: res.data.msg
            })
            const textValue = res.data.data;
            //  alert("textValue="+textValue+"type="+type)
            if (res.data.data.length > 0) {
                if (type === "2") {
                    setTextRight(textValue)
                } else if (type === "3") {
                    setTextResultCommonContent(textValue)

                    //alert("textResultCommonContent="+textResultCommonContent)
                } else if (type === "4") {
                    setTextResultCommonId(textValue)
                }
            } else {
                setTextRight("")
                setTextResultCommonId("")
                setTextResultCommonContent("")
            }

        } else {
            message.error({
                content: res.data.msg
            })
        }

    }
    //场景2
    const tabsChange = async (tab) => {
        let scene2QueryText2Id1 = document.getElementById('scene2QueryText2Id1');
        let scene2QueryText2Id2 = document.getElementById('scene2QueryText2Id2');
        let scene2AddButtonId = document.getElementById('scene2AddButtonId');

        if (tab.id === "scene2Text1") {
            scene2QueryText2Id1.style.display = "block";
            scene2QueryText2Id2.style.display = "none";
            scene2AddButtonId.style.display = "none";
        } else if (tab.id === "scene2Text2") {
            scene2QueryText2Id1.style.display = "none";
            scene2QueryText2Id2.style.display = "block";
            scene2AddButtonId.style.display = "block";
        } else {
            scene2QueryText2Id1.style.display = "block";
            scene2QueryText2Id2.style.display = "none";
            scene2AddButtonId.style.display = "none";
        }
    }

    const tabsSceneChange = async (tab) => {
        let scene2LeftId = document.getElementById('scene2LeftId');
        let scene2RightId = document.getElementById('scene2RightId');
        let scene3LeftId = document.getElementById('scene3LeftId');
        let scene3RightId = document.getElementById('scene3RightId');
        let scenesId = document.getElementById("scenesId");
        let scene3TestId = document.getElementById("scene3TestId");
        let totalFrameId = document.getElementById("totalFrameId");
        if (tab.id === "scene1") {
            scene2LeftId.style.display = "none";
            scene2RightId.style.display = "none";
            scene3LeftId.style.display = "none";
            scene3RightId.style.display = "none";
            scene3TestId.style.display = "none";
            scenesId.style.height = "240px";
            totalFrameId.style.height = "100%";
        } else if (tab.id === "scene2") {
            scene2LeftId.style.display = "block";
            scene2RightId.style.display = "block";
            scene3LeftId.style.display = "none";
            scene3RightId.style.display = "none";
            scenesId.style.height = "240px";
            scene3TestId.style.display = "none";
            totalFrameId.style.height = "100%";
        } else if (tab.id === "scene3") {
            scene2LeftId.style.display = "none";
            scene2RightId.style.display = "none";
            scene3LeftId.style.display = "block";
            scene3RightId.style.display = "block";
            scenesId.style.height = "140px";
            scene3TestId.style.display = "block";
            totalFrameId.style.height = "auto";
        } else if (tab.id === "scene4") {
            scene2LeftId.style.display = "none";
            scene2RightId.style.display = "none";
            scene3LeftId.style.display = "none";
            scene3RightId.style.display = "none";
            scenesId.style.height = "240px";
            scene3TestId.style.display = "none";
            totalFrameId.style.height = "100%";
        } else {
            scene2LeftId.style.display = "block";
            scene2RightId.style.display = "block";
            scene3LeftId.style.display = "none";
            scene3RightId.style.display = "none";
            scenesId.style.height = "240px";
            scene3TestId.style.display = "none";
            totalFrameId.style.height = "100%";
        }
    }
    const goConsolePage = () => {
        const w = window.open('_black') //这里是打开新窗口
        w.location.href = consolePage;
    }

    const goCodePage = () => {
        const w = window.open('_black') //这里是打开新窗口
        w.location.href = codePage;
    }

    const goVdbPage = (type) => {
        const w = window.open('_black') //这里是打开新窗口
        if(type==='test1'||type==='test3'){
            w.location.href = testPressurePage95 //这样就可以跳转了
        }else {
            w.location.href = testPressurePage
        }
    }

    const goMilVusPage = () => {
        const w = window.open('_black') //这里是打开新窗口
        w.location.href = milVusUrl //这样就可以跳转了

    }

    useEffect(() => {
        (async () => {

            const sceneRes = await  querySceneConfig(14,3)
            if(sceneRes.data.success){
                let datas = sceneRes.data.data;
                if(null!=datas){
                    datas.map(function (config){
                        if(config.configKey==="question_answer_vdb_console_page_url"){
                            setConsolePage(config.configValue);
                        }
                        if(config.configKey==="question_answer_vdb_code_page_url"){
                            setCodePage(config.configValue);
                        }
                        if(config.configKey==="question_answer_test_pressure_monitor_url"){
                            setTestPressurePage(config.configValue);
                            setTestPressurePage95(config.configValue);
                        }
                        // if(config.configKey==="question_answer_test_pressure_monitor_url95"){
                        //setTestPressurePage95(config.configValue);
                        // }
                    })
                }
            }
        })()
    }, [])
    return (
        <LayoutContent>
            <LayoutContent.Header title='知识问答'>
                <Button type="pay" onClick={() => delTextData()} loading={loadingDelete}>数据恢复</Button>
            </LayoutContent.Header>
            <div style={{height: '100%'}} id="totalFrameId">
                <div id="sceneLeftId"
                     style={{width: "50%", float: "left", height: "100%", overflow: "auto", display: "block"}}>
                    <div className="tableQuestionAnswer">
                        <div className="title">场景简介</div>
                        <Card style={{height: "240px", overflow: "auto"}} id="scenesId">
                            <Card.Body>
                                <Tabs tabs={tabs} onActive={(tab) => tabsSceneChange(tab)}>

                                    {/*<Card.Body>场景简介：演示VDB对比自建Milvus的性能和成本优势。*/}
                                    {/*</Card.Body>*/}
                                    {/*<Card.Body>操作方式：在等同的数据量下，对VDB和自建Milvus加载同样的请求压力：1）展示两侧的QPS和延迟对比；2）用表格计算两侧的价格对比。*/}
                                    {/*</Card.Body>*/}
                                    {/*</TabPanel>*/}
                                    <TabPanel id="scene2">
                                            <Card.Body>场景名：文本Embedding、插入和检索</Card.Body>
                                            <Card.Body>场景简介：用户可以上传示范文本、自己的文本或者Json文本，向量数据库进行自动Embedding并存储，用户可以通过精确检索、相似度检索、混合检索的方法检索该文本。</Card.Body>
                                            <Card.Body>Embedding介绍：腾讯云向量数据库（Tencent Cloud VectorDB）通过 HTTP API
                                                将非结构化文本数据送入向量数据库，向量数据库将原始文本数据转交给 Embedding
                                                模型进行向量化，再将转换后的向量数据以及原始文本一并存储在向量数据库中。其整个实现架构，如右图所示。
                                                本演示中采用bge-base-zh Embedding模型。
                                            </Card.Body>
                                    </TabPanel>
                                    <TabPanel id="scene3">
                                            <Card.Body>场景名：VDB对比自建Milvus</Card.Body>
                                            <Card.Body>场景简介：演示VDB对比自建Milvus的性能和成本优势。
                                            </Card.Body>
                                            <Card.Body>操作方式：在等同的数据量下，对VDB和自建Milvus加载同样的请求压力：1）展示两侧的QPS和延迟对比；2）计算两侧的价格对比。
                                            </Card.Body>
                                    </TabPanel>
                                    {/*<TabPanel id="scene4">*/}
                                    {/*<Card.Body>场景简介：演示端到端的针对腾讯云产品的智能问答场景。*/}
                                    {/*</Card.Body>*/}
                                    {/*<Card.Body>操作方式：提供对话框，用户在对话框输入问题，点击确认，返回腾讯云产品相关的解答。</Card.Body>*/}


                                </Tabs>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="tableQuestionAnswer" id="scene2LeftId" style={{display: "block"}}>
                        <div className="title">文本数据写入</div>
                        <Card>
                            <Card.Body style={{height: "480px", overflowX: "hidden", overflowY: "auto"}}>
                                <Tabs tabs={tabs1} onActive={(tab) => tabsChange(tab)}>
                                    <TabPanel id="scene2Text1">
                                        <div className="title">示例文本</div>
                                        <Card.Body> {scene2Text1}</Card.Body>
                                        <Card.Body>
                                            <button
                                                style={{marginLeft: "10px", marginTop: "20px", float: "right"}}
                                                onClick={() => addText(scene2Text1)} disabled={loadingAddText}>存储示例文本
                                            </button>
                                        </Card.Body>
                                        <div className="title" style={{marginTop: "50px"}}>自定义文本</div>
                                        <Card.Body>
                                            <TextArea style={{height: "100px", whiteSpace: "pre-wrap"}}
                                                      showCount
                                                      size="full"
                                                      value={autoText}
                                                      onChange={(value, context) => {
                                                          setAutoText(value);
                                                          console.log(value, context);
                                                      }}
                                                      placeholder="输入400字内的文本内容"
                                                      disabled={disabled}
                                                      readonly={readonly}
                                                      onPressEnter={console.log}
                                                      maxLength="400"
                                            />
                                        </Card.Body>
                                        <Card.Body>
                                            <button style={{marginLeft: "10px", marginTop: "10px", float: "right"}}
                                                    onClick={() => addText(autoText)} disabled={loadingAddText}>存储自定义文本
                                            </button>
                                        </Card.Body>

                                    </TabPanel>
                                    <TabPanel id="scene2Text2">
                                        <Card.Body>
                                            <TextArea style={{whiteSpace: "pre-wrap", height: "350px"}}
                                                      showCount
                                                      size="full"
                                                      value={scene2Text2}
                                                      placeholder="返回结果"
                                                      disabled={disabled}
                                                      readonly={readonly}
                                            />
                                        </Card.Body>
                                        <Card.Body>
                                            <button style={{marginLeft: "10px", marginTop: "10px", float: "right"}}
                                                    onClick={() => addJsonText(scene2Text2)}
                                                    disabled={loadingAddJson}>存储JSON文本
                                            </button>
                                        </Card.Body>
                                    </TabPanel>
                                </Tabs>
                            </Card.Body>
                        </Card>
                        <Card.Body style={{marginTop: "10px"}}>
                            <button onClick={() => goCodePage()}>查看示例代码</button>
                            <button style={{marginLeft: "20px"}}
                                    onClick={() => goConsolePage()}>DMC数据库管理控制台
                            </button>
                        </Card.Body>
                    </div>

                    <div className="tableQuestionAnswer" id="scene3LeftId" style={{display: "none"}}>

                        <div className="questionAnswerScene3TypeTitle">自建Milvus</div>
                        <Card>
                            <Card.Body>
                                <div style={{display: 'flex', height: '380px'}}>
                                    <div className="questionAnswerScene3DivTitle" style={{width: '8%', height: '100%'}}>
                                        <p>架构</p>
                                        <p>对比</p>
                                    </div>
                                    <div style={{width: '92%'}}>
                                        <img width='100%' alt="架构对比" height='380px'
                                             src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/question_answer/scene3_01.jpg"/>
                                    </div>
                                </div>

                            </Card.Body>


                            <Card.Body>
                                <div style={{display: 'flex', gap: '10px', height: '70px'}}>
                                    <div className="questionAnswerScene3DivTitle" style={{width: '8%', height: '100%'}}>
                                        <p>规格</p>
                                        <p>对比</p>
                                    </div>
                                    <div style={{width: '92%', border: '2px solid #ccc', padding: '10px'}}>
                                        {/*<p style={{textAlign:"center",fontWeight:"bold"}}>3*4C8G</p>*/}
                                        <p style={{textAlign:"center",fontWeight:"bold"}}>广州地域</p>
                                        <p style={{textAlign:"center",fontWeight:"bold"}}>采用腾讯云TKE，集群规格为L5</p>
                                        <p style={{textAlign:"center",fontWeight:"bold"}}>Worker节点：3*标准型 S6.LARGE16 4核16G</p>
                                    </div>
                                </div>
                            </Card.Body>


                            <Card.Body>
                                <div style={{display: 'flex', gap: '10px', height: '70px'}}>
                                    <div className="questionAnswerScene3DivTitle" style={{width: '8%', height: '100%'}}>
                                        <p>成本</p>
                                        <p>对比</p>
                                        <p>（元/月）</p>
                                    </div>
                                    <div style={{width: '92%', border: '2px solid #ccc', padding: '10px'}}>
                                        {/*<p style={{textAlign:"center",fontWeight:"bold"}}>2960</p>*/}
                                        <p style={{textAlign:"center",fontWeight:"bold"}}>CVM+容器服务价格：2960元/月

                                        </p>
                                        <p style={{textAlign:"center",fontWeight:"bold"}}>
                                            （未计算硬盘、CLB及流量费用）
                                        </p>
                                    </div>
                                </div>
                            </Card.Body>


                            {/*<Card.Body>*/}
                            {/*    <div style={{display:'flex', gap:'10px',height:'80px'}}>*/}
                            {/*        <div className="questionAnswerScene3DivTitle" style={{width:'8%',height:'100%'}}>*/}
                            {/*            <p>性能</p>*/}
                            {/*            <p>对比</p>*/}
                            {/*        </div>*/}
                            {/*        <div style={{width:'30%',border: '2px solid #ccc', padding: '10px' }}>*/}

                            {/*        </div>*/}
                            {/*        <div style={{width:'30%',border: '2px solid #ccc', padding: '10px' }}>*/}

                            {/*        </div>*/}
                            {/*        <div style={{width:'30%',border: '2px solid #ccc', padding: '10px' }}>*/}

                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</Card.Body>*/}

                        </Card>
                    </div>

                    {/*<div className="tableQuestionAnswer" id="scene4LeftId" style={{display: "none"}}>*/}

                    {/*    <div className="title">场景4</div>*/}

                    {/*</div>*/}

                    {/*<div className="tableQuestionAnswer" id="scene3LeftId" style={{display: "none"}}>*/}

                    {/*    <div className="title">场景1</div>*/}

                    {/*</div>*/}

                </div>
                <div id="scene2RightId"
                     style={{width: "50%", float: "left", height: "100%", display: "block", overflow: "auto"}}>
                    <div className="tableQuestionAnswer">
                        <div className="title">Embedding示意图</div>
                        <Card style={{height: "240px", overflow: "auto"}}>
                            <Card.Body>
                                <img alt="Embedding示意图"
                                     src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/question_answer/questionAnswer_scene2_embedding.png"
                                     style={{width: "100%", height: "100%"}}/>
                            </Card.Body>
                        </Card>

                    </div>
                    <div className="tableQuestionAnswer">
                        <div className="title">相似性检索</div>
                        <Card id="scene2QueryText2Id1"
                              style={{height: "480px", display: "block", overflowX: "hidden", overflowY: "auto"}}>
                            <Card.Body>
                                <TextArea
                                    showCount
                                    size="full"
                                    value={text}
                                    onChange={(value, context) => {
                                        setText(value);
                                        console.log(value, context);
                                    }}
                                    placeholder="输入400字内的文本内容"
                                    disabled={disabled}
                                    readonly={readonly}
                                    onPressEnter={console.log}
                                    maxLength="400"

                                />
                                <div style={{marginTop: "10px", marginBottom: "10px"}}>
                                    <button style={{float: "right"}}
                                            type="primary" onClick={() => queryData(text)}>搜索
                                    </button>
                                    <span style={{fontWeight: "bold"}}>检索结果</span>
                                </div>
                                <TextArea style={{whiteSpace: "pre-wrap", height: "200px"}}
                                          showCount
                                          size="full"
                                          value={textResult}
                                          placeholder="检索结果"
                                          disabled={disabled}
                                          readonly={readonly}
                                />
                            </Card.Body>
                        </Card>
                        <Card id="scene2QueryText2Id2" style={{
                            height: "480px",
                            marginTop: "0px",
                            display: "none",
                            overflowX: "hidden",
                            overflowY: "auto"
                        }}>
                            <Card.Body>
                                <Tabs tabs={tabs2}>
                                    <TabPanel id="scene2QueryText1">
                                        <div className="title">filter（根据条件表达式查询）</div>
                                        <TextArea
                                            showCount
                                            size="full"
                                            value={rightFilterReq}
                                            onChange={(value, context) => {
                                                setRightFilterReq(value);
                                                console.log(value, context);
                                            }}
                                            placeholder="请输入条件表达式
                                                           举例: title='Sandbox' and timestamp <= 16872330721
                                                           注意: 必须是索引字段"
                                            disabled={disabled}
                                            readonly={readonly}
                                            onPressEnter={console.log}
                                            maxLength="400"
                                            style={{height: "50px"}}
                                        />
                                        <div className="title">主键 ID（documentIds，文档主键 ID 查询）</div>
                                        <Card.Body><Input value={rightIdReq}
                                                          onChange={(value, context) => {
                                                              setRightIdReq(value);
                                                              console.log(value, context);
                                                          }} placeholder="请输入ID"/></Card.Body>

                                        <div style={{marginTop: "10px", marginBottom: "10px"}}>
                                            <span style={{fontWeight: "bold"}}>检索结果</span>
                                            <button style={{float: "right"}}
                                                    type="primary"
                                                    onClick={() => queryJsonData(rightIdReq, rightFilterReq, "2")}>搜索
                                            </button>
                                        </div>
                                        <TextArea
                                            showCount
                                            size="full"
                                            value={textRight}
                                            placeholder="检索结果"
                                            disabled={disabled}
                                            readonly={readonly}
                                            style={{height: "160px"}}
                                        />
                                    </TabPanel>
                                    <TabPanel id="scene2QueryText2">
                                        <Tabs tabs={tabs3}>
                                            <TabPanel id="scene2QueryCommonText1">

                                                <TextArea
                                                    showCount
                                                    size="full"
                                                    value={commonContentReq}
                                                    onChange={(value, context) => {
                                                        setCommonContentReq(value);
                                                        console.log(value, context);
                                                    }}
                                                    placeholder="若已配置Embedding模型,可直接输入需要进行相似性检索的文本内容"
                                                    disabled={disabled}
                                                    readonly={readonly}
                                                    onPressEnter={console.log}
                                                    maxLength="400"
                                                    style={{height: "80px"}}


                                                />
                                                <div className="title">filter（根据条件表达式查询）</div>
                                                <TextArea
                                                    showCount
                                                    size="full"
                                                    value={commonFilterReq}
                                                    onChange={(value, context) => {
                                                        setCommonFilterReq(value);
                                                        console.log(value, context);
                                                    }}
                                                    placeholder="请输入条件表达式
                                                           举例: title='Sandbox' and timestamp <= 16872330721
                                                           注意: 必须是索引字段"
                                                    disabled={disabled}
                                                    readonly={readonly}
                                                    onPressEnter={console.log}
                                                    maxLength="400"
                                                    style={{height: "50px"}}
                                                />

                                                <div style={{marginTop: "10px", marginBottom: "10px"}}>
                                                    <span style={{fontWeight: "bold"}}>检索结果</span>
                                                    <button style={{float: "right"}}
                                                            type="primary"
                                                            onClick={() => queryJsonData(commonContentReq, commonFilterReq, "3")}>搜索
                                                    </button>
                                                </div>
                                                <TextArea
                                                    showCount
                                                    size="full"
                                                    value={textResultCommonContent}
                                                    placeholder="检索结果"
                                                    disabled={disabled}
                                                    readonly={readonly}
                                                />
                                            </TabPanel>
                                            <TabPanel id="scene2QueryCommonText2">
                                                <TextArea
                                                    showCount
                                                    size="full"
                                                    value={commonIdReq}
                                                    onChange={(value, context) => {
                                                        setCommonIdReq(value);
                                                        console.log(value, context);
                                                    }}
                                                    placeholder="请输入需要进行相似性检索的文档ID值允许一个ID"
                                                    disabled={disabled}
                                                    readonly={readonly}
                                                    onPressEnter={console.log}
                                                    maxLength="400"
                                                    style={{height: "80px"}}
                                                />
                                                <div className="title">filter（根据条件表达式查询）</div>
                                                <TextArea
                                                    showCount
                                                    size="full"
                                                    value={commonFilterReq}
                                                    onChange={(value, context) => {
                                                        setCommonFilterReq(value);
                                                        console.log(value, context);
                                                    }}
                                                    placeholder="请输入条件表达式
                                                           举例: title='Sandbox' and timestamp <= 16872330721
                                                           注意: 必须是索引字段"
                                                    disabled={disabled}
                                                    readonly={readonly}
                                                    onPressEnter={console.log}
                                                    maxLength="400"
                                                    style={{height: "50px"}}
                                                />

                                                <div style={{marginTop: "10px", marginBottom: "10px"}}>
                                                    <span style={{fontWeight: "bold"}}>检索结果</span>
                                                    <button style={{float: "right"}}
                                                            type="primary"
                                                            onClick={() => queryJsonData(commonIdReq, commonFilterReq, "4")}>搜索
                                                    </button>
                                                </div>
                                                <TextArea
                                                    showCount
                                                    size="full"
                                                    value={textResultCommonId}
                                                    placeholder="检索结果"
                                                    disabled={disabled}
                                                    readonly={readonly}
                                                />
                                            </TabPanel>
                                        </Tabs>
                                    </TabPanel>

                                </Tabs>
                            </Card.Body>
                        </Card>
                    </div>

                </div>
                <div id={"scene3RightId"}
                     style={{width: "50%", float: "left", height: "100%", display: "none", overflow: "auto"}}>
                    <div className="tableQuestionAnswer">
                        <div className="title">压测信息</div>
                        <Card>
                            <Card.Body style={{height: "140px", overflow: "auto"}}>
                                <p>● 测试工具：开源工具 ann-benchamrk
                                    是一个用于评估近似最近邻（ANN）搜索库的性能测试工具。它提供了一套标准的测试数据集和评估指标，可以用于比较不同量级数据库的性能表现。</p>
                                <p>● 数据集：采用两套数据集分别存放在不同的collection中，相互独立，用于不同的压测场景。</p>
                                <p>&nbsp;  &nbsp; 1. SIFT（1000000 *128）sift-128-euclidean</p>
                                <p>&nbsp;  &nbsp; 2. Chinese（1000000 *768）chinese-768-angular</p>
                                <p>● 索引类型：HNSW，参数取值（m=16 efConstruction=200）</p>
                                <p>● 相似性检索：余弦相似度COSINE</p>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="tableQuestionAnswer">
                        <div className="questionAnswerScene3TypeTitle1">VectorDB</div>
                        <Card>
                            <Card.Body style={{height: "390px"}}>
                                <img alt="VectorDB"
                                     src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/question_answer/scene3_02.png"
                                     width='95%' height='380px'/>
                            </Card.Body>
                            <Card.Body>
                                <div style={{height: "45px", width: '97%', border: '2px solid #ccc', padding: '10px'}}>

                                    {/*<p style={{textAlign:"center",fontWeight:"bold"}}>3*4C8G</p>*/}
                                    <p style={{textAlign:"center",fontWeight:"bold"}}>广州地域</p>
                                    <p style={{textAlign:"center",fontWeight:"bold"}}>选用计算型VDB，3 * P.MEDIUM 4核8G</p>
                                </div>
                            </Card.Body>
                            <Card.Body>
                                <div style={{height: "45px", width: '97%', border: '2px solid #ccc', padding: '10px'}}>

                                    {/*<p style={{textAlign:"center",fontWeight:"bold"}}>2580</p>*/}
                                    <p style={{textAlign:"center",fontWeight:"bold"}}>3*8*76=1824元/月</p>
                                    <p style={{textAlign:"center",fontWeight:"bold"}}>（未计算硬盘、CLB及流量费用）</p>
                                </div>
                            </Card.Body>


                        </Card>

                    </div>

                </div>

                {/*<div id={"scene4RightId"}*/}
                {/*     style={{width: "50%", float: "left", height: "100%", display: "none", overflow: "auto"}}>*/}
                {/*    <div className="tableQuestionAnswer">*/}
                {/*        <div className="title">场景4</div>*/}
                {/*    </div>*/}

                {/*</div>*/}

                {/*<div id={"scene1RightId"}*/}
                {/*     style={{width: "50%", float: "left", height: "100%", display: "none", overflow: "auto"}}>*/}
                {/*    <div className="tableQuestionAnswer">*/}
                {/*        <div className="title">场景1</div>*/}
                {/*    </div>*/}

                {/*</div>*/}


            </div>

            <div id="scene3TestId" style={{display: "none", width: "99.5%", marginTop: "2px"}}>
                <Card style={{marginLeft: "10px"}}>

                    <div style={{display: "flex", width: "99%"}}>

                        <div className="questionAnswerScene3DivTitle"
                             style={{marginLeft: "5px", width: '3.7%', height: '110px', float: "left"}}>

                            <Card.Body>
                                <p>性能</p>
                                <p>对比</p>
                            </Card.Body>
                        </div>
                        <div style={{width: "15%"}}/>
                        <div>
                            <Card.Body>
                                <section>
                                    <Radio.Group value={testData} onChange={value => setTestData(value)}>
                                        <Card.Body style={{
                                            marginLeft: "10px",
                                            float: "left",
                                            gap: '10px',
                                            padding: '8px',
                                            marginTop: "2px",
                                            height: "95px",
                                            backgroundColor: "lightsteelblue"
                                        }}>
                                            <Radio name="test1">
                                                <p>1.数据集：sift-128-euclidean </p>
                                                <p>&nbsp;&nbsp; 行数：100万行</p>
                                                <p>&nbsp;&nbsp; 维度：128维 </p>
                                                <p>&nbsp;&nbsp; 召回率：95</p>
                                                <p>&nbsp;&nbsp; 压测并发：5进程，每进程10线程</p>
                                            </Radio>
                                        </Card.Body>
                                        <Card.Body style={{
                                            marginLeft: "10px",
                                            float: "left",
                                            gap: '10px',
                                            padding: '8px',
                                            marginTop: "2px",
                                            height: "95px",
                                            backgroundColor: "lightsteelblue"
                                        }}>
                                            <Radio name="test2">
                                                <p>2.数据集：sift-128-euclidean</p>
                                                <p>&nbsp;&nbsp; 行数：100万行</p>
                                                <p>&nbsp;&nbsp; 维度：128维 </p>
                                                <p>&nbsp;&nbsp; 召回率：99</p>
                                                <p>&nbsp;&nbsp; 压测并发：5进程，每进程10线程</p>
                                            </Radio>
                                        </Card.Body>
                                        <Card.Body style={{
                                            marginLeft: "10px",
                                            float: "left",
                                            gap: '10px',
                                            padding: '8px',
                                            marginTop: "2px",
                                            height: "95px",
                                            backgroundColor: "lightsteelblue"
                                        }}>
                                            <Radio name="test3">
                                                <p>3.数据集：chinese-768-angular </p>
                                                <p>&nbsp;&nbsp; 行数：100万行</p>
                                                <p>&nbsp;&nbsp; 维度：768维 </p>
                                                <p>&nbsp;&nbsp; 召回率：95</p>
                                                <p>&nbsp;&nbsp; 压测并发：5进程，每进程10线程</p>
                                            </Radio>
                                        </Card.Body>
                                        <Card.Body style={{
                                            marginLeft: "10px",
                                            float: "left",
                                            gap: '10px',
                                            padding: '8px',
                                            marginTop: "2px",
                                            height: "95px",
                                            backgroundColor: "lightsteelblue"
                                        }}>

                                            <Radio name="test4">
                                                <p>
                                                    4.数据集：chinese-768-angular</p>
                                                <p>&nbsp;&nbsp; 行数：100万行</p>
                                                <p>&nbsp;&nbsp; 维度：768维 </p>
                                                <p>&nbsp;&nbsp; 召回率：99</p>
                                                <p>&nbsp;&nbsp; 压测并发：5进程，每进程10线程</p>
                                            </Radio>
                                        </Card.Body>

                                    </Radio.Group>

                                </section>
                            </Card.Body>
                        </div>

                        <div style={{width: "5%"}}/>
                        <div>
                            <button type="pay" style={{marginTop: "10px"}} onClick={() => testPressure(testData)}
                                    disabled={loadingTestPressure}>开始压测
                            </button>
                            <br/>
                            <button type="pay" style={{marginTop: "10px"}} onClick={() => goVdbPage(testData)}>跳转至VectorDB</button>
                            <br/>
                            <button type="pay" style={{marginTop: "10px"}} onClick={() => goMilVusPage()}>跳转至自建Milvuls</button>
                        </div>
                    </div>

                </Card>

            </div>


        </LayoutContent>
    );
}
