import React from "react";
import "tea-component/dist/tea.css";
import {
    Layout, Card,  Table, Bubble,
    Icon, H4,Tabs, TabPanel
} from "tea-component";
import './indexAcrossCloud.scss'

const { Content: LayoutContent} = Layout;




const cvmList = [
    {
        instanceId: "Region",
        instanceName: "广州",
    },
    {
        instanceId: "AZ",
        instanceName: "广州三区、广州四区",
    },
    {
        instanceId: "VPC",
        instanceName: "CIDR:10.0.0/16",
    },
    {
        instanceId: "子网 Subnet-Basic",
        instanceName: "广州三区 CIDR：10.0.4.0/24，广州四区 CIDR：10.0.1.0/24",
    },
    {
        instanceId: "子网 Subnet-K8S",
        instanceName: "广州三区 CIDR：10.0.3.0/24，广州四区 CIDR：10.0.0.0/24",
    },
    {
        instanceId: "容器网络",
        instanceName: "广州三区 CIDR：172.16.0.0/16，广州四区 CIDR：10.0.0.0/24",
    },
];

const cvmListOther = [
    {
        one: 'DNSPOD',
        two: 'N/A',
        three: '腾讯云',
        four: '域名解析服务，在两朵云或者两地域部署的应用之间，根据地域进行流量分配',
    },
    {
        one: 'Kong',
        two: 'N/A',
        three: '腾讯云',
        four: '在两地域部署腾讯云原生网关，根据业务路由规则将请求路由至多地域/多云。在本演示中根据user id的奇偶进行路由，将user id为奇数的请求路由至腾讯云上海，将user id为偶数的请求路由至腾讯云广州',
    },
    {
        one: 'CLB',
        two: 'N/A',
        three: '腾讯云',
        four: '应用接入层的负载均衡器，将业务请求转发至本地域/本云的后端应用',
    },
    {
        one: 'Nginx',
        two: 'N/A',
        three: '开源',
        four: '在其他地域/其他云自建的负载均衡器，将业务请求转发至本地域/本云的后端应用',
    },
    {
        one: 'TKE',
        two: 'N/A',
        three: '腾讯云',
        four: '部署在腾讯云广州的托管容器集群，运行Q云书城应用',
    },
    {
        one: 'Kubernetes',
        two: 'N/A',
        three: '开源',
        four: '部署在其他地域/其他云自建的开源容器集群，运行Q云书城应用',
    },
    {
        one: 'CRS',
        two: 'N/A',
        three: '腾讯云',
        four: '部署在腾讯云广州的托管Redis集群，缓存数据；双地域/双云的Redis缓存相互独立',
    },
    {
        one: 'Redis',
        two: 'N/A',
        three: '开源',
        four: '部署在其他地域/其他云自建的开源Redis集群，缓存数据',
    },
    {
        one: 'CDB for MySQL',
        two: 'N/A',
        three: '腾讯云',
        four: '部署在腾讯云广州的托管MySQL数据库，存储业务数据',
    },
    {
        one: 'CDB for MySQL',
        two: 'N/A',
        three: '腾讯云',
        four: '部署在腾讯云广州的托管MySQL数据库，存储业务数据',
    },

    {
        one: 'MySQL',
        two: 'N/A',
        three: '开源',
        four: '部署在其他地域/其他云自建的开源MySQL数据库，存储业务数据',
    },
    {
        one: 'DTS',
        two: 'N/A',
        three: '腾讯云',
        four: '在两地域/两朵云的MySQL数据库之间进行数据双向同步',
    },
    {
        one: 'TDCC',
        two: 'N/A',
        three: '腾讯云',
        four: '跨地域/跨云的多K8S统一管理',
    },
    {
        one: 'CLS',
        two: 'N/A',
        three: '腾讯云',
        four: '日志收集与存储',
    },
    {
        one: 'Prometheus',
        two: 'N/A',
        three: '腾讯云',
        four: '监控统一收集和展示',
    },
    {
        one: 'PTS',
        two: 'N/A',
        three: '腾讯云',
        four: '模拟业务请求流量',
    }

]

const cvmListOne = [
    {
        one: 'QCBM-Front',
        two: '使用 React 开发的前端项目，基于 Nginx 官方提供的 [1.19.8 Docker 镜像](https://hub.docker.com/_/nginx) 构建和部署。'
    },
    {
        one: 'QCBM-Gateway',
        two: 'API 网关，接受前端的 HTTP 请求，并将其转化为后台的 Dubbo 请求。',
    },
    {
        one: 'User-Service',
        two: '基于 Dubbo 的微服务，提供用户注册、登录、鉴权等功能。',
    },
    {
        one: 'Favorites-Service',
        two: '基于 Dubbo 的微服务，提供用户图书收藏功能。',
    },
    {
        one: 'Order-Service',
        two: '基于 Dubbo 的微服务，提供用户订单生成和查询等功能。',
    },
    {
        one: 'Store-Service',
        two: '基于 Dubbo 的微服务，提供图书信息的存储等功能。',
    },
    {
        one: 'Seckill-Service',
        two: '基于 Dubbo 的微服务，提供秒杀功能，秒杀高并发不影响其他服务。',
    }
]

const cvmListTwo = [
    {
        one: '用户注册',
        two: 'POST',
        three: '/api/auth/signup',
    },
    {
        one: '用户登录',
        two: 'POST',
        three: '/api/auth/signin',
    },
    {
        one: '查询用户',
        two: 'GET',
        three: '/api/admin/user/{userId}',
    },
    {
        one: '删除用户',
        two: 'DELETE',
        three: '/api/admin/user/delete',
    },
    {
        one: '图书列表',
        two: 'GET',
        three: '/api/book/all',
    },
    {
        one: '图书查询',
        two: 'GET',
        three: '/api/book/{isbn}',
    },
    {
        one: '购书',
        two: 'GET',
        three: '/api/book/purchase',
    },
    {
        one: '用户订单列表',
        two: 'GET',
        three: '/api/order/{userId}',
    },
    {
        one: '图书收藏',
        two: 'GET',
        three: '/api/favorites/add',
    },
    {
        one: '用户收藏列表',
        two: 'GET',
        three: '/api/favorites/query/{userId}',
    },
    {
        one: '删除用户收藏的图书',
        two: 'GET',
        three: '/api/favorites/delete',
    },
    {
        one: '秒杀列表查询接口',
        two: 'GET',
        three: '/api/bookstorage/list',
    },
    {
        one: '秒杀接口',
        two: 'GET',
        three: '/api/seckill/book?userId={userId}&isbn={isbn}',
    },
    {
        one: '订单查询接口',
        two: 'GET',
        three: '/api/order?userId={userId}&pageSize={pageSize}&pageNumber={pageNumber}',
    },

]

export default function BaseInfo() {
    const tabs = [
        {id: "basic", label: "系统架构"},
        {id: "network", label: "云原生产品和组件"},
        {id: "monitor", label: "网络规划"},
        {id: "sg", label: 'QCBM项目'},

    ];

    const getBubble = () => {
        return (
            <Bubble
                arrowPointAtCenter
                placement="top-end"
                content={`QCBM 是采用微服务架构，并使用 dubbo-2.7.8 框架开发的一个网上书城沙盒演示项目。QCBM 的部署和代码托管在 Coding，详情可参见 [QCBM 项目](https://github.com/TencentCloud/container-demo/tree/main/dubbo-on-tke)。`}

            >
                <Icon type="info" style={{marginLeft: '5px'}}/>
            </Bubble>
        )
    }

    return (
        <LayoutContent>
            <LayoutContent.Header title='基本信息'/>
            <LayoutContent.Body>
                <Card>
                    <Card.Body>
                        <Tabs tabs={tabs}>

                            <TabPanel id="basic">
                                <div className="system">

                                    <div className="img" >
                                        <img
                                            src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/across_cloud_double_live/latest/crosscloud-doublelive-run.gif"
                                            alt="" style={{width: '90%', height: '90%'}}/>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel id="network">
                                <div className="componentsStoreCalc">

                                    <Table
                                        verticalTop
                                        records={cvmListOther}
                                        recordKey="one"
                                        bordered='all'
                                        columns={[
                                            {
                                                key: "one",
                                                header: "产品/组件",
                                                align: 'left',
                                                width: '10%',
                                            },
                                            {
                                                key: "two",
                                                header: "版本",
                                                align: 'left',
                                                width: '10%',
                                            },
                                            {
                                                key: "three",
                                                header: "来源",
                                                align: 'left',
                                                width: '10%',
                                            },
                                            {
                                                key: "four",
                                                header: "备注",
                                                align: 'left',
                                                width: '70%'
                                            }

                                        ]}
                                    />
                                </div>
                            </TabPanel>
                            <TabPanel id="monitor">
                                <div className="componentsStoreCalc">

                                    <Table
                                        verticalTop
                                        records={cvmList}
                                        recordKey="instanceId"
                                        bordered='all'
                                        columns={[
                                            {
                                                key: "instanceId",
                                                header: "网络规划",
                                                align: 'left',
                                                width: '300px'
                                            },
                                            {
                                                key: "instanceName",
                                                header: "可用区域",
                                                align: 'left'
                                            },
                                        ]}
                                    />
                                </div>
                            </TabPanel>
                            <TabPanel id="sg">
                                <div className="project">
                                    <div className="title">
                                        QCBM 是采用微服务架构，并使用 dubbo-2.7.8 框架开发的一个网上书城沙盒演示项目。QCBM 的部署和代码托管在 Coding，详情可参见
                                        [QCBM
                                        项目](https://github.com/TencentCloud/container-demo/tree/main/dubbo-on-tke)。
                                    </div>
                                    <H4 style={{margin: '20px auto 10px'}}>QCBM 首页如下图展所示：</H4>
                                    <div className="img" style={{
                                        width: '100%',
                                        height: '600px',
                                        margin: '0 auto',
                                        boxSizing: 'border-box',
                                        padding: '10px',
                                    }}>

                                        <img src="https://main.qcloudimg.com/raw/958f718bb6e1656449e8bcdd9dd88ae2.png"
                                             alt="" style={{width: '100%', height: '100%'}}/>
                                    </div>
                                    <H4 style={{margin: '20px auto 10px'}}>QCBM 包含以下微服务：</H4>
                                    <Table
                                        verticalTop
                                        records={cvmListOne}
                                        recordKey="one"
                                        bordered='all'
                                        columns={[
                                            {
                                                key: "one",
                                                header: "微服务",
                                                align: 'left',
                                                width: '300px'
                                            },
                                            {
                                                key: "two",
                                                header: "说明",
                                                align: 'left',
                                                render: function getTwo(cvmListOne) {

                                                    return (
                                                        <p>{cvmListOne.two}</p>
                                                    )
                                                }
                                            },

                                        ]}
                                    />
                                    <H4 style={{margin: '20px auto 10px'}}>QCBM-Gateway 提供了如下 API 接口：</H4>
                                    <Table
                                        verticalTop
                                        records={cvmListTwo}
                                        recordKey="one"
                                        bordered='all'
                                        columns={[
                                            {
                                                key: "one",
                                                header: "API Function",
                                                align: 'left',
                                                width: '300px'
                                            },
                                            {
                                                key: "two",
                                                header: "Method",
                                                align: 'left',
                                                width: '200px'
                                            },
                                            {
                                                key: "three",
                                                header: "Path",
                                                align: 'left'
                                            },

                                        ]}
                                    />
                                </div>
                            </TabPanel>

                        </Tabs>


                    </Card.Body>
                </Card>
            </LayoutContent.Body>
        </LayoutContent>
    );
}
