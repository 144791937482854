import React, {useEffect, useState} from "react";
import "tea-component/dist/tea.css";
import {
    Card,  Layout, Button, Input, Select, message
} from "tea-component";


import {addUser} from "../../../services/function";



const {Content: LayoutContent} = Layout;
//本地测试“npm install crypto-js” 公网直接引比较快的库，或者直接下载js打包到项目
const CryptoJS = require('crypto-js');

/**
 * aes加密 不带偏移量参数
 * @param word
 * @param aesKey
 * @returns {string}
 */
function encrypt(word,aesKey){
    let key = CryptoJS.enc.Utf8.parse(aesKey);
    let srcs = CryptoJS.enc.Utf8.parse(word);
    let encrypted = CryptoJS.AES.encrypt(srcs, key, {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7});
    return encrypted.toString();
}

// /**
//  * 解密 不带偏移量参数
//  * @param word
//  * @param aesKey
//  * @returns {*}
//  */
// function decrypt(word,aesKey){
//     var key = CryptoJS.enc.Utf8.parse(aesKey);
//     var decrypt = CryptoJS.AES.decrypt(word, key, {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7});
//     return CryptoJS.enc.Utf8.stringify(decrypt).toString();
// }



export default function AddUserFunction() {
    const [roleValue, setRoleValue] = useState("USER")
    const selectRoleList = [
        {value: "USER", text: "USER", tooltip: "USER"},
        {value: "ADMIN", text: "ADMIN", tooltip: "ADMIN"},
    ]
    const submitChange = async () => {
        let key = "987sdw2wer888fff";//秘钥
        let userName = document.getElementById("userName").value;
        let password = document.getElementById("password").value;
        let rePassword = document.getElementById("rePassword").value;
        if (null===userName || userName === 'undefined' || userName === "") {
            message.error({
                content: "请输入用户名，用户名不能为空"
            })
            return;
        }
        if (null == password || password === 'undefined' || password === "") {
            message.error({
                content: "请输入密码，密码不能为空"
            })
            return;
        }
        if (null == rePassword || rePassword === 'undefined' || rePassword === "") {
            message.error({
                content: "请输入确认密码，确认密码不能为空"
            })
            return;
        }

        if (password !==rePassword) {
            message.error({
                content: "请重新输入密码，密码和确认密码不一致"
            })
            return;
        }
         password =encodeURIComponent( encrypt(password, key));
         rePassword = encodeURIComponent(encrypt(rePassword, key));
         userName = encodeURIComponent(encrypt(userName, key));
         let  role = encodeURIComponent(encrypt(roleValue,key));
        const res = await addUser(userName,password,rePassword,role)
        if (res.data.success) {
            message.success({
                content: res.data.msg
            })
        } else {
            message.error({
                content: res.data.msg
            })
        }
        if (res.data && res.data.length > 200 && res.data.indexOf("Please sign in") !== -1) {
            window.location.href = "/login"
        }


    }
    useEffect(() => {
        (async () => {


        })()

    }, [])
    return (
        <LayoutContent>
            <LayoutContent.Header title='新增用户'>
            </LayoutContent.Header>

            <Card style={{width: "100%", height: "100%",textAlign:"left"}}>
                <Card.Body>
                   <span style={{marginLeft:"30%",marginRight:"20px"}}>用户名：</span><Input
                    placeholder="请输入用户名"
                    maxLength="20" size="m"
                    id="userName"
                    name="userName"/>
                </Card.Body>
                <Card.Body>
                    <span style={{marginLeft:"30%",marginRight:"25px"}} >角  色：</span> <Select
                    appearance="button"
                    options={selectRoleList}
                    value={roleValue}
                    onChange={value => setRoleValue(value)}
                    size="auto"
                />
                </Card.Body>
                <Card.Body>
                    <span style={{marginLeft:"30%",marginRight:"28px"}}>密   码：</span><Input type="password"
                    placeholder="请输入密码"
                    maxLength="20" size="m"
                    id="password"
                    name="password"/>
                </Card.Body>
                <Card.Body>
                    <span style={{marginLeft:"30%",marginRight:"8px"}}>确认密码：</span><Input type="password"
                    placeholder="请输入确认密码"
                    maxLength="20" size="m"
                    id="rePassword"
                    name="rePassword"/>
                </Card.Body>
                <Card.Body>

                    <Button onClick={() => submitChange()} style={{marginLeft: "45%", marginTop: "30px"}}
                            type="pay">新增</Button>
                </Card.Body>

            </Card>

        </LayoutContent>
    );
}
