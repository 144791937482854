import React, {useEffect, useState} from "react";
import "tea-component/dist/tea.css";
import {
    Layout,
    Input,
    List,
    Button,
    Modal,
    message,
    Card,
    Tabs,
    TabPanel,
} from "tea-component";
import './index.scss'
import {increasePodNumF,modifyPodNum,getDeploymentTaskResult,switchKong,getResourceInfo,seckillRecover,seckillStart,seckillList,updateKong} from '../../../services/bookStorage'
import {getFormatDate} from "../../../utils/getFormatDate";
const {Content: LayoutContent} = Layout;


export default function BigPromoteFunction() {
    const [increasePod, setIncreasePod] = useState([])
    const [modalVisible1, setModalVisible1] = useState(false)
    const [visible, setVisible] = useState(false)
    const [resourceInfo,setResourceInfo] = useState([])
    const [isLoadingSec1, setLoadingSec1] = useState(false)
    const [isLoadingSec2, setLoadingSec2] = useState(false)
    const [isLoadingSec3, setLoadingSec3] = useState(false)
    const [isLoadingSec4, setLoadingSec4] = useState(false)
    const [isLoadingUpdateKong, setLoadingUpdateKong] = useState(false)
    const tabs = [
        {id: "scene1", label: "场景1"},
        {id: "scene2", label: "场景2"},
        {id: "scene3", label: "场景3"},
        {id: "scene4", label: "场景4"}
    ];

    const [sence1Url, setSence1Url] = useState("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/seckill/seckill_sence1.gif")
    const [sence2Url, setSence2Url] = useState("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/seckill/seckill_sence2.gif")
    const [sence3Url, setSence3Url] = useState("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/seckill/seckill_sence3.gif")
   // const [sence4Url, setSence4Url] = useState("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/qcbm-console-s2-seckill-hpc.png")

    let pollTaskResultInterval=-1
    function diffSecond(start, end) {
        if(null===end||'undefined'===end){
            return 0;
        }
        if(null===start||'undefined'===start){
            start=getFormatDate();
        }
        const startTime = new Date(start);
        const endTime = new Date(end);
        return Math.abs(startTime - endTime) / 1000; // 得到相差的秒数;
    }
    const[list,setList]=useState([
        {
            timeStr:getFormatDate(),
            frontTips:'开始扩缩容front-test,该过程可能需要几分钟，请稍后',
            orderTips:'开始扩缩容order-service-test,该过程可能需要几分钟，请稍后',
            gatewayTips:'开始扩缩容qcbm-gateway-test,该过程可能需要几分钟，请稍后',
            secKillTips:"开始扩缩容secKill-service-test,该过程可能需要几分钟，请稍后",
            storeTips:"开始扩缩容store-service-test,该过程可能需要几分钟，请稍后",
            timeFrontNum:"0秒",
            timeOrderNum:"0秒",
            timeGatewayNum:"0秒",
            timeSecKillNum:"0秒",
            timeStoreNum:"0秒",
            frontEndTime:getFormatDate(),
            orderEndTime:getFormatDate(),
            gatewayEndTime:getFormatDate(),
            secKillEndTime:getFormatDate(),
            storeEndTime:getFormatDate(),
        },

    ])

    const [topList,setTopList]=useState({
        frontTips:'开始扩缩容front-test,该过程可能需要几分钟，请稍后',
        orderTips:'开始扩缩容order-service-test,该过程可能需要几分钟，请稍后',
        gatewayTips:'开始扩缩容qcbm-gateway-test,该过程可能需要几分钟，请稍后',
        secKillTips:"开始扩缩容secKill-service-test,该过程可能需要几分钟，请稍后",
        storeTips:"开始扩缩容store-service-test,该过程可能需要几分钟，请稍后",
    })

    const close = () => {
        setVisible(false)
        setTopList({
            frontTips:'开始扩缩容front-test,该过程可能需要几分钟，请稍后',
            orderTips:'开始扩缩容order-service-test,该过程可能需要几分钟，请稍后',
            gatewayTips:'开始扩缩容qcbm-gateway-test,该过程可能需要几分钟，请稍后',
            secKillTips:"开始扩缩容secKill-service-test,该过程可能需要几分钟，请稍后",
            storeTips:"开始扩缩容store-service-test,该过程可能需要几分钟，请稍后",
        })
        setList([
            {
                frontTips:'开始扩缩容front-test,该过程可能需要几分钟，请稍后',
                orderTips:'开始扩缩容order-service-test,该过程可能需要几分钟，请稍后',
                gatewayTips:'开始扩缩容qcbm-gateway-test,该过程可能需要几分钟，请稍后',
                secKillTips:"开始扩缩容secKill-service-test,该过程可能需要几分钟，请稍后",
                storeTips:"开始扩缩容store-service-test,该过程可能需要几分钟，请稍后",
            },

        ])
    };
    const changeincreasePod = (value,item)=>{
        let temp = {}
        increasePod[item] = value
        for(let i in increasePod) {
            temp[i] = increasePod[i]
        }
        setIncreasePod(temp)
    }

    // const setIncreasePodDate = async () => {
    //     const increaseDate = await getK8sDeployment()
    //     if(increaseDate.status===200){
    //         setIncreasePod(increaseDate.data);
    //     }
    //     if(increaseDate.data&&increaseDate.data.length>200&&increaseDate.data.indexOf("Please sign in")!=-1) {
    //         window.location.href="/login"
    //     }
    // }


    const submitPodNumChange = async () => {
        let r = /^[0-9]*[1-9][0-9]*$/
        if(increasePod.frontNum=== null||increasePod.frontNum==='undefined'||increasePod.frontNum===""||!r.test(increasePod.frontNum)){
            setModalVisible1(true)
            message.error({
                content:"修改失败，参数不能为空或参数值不为正整数"
            })
            return ;
        }
        if(increasePod.orderNum=== null||increasePod.orderNum==='undefined'||increasePod.orderNum===""||!r.test(increasePod.orderNum)){
            setModalVisible1(true)
            message.error({
                content:"修改失败，参数不能为空或参数值不为正整数"
            })
            return ;
        }

        if(increasePod.gateWayNum=== null||increasePod.gateWayNum==='undefined'||increasePod.gateWayNum===""||!r.test(increasePod.gateWayNum)){
            setModalVisible1(true)
            message.error({
                content:"修改失败，参数不能为空或参数值不为正整数"
            })
            return ;
        }

        if(increasePod.secKillNum=== null||increasePod.secKillNum==='undefined'||increasePod.secKillNum===""||!r.test(increasePod.secKillNum)){
            setModalVisible1(true)
            message.error({
                content:"修改失败，参数不能为空或参数值不为正整数"
            })
            return ;
        }

        if(increasePod.storeNum=== null||increasePod.storeNum==='undefined'||increasePod.storeNum===""||!r.test(increasePod.storeNum)){
            setModalVisible1(true)
            message.error({
                content:"修改失败，参数不能为空或参数值不为正整数"
            })
            return ;
        }
        const res = await modifyPodNum(increasePod)
        if(res.data.success){
            list[0].timeStr=getFormatDate();
            setVisible(true)
            setModalVisible1(false)
            pollTaskResultInterval=setInterval(() => {
                getTaskResultData(res.data.taskId)

            }, 3000);

        }else{
            if(res.data&&res.data.length>200&&res.data.indexOf("Please sign in")!==-1) {
                window.location.href="/login"
            }
            message.error({
                content: res.data.msg,
            })
        }
    }

    //
    // const increaseClbBand= async() =>{
    //     const res = await modifyClbBand(1024)
    //     if(res.data.success){
    //         message.success({
    //             content:"CLB扩容成功"
    //         })
    //     }
    //     else if(res.data&&res.data.length>200&&res.data.indexOf("Please sign in")!=-1) {
    //         window.location.href="/login"
    //     }
    //     else {
    //         message.error({
    //             content:"CLB扩容失败"
    //         })
    //     }
    //
    // }

    // const reduceClbBand= async() =>{
    //     const res = await modifyClbBand(5)
    //     if(res.data.success){
    //         message.success({
    //             content:"CLB缩容成功"
    //         })
    //     }
    //     else if(res.data&&res.data.length>200&&res.data.indexOf("Please sign in")!=-1) {
    //         window.location.href="/login"
    //     }
    //     else {
    //         message.error({
    //             content:"CLB缩容失败"
    //         })
    //     }
    //
    // }

    // const getClbBandValue= async() =>{
    //     const res = await getClbBand()
    //     if(res.data.success){
    //         message.success({
    //             content:res.data.msg
    //         })
    //     }
    //     else if(res.data&&res.data.length>200&&res.data.indexOf("Please sign in")!=-1) {
    //         window.location.href="/login"
    //     }
    //     else {
    //         message.error({
    //             content:"CLB带宽查询失败"
    //         })
    //     }
    //
    // }

    const refreshPage = () =>{
        window.location.reload();
    }


    // const goBackPage = () => {
    //     window.history.back()
    // }
    const goTxyLogin = () => {

        const w = window.open('_black') //这里是打开新窗口
        w.location.href = 'https://cloud.tencent.com/login'//这样就可以跳转了
    }

    const goYunShuCheng= () => {

        const w = window.open('_black') //这里是打开新窗口
        w.location.href = 'http://qcbm.yunnative.com/seckill' //这样就可以跳转了
    }

    // 获取扩缩容数据
    const getIncreasePodData=async ()=>{
        setLoadingSec3(true)
        const res=await increasePodNumF()
        setLoadingSec3(false)
        if(res.data.success){
            setSence3Url("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/seckill/seckill_sence4.gif")
            const podRes= await  getResourceInfo();
            setResourceInfo(podRes.data.data);
            list[0].timeStr=getFormatDate();
            setVisible(true)
            pollTaskResultInterval=setInterval(() => {
                getTaskResultData(res.data.taskId)

            }, 3000);

        }else{
            if(res.data&&res.data.length>200&&res.data.indexOf("Please sign in")!==-1) {
                window.location.href="/login"
            }
            message.error({
                content: res.data.msg,
            })
        }

    }

    // const getReducePodData=async ()=>{
    //     const res=await reducePodNumF()
    //     if(res.data.success){
    //         list[0].timeStr=getFormatDate();
    //         setVisible(true)
    //         pollTaskResultInterval=setInterval(() => {
    //             getTaskResultData(res.data.taskId)
    //
    //         }, 3000);
    //
    //     }else{
    //         if(res.data&&res.data.length>200&&res.data.indexOf("Please sign in")!=-1) {
    //             window.location.href="/login"
    //         }
    //         message.warning({
    //             content: res.data.msg,
    //         })
    //     }
    //
    // }
    // 获取秒杀任务进度
    const getTaskResultData=async (currentTaskId)=>{
        let listOne=list
        const res=await getDeploymentTaskResult(currentTaskId)
        if(res.data.data.status===2||res.data.data.status===3){
            clearInterval(pollTaskResultInterval)
            pollTaskResultInterval = -1;
        }
        listOne.push({
            timeStr:res.data.data.timeStr,
            frontEndTime:res.data.data.frontEndTime,
            orderEndTime:res.data.data.orderEndTime,
            gatewayEndTime:res.data.data.gatewayEndTime,
            secKillEndTime:res.data.data.secKillEndTime,
            storeEndTime:res.data.data.storeEndTime,

            frontTips:res.data.data.frontTips,
            orderTips:res.data.data.orderTips,
            storeTips:res.data.data.storeTips,
            secKillTips:res.data.data.secKillTips,
            gatewayTips:res.data.data.gatewayTips,
            timeNum:diffSecond(list[0].timeStr,res.data.data.timeStr)+"秒",
            timeFrontNum:diffSecond(list[0].timeStr,res.data.data.frontEndTime)+"秒",
            timeGatewayNum:diffSecond(list[0].timeStr,res.data.data.gatewayEndTime)+"秒",
            timeOrderNum:diffSecond(list[0].timeStr,res.data.data.orderEndTime)+"秒",
            timeSecKillNum:diffSecond(list[0].timeStr,res.data.data.secKillEndTime)+"秒",
            timeStoreNum:diffSecond(list[0].timeStr,res.data.data.storeEndTime)+"秒",
        })
        setList(listOne)
        setTopList({
            frontTips:res.data.data.frontTips,
            gatewayTips:res.data.data.gatewayTips,
            orderTips:res.data.data.orderTips,
            secKillTips:res.data.data.secKillTips,
            storeTips:res.data.data.storeTips,
        })
        let div=document.getElementById('podbox_black')
        if(null!=div){
        div.scrollTop=div.scrollHeight
        }

    }

    const switchKongOn= async() =>{
        setLoadingSec2(true)
        //document.getElementById('kongGateway').src = "https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/qcbm-flow-normal.gif";
        setSence2Url("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/seckill/seckill_sence3.gif")
        const res = await switchKong("on");
        setLoadingSec2(false)
        if(res.data.success){
            const kongRes= await getResourceInfo();
            setResourceInfo(kongRes.data.data);
            if(kongRes.data.data.kong==='已限流'){
                setSence2Url("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/seckill/seckill_sence3.gif");
            }
            if(kongRes.data.data.pod==='已扩容'){
                setSence3Url("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/seckill/seckill_sence4.gif");
            }
            message.success({
                content:"开启(云原生网关)限流成功"
            })
        }
        else if(res.data&&res.data.length>200&&res.data.indexOf("Please sign in")!==-1) {
            window.location.href="/login"
        }
        else {
            message.error({
                content:"开启(云原生网关)限流失败"
            })
        }

    }

    const updateKongValue= async() =>{
        setLoadingUpdateKong(true)
        const res = await updateKong();
        setLoadingUpdateKong(false)
        if(res.data.success){
            const kongRes= await getResourceInfo();
            setResourceInfo(kongRes.data.data);
            message.success({
                content:"调整(云原生网关)限流值成功"
            })
        }
        else if(res.data&&res.data.length>200&&res.data.indexOf("Please sign in")!==-1) {
            window.location.href="/login"
        }
        else {
            message.error({
                content:"调整(云原生网关)限流值失败"
            })
        }

    }



    //秒杀恢复服务
    const seckillRecovery= async() =>{
        setLoadingSec4(true)
        const res = await seckillRecover();
        setLoadingSec4(false)
        if(res.data.success){
            setSence1Url("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/seckill/seckill_sence1.gif");
            message.success({
                content:res.data.msg
            })
        }
        else if(res.data&&res.data.length>200&&res.data.indexOf("Please sign in")!==-1) {
            window.location.href="/login"
        }
        else {
            message.error({
                content:res.data.data
            })
        }
        const recoverRes= await getResourceInfo();
        setResourceInfo(recoverRes.data.data);
        if(recoverRes.data.data.kong==='已限流'){
            setSence2Url("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/seckill/seckill_sence3.gif");
        }else{
            setSence2Url("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/seckill/seckill_sence2.gif");
        }
        if(recoverRes.data.data.pod==='已扩容'){
            setSence3Url("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/seckill/seckill_sence4.gif");
        }else{
            setSence3Url("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/seckill/seckill_sence3.gif");
        }


    }

    //pts秒杀开始
    const ptsStart= async() =>{
        setLoadingSec1(true)
        const res = await seckillStart();
        setLoadingSec1(false)
        if(res.data.success){
            setSence1Url("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/seckill/seckill_sence2.gif");
            message.success({
                content:res.data.msg
            })
        }
        else if(res.data&&res.data.length>200&&res.data.indexOf("Please sign in")!==-1) {
            window.location.href="/login"
        }
        else {
            message.error({
                content:res.data.msg
            })
        }
        const recoverRes= await getResourceInfo();
        setResourceInfo(recoverRes.data.data);
        if(recoverRes.data.data.kong==='已限流'){
            setSence2Url("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/seckill/seckill_sence3.gif");
        }
        if(recoverRes.data.data.pod==='已扩容'){
            setSence3Url("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/seckill/seckill_sence4.gif");
        }
    }


    useEffect(() => {
        (async () => {
         const res= await getResourceInfo();
         setResourceInfo(res.data.data);
         if(res.data.data.kong==='已限流'){
             setSence2Url("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/seckill/seckill_sence3.gif");
         }
         if(res.data.data.pod==='已扩容'){
             setSence3Url("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/seckill/seckill_sence4.gif");
         }
         const secKillRes = await seckillList()
            console.log("打印："+secKillRes.data.taskId)
             if(secKillRes.data.taskId>0){
                 setSence1Url("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/seckill/seckill_sence2.gif");
             }

        })()
    }, [])
    return (
        <LayoutContent>
            <LayoutContent.Header title='操作'>
                <Button  onClick={()=>seckillRecovery()}   type="primary" loading={isLoadingSec4}>秒杀复原</Button>
                <Button  onClick={()=>ptsStart()} type="pay" loading={isLoadingSec1}>开启秒杀</Button>
                <Button  onClick={()=>switchKongOn()} type="pay" loading={isLoadingSec2}>开启(云原生网关)限流</Button>
                <Button  onClick={()=>getIncreasePodData()} type="pay" loading={isLoadingSec3}>快速扩容</Button>
                <Button  onClick={()=>updateKongValue()} type="pay" loading={isLoadingUpdateKong}>调整(云原生网关)限流值</Button>
                <Button  type="weak" style={{border: 0}}>
                    <span style={{marginTop:"10px",marginBottom:"10px" }}> 秒杀带宽：{resourceInfo.clb}&nbsp;|</span>
                     <span style={{marginTop:"10px",marginBottom:"10px", marginLeft: "5px" }}> 限流网关：{resourceInfo.kong} &nbsp;|</span>
                    <span  style={{marginTop:"10px",marginBottom:"10px"}}> 秒杀服务：{resourceInfo.pod}</span>
                </Button>

                <Button style={{float: "right"}} onClick={() => goTxyLogin()} type="weak">登录腾讯云</Button>
                <Button style={{float: "right"}} onClick={() => refreshPage()} type="weak">刷新</Button>
                <Button style={{float: "right"}} onClick={() => goYunShuCheng()} type="weak">跳转云书城</Button>

            </LayoutContent.Header>

            <div id="main">
                <div style={{overflow: "scroll"}}>
                    <div className="tableSecKill">
                        <div className="title">场景简介</div>
                        <Card>
                            <Card.Body>
                                <Tabs tabs={tabs}>
                                <TabPanel id="scene1">
                                        <Card.Body>背景介绍：为保证秒杀活动开始时触达更多客户，电商一般会进行活动预热，网站用户访问量增加 </Card.Body>
                                        <Card.Body>场景描述：请求量开始增大，逐渐超出服务承载能力。不具备高并发能力的系统，开始产生4xx，5xx错误，甚至网站整体崩溃，服务不可用</Card.Body>
                                        <Card.Body>推荐产品：云压测，CLS，云监控，Prometheus，Grafana</Card.Body>
                                        <Card.Body><img src={sence1Url} alt="" style={{  width: '100%', height: '100%' }} /></Card.Body>
                                </TabPanel>
                                <TabPanel id="scene2" >
                                        <Card.Body>背景介绍：秒杀活动开始，客户开始抢购，瞬时海量流量到来 </Card.Body>
                                        <Card.Body>场景描述：当瞬时高并发流量到来时，通过配置云原生网关的分布式限流阈值，对可承载范围内的请求量，提供正常的访问体验，避免服务崩溃</Card.Body>
                                        <Card.Body>推荐产品：云原生网关Kong，Redis（分布式限流）</Card.Body>
                                        <Card.Body><img  src={sence2Url} alt="" style={{ width: '100%', height: '100%'}} /></Card.Body>
                                </TabPanel>

                                <TabPanel id="scene3">

                                        <Card.Body>背景介绍：客户抱怨抢购失败，在库存充足的前提下，允许更多客户成功访问 </Card.Body>
                                        <Card.Body>场景描述：通过容器集群快速扩容能力，快速提升系统并发承载能力，扩大限流阈值，为更多的客户提供访问，提升商业口碑</Card.Body>
                                        <Card.Body>推荐产品：TKE，EKS</Card.Body>
                                        <Card.Body><img src={sence3Url} alt="" style={{  width: '100%', height: '100%' }} /></Card.Body>
                                </TabPanel>

                                <TabPanel id="scene4">
                                        <Card.Body>背景介绍：真实的大促活动前，会配置在预定的时间，采用容器集群定时+自动扩缩容方法（HPC+HPA），对服务进行自动伸缩</Card.Body>
                                        <Card.Body>场景描述：秒杀活动开启前，通过腾讯云HPC能力，配置扩容生效时间，辅以HPA自动能力，在提升承载力的同时，提高资源利用率，无需资源预留</Card.Body>
                                        <Card.Body>推荐产品：TKE，EKS</Card.Body>
                                        <Card.Body>
                                            <iframe
                                                src="https://console.cloud.tencent.com/tke2/cluster/sub/update/k8sResource/hpc/ModifyHpcCron?rid=1&clusterId=cls-0xcqq9f4&np=qcbm-test&resourceIns=hpc-gw"
                                                width="100%" height="800px" frameBorder="12"/>
                                            {/*<img  src={sence4Url} alt="" style={{ width: '100%', height: '600px' }} />*/}
                                        </Card.Body>
                                </TabPanel>
                            </Tabs>
                            </Card.Body>
                        </Card>
                    </div>


            </div>
            <div>
                <iframe
                    src="http://qcbmm.yunnative.com:3000/d/vwWwR0W4k/yun-shu-cheng-dian-shang-miao-sha-jian-kong-da-ping?orgId=1&refresh=5s&from=now-15m&to=now&kiosk=off"
                    width="100%" height="100%" frameBorder="12" />

                {/*<iframe*/}
                {/*    src="https://console.cloud.tencent.com/tse/kong-detail?rid=1&id=gateway-14366216&tab=monitor"*/}
                {/*    width="100%" height="50%" frameBorder="12" ></iframe>*/}

            </div>

        </div>


            {/*        <div className="presentation">*/}
            {/*    <div className="presentation_box">*/}

            {/*        <iframe  src="http://qcbmm.yunnative.com:3000/d/H3WlHHYnk/yun-shu-cheng-dian-shang-miao-sha?orgId=1&refresh=5s&from=now-15m&to=now&kiosk=off" width="100%" height="100%"*/}
            {/*                frameborder="12"></iframe>*/}

            {/*    </div>*/}
            {/*</div>*/}

            <Modal visible={modalVisible1} onClose={()=>setModalVisible1(false)} caption="弹性扩缩容-扩缩容微服务POD副本数" style={{ fontSize: '14px' }}>
                <Modal.Body style={{textAlign:"center"}}>
                    {
                        <div style={{display:'flex'}} key={0}>
                            <div className="left" style={{width:"30%"}}>
                                <p>front-test</p>
                            </div>
                            <div className="right" style={{width:"70%"}}>
                                <Input   type="text" required  value={increasePod['frontNum']} onChange={value => changeincreasePod(value,'frontNum')}/>
                            </div>
                        </div>}
                    {
                        <div style={{display:'flex'}} key={1}>
                            <div className="left" style={{width:"30%"}}>
                                <p>order-service-test</p>
                            </div>
                            <div className="right" style={{width:"70%"}}>
                                <Input type="text" value={increasePod['orderNum']} onChange={value => changeincreasePod(value,'orderNum')}/>
                            </div>
                        </div>

                    }
                    {
                        <div style={{display:'flex'}} key={2}>
                            <div className="left" style={{width:"30%"}}>
                                <p>qcbm-gateway-test</p>
                            </div>
                            <div className="right" style={{width:"70%"}}>
                                <Input type="text" value={increasePod['gateWayNum']} onChange={value => changeincreasePod(value,'gateWayNum')}/>
                            </div>
                        </div>

                    }
                    {
                        <div style={{display:'flex'}} key={3}>
                            <div className="left" style={{width:"30%"}}>
                                <p>seckill-service-test</p>
                            </div>
                            <div className="right" style={{width:"70%"}}>
                                <Input type="text" value={increasePod['secKillNum']} onChange={value => changeincreasePod(value,'secKillNum')} />
                            </div>
                        </div>

                    }
                    {
                        <div style={{display:'flex'}} key={3}>
                            <div className="left" style={{width:"30%"}}>
                                <p>store-service-test</p>
                            </div>
                            <div className="right" style={{width:"70%"}}>
                                <Input type="text" value={increasePod['storeNum']} onChange={value => changeincreasePod(value,'storeNum')} />
                            </div>
                        </div>

                    }

                    <Button style={{marginTop:"20px"}} onClick={()=>submitPodNumChange()} type="primary">修改</Button>
                </Modal.Body>
            </Modal>


            <Modal visible={visible} caption="弹性扩缩容成功-详情" onClose={close} size='xl' style={{ fontSize: '14px' }}>
                <Modal.Body>
                    <List type="bullet" style={{ marginLeft: '20px', marginTop: '10px' }}>
                        <List.Item>{topList.frontTips}   &nbsp;   开始时间：{list[0].timeStr} {topList.frontTips.indexOf("完成")!==-1?" 结束时间："+list[list.length-1].frontEndTime  +"    耗时："+list[list.length-1].timeFrontNum:""} </List.Item>
                        <List.Item>{topList.gatewayTips} &nbsp; 开始时间：{list[0].timeStr} {topList.gatewayTips.indexOf("完成")!==-1?" 结束时间："+list[list.length-1].gatewayEndTime  +"   耗时："+list[list.length-1].timeGatewayNum:""}</List.Item>
                        <List.Item>{topList.orderTips} &nbsp; 开始时间：{list[0].timeStr}  {topList.orderTips.indexOf("完成")!==-1?" 结束时间："+list[list.length-1].orderEndTime  +"  耗时："+list[list.length-1].timeOrderNum:""}</List.Item>
                        <List.Item>{topList.secKillTips}   &nbsp;   开始时间：{list[0].timeStr} {topList.secKillTips.indexOf("完成")!==-1?" 结束时间："+list[list.length-1].secKillEndTime  +"    耗时："+list[list.length-1].timeSecKillNum:""} </List.Item>
                        <List.Item>{topList.storeTips} &nbsp; 开始时间：{list[0].timeStr} {topList.storeTips.indexOf("完成")!==-1?" 结束时间："+list[list.length-1].storeEndTime  +"   耗时："+list[list.length-1].timeStoreNum:""}</List.Item>
                    </List>
                    <div className="podbox" id='podbox_black'>
                        {
                            list.map((item,index) => {
                                return (
                                    <div className='podbox_black'  key={index}>
                                        <div className="podleft">
                                            <p>{item.timeStr}</p>
                                        </div>
                                        <div className="podright">
                                            <p>{item.frontTips}   &nbsp;{ item.frontTips.indexOf("完成")!==-1?" 耗时："+item.timeFrontNum:"" }</p>
                                            <p>{item.gatewayTips} &nbsp;{ item.gatewayTips.indexOf("完成")!==-1?" 耗时："+item.timeGatewayNum:"" }</p>
                                            <p>{item.orderTips} &nbsp;{ item.orderTips.indexOf("完成")!==-1? " 耗时："+item.timeOrderNum:"" }</p>
                                            <p>{item.secKillTips} &nbsp;{ item.secKillTips.indexOf("完成")!==-1?" 耗时："+item.timeSecKillNum:"" }</p>
                                            <p>{item.storeTips} &nbsp;{ item.storeTips.indexOf("完成")!==-1? " 耗时："+item.timeStoreNum:"" }</p>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>


                </Modal.Body>

            </Modal>
        </LayoutContent>
    );
}
