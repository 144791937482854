import React, {useEffect, useState} from "react";
import {Link, useHistory} from 'react-router-dom'
import "tea-component/dist/tea.css";
import {NavMenu, List, Button, Badge} from "tea-component";
import {querySysConfig, getUserName} from "../services/function"

export default function TopMenu() {
    const [list, setList] = useState([])
    const [list1, setList1] = useState([])
    const [loginName, setLoginName] = useState("用户名")
    const [list2, setList2] = useState([
        {
            name: "场景预约",
            src: "/#/reserveSceneFunction"
        },
        {
            name: "退出登录",
            src: "/logout"
        }
    ])
    const history = useHistory()

    const goWeb = (src) => {
        window.open(src)
    }

    const goWeb1 = (src) => {
        window.location.href = src
    }


    useEffect(() => {
        (async () => {
            const res = await querySysConfig("1", "1,2");
            if (res.data.success) {
                res && res.data && setList(res.data.data.consolePro);
                res && res.data && setList1(res.data.data.consoleMore);
            }
            if (res.data && res.data > 200 && res.data.indexOf("Please sign in") != -1) {
                window.location.href = "/login"
            }
            const resUserName = await getUserName();
            if (resUserName.data.success) {
                setLoginName(resUserName.data.data.loginName)
            }
            if(resUserName.data.data.role==="ADMIN"){
                const listMenu =[
                    {
                        name: "场景预约",
                        src: "/#/reserveSceneFunction"
                    },
                    {
                        name: "新增用户",
                        src: "/#/addUserFunction"
                    },
                    {
                        name: "登录日志",
                        src: "/#/loginFunction"
                    },
                    {
                        name: "退出登录",
                        src: "/logout"
                    }
                ]
                setList2(listMenu);
            }else if(resUserName.data.data.role==="PLATFORM"){
                const listMenu =[
                    {
                        name: "退出登录",
                        src: "/logout"
                    }
                ]
                setList2(listMenu);
            }
            if (resUserName.data && resUserName.data > 200 && resUserName.data.indexOf("Please sign in") != -1) {
                window.location.href = "/login"
            }

        })()
    }, [])
    return (
        <NavMenu
            left={
                <>
                    <NavMenu.Item >

                    </NavMenu.Item>
                </>
            }
            right={
                <>
                    {/*<Link style={{color:"white",marginLeft:"20px"}} to="/reserveSceneFunction">场景预约</Link>*/}
                    {/*<a style={{color:"white",marginLeft:"20px"}} href="/logout">退出登录</a>*/}
                    <NavMenu.Item
                        type="dropdown"
                        overlay={close => (
                            <List type="option">
                                {
                                    list.map((item, index) => {
                                        return (
                                            <List.Item key={index} onClick={() => {
                                                goWeb(item.src)
                                            }}>{item.name}</List.Item>
                                        )
                                    })
                                }
                            </List>
                        )}>
                        云产品控制台
                    </NavMenu.Item>
                    <NavMenu.Item
                        type="dropdown"
                        overlay={close => (
                            <List type="option">
                                {
                                    list1.map((item, index) => {
                                        return (
                                            <List.Item key={index} onClick={() => {
                                                goWeb(item.src)
                                            }}>{item.name}</List.Item>
                                        )
                                    })
                                }
                            </List>
                        )}>
                        更多
                    </NavMenu.Item>

                    <NavMenu.Item
                        type="dropdown"
                        overlay={close => (
                            <List type="option">
                                {
                                    list2.map((item, index) => {
                                        return (
                                            <List.Item key={index} onClick={() => {
                                                goWeb1(item.src)
                                            }}>{item.name}</List.Item>
                                        )
                                    })
                                }
                            </List>
                        )}>
                        {loginName}
                    </NavMenu.Item>

                </>

            }
        />
    );
}
