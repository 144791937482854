import React, {useEffect, useState} from "react";
import "tea-component/dist/tea.css";
import {
    Layout,
} from "tea-component";
import './index.scss'
import {querySceneConfig} from "../../../services/function";

const { Content: LayoutContent } = Layout;

export default function BaseInfo() {
    const [pptUrl,setPptUrl] = useState("https://drive.weixin.qq.com/s?k=AJEAIQdfAAoU0wO6jI");

    useEffect(() => {
        (async () => {
            const sceneRes = await  querySceneConfig(14,3)
            if(sceneRes.data.success){
                let datas = sceneRes.data.data;
                if(null!=datas){
                    datas.map(function (config){
                        if(config.configKey==="question_answer_ppt_url"){
                            setPptUrl(config.configValue);
                        }

                    })
                }
            }

        })()

    }, [])
    return (
        <LayoutContent>
            <LayoutContent.Header title='演示说明'/>
            <div className="presentation">
                <div className="presentation_box">
                    <iframe src={pptUrl} width="100%" height="100%"
                            frameBorder="0"/>
                </div>
            </div>
        </LayoutContent>
    );
}
