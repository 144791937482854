import React from "react";
import "tea-component/dist/tea.css";
import {
    Layout, Card, ImagePreview, List, Table, Bubble,
    Icon, H4, ExternalLink, Tabs, TabPanel
} from "tea-component";
import './index.scss'
const { Body, Sider, Content: LayoutContent } = Layout;

const cvmList = [
    {
        instanceId: "Region",
        instanceName: "广州",
    },
    {
        instanceId: "AZ",
        instanceName: "广州三区、广州四区",
    },
    {
        instanceId: "VPC",
        instanceName: "CIDR:10.0.0/16",
    },
    {
        instanceId: "子网 Subnet-Basic",
        instanceName: "广州三区 CIDR：10.0.4.0/24，广州四区 CIDR：10.0.1.0/24",
    },
    {
        instanceId: "子网 Subnet-K8S",
        instanceName: "广州三区 CIDR：10.0.3.0/24，广州四区 CIDR：10.0.0.0/24",
    },
    {
        instanceId: "容器网络",
        instanceName: "广州三区 CIDR：172.16.0.0/16，广州四区 CIDR：10.0.0.0/24",
    },
];

const cvmListOther = [
    {
        one: 'CVM',
        two: 'N/A',
        three: '腾讯云',
        four: '标准型',
    },
    {
        one: 'CLB',
        two: 'N/A',
        three: '腾讯云',
        four: '公网型，广州三区 (主) / 广州四区 (备)',
    },
    {
        one: 'TKE',
        two: 'N/A',
        three: '腾讯云',
        four: 'TKE 托管模式；kubernetes 版本：1.8.4；docker 版本：19.3',
    },
    {
        one: 'TCR',
        two: 'N/A',
        three: '腾讯云',
        four: '镜像仓库个人版',
    },
    {
        one: 'MySQL',
        two: '5.7',
        three: '腾讯云',
        four: 'TencentDB for MySQL 双节点，广州三区 (主) / 广州四区 (备)',
    },
    {
        one: 'Redis',
        two: '5.0',
        three: '腾讯云',
        four: 'TencentDB for Redis 标准型，广州三区 (主) / 广州四区 (备)',
    },
    {
        one: 'COS',
        two: 'N/A',
        three: '腾讯云',
        four: '图片存储, 跨区高可用',
    },
    {
        one: 'Kafka',
        two: '1.1.1',
        three: '腾讯云',
        four: '标准型',
    },
    {
        one: 'CLS',
        two: 'N/A',
        three: '腾讯云',
        four: '日志服务',
    },
    {
        one: 'ES',
        two: 'N/A',
        three: '腾讯云',
        four: 'Elasticsearch Service服务',
    },
    {
        one: 'SCF',
        two: 'N/A',
        three: '腾讯云',
        four: '用于日志分析，云审计',
    },
    {
        one: 'Coding',
        two: 'N/A',
        three: '腾讯云',
        four: '代码托管，持续集成和部署',
    },
    {
        one: 'TencentKona',
        two: '8.0.5',
        three: '腾讯云开源GPL',
        four: 'Docker 镜像为 alpine:v3.13',
    },
    {
        one: 'Dubbo',
        two: '2.7.8',
        three: '开源社区',
        four: '[Github 地址](https://github.com/apache/dubbo)',
    },
    {
        one: 'Nacos',
        two: '2.0.3.1',
        three: '腾讯云',
        // four: '点此 [下载](https://github.com/alibaba/nacos/releases/download/2.0.0-bugfix/nacos-server-2.0.0.tar.gz)',
        four:'腾讯云微服务引擎中已支持组件Nacos，微服务引擎（Tencent Cloud Service Engine，TSE）提供开源增强的注册配置中心和服务治理中心，应用零代码改造即可接入，帮助您快速构建高可用、可扩展的分布式或者微服务应用架构'
    },
]

const cvmListOne = [
    {
        one: 'QCBM-Front',
        two: '使用 React 开发的前端项目，基于 Nginx 官方提供的 [1.19.8 Docker 镜像](https://hub.docker.com/_/nginx) 构建和部署。'
    },
    {
        one: 'QCBM-Gateway',
        two: 'API 网关，接受前端的 HTTP 请求，并将其转化为后台的 Dubbo 请求。',
    },
    {
        one: 'User-Service',
        two: '基于 Dubbo 的微服务，提供用户注册、登录、鉴权等功能。',
    },
    {
        one: 'Favorites-Service',
        two: '基于 Dubbo 的微服务，提供用户图书收藏功能。',
    },
    {
        one: 'Order-Service',
        two: '基于 Dubbo 的微服务，提供用户订单生成和查询等功能。',
    },
    {
        one: 'Store-Service',
        two: '基于 Dubbo 的微服务，提供图书信息的存储等功能。',
    },

]

const cvmListTwo = [
    {
        one: '用户注册',
        two: 'POST',
        three: '/api/auth/signup',
    },
    {
        one: '用户登录',
        two: 'POST',
        three: '/api/auth/signin',
    },
    {
        one: '查询用户',
        two: 'GET',
        three: '/api/admin/user/{userId}',
    },
    {
        one: '删除用户',
        two: 'DELETE',
        three: '/api/admin/user/delete',
    },
    {
        one: '图书列表',
        two: 'GET',
        three: '/api/book/all',
    },
    {
        one: '图书查询',
        two: 'GET',
        three: '/api/book/{isbn}',
    },
    {
        one: '购书',
        two: 'GET',
        three: '/api/book/purchase',
    },
    {
        one: '用户订单列表',
        two: 'GET',
        three: '/api/order/{userId}',
    },
    {
        one: '图书收藏',
        two: 'GET',
        three: '/api/favorites/add',
    },
    {
        one: '用户收藏列表',
        two: 'GET',
        three: '/api/favorites/query/{userId}',
    },
    {
        one: '删除用户收藏的图书',
        two: 'GET',
        three: '/api/favorites/delete',
    },

]

export default function BaseInfo() {
    const tabs = [
        { id: "basic", label: "系统架构" },
        { id: "network", label: "云原生产品和组件" },
        { id: "monitor", label: "网络规划" },
        { id: "sg", label: 'QCBM项目'},
       
    ];

    const getBubble=()=>{
        return(
            <Bubble
            arrowPointAtCenter
            placement="top-end"
            content={`QCBM 是采用微服务架构，并使用 dubbo-2.7.8 框架开发的一个网上书城沙盒演示项目。QCBM 的部署和代码托管在 Coding，详情可参见 [QCBM 项目](https://github.com/TencentCloud/container-demo/tree/main/dubbo-on-tke)。`}

        >
            <Icon type="info" style={{ marginLeft: '5px' }} />
        </Bubble>
        )
    }

    return (
        <LayoutContent>
            <LayoutContent.Header title='基本信息' />
            <LayoutContent.Body>
                <Card>
                    <Card.Body>
                        <Tabs tabs={tabs}>

                            <TabPanel id="basic">
                                <div className="system">

                                    <div className="img" style={{
                                        width: '100%',
                                        height: '600px',
                                        margin: '0 auto',
                                        boxSizing: 'border-box',
                                        padding: '10px',
                                    }}>
                                        <img src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/intro/sys-arch-2.png" alt="" style={{ width: '100%', height: '100%' }} />
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel id="network">
                                <div className="components">

                                    <Table
                                        verticalTop
                                        records={cvmListOther}
                                        recordKey="one"
                                        bordered='all'
                                        columns={[
                                            {
                                                key: "one",
                                                header: "产品/组件",
                                                align: 'left',
                                                width:'150px',
                                            },
                                            {
                                                key: "two",
                                                header: "版本",
                                                align: 'left',
                                                width:'150px',
                                            },
                                            {
                                                key: "three",
                                                header: "来源",
                                                align: 'left',
                                                width:'150px',
                                            },
                                            {
                                                key: "four",
                                                header: "备注",
                                                align: 'left',
                                                render: function getFour(cvmListOther) {

                                                    if (cvmListOther.four.indexOf('Github') !== -1 || cvmListOther.four.indexOf('下载') !== -1) {

                                                        return (
                                                            <ExternalLink
                                                                href={/(.+)?(?:\(|（)(.+)(?=\)|）)/.exec(cvmListOther.four)[2]}
                                                                style={{ marginRight: 10 }}
                                                            >
                                                                {cvmListOther.four}
                                                            </ExternalLink>
                                                        )

                                                    } else {
                                                        return (
                                                            <p>{cvmListOther.four}</p>
                                                        )
                                                    }
                                                }
                                            },
                                        ]}
                                    />
                                </div>
                            </TabPanel>
                            <TabPanel id="monitor">
                                <div className="network">
                                    
                                    <Table
                                        verticalTop
                                        records={cvmList}
                                        recordKey="instanceId"
                                        bordered='all'
                                        columns={[
                                            {
                                                key: "instanceId",
                                                header: "网络规划",
                                                align: 'left',
                                                width:'300px'
                                            },
                                            {
                                                key: "instanceName",
                                                header: "可用区域",
                                                align: 'left'
                                            },
                                        ]}
                                    />
                                </div>
                            </TabPanel>
                            <TabPanel id="sg">
                            <div className="project">
                            <div className="title">
                            QCBM 是采用微服务架构，并使用 dubbo-2.7.8 框架开发的一个网上书城沙盒演示项目。QCBM 的部署和代码托管在 Coding，详情可参见 [QCBM 项目](https://github.com/TencentCloud/container-demo/tree/main/dubbo-on-tke)。
                            </div>
                            <H4 style={{ margin: '20px auto 10px' }}>QCBM 首页如下图展所示：</H4>
                            <div className="img" style={{
                                width: '100%',
                                height: '600px',
                                margin: '0 auto',
                                boxSizing: 'border-box',
                                padding: '10px',
                            }}>

                                <img src="https://main.qcloudimg.com/raw/958f718bb6e1656449e8bcdd9dd88ae2.png" alt="" style={{ width: '100%', height: '100%' }} />
                            </div>
                            <H4 style={{ margin: '20px auto 10px' }}>QCBM 包含以下微服务：</H4>
                            <Table
                                verticalTop
                                records={cvmListOne}
                                recordKey="one"
                                bordered='all'
                                columns={[
                                    {
                                        key: "one",
                                        header: "微服务",
                                        align: 'left',
                                        width:'300px'
                                    },
                                    {
                                        key: "two",
                                        header: "说明",
                                        align: 'left',
                                        render: function getTwo(cvmListOne) {

                                            return (
                                                <p>{cvmListOne.two}</p>
                                            )
                                        }
                                    },

                                ]}
                            />
                            <H4 style={{ margin: '20px auto 10px' }}>QCBM-Gateway 提供了如下 API 接口：</H4>
                            <Table
                                verticalTop
                                records={cvmListTwo}
                                recordKey="one"
                                bordered='all'
                                columns={[
                                    {
                                        key: "one",
                                        header: "API Function",
                                        align: 'left',
                                        width:'300px'
                                    },
                                    {
                                        key: "two",
                                        header: "Method",
                                        align: 'left',
                                        width:'200px'
                                    },
                                    {
                                        key: "three",
                                        header: "Path",
                                        align: 'left'
                                    },

                                ]}
                            />
                        </div>
                            </TabPanel>
                            
                        </Tabs>



                       
                    </Card.Body>
                </Card>
            </LayoutContent.Body>
        </LayoutContent >
    );
}
