import React, { useState, useContext } from "react";
import "tea-component/dist/tea.css";
import {
    Tabs, TabPanel, Layout, Card, List, Radio, Button, Modal, InputAdornment,
    Input, message,
} from "tea-component";
import { MyContext } from "../../../../../context-manger";
import './index.scss'
import { getSchedule, getTaskResult } from '../../../../../services/function'
import { getFormatDate } from '../../../../../utils/getFormatDate'
const { Header, Body, Sider, Content: LayoutContent } = Layout;

function diffSecond(start, end) {
    if(null==end||'undefined'==end){
        return 0;
    }
    if(null==start||'undefined'==start){
        start=getFormatDate();
    }
    const startTime = new Date(start);
    const endTime = new Date(end);
    const second_time = Math.abs(startTime - endTime) / 1000; // 得到相差的秒数
    return second_time;
}

export default function Dispatch() {
    const [list,setList] = useState([
        {
            timeStr: getFormatDate(),
            clbTips: '开始流量调度',
            timeNum:"0秒",
            timeClbNum:"0秒",
            timeMysqlNum:"0秒",
            timeRedisNum:"0秒",
            clbEndTime:getFormatDate(),
            mySqlEndTime:getFormatDate(),
            redisEndTime:getFormatDate()
        },

    ])
    let pollTaskResultInterval = -1
    const {setUrl}=useContext(MyContext)
    const [visible, setVisible] = useState(false);
    const [valueOne, setValueOne] = useState('')
    const [valueTwo, setValueTwo] = useState('')
    const [topList, setTopList] = useState({
        clbTips: '开始流量调度',

    })

    const open = () => {
        if (valueOne === '' || valueTwo === '') {
            message.error({
                content: "请输入流量数据",
            })
        } else {
            setVisible(true)
        }
    };
    const close = () => {
        setVisible(false)
        setList([
            {
                timeStr: getFormatDate(),
                clbTips: '开始流量调度',
            },
    
        ]);
        setTopList({
            clbTips: '开始流量调度',
    
        })
    };

    // 获取流量调度架构图
    const getScheduleData=async()=>{
        if (valueOne === '' || valueTwo === '') {
            message.error({
                content: "请输入流量数据",
            })
        } else {
            setVisible(true)
            let data={
                az3TrafficRatio:valueOne,
                az4TrafficRatio:valueTwo
            }
            const res=await getSchedule(data)
            console.log(res);
            if(res.data.success){
                list[0].timeStr=getFormatDate();
                setUrl(`https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/qcbm-flow-normal.gif`)
                
                pollTaskResultInterval=setInterval(() => {
                    getTaskResultData(res.data.taskId)
                    
                 }, 1000);
            }else{
                message.warning({
                    content: res.data.msg,
                  })
            }
        }
       
    }
    // 获取流量调度任务进度
    const getTaskResultData=async (currentTaskId)=>{
        let listOne=list
        const res=await getTaskResult(currentTaskId)
        if(res.data.data.status===2||res.data.data.status===3){
            clearInterval(pollTaskResultInterval)
            pollTaskResultInterval = -1;
        }
        listOne.push({
            timeStr:res.data.data.timeStr,
            clbTips:res.data.data.clbTips,
            clbEndTime:res.data.data.clbEndTime,
            timeClbNum:diffSecond(list[0].timeStr,res.data.data.clbEndTime)+"秒",
           
        })
        setList(listOne)
        setTopList({
            clbTips:res.data.data.clbTips,
           
        })
        let div=document.getElementById('box_black')
            div.scrollTop=div.scrollHeight

    }

    return (
        <>
            <div>
                <p>在AZ间按指定的比例分配流量：</p>
                <section>
                    <InputAdornment before="GZ-AZ3" style={{ marginTop: '20px', marginLeft: "20px" }}>
                        <Input value={valueOne} onChange={(value) => { setValueOne(value) }} />
                    </InputAdornment>
                </section>
                <section>
                    <InputAdornment before="GZ-AZ4" style={{ marginTop: '10px', marginLeft: "20px" }}>
                        <Input value={valueTwo} onChange={(value) => { setValueTwo(value) }} />
                    </InputAdornment>
                </section>
                <Button type="primary" style={{ marginTop: '15px', marginLeft: '20px' }} onClick={getScheduleData}>
                    流量调度
                </Button>
                <Modal visible={visible} caption="双活切换" onClose={close} size='xl' style={{ fontSize: '14px' }}>
                    <Modal.Body>
                        <List type="bullet" style={{ marginLeft: '20px', marginTop: '10px' }}>
                            <List.Item>{topList.clbTips}   &nbsp;   开始时间：{list[0].timeStr} {topList.clbTips.indexOf("完成")!=-1?" 结束时间："+list[list.length-1].clbEndTime  +"    耗时："+list[list.length-1].timeClbNum:""} </List.Item>

                        </List>
                        <div className="box" id='box_black'>
                            {
                                list.map((item,index) => {
                                    return (
                                        <div className='box_blackOne' key={index}>
                                            <div className="left">
                                                <p>{item.timeStr}</p>
                                            </div>
                                            <div className="right">
                                                <p>{item.clbTips}   &nbsp;{ item.clbTips.indexOf("完成")!=-1?" 耗时："+item.timeClbNum:"" }</p>
                                            </div>

                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Modal.Body>

                </Modal>
            </div>
        </>
    )
}
