import React from "react";
import "tea-component/dist/tea.css";
import {Card, Layout, Table, TabPanel, Tabs} from "tea-component";

const {Content: LayoutContent} = Layout;

export default function PolarisBaseInfo() {

    const tabs = [
        {id: "tem", label: "系统架构"},
        {id: "framework", label: "云原生产品和组件"},
        {id: "network", label: "网络规划"},
        {id: "application", label: "应用拓扑"},
    ];

    const cvmList = [
        {
            instanceId: "Region",
            instanceName: "华北地区（北京）",
        },
        {
            instanceId: "AZ",
            instanceName: "北京七区，北京六区",
        },
        {
            instanceId: "VPC",
            instanceName: "10.1.0.0/16",
        }]

    const cvmPc = [
        {
            name: "TSE-云原生网关",
            func: "路由，染色",
            character: "托管Kong网关"
        },
        {
            name: "TKE",
            func: "提供微服务容器计算资源",
            character: "托管容器服务"
        },
        {
            name: "TSE-北极星",
            func: "注册中心，服务治理中心",
            character: "统一异构系统服务治理，东西流量治理"
        },
        {
            name: "TCR",
            func: "镜像仓库",
            character: "托管容器镜像服务"
        },
        {
            name: "CDB",
            func: "商品、订单数据存储",
            character: "跨区高可用"
        },
        {
            name: "CRS",
            func: "商品、收藏夹等数据缓存",
            character: "一单分片8～10万QPS，跨区高可用"
        },
        {
            name: "TDMQ-Pulsar",
            func: "订单数据异步处理",
            character: ">10万QPS，数据强一致"
        },
        {
            name: "CLS",
            func: "日志收集与存储",
            character: "日志分析与云审计"
        },
        {
            name: "Promethues",
            func: "监控采集与展示",
            character: "高可用、弹性伸缩"
        }, {
            name: "Coding",
            func: "持续集成",
            character: "一站式DevOps开发平台"
        }

    ]
    return (
        <LayoutContent>
            <LayoutContent.Body>
                <Card style={{height: "100%"}}>
                    <Card.Body>
                        <Tabs tabs={tabs}>
                            <TabPanel id="tem">
                                <div style={{
                                    width: '888px',
                                    height: '698px',
                                    margin: '0 auto',
                                    boxSizing: 'border-box',
                                    padding: '10px',
                                }}>
                                    <img
                                        src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/polaris/%E7%81%B0%E5%BA%A6-2.gif"
                                        alt="" style={{width: '100%', height: '100%'}}/>
                                </div>
                            </TabPanel>
                            <TabPanel id="framework">
                                <div>
                                    <Table
                                        verticalTop
                                        records={cvmPc}
                                        recordKey="instanceId"
                                        bordered='all'
                                        columns={[
                                            {
                                                key: "name",
                                                header: "产品名称",
                                                align: 'left',
                                                width: '300px'
                                            },
                                            {
                                                key: "func",
                                                header: "产品功能",
                                                align: 'left'
                                            },
                                            {
                                                key: "character",
                                                header: "产品特性",
                                                align: 'left'
                                            }
                                        ]}
                                    />
                                </div>
                            </TabPanel>
                            <TabPanel id="network">
                                <div>
                                    <Table
                                        verticalTop
                                        records={cvmList}
                                        recordKey="instanceId"
                                        bordered='all'
                                        columns={[
                                            {
                                                key: "instanceId",
                                                header: "网络规划",
                                                align: 'left',
                                                width: '300px'
                                            },
                                            {
                                                key: "instanceName",
                                                header: "可用区域",
                                                align: 'left'
                                            },
                                        ]}
                                    />
                                </div>
                            </TabPanel>

                            <TabPanel id="application">
                                <div style={{
                                    width: '888px',
                                    height: '698px',
                                    margin: '0 auto',
                                    boxSizing: 'border-box',
                                    padding: '10px',
                                }}>
                                    <img
                                        src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/polaris/application_topology.png"
                                        alt="" style={{width: '100%', height: '100%'}}/>
                                </div>
                            </TabPanel>
                        </Tabs>
                    </Card.Body>
                </Card>
            </LayoutContent.Body>
        </LayoutContent>
    );
}