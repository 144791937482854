import React, {useState, useEffect} from "react";
import "tea-component/dist/tea.css";
import {
    Layout, Card, Input, List, Table, Bubble,
    Icon, H4, InputNumber, Tabs, TabPanel, Button, message
} from "tea-component";
import './indexStoreCalc.scss'
import {getResourceInfo, seckillList} from "../../../services/bookStorage";

const {Body, Sider, Content: LayoutContent} = Layout;


// const costList = [
//     {
//         descriptionFunc: "存算一体（CVM）",
//         firstYear: "778404",
//         secondYear: "1015273",
//         thirdYear: "1252141",
//         fourYear:"1489009",
//         fiveYear:"1725878"
//     },
//     {
//         descriptionFunc: "存算分离（DLC）",
//         firstYear: "487073",
//         secondYear: "539475",
//         thirdYear: "591876",
//         fourYear:"644278",
//         fiveYear:"696680"
//     },
//     {
//         descriptionFunc: "成本比例（DLC vs CVM）",
//         firstYear: "37%",
//         secondYear: "47% ",
//         thirdYear: "53%",
//         fourYear:"57%",
//         fiveYear:"60%"
//     },
//
// ];


// const costList = [
//     {
//         descriptionFunc: "存算一体（CVM）",
//         firstYear: "778404",
//         secondYear: "1015273",
//         thirdYear: "1252141",
//         fourYear:"1489009",
//         fiveYear:"1725878"
//     },
//     {
//         descriptionFunc: "存算分离（DLC）",
//         firstYear: "487073",
//         secondYear: "539475",
//         thirdYear: "591876",
//         fourYear:"644278",
//         fiveYear:"696680"
//     },
//     {
//         descriptionFunc: "成本比例（DLC vs CVM）",
//         firstYear: "37%",
//         secondYear: "47% ",
//         thirdYear: "53%",
//         fourYear:"57%",
//         fiveYear:"60%"
//     },
//
// ];

const cvmList = [
    {
        instanceId: "Region",
        instanceName: "广州",
    },
    {
        instanceId: "AZ",
        instanceName: "广州三区、广州四区",
    },
    {
        instanceId: "VPC",
        instanceName: "CIDR:10.0.0/16",
    },
    {
        instanceId: "子网 Subnet-Basic",
        instanceName: "广州三区 CIDR：10.0.4.0/24，广州四区 CIDR：10.0.1.0/24",
    },
    {
        instanceId: "子网 Subnet-K8S",
        instanceName: "广州三区 CIDR：10.0.3.0/24，广州四区 CIDR：10.0.0.0/24",
    },
    {
        instanceId: "容器网络",
        instanceName: "广州三区 CIDR：172.16.0.0/16，广州四区 CIDR：10.0.0.0/24",
    },
];

const cvmListOther = [
    {
        one: 'COS',
        two: 'N/A',
        three: '腾讯云',
        four: '统一数据存储，支持iceberg表格式，跨可用区高可用',
    },
    {
        one: 'DLC',
        two: 'N/A',
        three: '腾讯云',
        four: '提供serverless计算资源和操作入口，极致弹性，极致性能',
    },
    {
        one: 'EMR',
        two: 'N/A',
        three: '腾讯云',
        four: '提供存储和算力的基础平台，服务丰富，灵活性高',
    },
    {
        one: 'Wedata',
        two: 'N/A',
        three: '腾讯云',
        four: '数据入湖，监控一体化数据集成、开发和治理平台',
    },
    {
        one: 'CDB',
        two: 'N/A',
        three: '腾讯云',
        four: '存算一体场景下的元数据存储，跨区高可用',
    },
    {
        one: 'Promethues',
        two: 'N/A',
        three: '腾讯云',
        four: '监控采集与展示，高可用、弹性伸缩',
    },


]

const cvmListOne = [
    {
        one: 'QCBM-Front',
        two: '使用 React 开发的前端项目，基于 Nginx 官方提供的 [1.19.8 Docker 镜像](https://hub.docker.com/_/nginx) 构建和部署。'
    },
    {
        one: 'QCBM-Gateway',
        two: 'API 网关，接受前端的 HTTP 请求，并将其转化为后台的 Dubbo 请求。',
    },
    {
        one: 'User-Service',
        two: '基于 Dubbo 的微服务，提供用户注册、登录、鉴权等功能。',
    },
    {
        one: 'Favorites-Service',
        two: '基于 Dubbo 的微服务，提供用户图书收藏功能。',
    },
    {
        one: 'Order-Service',
        two: '基于 Dubbo 的微服务，提供用户订单生成和查询等功能。',
    },
    {
        one: 'Store-Service',
        two: '基于 Dubbo 的微服务，提供图书信息的存储等功能。',
    },
    {
        one: 'Seckill-Service',
        two: '基于 Dubbo 的微服务，提供秒杀功能，秒杀高并发不影响其他服务。',
    }
]

const cvmListTwo = [
    {
        one: '用户注册',
        two: 'POST',
        three: '/api/auth/signup',
    },
    {
        one: '用户登录',
        two: 'POST',
        three: '/api/auth/signin',
    },
    {
        one: '查询用户',
        two: 'GET',
        three: '/api/admin/user/{userId}',
    },
    {
        one: '删除用户',
        two: 'DELETE',
        three: '/api/admin/user/delete',
    },
    {
        one: '图书列表',
        two: 'GET',
        three: '/api/book/all',
    },
    {
        one: '图书查询',
        two: 'GET',
        three: '/api/book/{isbn}',
    },
    {
        one: '购书',
        two: 'GET',
        three: '/api/book/purchase',
    },
    {
        one: '用户订单列表',
        two: 'GET',
        three: '/api/order/{userId}',
    },
    {
        one: '图书收藏',
        two: 'GET',
        three: '/api/favorites/add',
    },
    {
        one: '用户收藏列表',
        two: 'GET',
        three: '/api/favorites/query/{userId}',
    },
    {
        one: '删除用户收藏的图书',
        two: 'GET',
        three: '/api/favorites/delete',
    },
    {
        one: '秒杀列表查询接口',
        two: 'GET',
        three: '/api/bookstorage/list',
    },
    {
        one: '秒杀接口',
        two: 'GET',
        three: '/api/seckill/book?userId={userId}&isbn={isbn}',
    },
    {
        one: '订单查询接口',
        two: 'GET',
        three: '/api/order?userId={userId}&pageSize={pageSize}&pageNumber={pageNumber}',
    },

]

export default function BaseInfo() {
    const [costList, setCostList] = useState([

        {
            descriptionFunc: "非存算分离（D2）",
            firstYear: "778404",
            secondYear: "1015273",
            thirdYear: "1252141",
            fourYear: "1489009",
            fiveYear: "1725878"
        },
        {
            descriptionFunc: "存算分离（DLC）",
            firstYear: "487073",
            secondYear: "539475",
            thirdYear: "591876",
            fourYear: "644278",
            fiveYear: "696680"
        },
        {
            descriptionFunc: "成本下降比例（DLC vs D2）",
            firstYear: "37%",
            secondYear: "47% ",
            thirdYear: "53%",
            fourYear: "57%",
            fiveYear: "60%"
        },


    ])

    const changeCostInfo = async () => {
        let r = /^[0-9]*[1-9][0-9]*$/
        let value = document.getElementById("dayCostId").value;
        let cpuValue = document.getElementById("costCpuId").value;
        let rate = document.getElementById("costRateId").value;
        if(value== null||value=='undefined'||value==""||!r.test(value)||value<=0){
            message.error({
                content: "数据增量（GB/日）输入错误，请输入大于0的数字"
            })
            return;
        }
        let cpu= cpuValue%32;
        if(cpuValue== null||cpuValue=='undefined'||cpuValue==""||!r.test(cpuValue)||cpuValue<=0||cpu!=0){
            message.error({
                content: "CPU核数输入错误，请输入能被32整除的数字"
            })
            return;
        }

        if(rate== null||rate=='undefined'||rate==""||!r.test(rate)||rate<=0||rate>100){
            message.error({
                content: "存储预留比率（%）输入错误，请输入小于100且大于0的数字"
            })
            return;
        }
        const costList1 = [
            {
                descriptionFunc: "非存算分离（D2）",
                firstYear: "778404",
                secondYear: "1015273",
                thirdYear: "1252141",
                fourYear: "1489009",
                fiveYear: "1725878"
            },
            {
                descriptionFunc: "存算分离（DLC）",
                firstYear: "487073",
                secondYear: "539475",
                thirdYear: "591876",
                fourYear: "644278",
                fiveYear: "696680"
            },
            {
                descriptionFunc: "成本下降比例（DLC vs D2）",
                firstYear: "37%",
                secondYear: "47% ",
                thirdYear: "53%",
                fourYear: "57%",
                fiveYear: "60%"
            },

        ];
        costList1[0].firstYear = Math.round((cpuValue / 32 + (value * 365 * 3) * 1 / (rate / 100) / (11176 * 4)) * 5641 * 12) + "";
        costList1[0].secondYear = Math.round((cpuValue / 32 + (value * 365 * 3) * 2 / (rate / 100) / (11176 * 4)) * 5641 * 12) + "";
        costList1[0].thirdYear = Math.round((cpuValue / 32 + (value * 365 * 3) * 3 / (rate / 100) / (11176 * 4)) * 5641 * 12) + "";
        costList1[0].fourYear = Math.round((cpuValue / 32 + (value * 365 * 3) * 4 / (rate / 100) / (11176 * 4)) * 5641 * 12) + "";
        costList1[0].fiveYear = Math.round((cpuValue / 32 + (value * 365 * 3) * 5 / (rate / 100) / (11176 * 4)) * 5641 * 12) + "";
        costList1[1].firstYear = Math.round(value * ((365 * 366 / 2) * 0.118 / 30) + 150 * cpuValue * 12) + "";
        costList1[1].secondYear = Math.round(value * (((365 * 2) * (365 * 2 + 1) / 2) * 0.118 / 30 - (365 * 366 / 2) * 0.118 / 30) + 150 * cpuValue * 12) + "";
        costList1[1].thirdYear = Math.round(value * (((365 * 3) * (365 * 3 + 1) / 2) * 0.118 / 30 - ((365 * 2) * (365 * 2 + 1) / 2) * 0.118 / 30) + 150 * cpuValue * 12) + "";
        costList1[1].fourYear = Math.round(value * (((365 * 4) * (365 * 4 + 1) / 2) * 0.118 / 30 - ((365 * 3) * (365 * 3 + 1) / 2) * 0.118 / 30) + 150 * cpuValue * 12) + "";
        costList1[1].fiveYear = Math.round(value * (((365 * 5) * (365 * 5 + 1) / 2) * 0.118 / 30 - ((365 * 4) * (365 * 4 + 1) / 2) * 0.118 / 30) + 150 * cpuValue * 12) + "";
        costList1[2].firstYear = Math.round(((costList1[0].firstYear - costList1[1].firstYear) / costList1[0].firstYear) * 100) + "%";
        costList1[2].secondYear = Math.round(((costList1[0].secondYear - costList1[1].secondYear) / costList1[0].secondYear) * 100) + "%";
        costList1[2].thirdYear = Math.round(((costList1[0].thirdYear - costList1[1].thirdYear) / costList1[0].thirdYear) * 100) + "%";
        costList1[2].fourYear = Math.round(((costList1[0].fourYear - costList1[1].fourYear) / costList1[0].fourYear) * 100) + "%";
        costList1[2].fiveYear = Math.round(((costList1[0].fiveYear - costList1[1].fiveYear) / costList1[0].fiveYear) * 100) + "%";
        setCostList(costList1)
        return costList;
    }


    const tabs = [
        {id: "basic", label: "系统架构"},
        {id: "cost", label: "总成本趋势对比"},
        //{ id: "data", label: "数据查询效率对比" },
        {id: "network", label: "云原生产品和组件"},
        {id: "monitor", label: "网络规划"},
        {id: "sg", label: 'QCBM项目'},

    ];

    const getBubble = () => {
        return (
            <Bubble
                arrowPointAtCenter
                placement="top-end"
                content={`QCBM 是采用微服务架构，并使用 dubbo-2.7.8 框架开发的一个网上书城沙盒演示项目。QCBM 的部署和代码托管在 Coding，详情可参见 [QCBM 项目](https://github.com/TencentCloud/container-demo/tree/main/dubbo-on-tke)。`}

            >
                <Icon type="info" style={{marginLeft: '5px'}}/>
            </Bubble>
        )
    }
    // useEffect(() => {
    //     (async () => {
    //         //changeCostInfo();
    //     })()
    // }, [])
    return (
        <LayoutContent>
            <LayoutContent.Header title='基本信息'/>
            <LayoutContent.Body>
                <Card>
                    <Card.Body>
                        <Tabs tabs={tabs}>

                            <TabPanel id="basic">
                                <div className="system">

                                    <div className="img" style={{marginTop: "80px"}}>
                                        <img
                                            src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/storeCalcSeparated.png"
                                            alt="" style={{width: '100%', height: '100%'}}/>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel id="cost">
                                <div style={{width: "100%", height: "750px"}}>
                                    <div style={{
                                        width: "60%",
                                        height: "450px",
                                        float: "left",
                                        marginLeft: "15px",
                                        marginTop: "20px",
                                        marginBottom: "30px"
                                    }}>

                                        <img
                                            src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/store_calc/store_calc_cost2.png"
                                            alt="" style={{width: '100%'}}/>
                                        {/*<img*/}
                                        {/*    src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/store_calc/store_calc_cost3.png"*/}
                                        {/*    alt="" style={{width: '80%', height: '40%', marginTop: "20px"}}/>*/}

                                    </div>
                                    <div style={{
                                        width: "30%",
                                        height: "350px",
                                        float: "left",
                                        marginLeft: "15px",
                                        marginTop: "20px",
                                        marginBottom: "30px"
                                    }}>
                                        <p className="cost-title">一、场景说明</p>
                                        <p className="cost-content">总资源256C1024G100T存储(单副本），数据按每天100G增量写入，数据至少保留3年，计算资源不单独新增 </p>
                                        <p className="cost-title">二、存储计算公式</p>
                                        <p className="cost-content"> 1、非存算分离：</p>
                                        <p className="cost-content">100G增量*365天*3年*3副本/70%/1024=459T/3年（3副本、存储预留比例70%）</p>
                                        <p className="cost-content"> 2、存算分离：</p>
                                        <p className="cost-content"> 100G增量*365天*3年*1副本/1024=107T/3年</p>
                                        <br/>
                                        <p className="cost-content"> 非存算分离服务器配置：大数据D2机型， 32核/128G/44T</p>
                                    </div>

                                    <div style={{width: "100%", height: "200px", clear: "both"}}>
                                        <div className="title" style={{marginTop: "30px"}}>
                                            <b>成本计算器</b>
                                        </div>
                                        <span style={{marginLeft: "15px"}}> CPU核数：</span>
                                        <Input size="s" defaultValue="256" id="costCpuId" name="costCpuId" maxLength="10"/>
                                        <span style={{marginLeft: "15px"}}>数据增量（GB/日）：</span>
                                        <Input type="text" size="s" defaultValue="100" name="dayCostId"
                                               id="dayCostId"
                                               style={{marginLeft: "15px"}} maxLength="10"/>
                                        <span style={{marginLeft: "15px"}}>存储预留比率（%）：</span>
                                        <Input size="s" defaultValue="70" id="costRateId" name="costRateId"
                                               style={{marginLeft: "15px"}}/>
                                        <Button onClick={() => changeCostInfo()}
                                                style={{marginTop: "10px", marginBottom: "10px", marginLeft: "20px"}}
                                        >计算</Button>

                                        <Table className="storeCalcTable"
                                               verticalTop
                                               records={
                                                   costList
                                               }
                                               recordKey="descriptionFunc"
                                               bordered='all'
                                               columns={[
                                                   {
                                                       key: "descriptionFunc",
                                                       header: "集群方案",
                                                       align: 'left',
                                                       width: '25%'
                                                   },
                                                   {
                                                       key: "firstYear",
                                                       header: "第一年",
                                                       align: 'left',
                                                       width: '15%',
                                                       render: node => {
                                                           if (node.descriptionFunc === '成本下降比例（DLC vs D2）') {
                                                               return <span
                                                                   style={{color: "red"}}>{node.firstYear}</span>;
                                                           } else {
                                                               return node.firstYear;
                                                           }

                                                       },

                                                   },
                                                   {
                                                       key: "secondYear",
                                                       header: "第二年",
                                                       align: 'left',
                                                       width: '15%',
                                                       render: node => {
                                                           if (node.descriptionFunc === '成本下降比例（DLC vs D2）') {
                                                               return <span
                                                                   style={{color: "red"}}>{node.secondYear}</span>;
                                                           } else {
                                                               return node.secondYear;
                                                           }

                                                       },
                                                   },
                                                   {
                                                       key: "thirdYear",
                                                       header: "第三年",
                                                       align: 'left',
                                                       width: '15%',
                                                       render: node => {
                                                           if (node.descriptionFunc === '成本下降比例（DLC vs D2）') {
                                                               return <span
                                                                   style={{color: "red"}}>{node.thirdYear}</span>;
                                                           } else {
                                                               return node.thirdYear;
                                                           }

                                                       },
                                                   }
                                                   , {
                                                       key: "fourYear",
                                                       header: "第四年",
                                                       align: 'left',
                                                       width: '15%',
                                                       render: node => {
                                                           if (node.descriptionFunc === '成本下降比例（DLC vs D2）') {
                                                               return <span
                                                                   style={{color: "red"}}>{node.fourYear}</span>;
                                                           } else {
                                                               return node.fourYear;
                                                           }

                                                       },
                                                   }
                                                   , {
                                                       key: "fiveYear",
                                                       header: "第五年",
                                                       align: 'left',
                                                       width: '15%',
                                                       render: node => {
                                                           if (node.descriptionFunc === '成本下降比例（DLC vs D2）') {
                                                               return <span
                                                                   style={{color: "red"}}>{node.fiveYear}</span>;
                                                           } else {
                                                               return node.fiveYear;
                                                           }

                                                       },
                                                   }
                                               ]}
                                        />
                                    </div>

                                </div>


                            </TabPanel>
                            {/*<TabPanel id="data">*/}
                            {/*    <div className="system">*/}

                            {/*        <div className="img">*/}
                            {/*            <img src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/total_cost_compare.png" alt="" style={{ width: '90%', height: '90%' }} />*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</TabPanel>*/}
                            <TabPanel id="network">
                                <div className="componentsStoreCalc">

                                    <Table
                                        verticalTop
                                        records={cvmListOther}
                                        recordKey="one"
                                        bordered='all'
                                        columns={[
                                            {
                                                key: "one",
                                                header: "产品/组件",
                                                align: 'left',
                                                width: '20%',
                                            },
                                            {
                                                key: "two",
                                                header: "版本",
                                                align: 'left',
                                                width: '20%',
                                            },
                                            {
                                                key: "three",
                                                header: "来源",
                                                align: 'left',
                                                width: '20%',
                                            },
                                            {
                                                key: "four",
                                                header: "备注",
                                                align: 'left',
                                                width: '40%'
                                            }

                                        ]}
                                    />
                                </div>
                            </TabPanel>
                            <TabPanel id="monitor">
                                <div className="componentsStoreCalc">

                                    <Table
                                        verticalTop
                                        records={cvmList}
                                        recordKey="instanceId"
                                        bordered='all'
                                        columns={[
                                            {
                                                key: "instanceId",
                                                header: "网络规划",
                                                align: 'left',
                                                width: '300px'
                                            },
                                            {
                                                key: "instanceName",
                                                header: "可用区域",
                                                align: 'left'
                                            },
                                        ]}
                                    />
                                </div>
                            </TabPanel>
                            <TabPanel id="sg">
                                <div className="project">
                                    <div className="title">
                                        QCBM 是采用微服务架构，并使用 dubbo-2.7.8 框架开发的一个网上书城沙盒演示项目。QCBM 的部署和代码托管在 Coding，详情可参见
                                        [QCBM
                                        项目](https://github.com/TencentCloud/container-demo/tree/main/dubbo-on-tke)。
                                    </div>
                                    <H4 style={{margin: '20px auto 10px'}}>QCBM 首页如下图展所示：</H4>
                                    <div className="img" style={{
                                        width: '100%',
                                        height: '600px',
                                        margin: '0 auto',
                                        boxSizing: 'border-box',
                                        padding: '10px',
                                    }}>

                                        <img src="https://main.qcloudimg.com/raw/958f718bb6e1656449e8bcdd9dd88ae2.png"
                                             alt="" style={{width: '100%', height: '100%'}}/>
                                    </div>
                                    <H4 style={{margin: '20px auto 10px'}}>QCBM 包含以下微服务：</H4>
                                    <Table
                                        verticalTop
                                        records={cvmListOne}
                                        recordKey="one"
                                        bordered='all'
                                        columns={[
                                            {
                                                key: "one",
                                                header: "微服务",
                                                align: 'left',
                                                width: '300px'
                                            },
                                            {
                                                key: "two",
                                                header: "说明",
                                                align: 'left',
                                                render: function getTwo(cvmListOne) {

                                                    return (
                                                        <p>{cvmListOne.two}</p>
                                                    )
                                                }
                                            },

                                        ]}
                                    />
                                    <H4 style={{margin: '20px auto 10px'}}>QCBM-Gateway 提供了如下 API 接口：</H4>
                                    <Table
                                        verticalTop
                                        records={cvmListTwo}
                                        recordKey="one"
                                        bordered='all'
                                        columns={[
                                            {
                                                key: "one",
                                                header: "API Function",
                                                align: 'left',
                                                width: '300px'
                                            },
                                            {
                                                key: "two",
                                                header: "Method",
                                                align: 'left',
                                                width: '200px'
                                            },
                                            {
                                                key: "three",
                                                header: "Path",
                                                align: 'left'
                                            },

                                        ]}
                                    />
                                </div>
                            </TabPanel>

                        </Tabs>


                    </Card.Body>
                </Card>
            </LayoutContent.Body>
        </LayoutContent>
    );
}
