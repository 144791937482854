import React, {useEffect, useState} from "react";
import "tea-component/dist/tea.css";
import {
    Card,  Layout,Table, Pagination, message
} from "tea-component";


import { queryLoginLogList} from "../../../services/function";



const {Content: LayoutContent} = Layout;


export default function LoginFunction() {
    const [loginLogList, setLoginLogList] = useState([]);
    const [pageIndex, setPageIndex] = useState("1");
    const [pageSize, setPageSize] = useState("10");
    const [totalData, setTotalData] = useState("0");
    const s1 = useSwitch();
    const s2 = useSwitch();
    const s3 = useSwitch();
    const s4 = useSwitch();
    const s5 = useSwitch();
    function useSwitch(defaultChecked = true) {
        const [value, onChange] = useState(defaultChecked);
        return { value, onChange: value => onChange(value) };
    }

    const queryLoginLog = async (pageIndex,pageSize) => {

        const res = await queryLoginLogList(pageIndex,pageSize)
        if (res.data.success) {
            setLoginLogList(res.data.data.data);
            setTotalData(res.data.data.total)
        } else {
            message.error({
                content: res.data.msg
            })
        }
        if (res.data && res.data.length > 200 && res.data.indexOf("Please sign in") !== -1) {
            window.location.href = "/login"
        }

    }

    const pageChange = async (pageQuery) =>{
        setPageSize(pageQuery.pageSize);
        setPageIndex(pageQuery.pageIndex);
        await queryLoginLog(pageQuery.pageIndex,pageQuery.pageSize);
    }
    useEffect(() => {
        (async () => {
            await queryLoginLog(pageIndex,pageSize);

        })()

    }, [])
    return (
        <LayoutContent>
            <LayoutContent.Header title='登录日志查看'>
            </LayoutContent.Header>

            <Card.Body>
                <div className="acrossCloudScene5Table"
                     style={{
                         display: "flex",
                         whiteSpace: "pre-wrap",
                         marginBottom: "15px"
                     }}>

                    <Table
                        verticalTop
                        records={
                            loginLogList
                        }
                        recordKey="productName"
                        bordered='all'

                        columns={[
                            {
                                key: "id",
                                header: "ID"
                            },
                            {
                                key: "userId",
                                header: "用户ID",

                            },
                            {
                                key: "userName",
                                header: "用户名称",

                            },
                            {
                                key: "loginTime",
                                header: "登录时间",
                            },
                            {
                                key: "loginOutTime",
                                header: "登出时间",

                            },
                            {
                                key: "createTime",
                                header: "创建时间",

                            },
                            {
                                key: "updateTime",
                                header: "更新时间",

                            },
                        ]}
                    />
                </div>

            </Card.Body>

            <Card.Body><Pagination  onPagingChange={(pageQuery) => pageChange(pageQuery)}
                                    recordCount={totalData}
                                    stateTextVisible={s1.value}
                                    pageSizeVisible={s2.value}
                                    pageIndexVisible={s3.value}
                                    jumpVisible={s4.value}
                                    endJumpVisible={s5.value}
            /></Card.Body>
        </LayoutContent>
    );
}
