import React from "react";
import "tea-component/dist/tea.css";
import {
    Layout, Card,Table, ExternalLink, Tabs, TabPanel
} from "tea-component";
import './index.scss'
const {Content: LayoutContent } = Layout;

const cvmList = [
    {
        instanceId: "Region",
        instanceName: "华北地区（北京）",
    },
    {
        instanceId: "AZ",
        instanceName: "北京七区，北京六区",
    },
    {
        instanceId: "VPC",
        instanceName: "10.1.0.0/16",
    },
    {
        instanceId: "容器子网",
        instanceName: "172.16.0.0/16",
    },
    {
        instanceId: "节点子网",
        instanceName: "10.0.0.0/24",
    }

];

const cvmListOther = [
    {
        one: 'TKE',
        two: 'N/A',
        three: '腾讯云',
        four: '提供机器学习计算环境，跨区高可用，资源弹性伸缩',
    },
    {
        one: 'TCR',
        two: 'N/A',
        three: '腾讯云',
        four: '镜像仓库，托管容器镜像服务，全球加速',
    },
    {
        one: 'CVM',
        two: 'N/A',
        three: '腾讯云',
        four: '搭载GPU硬件的云虚拟机，实时高速的并行计算和浮点计算能力',
    },
    {
        one: 'COS',
        two: 'N/A',
        three: '腾讯云',
        four: '训练数据及模型文件存储，跨区高可用，提供分层存储功能',
    },
    {
        one: 'CFS',
        two: 'N/A',
        three: '腾讯云',
        four: '训练数据及模型文件存储，跨区高可用',
    },
    {
        one: '云原生网关',
        two: 'N/A',
        three: '腾讯云',
        four: '流量治理，跨区高可用，一键扩容',
    },

    {
        one: 'CLS',
        two: 'N/A',
        three: '腾讯云',
        four: '日志采集与存储，一站式日志分析服务',
    },

    {
        one: 'Promethues',
        two: 'N/A',
        three: '腾讯云',
        four: '监控采集与展示，托管数据监控服务，跨区高可用',
    },

    {
        one: 'Coding',
        two: 'N/A',
        three: '腾讯云',
        four: '代码托管，持续集成，持续交付',
    }
]



export default function BaseInfo() {
    const tabs = [
        { id: "basic", label: "系统架构" },
        { id: "network", label: "云原生产品和组件" },
        { id: "monitor", label: "网络规划" },
        { id: "sg", label: '项目说明'},
       
    ];



    return (
        <LayoutContent>
            <LayoutContent.Header title='基本信息' />
            <LayoutContent.Body>
                <Card>
                    <Card.Body>
                        <Tabs tabs={tabs}>

                            <TabPanel id="basic">
                                <div className="system">

                                    <div className="img" style={{

                                        width: '1250px',
                                        height: '700px',
                                        margin: '0 auto',
                                        boxSizing: 'border-box',
                                        padding: '10px',
                                    }}>
                                        <img src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/gpuAi.png" alt="" style={{ width: '100%', height: '100%' }} />
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel id="network">
                                <div className="components">

                                    <Table
                                        verticalTop
                                        records={cvmListOther}
                                        recordKey="one"
                                        bordered='all'
                                        columns={[
                                            {
                                                key: "one",
                                                header: "产品/组件",
                                                align: 'left',
                                                width:'150px',
                                            },
                                            {
                                                key: "two",
                                                header: "版本",
                                                align: 'left',
                                                width:'150px',
                                            },
                                            {
                                                key: "three",
                                                header: "来源",
                                                align: 'left',
                                                width:'150px',
                                            },
                                            {
                                                key: "four",
                                                header: "备注",
                                                align: 'left',
                                                render: function getFour(cvmListOther) {

                                                    if (cvmListOther.four.indexOf('Github') !== -1 || cvmListOther.four.indexOf('下载') !== -1) {

                                                        return (
                                                            <ExternalLink
                                                                href={/(.+)?(?:\(|（)(.+)(?=\)|）)/.exec(cvmListOther.four)[2]}
                                                                style={{ marginRight: 10 }}
                                                            >
                                                                {cvmListOther.four}
                                                            </ExternalLink>
                                                        )

                                                    } else {
                                                        return (
                                                            <p>{cvmListOther.four}</p>
                                                        )
                                                    }
                                                }
                                            },
                                        ]}
                                    />
                                </div>
                            </TabPanel>
                            <TabPanel id="monitor">
                                <div className="network">
                                    <Table
                                        verticalTop
                                        records={cvmList}
                                        recordKey="instanceId"
                                        bordered='all'
                                        columns={[
                                            {
                                                key: "instanceId",
                                                header: "网络规划",
                                                align: 'left',
                                                width:'300px'
                                            },
                                            {
                                                key: "instanceName",
                                                header: "可用区域",
                                                align: 'left'
                                            },
                                        ]}
                                    />
                                </div>
                            </TabPanel>

                            <TabPanel id="sg">
                                <div className="system">

                                    <div className="img" style={{

                                        width: '1250px',
                                        height: '650px',
                                        margin: '0 auto',
                                        boxSizing: 'border-box',
                                        padding: '10px',
                                    }}>
                                        <p style={{marginBottom:"10px",fontWeight:"bold"}}>Stable diffusion webui是Stable diffusion的GUI，演示模型为sd-v1-5</p>

                                        <img src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/aigc/aigc_scene9.png" alt="" style={{ width: '90%', height: '90%' }} />
                                        <p style={{textAlign: "center"}}>程序地址：<a href="https://github.com/AUTOMATIC1111/stable-diffusion-webui" target="_blank">https://github.com/AUTOMATIC1111/stable-diffusion-webui</a></p>
                                    </div>
                                </div>
                            </TabPanel>
                        </Tabs>
                    </Card.Body>
                </Card>
            </LayoutContent.Body>
        </LayoutContent >
    );
}
