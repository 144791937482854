import {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  useRef,
  useImperativeHandle
} from 'react'
import { Form, Button, Modal, TagSelect } from 'tea-component'
import NodeCol from './nodeCol'
import { defaultColJson } from './defaultNode'

const NodeColJson = forwardRef(({ setData }, ref) => {
  const [dataJson, setDataJson] = useState(defaultColJson)

  const [visible, setVisible] = useState(false)
  const [title, setTitle] = useState('')
  const [keyJson, setKeyJson] = useState([])

  const nodeRef = useRef(null)
  const nodeJsonRef = useRef([])

  useImperativeHandle(ref, () => ({
    open
  }))
  const open = (data, t) => {
    setDataJson(data || defaultColJson)
    setTitle(t)
    setVisible(true)
  }
  const close = () => setVisible(false)

  return (
    <Modal
      className='nodeCol-pop'
      visible={visible}
      caption={title}
      onClose={close}
    >
      <Modal.Body>
        <Form>
          <Form.Item label='simple_json_builders'>
            <Button
              type='link'
              onClick={() => {
                nodeRef.current.open(dataJson.simpleJsonColDefs)
              }}
            >
              配置
            </Button>
            <NodeCol
              ref={nodeRef}
              setData={data => {
                setDataJson({
                  ...dataJson,
                  'simpleJsonColDefs': data
                })
              }}
            ></NodeCol>
          </Form.Item>
          <Form.Item label='mix_json_keys'>
            <TagSelect
              tips='按回车键确认'
              placeholder='输入key'
              defaultValue={dataJson.mix_json_keys}
              onChange={tags => {
                //对比 dataJson.mix_json_keys 和 tags 查出差异的index
                const indexs = dataJson.mix_json_keys.map((item, index) => {
                  if (tags.includes(item)) {
                    return index
                  }
                })
                const mix_json_builders = []
                indexs.forEach(index => {
                  index !== undefined &&
                    mix_json_builders.push(dataJson.mix_json_builders[index])
                })
                setDataJson({
                  ...dataJson,
                  'mix_json_keys': tags,
                  'mix_json_builders': mix_json_builders
                })
              }}
            />
          </Form.Item>
          <Form.Item label='mix_json_builders'>
            {dataJson.mix_json_keys.map((key, index) => (
              <span key={index}>
                <Button
                  type='link'
                  style={{ marginRight: '10px' }}
                  onClick={() => {
                    nodeJsonRef.current[index].open(
                      dataJson.mix_json_builders[index],
                      key
                    )
                  }}
                >
                  {key}配置
                </Button>
                <NodeColJson
                  ref={el => (nodeJsonRef.current[index] = el)}
                  setData={json => {
                    const jsonList = JSON.parse(
                      JSON.stringify(dataJson.mix_json_builders)
                    )
                    jsonList[index] = json
                    setDataJson({
                      ...dataJson,
                      'mix_json_builders': jsonList
                    })
                  }}
                ></NodeColJson>
              </span>
            ))}
          </Form.Item>
          <Form.Item label='cols_from_self_table'>
            <TagSelect
              defaultValue={dataJson.cols_from_self_table}
              placeholder='输入key'
              onChange={tags => {
                setDataJson({
                  ...dataJson,
                  'cols_from_self_table': tags
                })
              }}
              tips='按回车键确认'
            />
          </Form.Item>
          <Form.Item label='excluded_keys'>
            <TagSelect
              defaultValue={dataJson.excluded_keys}
              placeholder='输入key'
              onChange={tags => {
                setDataJson({
                  ...dataJson,
                  'excluded_keys': tags
                })
              }}
              tips='按回车键确认'
            />
          </Form.Item>
          <Form.Item label='filtered_keys'>
            <TagSelect
              defaultValue={dataJson.filtered_keys}
              placeholder='输入key'
              onChange={tags => {
                setDataJson({
                  ...dataJson,
                  'filtered_keys': tags
                })
              }}
              tips='按回车键确认'
            />
          </Form.Item>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type='primary'
          onClick={() => {
            setData(dataJson)
            console.log('col-json:', dataJson)
            close()
          }}
        >
          确定
        </Button>
        <Button type='weak' onClick={close}>
          取消
        </Button>
      </Modal.Footer>
    </Modal>
  )
})
export default NodeColJson
