import React, {useEffect, useState} from "react";
import "tea-component/dist/tea.css";
import {
    Card, Checkbox, Layout, Button, TabPanel, Tabs, message, Table, Pagination
} from "tea-component";
import './ReserveSceneFunciton.scss'
import moment, { isMoment } from "moment";
import {DatePicker} from "tea-component";
import {addReserveScene, getScene, listReserveScene, updateUserReserveSceneState} from "../../../services/function";
import {getFormatDate} from "../../../utils/getFormatDate";

const {Content: LayoutContent} = Layout;
const {RangePicker} = DatePicker;

export default function ReserserFunction() {
    const s1 = useSwitch();
    const s2 = useSwitch();
    const s3 = useSwitch();
    const s4 = useSwitch();
    const s5 = useSwitch();
    function useSwitch(defaultChecked = true) {
        const [value, onChange] = useState(defaultChecked);
        return { value, onChange: value => onChange(value) };
    }
    const [scene, setScene] = useState([]);
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [totalData, setTotalData] = useState("0");
    const [pageIndex, setPageIndex] = useState("1");
    const [pageSize, setPageSize] = useState("10");
    const [userReserveScene, setUserReserveScene] = useState([]);
    const [menuList, setMenuList] = useState([])
    const tabs = [
        {id: "scene1", label: "场景预约"},
        {id: "scene2", label: "查看场景预约详情"}
    ];
    const changeSelectTime = (value, value1) => {
        if(moment(value1).diff(value,"hour")>6){
            message.error({
                content: "预约开始时间和预约结束时间之间最大跨度为6个小时，请重新选择预约时间"
            })
            return ;
        }
        setStartTime(moment(value).format('YYYY-MM-DD HH:mm:ss'));
        setEndTime(moment(value1).format('YYYY-MM-DD HH:mm:ss'));
    }


    const range = (s, t) =>
        Array(24-6)
            .fill(t)
            .map((_, i) => t + i);

    const submitChange = async () => {
        if (scene.length <= 0) {
            message.error({
                content: "场景不能为空，请选择场景"
            })
            return;
        }
        if (null == startTime || startTime === 'undefined' || startTime === "") {
            message.error({
                content: "预约开始时间不能为空"
            })
            return;
        }
        if (null == endTime || endTime === 'undefined' || endTime === "") {
            message.error({
                content: "预约结束时间不能为空"
            })
            return;
        }

        if(moment(endTime).diff(startTime,"hour")>6){
            message.error({
                content: "预约开始时间和预约结束时间之间最大跨度为6个小时，请重新选择预约时间"
            })
            return ;
        }

        const res = await addReserveScene(scene, startTime, endTime)
        if (res.data.success) {
            message.success({
                content: res.data.msg
            })
        } else {
            message.error({
                content: res.data.msg
            })
        }
        if (res.data && res.data.length > 200 && res.data.indexOf("Please sign in") !== -1) {
            window.location.href = "/login"
        }


    }

    const queryReserveScene = async (pageIndex,pageSize) => {

        const res = await listReserveScene(pageIndex,pageSize)
        if (res.data.success) {
            setUserReserveScene(res.data.data.data);
            setTotalData(res.data.data.total)
            // message.success({
            //     content: res.data.msg
            // })
        } else {
            message.error({
                content: res.data.msg
            })
        }
        if (res.data && res.data.length > 200 && res.data.indexOf("Please sign in") !== -1) {
            window.location.href = "/login"
        }

    }


    const cancelReserve = async (data) => {

        const res = await updateUserReserveSceneState(data.id)
        if (res.data.success) {
            await queryReserveScene(pageIndex,pageSize);
            message.success({
                content: res.data.msg
            })
        } else {
            message.error({
                content: res.data.msg
            })
        }
        if (res.data && res.data.length > 200 && res.data.indexOf("Please sign in") !== -1) {
            window.location.href = "/login"
        }
    }


    const tabsChange = async (tab) => {

        if (tab.id === "scene2") {
            await queryReserveScene(1,10);
        }

    }

    const pageChange = async (pageQuery) =>{
        setPageIndex(pageQuery.pageIndex)
        setPageSize(pageQuery.pageSize)
        await queryReserveScene(pageQuery.pageIndex,pageQuery.pageSize);
    }

    const refreshPage = () => {
        window.location.reload();
    }
    const download = (url) => {
        window.location.href = url
    }

    function diffSecond(start) {
        if(null===start||'undefined'===start){
            start=getFormatDate();
        }
        const startTime = new Date(start);
        const endTime = new Date();
        const second_time = startTime - endTime ; //比较大小
        console.log("second_time"+second_time)
        return second_time;
    }

    function disabledDate(date, start) {
        // 选择范围在今天和今天之后，且选择跨度不能跨过1天
        const isAfterToday = date.isBefore(moment(), "day");
        if (moment.isMoment(start)) {
            return (
                !isAfterToday &&
                !(
                    moment(date)
                        .subtract(0, "day")
                        .isAfter(start, "day") ||
                    moment(date)
                        .add(0, "day")
                        .isBefore(start, "day")
                )
            );
        }
        return !isAfterToday;
    }

    useEffect(() => {
        (async () => {
            const sceneData = await getScene()
            const menuData = sceneData
                .filter(item => !!parseInt(item.enabled))
                .map(item => {
                    item.children = item.entry.map(subItem => {
                        subItem.link = `${subItem.link}`
                        return subItem
                    })
                    return item
                })
            setMenuList(menuData)

        })()

    }, [])
    return (
        <LayoutContent>
            <LayoutContent.Header title='场景预约'>
                <Button  type="pay" style={{marginLeft:"20px"}} href="javascript:void(0)" onClick={() => refreshPage()}>刷新</Button>



            </LayoutContent.Header>

            <Card style={{width: "100%", height: "100%"}}>
                <Card.Body>
                    <Tabs tabs={tabs} onActive={(tab) => tabsChange(tab)}>
                        <TabPanel id="scene1">
                            <Card.Body style={{color:"red",marginTop:"10px"}}> 温馨提示：预约【场景5：AI优化】 需要联系技术方案中心，提前开启GPU节点 &nbsp;&nbsp;<a href="javascript:void(0);"
                                                                                                                              onClick={() => download("https://johanyliu-1305426035.cos.ap-beijing.myqcloud.com/tem/tem_sandbox.pem")}>【点击下载】：Web Console登录公钥</a></Card.Body>
                            <Card.Body>
                            <Card.Body style={{marginBottom:"10px"}}> 选择场景：</Card.Body>
                            <Card.Body>
                                <section>
                                    <Checkbox.Group value={scene} onChange={value => setScene(value)}>
                                        {menuList.length > 0 &&
                                        menuList.map((data) => {
                                            return(   <Checkbox name={`${data.key}`}  style={{marginLeft: "30px"}} key={`${data.key}`}>
                                                    {`场景${data.key}：${data.title}`}
                                            </Checkbox>
                                            )
                                        })
                                        }
                                        {/*<Checkbox name="1" defaultValue={"true"} style={{marginLeft: "30px"}}>*/}
                                        {/*    场景1：同城双活*/}
                                        {/*</Checkbox>*/}
                                        {/*<Checkbox name="2" style={{marginLeft: "20px"}}>*/}
                                        {/*    场景2：电商秒杀*/}
                                        {/*</Checkbox>*/}
                                        {/*<Checkbox name="3" style={{marginLeft: "20px"}}>*/}
                                        {/*    场景3：实时分析*/}
                                        {/*</Checkbox>*/}
                                        {/*<Checkbox name="4" style={{marginLeft: "20px"}}>*/}
                                        {/*    场景4：存算分离*/}
                                        {/*</Checkbox>*/}
                                        {/*<Checkbox name="5" style={{marginLeft: "20px"}}>*/}
                                        {/*    场景5：AI优化*/}
                                        {/*</Checkbox>*/}
                                        {/*<Checkbox name="6" style={{marginLeft: "20px"}}>*/}
                                        {/*    场景6：跨云双活*/}
                                        {/*</Checkbox>*/}
                                        {/*<Checkbox name="7" style={{marginLeft: "20px"}}>*/}
                                        {/*    场景7：容器化*/}
                                        {/*</Checkbox>*/}
                                        {/*<Checkbox name="8" style={{marginLeft: "20px"}}>*/}
                                        {/*    场景8：灰度发布*/}
                                        {/*</Checkbox>*/}
                                    </Checkbox.Group>
                                </section>
                            </Card.Body>
                            <Card.Body style={{display: "none"}}>
                                已选中 {scene.length} 个场景：{scene.join(", ")}

                            </Card.Body>

                            <Card.Body style={{marginTop: "10px",marginBottom:"10px"}}>

                                预约开始时间至预约结束时间:

                            </Card.Body>
                            <Card.Body>
                                <RangePicker style={{marginLeft: "30px"}}
                                             separator="至"
                                             format="YYYY-MM-DD HH:mm:ss"
                                             showTime={{format: "HH:mm:ss"}}
                                           //  disabledHours={() => [...range(0, 8), ...range(13, 14), ...range(18, 23)]}
                                             disabledDate={disabledDate}
                                             disabledTime={(time, partial) => {
                                                 const [start] = time;
                                                 if (partial === "end" && isMoment(start)) {
                                                     return {
                                                         disabledHours: () =>  range(start.hour(), start.hour() + 6),
                                                         disabledMinutes: hour =>
                                                             hour - start.hour() > 6 ? range(0, start.minute() - 1) : [],
                                                     };
                                                 }
                                                 return {};
                                             }}
                                           //  // disabledTime={value=>disabledTaskDate(value[0],value[1])}
                                           // //range={value =>[moment(value[0], "HH:mm:ss"), moment(value[1], "HH:mm:ss")]}
                                             onChange={value => changeSelectTime(value[0], value[1])}
                                />

                            </Card.Body>


                                <Button onClick={() => submitChange()} style={{marginLeft: "40%", marginTop: "30px"}}
                                        type="pay">提交</Button>
                            </Card.Body>

                        </TabPanel>
                        <TabPanel id="scene2">

                            {/*<Card.Body>*/}

                            {/*  用户名:  <Input*/}
                            {/*        placeholder="请输入用户名"*/}
                            {/*        maxLength="20" size="s"*/}
                            {/*        id="userName"*/}
                            {/*        name="userName"/>*/}
                            {/*  状态：*/}
                            {/*</Card.Body>*/}

                            <Card.Body>
                                <div className="acrossCloudScene5Table"
                                     style={{
                                         display: "flex",
                                         whiteSpace: "pre-wrap",
                                         marginBottom: "15px"
                                     }}>

                                    <Table
                                        verticalTop
                                        records={
                                            userReserveScene
                                        }
                                        recordKey="productName"
                                        bordered='all'

                                        columns={[
                                            {
                                                key: "id",
                                                header: "ID"
                                            },
                                            {
                                                key: "loginName",
                                                header: "用户名",

                                            },
                                            {
                                                key: "sceneId",
                                                header: "场景名",

                                            },
                                            {
                                                key: "reserveState",
                                                header: "状态",
                                                render: userReserveScene => {
                                                    if(diffSecond(userReserveScene.endTime)<0){
                                                        return <span style={{color: "blue"}}>失效</span>
                                                    }
                                                   else if (userReserveScene.reserveState === 1) {
                                                        return <span style={{color: "green"}}>有效</span>;
                                                    } else if(userReserveScene.reserveState ===2){
                                                        return <span style={{color: "red"}}>取消</span>
                                                    }

                                                }

                                            },
                                            {
                                                key: "createTime",
                                                header: "创建时间",

                                            },
                                            {
                                                key: "startTime",
                                                header: "预约开始时间",

                                            },
                                            {
                                                key: "endTime",
                                                header: "预约结束时间",

                                            },
                                            {
                                                key: "op",
                                                header: "操作",
                                                render: function getFive(data) {
                                                    if(data.reserveState===1){
                                                    return <button onClick={() => cancelReserve(data)}>取消预约</button>
                                                    }else if(diffSecond(userReserveScene.endTime)<0) {
                                                        return <button onClick={() => cancelReserve(data)}  disabled="true">取消预约</button>
                                                    }else {
                                                        return <button onClick={() => cancelReserve(data)}  disabled="true">取消预约</button>
                                                    }

                                                }

                                            },

                                        ]}
                                    />
                                </div>

                            </Card.Body>

                            <Card.Body><Pagination  onPagingChange={(pageQuery) => pageChange(pageQuery)}
                                recordCount={totalData}
                                stateTextVisible={s1.value}
                                pageSizeVisible={s2.value}
                                pageIndexVisible={s3.value}
                                jumpVisible={s4.value}
                                endJumpVisible={s5.value}
                            /></Card.Body>
                        </TabPanel>

                    </Tabs>
                </Card.Body>
            </Card>

        </LayoutContent>
    );
}
