import React, {useEffect, useState} from "react";
import "tea-component/dist/tea.css";
import {
    Layout,
    Button,
    message,
    Table,
    Modal,
    Card,
    Tabs,
    TabPanel,
    ImagePreview, Text, Copy, Select, Input
} from "tea-component";
import './index.scss'
import {
    queryLogs,
    queryGpuAiPods,
    createGpuAiPods,
    deleteGpuAi,
    queryAiEvent,
    queryGpuAiNodes
} from "../../../services/function"

const {Content: LayoutContent, Content} = Layout;

function Title({children}) {
    return <h4 style={{margin: "20px 0"}}>{children}</h4>;
}

let pollTaskResultInterval

export default function AiFunction() {
    const [jobList, setJobList] = useState([])
    const [job1List, setJob1List] = useState([])
    const [modalVisible, setModalVisible] = useState(false)
    const [logModelVisible, setLogModelVisible] = useState(false)
    const [nodeList, setNodeList] = useState([])
    const [selectNode, setSelectNode] = useState("1")
    const [selectLog1, setSelectLog1] = useState([])
    const [selectEvent1, setSelectEvent1] = useState([])
    const [isLoadingGpu1, setLoadingGpu1] = useState(false)
    const [isLoadingGpu2, setLoadingGpu2] = useState(false)

    const [isLoadingDelGpu4, setLoadingDelGpu4] = useState(false)
    const [isLoadingEvent, setLoadingEvent] = useState(false)
    const [isLoadingLog, setLoadingLog] = useState(false)
    const [isLoadingRefresh, setLoadingRefresh] = useState(false)
    const [isLoadingRefreshLog, setLoadingRefreshLog] = useState(false)
    const [des1Visible, setDes1Visible] = useState(false)
    const [des2Visible, setDes2Visible] = useState(false)
    const {TextArea} = Input;
    const [contentText, setContentText] = useState("/usr/local/openmpi/bin/mpirun -np 2 -H taco-bench-worker-0:1,taco-bench-worker-1:1 --allow-run-as-root -bind-to none -map-by slot -x NCCL_ALGO=RING -x NCCL_DEBUG=INFO -x HOROVOD_MPI_THREADS_DISABLE=1 -x HOROVOD_FUSION_THRESHOLD=0  -x HOROVOD_CYCLE_TIME=0 -x LIGHT_TOPK_ALLREDUCE=1 -x LIGHT_TOPK_THRESHOLD=2097152 -x LD_LIBRARY_PATH -x PATH -mca btl_tcp_if_include eth0 python3 /mnt/tensorflow_synthetic_benchmark.py --model=VGG16 --batch-size=16")

    const selectPlan = [
        {value: "TACO", text: "TACO", tooltip: "TACO"},
        {value: "Horovod", text: "Horovod", tooltip: "TACO"}
    ];

    const selectModel = [

        {value: "VGG16", text: "VGG16", tooltip: "VGG16"},
        {value: "ResNet50", text: "ResNet50", tooltip: "ResNet50"}
    ];

    const selectBatchSize = [

        {value: "16", text: "16", tooltip: "16"},
        {value: "32", text: "32", tooltip: "32"},
        {value: "128", text: "128", tooltip: "128"},

    ]
    const [planValue, setPlanValue] = useState("TACO")

    const [modelValue, setModelValue] = useState("VGG16")

    const [batchSize, setBatchSize] = useState("16")

    const changeSelect = async () => {
        if (planValue == "TACO") {
            setContentText("/usr/local/openmpi/bin/mpirun -np 2 -H taco-bench-worker-0:1,taco-bench-worker-1:1 --allow-run-as-root -bind-to none -map-by slot -x NCCL_ALGO=RING -x NCCL_DEBUG=INFO -x HOROVOD_MPI_THREADS_DISABLE=1 -x HOROVOD_FUSION_THRESHOLD=0  -x HOROVOD_CYCLE_TIME=0 -x LIGHT_TOPK_ALLREDUCE=1 -x LIGHT_TOPK_THRESHOLD=2097152 -x LD_LIBRARY_PATH -x PATH -mca btl_tcp_if_include eth0 python3 /mnt/tensorflow_synthetic_benchmark.py --model=" + modelValue + " --batch-size=" + batchSize)
        } else if (planValue == "Horovod") {
            setContentText("/usr/local/openmpi/bin/mpirun -np 2 -H horovod-bench-worker-0:1,horovod-bench-worker-1:1 --allow-run-as-root -bind-to none -map-by slot -x NCCL_ALGO=RING -x NCCL_DEBUG=INFO -x HOROVOD_MPI_THREADS_DISABLE=1 -x HOROVOD_FUSION_THRESHOLD=0  -x HOROVOD_CYCLE_TIME=0  -x LD_LIBRARY_PATH -x PATH -mca btl_tcp_if_include eth0 python3 /mnt/tensorflow_synthetic_benchmark.py --model=" + modelValue + " --batch-size=" + batchSize)
        }
    }


    const [job1, setJob1] = useState("apiVersion: kubeflow.org/v1\n" +
        "kind: MPIJob\n" +
        "metadata:\n" +
        "  name: taco-bench\n" +
        "spec:\n" +
        "  slotsPerWorker: 1\n" +
        "  runPolicy:\n" +
        "    cleanPodPolicy: Running\n" +
        "  mpiReplicaSpecs:\n" +
        "    Launcher:\n" +
        "      replicas: 1\n" +
        "      template:\n" +
        "        spec:\n" +
        "          containers:\n" +
        "          - image: ccr.ccs.tencentyun.com/qcbm/taco-train:ttf115-cu112-cvm-0.4.2\n" +
        "            name: mpi-launcher\n" +
        "            command: [\"/bin/sh\", \"-ec\", \"sleep infinity\"]\n" +
        "            resources:\n" +
        "              limits:\n" +
        "                cpu: 1\n" +
        "                memory: 2Gi\n" +
        "    Worker:\n" +
        "      replicas: 2\n" +
        "      template:\n" +
        "        spec:\n" +
        "          containers:\n" +
        "          - image: ccr.ccs.tencentyun.com/qcbm/taco-train:ttf115-cu112-cvm-0.4.2\n" +
        "            name: mpi-worker\n" +
        "            securityContext:\n" +
        "              privileged: true\n" +
        "            volumeMounts:\n" +
        "              - mountPath: /sys/\n" +
        "                name: sys\n" +
        "              - mountPath: /dev/hugepages\n" +
        "                name: dev-hge\n" +
        "              - mountPath: /usr/local/tfabric/tools\n" +
        "                name: tfabric\n" +
        "            resources:\n" +
        "              limits:\n" +
        "                hugepages-1Gi: \"15Gi\"\n" +
        "                memory: \"5Gi\"\n" +
        "                nvidia.com/gpu: 1 # requesting 1 GPU\n" +
        "          volumes:\n" +
        "            - name: sys\n" +
        "              hostPath:\n" +
        "                path: /sys/\n" +
        "            - name: dev-hge\n" +
        "              hostPath:\n" +
        "                path: /dev/hugepages/\n" +
        "            - name: tfabric\n" +
        "              hostPath:\n" +
        "                path: /usr/local/tfabric/tools/\n")


    const [job2, setJob2] = useState("apiVersion: kubeflow.org/v1\n" +
        "kind: MPIJob\n" +
        "metadata:\n" +
        "  name: horovod-bench\n" +
        "spec:\n" +
        "  slotsPerWorker: 1\n" +
        "  runPolicy:\n" +
        "    cleanPodPolicy: Running\n" +
        "  mpiReplicaSpecs:\n" +
        "    Launcher:\n" +
        "      replicas: 1\n" +
        "      template:\n" +
        "        spec:\n" +
        "          containers:\n" +
        "            - image: ccr.ccs.tencentyun.com/qcbm/horovod-train:ttf115-cu112-cvm-0.4.2\n" +
        "              name: mpi-launcher\n" +
        "              command: [\"/bin/sh\", \"-ec\", \"sleep infinity\"]\n" +
        "              resources:\n" +
        "                limits:\n" +
        "                  cpu: 1\n" +
        "                  memory: 2Gi\n" +
        "    Worker:\n" +
        "      replicas: 2\n" +
        "      template:\n" +
        "        spec:\n" +
        "          containers:\n" +
        "            - image: ccr.ccs.tencentyun.com/qcbm/horovod-train:ttf115-cu112-cvm-0.4.2\n" +
        "              name: mpi-worker\n" +
        "              securityContext:\n" +
        "                privileged: true\n" +
        "              volumeMounts:\n" +
        "                - mountPath: /sys/\n" +
        "                  name: sys\n" +
        "                - mountPath: /dev/hugepages\n" +
        "                  name: dev-hge\n" +
        "                - mountPath: /usr/local/tfabric/tools\n" +
        "                  name: tfabric\n" +
        "              resources:\n" +
        "                limits:\n" +
        "                  hugepages-1Gi: \"15Gi\"\n" +
        "                  memory: \"5Gi\"\n" +
        "                  nvidia.com/gpu: 1 # requesting 1 GPU\n" +
        "          volumes:\n" +
        "            - name: sys\n" +
        "              hostPath:\n" +
        "                path: /sys/\n" +
        "            - name: dev-hge\n" +
        "              hostPath:\n" +
        "                path: /dev/hugepages/\n" +
        "            - name: tfabric\n" +
        "              hostPath:\n" +
        "                path: /usr/local/tfabric/tools/\n")


    const onCloseLog = () => {
        //console.log('pollTaskResultInterval', pollTaskResultInterval)
        setLogModelVisible(false)
        clearInterval(pollTaskResultInterval)
        // refreshPage();
    }

    const refreshPage = () => {
        window.location.reload();
    }

    const refreshPod = async (data) => {
        setLoadingRefresh(true);
        const jobRes = await queryGpuAiPods(data)
        if (jobRes.data.success) {
            if (1 == data) {
                jobRes && jobRes.data && setJobList(jobRes.data.data);
            } else {
                jobRes && jobRes.data && setJob1List(jobRes.data.data);
            }

        }
        setLoadingRefresh(false);
    }


    const goTxyLogin = () => {
        const w = window.open('_black') //这里是打开新窗口
        let url = 'https://cloud.tencent.com/login'
        w.location.href = url //这样就可以跳转了
    }
    const operateGpu1 = async () => {
        setLoadingGpu1(true)
        const res = await createGpuAiPods("1")
        if (res.data.success) {
            message.success({
                content: res.data.msg
            })
        } else if (res.data && res.data.length > 200 && res.data.indexOf("Please sign in") != -1) {
            window.location.href = "/login"
        } else {
            message.error({
                content: res.data.msg
            })
        }
        const jobRes = await queryGpuAiPods("1")
        if (jobRes.data.success) {
            jobRes && jobRes.data && setJobList(jobRes.data.data);
        }
        setLoadingGpu1(false)
        setTimeout(async () => {
            const jobRes = await queryGpuAiPods("1")
            if (jobRes.data.success) {
                jobRes && jobRes.data && setJobList(jobRes.data.data);
            }
        }, 5000)
    }
    const operateGpu2 = async () => {
        setLoadingGpu2(true)
        const res = await createGpuAiPods("2")
        if (res.data.success) {
            message.success({
                content: res.data.msg
            })
        } else if (res.data && res.data.length > 200 && res.data.indexOf("Please sign in") != -1) {
            window.location.href = "/login"
        } else {
            message.error({
                content: res.data.msg
            })
        }
        const jobRes = await queryGpuAiPods("2")
        if (jobRes.data.success) {
            jobRes && jobRes.data && setJob1List(jobRes.data.data);
        }
        setLoadingGpu2(false)
        setTimeout(async () => {
            const jobRes = await queryGpuAiPods("2")
            if (jobRes.data.success) {
                jobRes && jobRes.data && setJob1List(jobRes.data.data);
            }
        }, 5000)
    }


    const delGpu = async () => {
        setLoadingDelGpu4(true)
        const res = await deleteGpuAi()
        if (res.data.success) {
            message.success({
                content: res.data.msg
            })
        } else if (res.data && res.data.length > 200 && res.data.indexOf("Please sign in") != -1) {
            window.location.href = "/login"
        } else {
            setLoadingDelGpu4(false)
            message.error({
                content: res.data.msg
            })
        }

        setTimeout(async () => {
            const jobRes = await queryGpuAiPods("1")
            if (jobRes.data.success) {
                jobRes && jobRes.data && setJobList(jobRes.data.data);
            }

            const jobRes1 = await queryGpuAiPods("2")
            if (jobRes1.data.success) {
                jobRes1 && jobRes1.data && setJob1List(jobRes1.data.data);
            }
        }, 1000)

        setLoadingDelGpu4(false)
    }

    const queryAiEvents = async (scene, name) => {
        setModalVisible(false)
        const res = await queryAiEvent(scene, name)
        setModalVisible(true)
        if (res.data.success) {
            setSelectEvent1(res.data.data)
        } else if (res.data && res.data.length > 200 && res.data.data.indexOf("Please sign in") != -1) {
            window.location.href = "/login"
        } else {
            message.error({
                content: "事件生成中，请稍后"
            })
        }

    }


    const getLog = async () => {
        setLoadingLog(true)
        setLogModelVisible(false)
        const res = await queryLogs()
        const resList = res.data.data
        let logs = []
        if (null != resList && resList.length > 0) {
            logs = resList.map(i => {
                return {
                    name: i.name,
                    content: i.stream
                }
            })
            setSelectLog1(logs)
        }
        if (res.data.success) {
            setLogModelVisible(true)
            pollTaskResultInterval = setInterval(async () => {
                const res1 = await queryLogs()
                const resList1 = res1.data.data
                let logs = []
                if (null != resList1 && resList1.length > 0) {
                    logs = resList1.map(i => {
                        return {
                            name: i.name,
                            content: i.stream
                        }
                    })
                    setSelectLog1(logs)
                }

            }, 3000);
            // console.log(123, pollTaskResultInterval)

        } else if (res.data && res.data.length > 200 && res.data.data.indexOf("Please sign in") != -1) {
            window.location.href = "/login"
        } else {
            message.error({
                content: "日志生成中，请稍后再查"
            })
        }
        setLoadingLog(false)
    }


    const refreshLog = async () => {
        setLoadingRefreshLog(true)
        const res = await queryLogs()
        setLoadingRefreshLog(false);
        if (res.data.success) {
            setLogModelVisible(true)
            const resList = res.data.data
            let logs = []
            if (resList.length > 0) {
                logs = resList.map(i => {
                    return {
                        name: i.name,
                        content: i.stream
                    }
                })
                setSelectLog1(logs)
            }
        } else if (res.data && res.data.length > 200 && res.data.data.indexOf("Please sign in") != -1) {
            window.location.href = "/login"
        } else {
            message.error({
                content: res.data.msg
            })
        }
    }
    const refreshConsole = async (data) => {
        document.getElementById('refreshIframeGpu').src = "https://console.cloud.tencent.com/cvm/instance/detail?rid=" + data.rid + "&id=" + data.nodeId + "&tab=monitor";
    }




    const tabs = [
        {id: "scene1", label: "TACO环境"},
        {id: "scene2", label: "Horovod环境"},
    ];


    useEffect(() => {
        (async () => {
            const nodeRes = await queryGpuAiNodes()
            if (nodeRes.data.success) {
                nodeRes && nodeRes.data && setNodeList(nodeRes.data.data);
            }
            if (nodeRes.data && nodeRes.data > 200 && nodeRes.data.indexOf("Please sign in") != -1) {
                window.location.href = "/login"
            }
            const jobRes = await queryGpuAiPods("1")
            if (jobRes.data.success) {
                jobRes && jobRes.data && setJobList(jobRes.data.data);
            }

            const jobRes2 = await queryGpuAiPods("2")
            if (jobRes2.data.success) {
                jobRes2 && jobRes2.data && setJob1List(jobRes2.data.data);
            }
            if (jobRes.data && jobRes.data > 200 && jobRes.data.indexOf("Please sign in") != -1) {
                window.location.href = "/login"
            }
        })()

    }, [])
    return (
        <LayoutContent>
            <LayoutContent.Header title='AI加速优化'>
                <Button onClick={() => operateGpu1()} type="pay" loading={isLoadingGpu1} type="pay">TACO环境部署</Button>
                <Button onClick={() => operateGpu2()} type="pay" loading={isLoadingGpu2} type="pay">Horovod环境部署</Button>

                <Button onClick={() => delGpu()} type="primary" loading={isLoadingDelGpu4} type="primary">环境恢复</Button>
                <Button style={{float: "right"}} onClick={() => goTxyLogin()} type="weak">登录腾讯云</Button>
                <Button style={{float: "right"}} onClick={() => refreshPage()} type="weak">刷新</Button>
            </LayoutContent.Header>

            <div>
                <div style={{width: "40%", float: "left", height: "920px", overflow: "scroll"}}>

                    <div className="tableGpu">
                        <div className="title">节点列表</div>
                        <Table
                            verticalTop
                            records={
                                nodeList
                            }
                            recordKey="nodeId"
                            bordered='all'
                            columns={[
                                {
                                    key: "name",
                                    header: "节点名称",
                                    align: 'left'
                                },
                                {
                                    key: "nodeId",
                                    header: "CVM名称",
                                    align: 'left'
                                },

                                {
                                    key: "instanceType",
                                    header: "GPU机型",
                                    align: 'left',
                                    width:"30%"
                                },
                                {
                                    key: "qGpuTYpe",
                                    header: "GPU型号",
                                    align: 'left'
                                }
                            ]}
                        />
                    </div>

                    <div className="tableGpu">
                        <div className="title">场景简介</div>
                        <Card>
                            <Card.Body>
                                <Card.Body>程序介绍 ：horovod官方的benchmark脚本，支持基于随机数据的keras模型的分布式训练</Card.Body>
                                <Card.Body>TACO训练加速组件：
                                </Card.Body>
                                <Card.Body>1. LightCC：基于 Horovod 深度优化的分布式训练框架。
                                </Card.Body>
                                <Card.Body>2. HARP：自研用户态网络协议栈。
                                </Card.Body>
                                <Card.Body>查看任务YAML:
                                    <button
                                        style={{marginTop: "10px", marginBottom: "10px", marginLeft: "20px"}}
                                        onClick={() => {
                                            setDes1Visible(true);
                                        }}>TACO</button>
                                    <button
                                        style={{marginTop: "10px", marginBottom: "10px", marginLeft: "20px"}}
                                        onClick={() => {
                                            setDes2Visible(true);
                                        }}>Horovod
                                    </button>

                                </Card.Body>
                            </Card.Body>
                        </Card>

                    </div>

                    <div className="tableGpu">
                    <Card>
                        <Card.Body>
                            <Tabs tabs={tabs}>
                                <TabPanel id="scene1">

                                        <Card.Body> Taco环境运行情况
                                            <Button style={{marginLeft: "20px"}}
                                                    onClick={() => refreshPod("1")} type="primary">刷新</Button>
                                        </Card.Body>
                                        <Table
                                            verticalTop
                                            records={
                                                jobList
                                            }
                                            recordKey="name"
                                            bordered='all'
                                            columns={[
                                                {
                                                    key: "nodeName",
                                                    header: "节点名称",
                                                    align: 'left',
                                                    width: "15%"

                                                },
                                                {
                                                    key: "name",
                                                    header: "Pod名称",
                                                    align: 'left',
                                                    width: '30%'
                                                },
                                                {
                                                    key: "nameSpace",
                                                    header: "命名空间",
                                                    align: 'left',
                                                    width: "15%"

                                                },

                                                {
                                                    key: "status",
                                                    header: "状态",
                                                    align: 'left',
                                                    width: "15%"

                                                },
                                                {
                                                    key: "op",
                                                    header: "操作",
                                                    align: 'left',
                                                    width: "20%",
                                                    render: function getFive(data) {
                                                        return <Button onClick={() => {
                                                            setModalVisible(true);
                                                            queryAiEvents("1", data.name)
                                                        }} type="primary" loading={isLoadingEvent}>查看事件</Button>
                                                    }

                                                },
                                            ]}
                                        />

                                </TabPanel>

                                <TabPanel id="scene2">
                                    <div className="tableGpu">
                                        <Card.Body>Horovod环境运行情况
                                            <Button style={{marginLeft: "20px"}}
                                                    onClick={() => refreshPod("2")}
                                                    type="primary">刷新</Button></Card.Body>
                                        <Table
                                            verticalTop
                                            records={
                                                job1List
                                            }
                                            recordKey="name"
                                            bordered='all'
                                            columns={[
                                                {
                                                    key: "nodeName",
                                                    header: "节点名称",
                                                    align: 'left',
                                                    width: "15%"


                                                },
                                                {
                                                    key: "name",
                                                    header: "Pod名称",
                                                    align: 'left',
                                                    width: "30%"
                                                },
                                                {
                                                    key: "nameSpace",
                                                    header: "命名空间",
                                                    align: 'left',
                                                    width: "15%"

                                                },

                                                {
                                                    key: "status",
                                                    header: "状态",
                                                    align: 'left',
                                                    width: "15%"

                                                },
                                                {
                                                    key: "op",
                                                    header: "操作",
                                                    align: 'left',
                                                    width: "20%",
                                                    render: function getFive(data) {
                                                        return <Button onClick={() => {
                                                            setModalVisible(true);
                                                            queryAiEvents("2", data.name)
                                                        }} type="primary" loading={isLoadingEvent}>查看事件</Button>
                                                    }

                                                },
                                            ]}
                                        />
                                    </div>
                                </TabPanel>
                            </Tabs>
                        </Card.Body>
                    </Card>
                    </div>
                    <div className="tableGpu">
                        <div className="title">命令面板</div>
                        <Card>
                            <Card.Body>
                                <span className="selectFontClass">env：</span> <Select
                                appearance="button"
                                options={selectPlan}
                                value={planValue}
                                onChange={value => setPlanValue(value)}
                                size="auto"
                                className="selectButtonClass"
                            />
                                <span className="selectFontClass">model：</span> <Select
                                appearance="button"
                                options={selectModel}
                                value={modelValue}
                                onChange={value => setModelValue(value)}
                                size="auto"
                                className="selectButtonClass"
                            />
                                <span className="selectFontClass">batch-size：</span> <Select
                                appearance="button"
                                options={selectBatchSize}
                                value={batchSize}
                                onChange={value => setBatchSize(value)}
                                size="auto"
                                className="selectButtonClass"
                            />

                                <Button style={{marginLeft: "10px", marginTop: "5px"}} type="primary"
                                        onClick={() => changeSelect()}>生成</Button>
                                <Copy text={contentText}>
                                    <Button style={{marginLeft: "10px", marginTop: "5px"}}>复制</Button>
                                </Copy>

                                <div style={{marginTop: "10px"}}>
                                    <TextArea
                                        showCount
                                        size="full"
                                        value={contentText}></TextArea>
                                </div>


                            </Card.Body>

                        </Card>
                    </div>

                </div>


                <div style={{width: "60%", float: "left", height: "920px", overflow: "scroll"}}>
                    <div className="iframeTitle">TACO环境</div>
                    <iframe className="iframeA"
                            src="https://workbench.cloud.tencent.com/webshell?regionId=8&instanceId=ins-iixf4eh7&username=ubuntu&port=22"
                            width="100%" height="45%"  id="refreshIframeGpu"/>
                    <div className="iframeTitle">Horovod环境</div>

                    <iframe
                        className="iframeA"
                        src="https://workbench.cloud.tencent.com/webshell?regionId=8&instanceId=ins-iixf4eh7&username=ubuntu&port=22"
                        width="100%" height="45%"  id="refreshIframeGpu"/>
                </div>

            </div>
            <Modal visible={modalVisible} onClose={() => setModalVisible(false)} className="popup" caption="查看事件"
                   style={{fontSize: '14px'}}>
                <Modal.Body style={{textAlign: "center", width: '1150px', height: '500px'}}>
                    <Table style={{height: "500px", overflow: "scroll"}}
                           verticalTop
                           records={
                               selectEvent1
                           }
                           recordKey="name"
                           bordered='all'
                           columns={[
                               {
                                   key: "name",
                                   header: "名称",
                                   align: 'left',
                                   width: "15%"
                               },

                               {
                                   key: "type",
                                   header: "类型",
                                   align: 'left',
                                   width: "10%"

                               },
                               {
                                   key: "reason",
                                   header: "原因",
                                   align: 'left',
                                   width: "10%"

                               },
                               {
                                   key: "lastTransitionTime",
                                   header: "最后出现时间",
                                   align: 'left',
                                   width: "15%"

                               },
                               {
                                   key: "message",
                                   header: "消息",
                                   align: 'left'
                               }

                           ]}
                    />
                </Modal.Body>
            </Modal>

            {/*<Modal visible={logModelVisible} onClose={() => onCloseLog()} className="popup1"*/}
            {/*       caption="查看日志">*/}

            {/*    <Modal.Body style={{textAlign: "left"}}>*/}
            {/*        <button style={{marginTop: "10px", marginBottom: "10px", marginLeft: "20px"}}*/}
            {/*                onClick={() => {*/}
            {/*                    refreshLog();*/}
            {/*                }}>刷新*/}
            {/*        </button>*/}
            {/*        {selectLog1.length > 0 && (<div style={{display: "flex", whiteSpace: "pre-wrap"}}>*/}
            {/*            {*/}
            {/*                selectLog1.map((i, idx) => {*/}
            {/*                        return <div key={idx} style={{width: "50%"}}>*/}
            {/*                            <div style={{color: "red"}}>{i.name}</div>*/}
            {/*                            <div style={{color: "white"}}>{i.content}</div>*/}
            {/*                        </div>*/}
            {/*                    }*/}
            {/*                )*/}
            {/*            }*/}

            {/*        </div>)}*/}
            {/*    </Modal.Body>*/}
            {/*</Modal>*/}


            <Modal visible={des1Visible} onClose={() => setDes1Visible(false)} className="popupYamlAI"
                   caption="查看TACO Yaml文件"
                   style={{fontSize: '14px'}}>
                <Modal.Body style={{
                    textAlign: "left",
                    width: '600px',
                    height: '600px',
                    backgroundColor: 'black',
                    color: 'white',
                    overflow: "scroll"
                }}>
                    {/*<Copy text={job1}>*/}
                    {/*    <a style={{float: "right",marginRight:"10px"}}>复制</a>*/}
                    {/*</Copy>*/}
                    <div style={{display: "flex", whiteSpace: "pre-wrap"}}>
                        {job1}
                    </div>
                </Modal.Body>
            </Modal>

            <Modal visible={des2Visible} onClose={() => setDes2Visible(false)} className="popupYamlAI"
                   caption="查看Horovod Yaml文件"
                   style={{fontSize: '14px'}}>
                <Modal.Body style={{
                    textAlign: "left",
                    width: '600px',
                    height: '600px',
                    backgroundColor: 'black',
                    color: 'white',
                    overflow: "scroll"
                }}>
                    {/*<Copy text={job2}>*/}
                    {/*    <a style={{float: "right",marginRight:"10px"}}>复制</a>*/}
                    {/*</Copy>*/}
                    <div style={{display: "flex", whiteSpace: "pre-wrap"}}>
                        {job2}
                    </div>
                </Modal.Body>
            </Modal>


            {/*<Modal visible={cardModelVisible} onClose={() => setCardModelVisible(false)} className="popupLoginConsole"*/}
            {/*       caption="控制台"*/}
            {/*       style={{fontSize: '14px'}}>*/}
            {/*    <Modal.Body>*/}
            {/*        <div style={{width: "100%", height: "600px"}}>*/}
            {/*            <iframe*/}
            {/*                src="https://tkecache.cloud.tencent.com/qcloud/ccs/webconsole/index.html#rid=8&clusterId=cls-51llgohf&podId=qgpu-test4--1-s8mjx&containerName=container1&namespace=qgpu-test&shell=bash"*/}
            {/*                width="100%" height="100%" frameBorder="12" id="cardIframeGpu">*/}

            {/*            </iframe>*/}
            {/*        </div>*/}
            {/*    </Modal.Body>*/}
            {/*</Modal>*/}

        </LayoutContent>
    );
}
