import React, {useEffect, useState} from "react";
import "tea-component/dist/tea.css";
import {
    Layout,
    Button,
    message,
    Table,
    Modal,
    Card,
    Tabs,
    TabPanel,
    ImagePreview
} from "tea-component";
import './index.scss'
import {
    queryAiGcEvents,
    queryGpuAiGcNode,
    queryGpuAiGcPod,
    queryAiGcLogs,
    querySceneConfig
} from "../../../services/function"

const {Content: LayoutContent} = Layout;


let pollTaskResultInterval

export default function AiGcFunction() {
    const [nodeList, setNodeList] = useState([])
    const [jobList, setJobList] = useState([])
    const [modalVisible, setModalVisible] = useState(false)
    const [logModelVisible, setLogModelVisible] = useState(false)
    // const [cardModelVisible, setCardModelVisible] = useState(false)
    const [selectLog1, setSelectLog1] = useState([])
    const [selectEvent1, setSelectEvent1] = useState([])
    const [isLoadingEvent, setLoadingEvent] = useState(false)
    const [isLoadingLog, setLoadingLog] = useState(false)
    const [isLoadingRefresh, setLoadingRefresh] = useState(false)
    const [isLoadingRefreshLog, setLoadingRefreshLog] = useState(false)
    const [des1Visible, setDes1Visible] = useState(false)
    const [des2Visible, setDes2Visible] = useState(false)
    const [leftImage, setLeftImage] = useState("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/aigc/aigc_scene9_02.png")

    const [nonOptimization, setNonOptimization] = useState("http://43.142.236.60:32767/")
    const [optimization, setOptimization] = useState("http://150.158.225.101/")
    const [scene2Url, setScene2Url] = useState("https://console.cloud.tencent.com/cvm/instance/detail?searchParams=InstanceName%3Dcls-kq67os5v%26alias%3Dcls-kq67os5v%26rid%3D4&rid=4&id=ins-jvumu233&tab=monitor")

    const [scene3nonOptimization, setScene3NonOptimization] = useState("https://johanyliu-1305426035.cos.ap-beijing.myqcloud.com/taco/taco-ptm_100B_gpt3_layer96_a10040g.mov")
    const [scene3optimization, setScene3Optimization] = useState("https://johanyliu-1305426035.cos.ap-beijing.myqcloud.com/taco/com-ds_100B_gpt3_layer96_a10040g.mov")

    const job1 = "apiVersion: apps/v1\n" +
        "kind: Deployment\n" +
        "metadata:\n" +
        "  annotations:\n" +
        "    description: stable-diffusion-webui\n" +
        "  labels:\n" +
        "    k8s-app: stable-diffusion-webui\n" +
        "    qcloud-app: stable-diffusion-webui\n" +
        "  name: stable-diffusion-webui\n" +
        "  namespace: default\n" +
        "spec:\n" +
        "  replicas: 1\n" +
        "  selector:\n" +
        "    matchLabels:\n" +
        "      k8s-app: stable-diffusion-webui\n" +
        "      qcloud-app: stable-diffusion-webui\n" +
        "  template:\n" +
        "    metadata:\n" +
        "      labels:\n" +
        "        k8s-app: stable-diffusion-webui\n" +
        "        qcloud-app: stable-diffusion-webui\n" +
        "    spec:\n" +
        "      containers:\n" +
        "      - args:\n" +
        "        - --listen\n" +
        "        image: ccr.ccs.tencentyun.com/qcbm/stable-diffusion:v4\n" +
        "        imagePullPolicy: IfNotPresent\n" +
        "        name: sd-webui\n" +
        "        ports:\n" +
        "        - containerPort: 7860\n" +
        "          name: sd\n" +
        "          protocol: TCP\n" +
        "        resources:\n" +
        "          limits:\n" +
        "            cpu: \"20\"\n" +
        "            memory: 50Gi\n" +
        "            tke.cloud.tencent.com/qgpu-core: \"50\"\n" +
        "            tke.cloud.tencent.com/qgpu-memory: 10Gi\n" +
        "          requests:\n" +
        "            cpu: \"19\"\n" +
        "            memory: 51100Mi\n" +
        "        volumeMounts:\n" +
        "        - mountPath: /root/stable-diffusion-webui/models/Stable-diffusion\n" +
        "          name: sd\n" +
        "        - mountPath: /data\n" +
        "          name: unet\n" +
        "      imagePullSecrets:\n" +
        "      - name: qcloudregistrykey\n" +
        "      restartPolicy: Always\n" +
        "      volumes:\n" +
        "      - name: sd\n" +
        "        nfs:\n" +
        "          path: /erhyzv7m/models/Stable-diffusion\n" +
        "          server: 10.0.0.6\n" +
        "      - name: unet\n" +
        "        nfs:\n" +
        "          path: /erhyzv7m/data\n" +
        "          server: 10.0.0.6\n";


    const job2 = "apiVersion: apps/v1\n" +
        "kind: Deployment\n" +
        "metadata:\n" +
        "  annotations:\n" +
        "    description: stable-diffusion-webui-raw\n" +
        "  labels:\n" +
        "    k8s-app: stable-diffusion-webui-raw\n" +
        "    qcloud-app: stable-diffusion-webui-raw\n" +
        "  name: stable-diffusion-webui-raw\n" +
        "  namespace: default\n" +
        "spec:\n" +
        "  replicas: 1\n" +
        "  selector:\n" +
        "    matchLabels:\n" +
        "      k8s-app: stable-diffusion-webui-raw\n" +
        "      qcloud-app: stable-diffusion-webui-raw\n" +
        "  template:\n" +
        "    metadata:\n" +
        "      labels:\n" +
        "        k8s-app: stable-diffusion-webui-raw\n" +
        "        qcloud-app: stable-diffusion-webui-raw\n" +
        "    spec:\n" +
        "      containers:\n" +
        "      - args:\n" +
        "        - --listen\n" +
        "        image: ccr.ccs.tencentyun.com/qcbm/stable-diffusion:v4\n" +
        "        imagePullPolicy: IfNotPresent\n" +
        "        name: sd-webui\n" +
        "        ports:\n" +
        "        - containerPort: 7860\n" +
        "          name: sd\n" +
        "          protocol: TCP\n" +
        "        resources:\n" +
        "          limits:\n" +
        "            cpu: \"20\"\n" +
        "            memory: 50Gi\n" +
        "            tke.cloud.tencent.com/qgpu-core: \"50\"\n" +
        "            tke.cloud.tencent.com/qgpu-memory: 10Gi\n" +
        "          requests:\n" +
        "            cpu: \"19\"\n" +
        "            memory: 51100Mi\n" +
        "        volumeMounts:\n" +
        "        - mountPath: /root/stable-diffusion-webui/models/Stable-diffusion\n" +
        "          name: sd\n" +
        "        - mountPath: /data\n" +
        "          name: unet\n" +
        "      imagePullSecrets:\n" +
        "      - name: qcloudregistrykey\n" +
        "      restartPolicy: Always\n" +
        "      volumes:\n" +
        "      - name: sd\n" +
        "        nfs:\n" +
        "          path: /erhyzv7m/models/Stable-diffusion\n" +
        "          server: 10.0.0.6\n" +
        "      - name: unet\n" +
        "        nfs:\n" +
        "          path: /erhyzv7m/data\n" +
        "          server: 10.0.0.6\n";


    const onCloseLog = () => {
        //console.log('pollTaskResultInterval', pollTaskResultInterval)
        setLogModelVisible(false)
        clearInterval(pollTaskResultInterval)
        // refreshPage();
    }

    const refreshPage = () => {
        window.location.reload();
    }

    const refreshPod = async () => {
        setLoadingRefresh(true);
        const jobRes = await queryGpuAiGcPod()
        if (jobRes.data.success) {
            jobRes && jobRes.data && setJobList(jobRes.data.data);
        }
        setLoadingRefresh(false);
    }


    const goTxyLogin = () => {
        const w = window.open('_black') //这里是打开新窗口
        w.location.href = 'https://cloud.tencent.com/login' //这样就可以跳转了
    }

    const getEvents = async () => {
        setLoadingEvent(true)
        setModalVisible(false)
        const res = await queryAiGcEvents()
        setModalVisible(true)
        if (res.data.success) {
            const resList = res.data.data
            let events = []
            if (resList.length > 0) {
                events = resList.map(i => {
                    return {
                        name: i.name,
                        content: i.content
                    }
                })
                setSelectEvent1(events)
            }
        } else if (res.data && res.data.length > 200 && res.data.data.indexOf("Please sign in") !== -1) {
            window.location.href = "/login"
        } else {
            message.error({
                content: "事件生成中，请稍后"
            })
        }
        setLoadingEvent(false)
    }


    const getLog = async () => {
        setLoadingLog(true)
        setLogModelVisible(false)
        const res = await queryAiGcLogs()
        const resList = res.data.data
        let logs = []
        if (null != resList && resList.length > 0) {
            logs = resList.map(i => {
                return {
                    name: i.name,
                    content: i.stream
                }
            })
            setSelectLog1(logs)
        }
        if (res.data.success) {
            setLogModelVisible(true)
            pollTaskResultInterval = setInterval(async () => {
                const res1 = await queryAiGcLogs()
                const resList1 = res1.data.data
                let logs = []
                if (null != resList1 && resList1.length > 0) {
                    logs = resList1.map(i => {
                        return {
                            name: i.name,
                            content: i.stream
                        }
                    })
                    setSelectLog1(logs)
                }

            }, 3000);
            // console.log(123, pollTaskResultInterval)

        } else if (res.data && res.data.length > 200 && res.data.data.indexOf("Please sign in") !== -1) {
            window.location.href = "/login"
        } else {
            message.error({
                content: "日志生成中，请稍后再查"
            })
        }
        setLoadingLog(false)
    }


    const refreshLog = async () => {
        setLoadingRefreshLog(true)
        const res = await queryAiGcLogs()
        setLoadingRefreshLog(false);
        if (res.data.success) {
            setLogModelVisible(true)
            const resList = res.data.data
            let logs = []
            if (resList.length > 0) {
                logs = resList.map(i => {
                    return {
                        name: i.name,
                        content: i.stream
                    }
                })
                setSelectLog1(logs)
            }
        } else if (res.data && res.data.length > 200 && res.data.data.indexOf("Please sign in") !== -1) {
            window.location.href = "/login"
        } else {
            message.error({
                content: res.data.msg
            })
        }
    }
    const refreshConsole = async (data) => {
        document.getElementById('refreshIframeGpu').src = "https://console.cloud.tencent.com/cvm/instance/detail?rid=" + data.rid + "&id=" + data.nodeId + "&tab=monitor";
        document.getElementById('rightFrame1').style.display = "none";
        document.getElementById('rightFrame2').style.display = "block";
        document.getElementById('rightFrame3').style.display = "none";
    }

    const getCardInfo = async (data) => {
        // setCardModelVisible(true)
        // setTimeout(()=>{
        //    // console.log(111, document.getElementById('cardIframeGpu'))
        //     document.getElementById('cardIframeGpu').src = "https://tkecache.cloud.tencent.com/qcloud/ccs/webconsole/index.html#rid=8&clusterId=cls-51llgohf&podId="+data.name+"&containerName=container1&namespace="+data.nameSpace+"&shell=bash";
        //     console.log(2222,document.getElementById('cardIframeGpu').src)
        // },500)

        const w = window.open('_black') //这里是打开新窗口
        w.location.href = "https://tkecache.cloud.tencent.com/qcloud/ccs/webconsole/index.html#rid="+data.rid+"&clusterId=" + data.clusterId + "&podId=" + data.name + "&containerName="+data.containerName+"&namespace=" + data.nameSpace + "&shell=bash";
        //这样就可以跳转了
    }


    const tabs = [
        {id: "scene1", label: "场景1"},
        {id: "scene2", label: "场景2"},
        {id: "scene3", label: "场景3"},
    ];

    const tabsChange = async (tab) => {
        if ((tab.id === "scene2")) {
            document.getElementById('rightFrame2').style.display = "block";
            document.getElementById('rightFrame1').style.display = "none";
            document.getElementById('rightFrame3').style.display = "none";
            setLeftImage("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/aigc/aigc_scene9_1.png");
        }
        if ((tab.id === "scene1")) {
            document.getElementById('rightFrame2').style.display = "none";
            document.getElementById('rightFrame1').style.display = "block";
            document.getElementById('rightFrame3').style.display = "none";
            setLeftImage("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/aigc/aigc_scene9_02.png");
        }

        if ((tab.id === "scene3")) {
            document.getElementById('rightFrame1').style.display = "none";
            document.getElementById('rightFrame2').style.display = "none";
            document.getElementById('rightFrame3').style.display = "block";
            setLeftImage("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/aigc/aigc_scene9_03.png");
        }
    }


    useEffect(() => {
        //
        (async () => {
            const res = await queryGpuAiGcNode()
            if (res.data.success) {
                res && res.data && setNodeList(res.data.data);
            }
            if (res.data && res.data > 200 && res.data.indexOf("Please sign in") !== -1) {
                window.location.href = "/login"
            }
            const jobRes = await queryGpuAiGcPod()
            if (jobRes.data.success) {
                jobRes && jobRes.data && setJobList(jobRes.data.data);
            }

            if (jobRes.data && jobRes.data > 200 && jobRes.data.indexOf("Please sign in") !== -1) {
                window.location.href = "/login"
            }

            const sceneRes = await  querySceneConfig(11,3)
            if(sceneRes.data.success){
                let datas = sceneRes.data.data;
                if(null!=datas){
                    datas.map(function (config){
                        if(config.configKey==="ai_gc_non_optimization_url"){
                            setNonOptimization(config.configValue);
                        }
                        else if(config.configKey==="ai_gc_optimization_url"){
                            setOptimization(config.configValue);
                        }
                        else if(config.configKey === "ai_gc_scene2_url"){
                            setScene2Url(config.configValue);
                        }
                        else if(config.configKey === "ai_gc_scene3_non_optimization_url"){
                            setScene3NonOptimization(config.configValue);
                        }
                        else if(config.configKey === "ai_gc_scene3_optimization_url"){
                            setScene3Optimization(config.configValue);
                        }

                    })
                }
            }
        })()

    }, [])
    return (
        <LayoutContent>
            <LayoutContent.Header title='AIGC演示'>
                <Button style={{float: "right"}} onClick={() => goTxyLogin()} type="weak">登录腾讯云</Button>
                <Button style={{float: "right"}} onClick={() => refreshPage()} type="weak">刷新</Button>
            </LayoutContent.Header>


            <div style={{border:"1px solid black"}}>
                <div style={{width: "40%", float: "left", height: "920px", overflow: "scroll"}}>

                    <div className="tableAiGc">
                        <div className="tableAiGc">

                            <div className="title">场景简介</div>

                            <Card>
                                <Card.Body>
                                    <Tabs tabs={tabs} onActive={(tab) => tabsChange(tab)}>

                                        <TabPanel id="scene1">
                                            <Card.Body>场景名：Stable diffusion TACO推理加速 </Card.Body>
                                            <Card.Body>部署模式：两个SD WebUI推理服务都使用sd-1.5模型，其中一个为原始模型，一个为TACO优化模型</Card.Body>
                                            <Card.Body>执行预期：TACO优化模型，对比非优化，平均推理时间提升30%以上</Card.Body>
                                        </TabPanel>

                                        <TabPanel id="scene2">
                                            <Card.Body>场景名：GPU成本优化</Card.Body>
                                            <Card.Body>部署模式：虚拟化qGPU模式部署，1张A10 GPU显卡，部署两个SD
                                                WebUI服务，平分显卡算力和显存资源</Card.Body>
                                            <Card.Body>执行预期：两个SD WebUI服务可以同时启动，在保证各10G显存隔离的前提下，抢占剩余显卡资源</Card.Body>
                                            <Card.Body>查看任务YAML:
                                                <button
                                                    style={{
                                                        marginLeft: "20px"
                                                    }}
                                                    onClick={() => {
                                                        setDes1Visible(true);
                                                    }}>stable-diffusion-webui</button>
                                                <button
                                                    style={{
                                                        marginLeft: "20px"
                                                    }}
                                                    onClick={() => {
                                                        setDes2Visible(true);
                                                    }}>stable-diffusion-webui-raw
                                                </button>
                                            </Card.Body>
                                        </TabPanel>

                                        <TabPanel id="scene3">
                                            <Card.Body>场景名：TACO加速大模型预训练框架AngelPTM </Card.Body>
                                            <Card.Body>执行预期：TACO优化训练框架，对比非优化框架，模型容纳规模更大</Card.Body>
                                        </TabPanel>
                                    </Tabs>


                                </Card.Body>
                            </Card>

                        </div>
                        <div className="title">节点列表</div>
                        <Table
                            verticalTop
                            records={
                                nodeList
                            }
                            recordKey="nodeId"
                            bordered='all'
                            columns={[
                                {
                                    key: "name",
                                    header: "节点名称",
                                    align: 'left'
                                },
                                {
                                    key: "nodeId",
                                    header: "CVM名称",
                                    align: 'left'
                                },
                                {
                                    key: "instanceType",
                                    header: "GPU机型",
                                    align: 'left'
                                },
                                {
                                    key: "qGpuTYpe",
                                    header: "GPU型号",
                                    align: 'left'
                                },

                                // {
                                //     key: "instanceType",
                                //     header: "类型",
                                //     align: 'left'
                                // },
                                {
                                    key: "type",
                                    header: "节点类型",
                                    align: 'left'
                                },
                                // {
                                //     key: "status",
                                //     header: "状态",
                                //     align: 'left',
                                //     width:"5%"
                                //
                                // },

                                {
                                    key: "op",
                                    header: "操作",
                                    align: 'left',

                                    render: function getFour(data) {
                                        return <Button onClick={() => refreshConsole(data)} type="pay">查看监控</Button>
                                    }
                                },

                            ]}
                        />
                    </div>


                    <div className="tableAiGc">

                        <div className="title">Pod运行情况
                            <Button style={{marginLeft: "20px"}} onClick={() => {
                                setModalVisible(true);
                                getEvents()
                            }} type="primary" loading={isLoadingEvent}>查看事件</Button>
                            <Button style={{marginLeft: "20px"}} onClick={() => {
                                setLogModelVisible(true);
                                getLog()
                            }} type="primary" loading={isLoadingLog}>查看日志</Button>
                            <Button loading={isLoadingRefresh} style={{marginLeft: "20px"}}
                                    onClick={() => refreshPod()} type="primary">刷新</Button>
                        </div>
                        {/*<Card>*/}
                        {/*    <Card.Body>*/}


                        <Table
                            verticalTop
                            records={
                                jobList
                            }
                            recordKey="name"
                            bordered='all'
                            columns={[
                                {
                                    key: "nodeName",
                                    header: "节点名称",
                                    align: 'left'

                                },
                                {
                                    key: "name",
                                    header: "Pod名称",
                                    align: 'left'
                                },
                                {
                                    key: "nameSpace",
                                    header: "命名空间",
                                    align: 'left'

                                },
                                {
                                    key: "podIp",
                                    header: "podIP",
                                    align: 'left'

                                },

                                {
                                    key: "status",
                                    header: "状态",
                                    align: 'left'

                                },
                                {
                                    key: "op",
                                    header: "操作",
                                    align: 'left',
                                    render: function getFive(data) {
                                        return <Button onClick={() => getCardInfo(data)} type="pay">登录</Button>
                                    }

                                },
                            ]}
                        />

                        {/*    </Card.Body>*/}
                        {/*</Card>*/}
                    </div>

                    <div className="tableAiGc">
                        <div className="title">成本模型</div>
                        <Card>
                            <Card.Body>
                                <ImagePreview
                                    previewSrc={leftImage}
                                    previewTitle="成本模型">
                                    {open => <a onClick={open}> <img id="picIframeGpu"
                                                                     src={leftImage}
                                                                     alt="" style={{width: '100%', height: '100%'}}/>
                                    </a>}
                                </ImagePreview>

                            </Card.Body>
                        </Card>
                        {/*<a onClick={() => showPic()} >*/}
                        {/*<img id="picIframeGpu" src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/gpu/gpu_pic.png" alt="" style={{ width: '90%', height: '180px' }} />*/}
                        {/*</a>*/}
                    </div>

                </div>
                <div id="rightFrame2" style={{width: "60%", float: "left", height: "920px", overflow: "scroll",display: "none"}}>
                    <iframe
                        src={scene2Url}
                        width="100%" height="100%" frameBorder="12" id="refreshIframeGpu"/>
                </div>

                <div id="rightFrame1"
                     style={{width: "60%", float: "left", height: "920px", overflow: "scroll", display: "block"}}>

                    <div className="iframeAiGcTitle">非优化</div>

                    <iframe
                        className="iframeA"
                        src={nonOptimization}
                        width="100%" height="45%" frameBorder="12"/>

                    <div className="iframeAiGcTitle">TACO优化</div>
                    <iframe className="iframeA"
                            src={optimization}
                            width="100%" height="45%" frameBorder="12"/>
                </div>

                <div id="rightFrame3"
                     style={{width: "60%", float: "left", height: "920px", overflow: "scroll", display: "none"}}>

                    <div className="iframeAiGcTitle">非优化</div>

                    <video controls={true}
                           src={scene3nonOptimization}
                           height="45%" width="100%"/>

                    <div className="iframeAiGcTitle">TACO优化</div>
                    <video controls={true}
                           src={scene3optimization}
                           height="45%" width="100%"/>
                </div>
            </div>

            <Modal visible={modalVisible} onClose={() => setModalVisible(false)} className="popup" caption="查看事件"
                   style={{fontSize: '14px'}}>
                <Modal.Body style={{textAlign: "center", width: '1150px', height: '500px', overflow: "scroll"}}>

                    {selectEvent1.length > 0 ? (<div style={{display: "flex"}}>
                        {
                            selectEvent1.map((i, idx) => {
                                    return <div key={idx} style={{width: "50%"}}>
                                        <div>
                                            <tilte>{i.name}</tilte>
                                        </div>
                                        <div><Table
                                            verticalTop
                                            records={
                                                i.content
                                            }
                                            recordKey="name"
                                            bordered='all'
                                            columns={[
                                                {
                                                    key: "name",
                                                    header: "名称",
                                                    align: 'left',
                                                    width: "12.5%"
                                                },

                                                {
                                                    key: "lastTransitionTime",
                                                    header: "最后出现时间",
                                                    align: 'left',
                                                    width: "12.5%"

                                                },
                                                {
                                                    key: "message",
                                                    header: "消息",
                                                    align: 'left',
                                                    width: "25%"
                                                }

                                            ]}
                                        />
                                        </div>
                                    </div>
                                }
                            )
                        }
                    </div>):<div>

                        <Table
                            verticalTop
                            recordKey="name"
                            bordered='all'
                            columns={[
                                {
                                    key: "name",
                                    header: "名称",
                                    align: 'left',
                                    width: "12.5%"
                                },

                                {
                                    key: "lastTransitionTime",
                                    header: "最后出现时间",
                                    align: 'left',
                                    width: "12.5%"

                                },
                                {
                                    key: "message",
                                    header: "消息",
                                    align: 'left',
                                    width: "25%"
                                }

                            ]}
                        />
                        事件列表为空</div>}

                </Modal.Body>
            </Modal>

            <Modal visible={logModelVisible} onClose={() => onCloseLog()} className="popup1"
                   caption="查看日志">

                <Modal.Body style={{textAlign: "left", overflow: "scroll", width: "1150px", height: "600px"}}>
                    <Button type="primary" loading={isLoadingRefreshLog}
                            style={{marginTop: "10px", marginBottom: "10px", marginLeft: "20px"}}
                            onClick={() => {
                                refreshLog();
                            }}>刷新
                    </Button>
                    {selectLog1.length > 0 && (<div style={{display: "flex", whiteSpace: "pre-wrap"}}>
                        {
                            selectLog1.map((i, idx) => {
                                    return <div key={idx} style={{width: "50%"}}>
                                        <div style={{color: "red"}}>{i.name}</div>
                                        <div style={{color: "white"}}>{i.content}</div>
                                    </div>
                                }
                            )
                        }

                    </div>)}
                </Modal.Body>
            </Modal>


            <Modal visible={des1Visible} onClose={() => setDes1Visible(false)} className="popup2"
                   caption="查看stable-diffusion-webui"
                   style={{fontSize: '14px'}}>
                <Modal.Body style={{
                    textAlign: "left",
                    width: '500px',
                    height: '600px',
                    backgroundColor: 'black',
                    color: 'white',
                    overflow: "scroll"
                }}>
                    {/*<Copy text={job1}>*/}
                    {/*    <a style={{float: "right"}}>复制</a>*/}
                    {/*</Copy>*/}
                    <div style={{display: "flex", whiteSpace: "pre-wrap"}}>
                        {job1}
                    </div>
                </Modal.Body>
            </Modal>

            <Modal visible={des2Visible} onClose={() => setDes2Visible(false)} className="popup2"
                   caption="查看stable-diffusion-webui-raw"
                   style={{fontSize: '14px'}}>
                <Modal.Body style={{
                    textAlign: "left",
                    width: '500px',
                    height: '600px',
                    backgroundColor: 'black',
                    color: 'white',
                    overflow: "scroll"
                }}>
                    {/*<Copy text={job2}>*/}
                    {/*    <a style={{float: "right"}}>复制</a>*/}
                    {/*</Copy>*/}
                    <div style={{display: "flex", whiteSpace: "pre-wrap"}}>
                        {job2}
                    </div>
                </Modal.Body>
            </Modal>


            {/*<Modal visible={cardModelVisible} onClose={() => setCardModelVisible(false)} className="popupLoginConsole"*/}
            {/*       caption="控制台"*/}
            {/*       style={{fontSize: '14px'}}>*/}
            {/*    <Modal.Body>*/}
            {/*        <div style={{width: "100%", height: "600px"}}>*/}
            {/*            <iframe*/}
            {/*                src="https://tkecache.cloud.tencent.com/qcloud/ccs/webconsole/index.html#rid=8&clusterId=cls-51llgohf&podId=qgpu-test4--1-s8mjx&containerName=container1&namespace=qgpu-test&shell=bash"*/}
            {/*                width="100%" height="100%" frameBorder="12" id="cardIframeGpu">*/}

            {/*            </iframe>*/}
            {/*        </div>*/}
            {/*    </Modal.Body>*/}
            {/*</Modal>*/}


            {/*<Modal visible={picModelVisible} onClose={() => setPicModelVisible(false)} caption="查看成本模型"*/}
            {/*       style={{fontSize: '14px', width: "1200px", height: "600px"}}>*/}
            {/*    <Modal.Body>*/}
            {/*        <div style={{width: "1000px", height: "600px"}}>*/}
            {/*            <iframe*/}
            {/*                src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/gpu/gpu_pic_big.png"*/}
            {/*                width="100%" height="100%" frameBorder="12" id="cardIframeGpu">*/}

            {/*            </iframe>*/}
            {/*        </div>*/}
            {/*    </Modal.Body>*/}
            {/*</Modal>*/}


        </LayoutContent>
    );
}
