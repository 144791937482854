import React from "react";
import "tea-component/dist/tea.css";
import {
    Layout, Card, ImagePreview, List, Table, Bubble,
    Icon, H4, ExternalLink, Tabs, TabPanel
} from "tea-component";
import './index.scss'
const { Body, Sider, Content: LayoutContent } = Layout;

const cvmList = [
    {
        instanceId: "Region",
        instanceName: "华北地区（北京）",
    },
    {
        instanceId: "AZ",
        instanceName: "北京七区，北京六区",
    },
    {
        instanceId: "VPC",
        instanceName: "10.1.0.0/16",
    },
    {
        instanceId: "容器子网",
        instanceName: "10.1.5.0/24",
    },
    {
        instanceId: "节点子网",
        instanceName: "10.1.6.0/24,10.1.7.0/24",
    }

];

const cvmListOther = [
    {
        one: 'TKE',
        two: 'N/A',
        three: '腾讯云',
        four: '提供机器学习计算环境，跨区高可用，资源弹性伸缩',
    },
    {
        one: 'TCR',
        two: 'N/A',
        three: '腾讯云',
        four: '镜像仓库，托管容器镜像服务，全球加速',
    },
    {
        one: 'CVM',
        two: 'N/A',
        three: '腾讯云',
        four: '搭载GPU硬件的云虚拟机，实时高速的并行计算和浮点计算能力',
    },
    {
        one: 'COS',
        two: 'N/A',
        three: '腾讯云',
        four: '训练数据及模型文件存储，跨区高可用，提供分层存储功能',
    },
    {
        one: '云数据库MySQL',
        two: '5.7',
        three: '腾讯云',
        four: '事务型数据存储，跨区高可用，一键扩容',
    },
    {
        one: '云数据库Redis',
        two: '5.0',
        three: '腾讯云',
        four: '缓存服务，跨区高可用，一键扩容',
    },
    {
        one: '云原生网关',
        two: 'N/A',
        three: '腾讯云',
        four: '流量治理，跨区高可用，一键扩容',
    },

    {
        one: 'CLS',
        two: 'N/A',
        three: '腾讯云',
        four: '日志采集与存储，一站式日志分析服务',
    },

    {
        one: 'Promethues',
        two: 'N/A',
        three: '腾讯云',
        four: '监控采集与展示，托管数据监控服务，跨区高可用',
    },

    {
        one: 'Coding',
        two: 'N/A',
        three: '腾讯云',
        four: '代码托管，持续集成，持续交付',
    }
]

const cvmListOne = [
    {
        one: 'QCBM-Front',
        two: '使用 React 开发的前端项目，基于 Nginx 官方提供的 [1.19.8 Docker 镜像](https://hub.docker.com/_/nginx) 构建和部署。'
    },
    {
        one: 'QCBM-Gateway',
        two: 'API 网关，接受前端的 HTTP 请求，并将其转化为后台的 Dubbo 请求。',
    },
    {
        one: 'User-Service',
        two: '基于 Dubbo 的微服务，提供用户注册、登录、鉴权等功能。',
    },
    {
        one: 'Favorites-Service',
        two: '基于 Dubbo 的微服务，提供用户图书收藏功能。',
    },
    {
        one: 'Order-Service',
        two: '基于 Dubbo 的微服务，提供用户订单生成和查询等功能。',
    },
    {
        one: 'Store-Service',
        two: '基于 Dubbo 的微服务，提供图书信息的存储等功能。',
    },
    {
        one: 'Seckill-Service',
        two: '基于 Dubbo 的微服务，提供秒杀功能，秒杀高并发不影响其他服务。',
    },

]

const cvmListTwo = [
    {
        one: '用户注册',
        two: 'POST',
        three: '/api/auth/signup',
    },
    {
        one: '用户登录',
        two: 'POST',
        three: '/api/auth/signin',
    },
    {
        one: '查询用户',
        two: 'GET',
        three: '/api/admin/user/{userId}',
    },
    {
        one: '删除用户',
        two: 'DELETE',
        three: '/api/admin/user/delete',
    },
    {
        one: '图书列表',
        two: 'GET',
        three: '/api/book/all',
    },
    {
        one: '图书查询',
        two: 'GET',
        three: '/api/book/{isbn}',
    },
    {
        one: '购书',
        two: 'GET',
        three: '/api/book/purchase',
    },
    {
        one: '用户订单列表',
        two: 'GET',
        three: '/api/order/{userId}',
    },
    {
        one: '图书收藏',
        two: 'GET',
        three: '/api/favorites/add',
    },
    {
        one: '用户收藏列表',
        two: 'GET',
        three: '/api/favorites/query/{userId}',
    },
    {
        one: '删除用户收藏的图书',
        two: 'GET',
        three: '/api/favorites/delete',
    },
    {
        one: '秒杀列表查询接口',
        two: 'GET',
        three: '/api/bookstorage/list',
    },
    {
        one: '秒杀接口',
        two: 'GET',
        three: '/api/seckill/book?userId={userId}&isbn={isbn}',
    },
    {
        one: '订单查询接口',
        two: 'GET',
        three: '/api/order?userId={userId}&pageSize={pageSize}&pageNumber={pageNumber}',
    },

]

export default function BaseInfo() {
    const tabs = [
        { id: "basic", label: "系统架构" },
        { id: "network", label: "云原生产品和组件" },
        { id: "monitor", label: "网络规划" },
        { id: "sg", label: '项目说明'},
       
    ];

    const getBubble=()=>{
        return(
            <Bubble
            arrowPointAtCenter
            placement="top-end"
            content={`QCBM 是采用微服务架构，并使用 dubbo-2.7.8 框架开发的一个网上书城沙盒演示项目。QCBM 的部署和代码托管在 Coding，详情可参见 [QCBM 项目](https://github.com/TencentCloud/container-demo/tree/main/dubbo-on-tke)。`}

        >
            <Icon type="info" style={{ marginLeft: '5px' }} />
        </Bubble>
        )
    }

    return (
        <LayoutContent>
            <LayoutContent.Header title='基本信息' />
            <LayoutContent.Body>
                <Card>
                    <Card.Body>
                        <Tabs tabs={tabs}>

                            <TabPanel id="basic">
                                <div className="system">

                                    <div className="img" style={{

                                        width: '1250px',
                                        height: '650px',
                                        margin: '0 auto',
                                        boxSizing: 'border-box',
                                        padding: '10px',
                                    }}>
                                        <img src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/gpuAi.png" alt="" style={{ width: '90%', height: '90%' }} />
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel id="network">
                                <div className="components">

                                    <Table
                                        verticalTop
                                        records={cvmListOther}
                                        recordKey="one"
                                        bordered='all'
                                        columns={[
                                            {
                                                key: "one",
                                                header: "产品/组件",
                                                align: 'left',
                                                width:'150px',
                                            },
                                            {
                                                key: "two",
                                                header: "版本",
                                                align: 'left',
                                                width:'150px',
                                            },
                                            {
                                                key: "three",
                                                header: "来源",
                                                align: 'left',
                                                width:'150px',
                                            },
                                            {
                                                key: "four",
                                                header: "备注",
                                                align: 'left',
                                                render: function getFour(cvmListOther) {

                                                    if (cvmListOther.four.indexOf('Github') !== -1 || cvmListOther.four.indexOf('下载') !== -1) {

                                                        return (
                                                            <ExternalLink
                                                                href={/(.+)?(?:\(|（)(.+)(?=\)|）)/.exec(cvmListOther.four)[2]}
                                                                style={{ marginRight: 10 }}
                                                            >
                                                                {cvmListOther.four}
                                                            </ExternalLink>
                                                        )

                                                    } else {
                                                        return (
                                                            <p>{cvmListOther.four}</p>
                                                        )
                                                    }
                                                }
                                            },
                                        ]}
                                    />
                                </div>
                            </TabPanel>
                            <TabPanel id="monitor">
                                <div className="network">
                                    <Table
                                        verticalTop
                                        records={cvmList}
                                        recordKey="instanceId"
                                        bordered='all'
                                        columns={[
                                            {
                                                key: "instanceId",
                                                header: "网络规划",
                                                align: 'left',
                                                width:'300px'
                                            },
                                            {
                                                key: "instanceName",
                                                header: "可用区域",
                                                align: 'left'
                                            },
                                        ]}
                                    />
                                </div>
                            </TabPanel>

                            <TabPanel id="sg">
                                <div className="system">

                                    <div className="img" style={{

                                        width: '1250px',
                                        height: '650px',
                                        margin: '0 auto',
                                        boxSizing: 'border-box',
                                        padding: '10px',
                                    }}>

                                        <img src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/console-gpu-des.png" alt="" style={{ width: '90%', height: '90%' }} />
                                        <p style={{textAlign: "center"}}>程序地址：<a href="https://github.com/tensorflow/benchmarks/tree/master/scripts/tf_cnn_benchmarks">https://github.com/tensorflow/benchmarks/tree/master/scripts/tf_cnn_benchmarks</a></p>
                                    </div>
                                </div>
                            </TabPanel>
                        </Tabs>
                    </Card.Body>
                </Card>
            </LayoutContent.Body>
        </LayoutContent >
    );
}
