import React, {useEffect, useState} from "react";

import "tea-component/dist/tea.css";
import {Button, Card, Layout, message, Modal, Table, TabPanel, Tabs} from "tea-component";
import {Justify} from "tea-component";
import {
    createBadService,
    createNewService,
    polarisSwitchKong,
    queryPodsInfo,
    queryPolarisInfo,
    queryPolarisKong,
    recoveryService, restartGateway
} from "../../../services/function";

const {Content: LayoutContent} = Layout;

export default function PolarisService() {

    const tabs = [
        {id: "pro", label: "生产环境"},
        {id: "gray", label: "灰度环境（正常）"},
        {id: "gray-fault", label: "灰度环境（故障）"},
    ];

    const kongColumns = [
        {
            key: "router",
            header: "路由",
            align: 'center',
            width: '10%'
        },
        {
            key: "name",
            header: "服务名",
            align: 'center',
            width: '10%'
        }, {
            key: "header",
            header: "请求头",
            align: 'center',
            width: '10%',
        },
        {
            key: "method",
            header: "请求方法",
            align: 'center',
            width: '10%'
        },
        {
            key: "color",
            header: "染色",
            align: 'center',
            width: '10%'
        }

    ]

    const proPolarisColumns = [
        {
            key: "name",
            header: "名称",
            align: 'center',
            width: '10%'
        },
        {
            key: "nameSpace",
            header: "命名空间",
            align: 'center',
            width: '10%'
        }, {
            key: "count",
            header: "实例数",
            align: 'center',
            width: '10%'
        },
        {
            key: "router",
            header: "动态路由",
            align: 'center',
            width: '10%'
        },
        {
            key: "circuitBreaker",
            header: "熔断",
            align: 'center',
            width: '10%'
        }, {
            key: "info",
            header: "操作",
            align: 'center',
            width: '10%'
        }
    ]

    //当前tab页
    const [currentTab, setCurrentTab] = useState("pro")
    // pod弹窗
    const [podInfo, setPodInfo] = useState()
    const [podVisible, setPodVisible] = useState(false)
    //pod列表
    const [podsInfo, setPodsInfo] = useState([])
    //北极星服务列表
    const [polarisInfo, setPolarisInfo] = useState([])
    const [polarisVisible, setPolarisVisible] = useState(false)
    const [polarisDetailInfo, setPolarisDetailInfo] = useState()
    const [polarisExampleVisible, setPolarisExampleVisible] = useState(false)

    const [isLoading1, setLoading1] = useState(false)
    const [isLoading2, setLoading2] = useState(false)
    const [isLoading3, setLoading3] = useState(false)
    const [isLoading4, setLoading4] = useState(false)
    const [isLoading5, setLoading5] = useState(false)

    const newService = async () => {
        setLoading1(true);
        const res = await createNewService()
        if (res.data && res.data.length > 200 && res.data.indexOf("Please sign in") !== -1) {
            window.location.href = "/login"
        } else if (res.data.success) {
            message.success({
                content: "新版服务发布成功"
            })
            await getPodsInfo();
        } else if (!res.data.success) {
            message.error({
                content: res.data.data
            })
        }
        setLoading1(false);
    }

    const newServiceBad = async () => {
        setLoading2(true);
        const res = await createBadService()
        if (res.data && res.data.length > 200 && res.data.indexOf("Please sign in") !== -1) {
            window.location.href = "/login"
        } else if (!res.data.success) {
            message.error({
                content: res.data.data
            })
        } else if (res.data.success) {
            message.success({
                content: "新版服务发布（故障）成功"
            })
            await getPodsInfo();
        }
        setLoading2(false);
    }

    const recovery = async () => {
        setLoading3(true);
        const res = await recoveryService()
        if (res.data && res.data.length > 200 && res.data.indexOf("Please sign in") !== -1) {
            window.location.href = "/login"
        } else if (!res.data.success) {
            message.error({
                content: res.data.data
            })
        } else if (res.data.success) {
            setCurrentTab("pro")
            message.success({
                content: "恢复成功"
            })
            await getPodsInfo();
        }
        const polarisRes = await polarisSwitchKong("off");
        if (polarisRes.data && polarisRes.data.length > 200 && polarisRes.data.indexOf("Please sign in") !== -1) {
            window.location.href = "/login"
        } else if (!polarisRes.data.success) {
            message.error({
                content: "网关染色失败"
            })
        }
        setLoading3(false);
    }

    const restartGatewayService = async () => {
        setLoading5(true);
        const res = await restartGateway()
        if (res.data && res.data.length > 200 && res.data.indexOf("Please sign in") !== -1) {
            window.location.href = "/login"
        } else if (!res.data.success) {
            message.error({
                content: res.data.msg
            })
        } else if (res.data.success) {
            message.success({
                content: res.data.msg
            })
            await getPodsInfo();
        }
        setLoading5(false);
    }

    const goTxyLogin = () => {
        const w = window.open('_black') //这里是打开新窗口
        w.location.href = 'https://cloud.tencent.com/login' //这样就可以跳转了
    }

    const refreshPage = () => {
        window.location.reload();
    }

    const goYunShuCheng = () => {
        const w = window.open('_black') //这里是打开新窗口
        w.location.href = 'http://gray.qcbm.yunnative.com' //这样就可以跳转了
    }

    const goYunShuChengWeb = () => {
        const w = window.open('_black') //这里是打开新窗口
        w.location.href = 'http://gray.web.yunnative.com' //这样就可以跳转了
    }

    const goYunShuChengMobile = () => {
        const w = window.open('_black') //这里是打开新窗口
        w.location.href = 'http://gray.mobile.yunnative.com' //这样就可以跳转了
    }

    const goKong = () => {
        const w = window.open('_black') //这里是打开新窗口
        w.location.href = 'https://console.cloud.tencent.com/tse/kong-detail?rid=1&id=gateway-fdff0024' //这样就可以跳转了
    }
    const goTke = () => {
        const w = window.open('_black') //这里是打开新窗口
        w.location.href = 'https://console.cloud.tencent.com/tke2/cluster/sub/list/basic/info?rid=8&clusterId=cls-p56v7yov' //这样就可以跳转了
    }
    const goPolaris = () => {
        const w = window.open('_black') //这里是打开新窗口
        w.location.href = 'https://console.cloud.tencent.com/tse/governance-detail?rid=33&id=ins-9d0f8aa9' //这样就可以跳转了
    }

    const goLianLu = () => {
        const w = window.open('_black') //这里是打开新窗口
        w.location.href = 'https://console.cloud.tencent.com/apm/monitor/system?from=now-15m&to=now&team=apm-YrSSqH7sf&rid=8&role=server' //这样就可以跳转了
    }

    const kongInfoPro = [
        {
            router: "qcbm-front-router-web",
            name: "qcbm-front",
            header: "app:web",
            method: "GET",
            color: "否"
        },
        {
            router: "qcbm-front-router-mobile",
            name: "qcbm-front",
            header: <span>app:mobile</span>,
            method: "GET",
            color: "否"
        }
    ]

    const kongInfoGray = [
        {
            router: "qcbm-front-router-web",
            name: "qcbm-front",
            header: "app:web",
            method: "GET",
            color: "否"
        },
        {
            router: "qcbm-front-router-mobile",
            name: "qcbm-front",
            header: <span style={{color: "red"}}>app:mobile&&gray:true</span>,
            method: "GET",
            color: "是"
        }
    ]

    const [job1, setJob1] = useState("//服务注册插件\n" +
        "<dependency>\n" +
        "\t<groupId>com.tencent.polaris</groupId>\n" +
        "\t<artifactId>dubbo-registry-polaris</artifactId>\n" +
        "\t<version>${polaris.version}</version>\n" +
        "</dependency>\n" +
        "//服务熔断插件\n" +
        "<dependency>\n" +
        "\t<groupId>com.tencent.polaris</groupId>\n" +
        "\t<artifactId>dubbo-circuitbreaker-polaris</artifactId>\n" +
        "\t<version>${polaris.version}</version>\n" +
        "</dependency>\n" +
        "//服务路由插件\n" +
        "<dependency>\n" +
        "\t<groupId>com.tencent.polaris</groupId>\n" +
        "\t<artifactId>dubbo-router-polaris</artifactId>\n" +
        "\t<version>${polaris.version}</version>\n" +
        "</dependency>\n" +
        "\n" +
        "//application.yaml配置文件\n" +
        "dubbo.registry.address=polaris://localhost:8091?username=polaris&password=*****\n" +
        "\n" +
        "//注册服务（服务端）\n" +
        "@DubboService(version = \"${provicer.service.version}\")\n" +
        "public class ProviderServiceImpl implements ProvicerService {}\n" +
        "\n" +
        "//服务调用（消费端）\n" +
        "@DubboReference(version = \"1.0.0\")\n" +
        "private ProvicerService provicerService;\n")

    const [job2, setJob2] = useState("//服务注册插件\n" +
        "<dependency>\n" +
        "\t<groupId>com.tencent.polaris</groupId>\n" +
        "\t<artifactId>dubbo-registry-polaris</artifactId>\n" +
        "\t<version>${polaris.version}</version>\n" +
        "</dependency>\n" +
        "//服务熔断插件\n" +
        "<dependency>\n" +
        "\t<groupId>com.tencent.polaris</groupId>\n" +
        "\t<artifactId>dubbo-circuitbreaker-polaris</artifactId>\n" +
        "\t<version>${polaris.version}</version>\n" +
        "</dependency>\n" +
        "//服务路由插件\n" +
        "<dependency>\n" +
        "\t<groupId>com.tencent.polaris</groupId>\n" +
        "\t<artifactId>dubbo-router-polaris</artifactId>\n" +
        "\t<version>${polaris.version}</version>\n" +
        "</dependency>")

    const [job3, setJob3] = useState("//application.yaml配置文件\n" +
        "dubbo.registry.address=polaris://localhost:8091?username=polaris&password=*****")

    const [job4, setJob4] = useState("//注册服务（服务端）\n" +
        "@DubboService(version = \"${provicer.service.version}\")\n" +
        "public class ProviderServiceImpl implements ProvicerService {}\n" +
        "\n" +
        "//服务调用（消费端）\n" +
        "@DubboReference(version = \"1.0.0\")\n" +
        "private ProvicerService provicerService;")

    const getPodsInfo = async () => {
        const pods = await queryPodsInfo()
        const infos = []
        const data = pods.data.data
        data.map((item) => {

            const info = {}
            if (item.name.includes("bad") || item.name.includes("new")) {
                info["name"] = <span style={{color: "red"}}>{item.name}</span>
                info["nameSpace"] = <span style={{color: "red"}}>{item.nameSpace}</span>
                info["podsCount"] = <span style={{color: "red"}}>{item.podsCount}</span>
                info["status"] = <span style={{color: "red"}}>{item.status}</span>
                info["pods"] = <Button onClick={() => {
                    setPodVisible(true)
                    setPodInfo(buildPodInfo(item.pods))
                }}>POD 信息</Button>
                infos.push(info)
            } else {
                info["name"] = <span style={{color: "black"}}>{item.name}</span>
                info["nameSpace"] = <span style={{color: "black"}}>{item.nameSpace}</span>
                info["podsCount"] = <span style={{color: "black"}}>{item.podsCount}</span>
                info["status"] = <span style={{color: "black"}}>{item.status}</span>
                info["pods"] = <Button onClick={() => {
                    setPodVisible(true)
                    setPodInfo(buildPodInfo(item.pods))
                }}>POD 信息</Button>
                infos.push(info)
            }

        })
        setPodsInfo(infos)
    }


    const startKong = async () => {
        setLoading4(true)
        const res = await polarisSwitchKong("on");
        setLoading4(false)
        if (res.data.success) {
            setCurrentTab("nonPro")
            message.success({
                content: "开启网关染色成功"
            })
        } else if (res.data && res.data.length > 200 && res.data.indexOf("Please sign in") !== -1) {
            window.location.href = "/login"
        } else {
            message.error({
                content: "开启网关染色失败"
            })
        }

    }

    const getPolarisInfo = async () => {
        const polaris = await queryPolarisInfo()
        const data = polaris.data.data
        const infos = []
        data.map((item) => {
            const info = {}
            info["name"] = item.name
            info["nameSpace"] = item.nameSpace
            info["count"] = item.count
            info["router"] = item.router
            info["circuitBreaker"] = item.circuitBreaker
            info["info"] = <Button onClick={() => {
                setPolarisVisible(true)
                setPolarisDetailInfo(buildPolarisDetail(item.details, item.transRouters, item.circuitBreakers))
            }}>详细内容</Button>
            infos.push(info)
        })

        setPolarisInfo(infos)
    }

    useEffect(() => {
        (async () => {
            getPodsInfo().then(r => {
                }
            )
            const res = await queryPolarisKong();
            if (res.data && res.data.length > 200 && res.data.indexOf("Please sign in") !== -1) {
                window.location.href = "/login"
            } else if (res.data.data === "true") {
                setCurrentTab("nonPro")
            } else if (res.data.data === "false") {
                //console.log("res.data.data="+res.data.data);
                setCurrentTab("pro")
            }
        })()
    }, [])

    useEffect(() => {
            getPolarisInfo().then(r => {
            })
        }
        , [])

    function choiceKongInfo(env) {
        console.log("env=" + env)
        //进行判断
        if (env === "pro") {
            return kongInfoPro
        } else {
            return kongInfoGray
        }
    }

    function buildPodInfo(pods) {
        return (
            <Card.Body>
                <Table.ActionPanel>
                    <Justify/>
                    <Table
                        verticalTop
                        records={pods}
                        bordered='all'
                        columns={[
                            {
                                key: "name",
                                header: "实例名称",
                                align: 'center',
                                width: '10%'
                            },
                            {
                                key: "hostIP",
                                header: "实例所在节点IP",
                                align: 'center',
                                width: '10%'
                            }, {
                                key: "podIP",
                                header: "实例IP",
                                align: 'center',
                                width: '10%'
                            },
                            {
                                key: "status",
                                header: "状态",
                                align: 'center',
                                width: '10%'
                            },
                            {
                                key: "createTime",
                                header: "创建时间",
                                align: 'center',
                                width: '10%'
                            }
                        ]}/>
                </Table.ActionPanel>
            </Card.Body>)
    }

    function buildPolarisDetail(details, transRouters, circuitBreakers) {
        const circuitBreakersHidden = circuitBreakers.length <= 0
        const transRouterHidden = transRouters.length <= 0
        return (
            <Card.Body>
                <Table.ActionPanel>
                    <Justify left={
                        <>
                            <div className="cost-title">实例列表</div>
                        </>
                    }/>
                    <Table
                        verticalTop
                        records={details}
                        bordered='all'
                        columns={[
                            {
                                key: "host",
                                header: "实例IP",
                                align: 'center',
                                width: '10%'
                            },
                            {
                                key: "port",
                                header: "端口",
                                align: 'center',
                                width: '10%'
                            }, {
                                key: "protocol",
                                header: "协议",
                                align: 'center',
                                width: '10%'
                            },
                            {
                                key: "application",
                                header: "application",
                                align: 'center',
                                width: '10%'
                            },
                            {
                                key: "status",
                                header: "健康状态",
                                align: 'center',
                                width: '10%'
                            }
                        ]}/>
                </Table.ActionPanel>
                <br/>
                <div hidden={transRouterHidden}>
                    <Table.ActionPanel>
                        <Justify left={
                            <>
                                <div className="cost-title">路由规则</div>
                            </>
                        }/>
                        <Table
                            verticalTop
                            records={transRouters}
                            bordered='all'
                            columns={[
                                {
                                    key: "name",
                                    header: "规则名",
                                    align: 'center',
                                    width: '10%'
                                },
                                {
                                    key: "enable",
                                    header: "状态",
                                    align: 'center',
                                    width: '10%'
                                },
                                {
                                    key: "priority",
                                    header: "路由优先级",
                                    align: 'center',
                                    width: '10%'
                                },
                                {
                                    key: "groupPriority",
                                    header: "实例分组优先级",
                                    align: 'center',
                                    width: '10%'
                                }, {
                                    key: "sourceService",
                                    header: "来源服务",
                                    align: 'center',
                                    width: '10%'
                                },
                                {
                                    key: "sourceTag",
                                    header: "来源服务标签",
                                    align: 'center',
                                    width: '10%'
                                },
                                {
                                    key: "service",
                                    header: "目标服务",
                                    align: 'center',
                                    width: '10%'
                                },
                                {
                                    key: "serviceTag",
                                    header: "目标服务实例分组",
                                    align: 'center',
                                    width: '30%'
                                }
                            ]}/>
                    </Table.ActionPanel>
                </div>
                <br/>
                <div hidden={circuitBreakersHidden}>
                    <Table.ActionPanel>
                        <Justify left={
                            <>
                                <div className="cost-title">熔断规则</div>
                            </>
                        }/>
                        <Table
                            verticalTop
                            records={circuitBreakers}
                            bordered='all'
                            style={{wordWrap: "break-word"}}
                            columns={[
                                {
                                    key: "sourceNameSpace",
                                    header: "命名空间",
                                    align: 'center',
                                    width: '5%'
                                },
                                {
                                    key: "sourceService",
                                    header: "服务名",
                                    align: 'center',
                                    width: '5%'
                                }, {
                                    key: "method",
                                    header: "接口名",
                                    align: 'center',
                                    width: '5%'
                                },
                                {
                                    key: "condition",
                                    header: "熔断条件",
                                    align: 'center',
                                    width: '10%'
                                },
                                {
                                    key: "sleepWindow",
                                    header: "半开时间",
                                    align: 'center',
                                    width: '5%'
                                },
                                {
                                    key: "recover",
                                    header: "探测恢复",
                                    align: 'center',
                                    width: '10%'
                                }
                            ]}/>
                    </Table.ActionPanel></div>
            </Card.Body>
        )
    }

    return (
        <LayoutContent>
            <LayoutContent.Header title="全链路灰度">
                <Button type="pay" onClick={() => newService()} loading={isLoading1}>新版服务发布</Button>
                <Button type="pay" onClick={() => newServiceBad()} loading={isLoading2}>新版服务发布（故障）</Button>
                {/*<Button type="pay" onClick={() => restartGatewayService()} loading={isLoading5}>重启Gateway</Button>*/}
                <Button type="pay" onClick={() => startKong()} loading={isLoading4}>开启网关染色</Button>
                <Button type="pay" onClick={() => goLianLu()}>跳转链路追踪</Button>
                <Button type="primary" onClick={() => recovery()} loading={isLoading3}>环境恢复</Button>
                <Button style={{float: "right"}} onClick={() => goTxyLogin()} type="weak">登录腾讯云</Button>
                <Button style={{float: "right"}} onClick={() => refreshPage()} type="weak">刷新</Button>
                <Button style={{float: "right"}} onClick={() => goYunShuCheng()} type="weak">跳转云书城</Button>
                <Button style={{float: "right"}} onClick={() => goYunShuChengMobile()}
                        type="weak">跳转云书城（Mobile端）</Button>
                <Button style={{float: "right"}} onClick={() => goYunShuChengWeb()} type="weak">跳转云书城（Web端）</Button>

            </LayoutContent.Header>
            <div id="main">
                <div style={{overflow: "scroll"}}>
                    <div className="tablePolaris" style={{marginLeft: "20px", marginRight: "20px"}}>
                        <div style={{
                            fontWeight: "bold",
                            marginTop: "5px",
                            marginBottom: "5px",
                            marginLeft: "5px",
                            fontSize: "14px"
                        }}>场景简介
                        </div>
                        <Card>
                            <Card.Body>
                                <Tabs tabs={tabs}>
                                    <TabPanel id="pro">
                                        <Card.Body>
                                            <Card.Body>
                                                <span>背景介绍：</span>
                                                <span>云书城网站后端基于TKE容器部署，由5个Dubbo微服务组成，基于云原生网关+北极星实现服务治理；前端访问有两种接入方式：Web和Mobile：Web端HTTP请求带有app:web请求头，Mobile端HTTP请求带有app:mobile请求头。</span></Card.Body>
                                            <Card.Body><span>场景1：</span>
                                                <span>Web端及Mobile端，访问线上环境时，服务间路由流量相同</span></Card.Body>
                                            <Card.Body>
                                                <img
                                                    src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/polaris/%E7%81%B0%E5%BA%A6-1.gif"
                                                    alt="" style={{marginTop: "15px", width: '100%', height: '100%'}}/>
                                            </Card.Body>
                                        </Card.Body>
                                    </TabPanel>

                                    <TabPanel id="gray">
                                        <Card.Body>
                                            <Card.Body><span>背景介绍：</span>
                                                <span>云书城网站后端基于TKE容器部署，由5个Dubbo微服务组成，基于云原生网关+北极星实现服务治理；前端访问有两种接入方式：Web和Mobile：Web端HTTP请求带有app:web请求头，Mobile端HTTP请求带有app:mobile请求头。</span></Card.Body>
                                            <Card.Body><span>场景2：</span>
                                                <span>通过云原生网关，Mobile端请求添加gray:true标签，后续服务间调用依据此标签条件路由</span></Card.Body>
                                            <Card.Body>
                                                <img
                                                    src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/polaris/%E7%81%B0%E5%BA%A6-2.gif"
                                                    alt="" style={{marginTop: "15px", width: '100%', height: '100%'}}/>
                                            </Card.Body>
                                        </Card.Body>
                                    </TabPanel>
                                    <TabPanel id="gray-fault">
                                        <Card.Body>
                                            <Card.Body><span>背景介绍：</span>
                                                <span>云书城网站后端基于TKE容器部署，由5个Dubbo微服务组成，基于云原生网关+北极星实现服务治理；前端访问有两种接入方式：Web和Mobile：Web端HTTP请求带有app:web请求头，Mobile端HTTP请求带有app:mobile请求头。</span></Card.Body>
                                            <Card.Body><span>场景3：</span>
                                                <span>新版本收藏服务(Favourite)线上回归，故障大于5次后被熔断，Mobile流量回归至旧版本，保证Mobile端客户业务访问连续性</span></Card.Body>
                                            <Card.Body>
                                                <img
                                                    src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/polaris/%E7%81%B0%E5%BA%A6-3.gif"
                                                    alt="" style={{marginTop: "15px", width: '100%', height: '100%'}}/>
                                            </Card.Body>
                                        </Card.Body>
                                    </TabPanel>
                                </Tabs>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                <div style={{overflow: "scroll"}}>
                    <Card.Body>
                        <Table.ActionPanel>
                            <Justify
                                left={
                                    <>
                                        <div className="cost-title" style={{fontSize: "14px"}}>云原生网关接入层信息</div>
                                    </>
                                }

                                right={
                                    <>
                                        <Button type="primary" onClick={() => {
                                            goKong()
                                        }}>跳转云原生网关</Button>
                                    </>
                                }
                            />
                            <Table
                                verticalTop
                                records={choiceKongInfo(currentTab)}
                                bordered='all'
                                columns={kongColumns}/>
                        </Table.ActionPanel>
                    </Card.Body>
                    <br/>
                    <Card.Body>
                        <Table.ActionPanel>
                            <Justify
                                left={
                                    <>
                                        <div className="cost-title" style={{fontSize: "14px"}}>K8S Deployment 运行情况</div>
                                    </>
                                }
                                right={
                                    <>
                                        <Button style={{color: "black"}} onClick={() => {
                                            getPodsInfo().then(r => {
                                            })
                                        }}>刷新</Button>

                                        <Button type="primary" onClick={() => {
                                            goTke()
                                        }}>跳转TKE</Button>
                                    </>
                                }
                            />
                            <Table
                                verticalTop
                                records={podsInfo}
                                bordered='all'
                                columns={[
                                    {
                                        key: "name",
                                        header: "名称",
                                        align: 'center',
                                        width: '10%'
                                    },
                                    {
                                        key: "nameSpace",
                                        header: "命名空间",
                                        align: 'center',
                                        width: '10%'
                                    }, {
                                        key: "podsCount",
                                        header: "Pod 数",
                                        align: 'center',
                                        width: '10%'
                                    },
                                    {
                                        key: "status",
                                        header: "状态",
                                        align: 'center',
                                        width: '10%'
                                    }, {
                                        key: "pods",
                                        header: "操作",
                                        align: 'center',
                                        width: '10%',
                                    }
                                ]}/>
                        </Table.ActionPanel>
                    </Card.Body>
                    <br/>
                    <Card.Body>
                        <Table.ActionPanel>
                            <Justify
                                left={
                                    <>
                                        <div className="cost-title" style={{fontSize: "14px"}}>北极星服务列表</div>
                                    </>
                                }
                                right={
                                    <>
                                        <Button style={{color: "black"}} onClick={() => {
                                            getPolarisInfo().then(r => {
                                            })
                                        }}>刷新</Button>
                                        <Button type="pay" onClick={() => {
                                            setPolarisExampleVisible(true)
                                        }}>北极星接入示例</Button>

                                        <Button type="primary" onClick={() => {
                                            goPolaris()
                                        }}>跳转北极星</Button>
                                    </>
                                }
                            />
                            <Table
                                verticalTop
                                records={polarisInfo}
                                bordered='all'
                                columns={proPolarisColumns}/>
                        </Table.ActionPanel>
                    </Card.Body>
                </div>
            </div>

            <Modal visible={podVisible} onClose={() => setPodVisible(false)}
                   caption="pod 详情"
                   size="80%"
                   style={{fontSize: '16px'}}>
                <Modal.Body style={{
                    textAlign: "left",
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'white',
                    color: 'black'
                }}>
                    <div style={{display: "flex", whiteSpace: "pre-wrap"}}>
                        {podInfo}
                    </div>
                </Modal.Body>
            </Modal>


            <Modal visible={polarisVisible} onClose={() => setPolarisVisible(false)}
                   caption="北极星"
                   size="80%"
                   style={{fontSize: '16px'}}>
                <Modal.Body style={{
                    textAlign: "left",
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'white',
                    color: 'black'
                }}>
                    <div style={{display: "flex", whiteSpace: "pre-wrap"}}>
                        {polarisDetailInfo}
                    </div>
                </Modal.Body>
            </Modal>


            <Modal visible={polarisExampleVisible} onClose={() => setPolarisExampleVisible(false)} className="popupYaml"
                   size="l"
                   caption="北极星接入示例"
                   style={{fontSize: '15px'}}>
                <Modal.Body style={{
                    textAlign: "left",
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'black',
                    color: 'white'
                }}>
                    <div style={{display: "flex", whiteSpace: "pre-wrap"}}>
                        <div>
                            <hr/>
                            {/*<div style={{width: "33%", float: "left"}}>*/}
                            <p style={{color: "red"}}>pom.xml修改</p>
                            <p>{job2}</p>
                            <hr/>
                            {/*</div>*/}
                            {/*<div style={{width: "33%"}}>*/}
                            <p style={{color: "red"}}>配置文件修改</p>
                            <p>{job3}</p>
                            <hr/>
                            {/*</div>*/}
                            {/*<div style={{width: "33%"}}>*/}
                            <p style={{color: "red"}}>注册发现使用示例</p>
                            <p>{job4}</p>
                            {/*</div>*/}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </LayoutContent>
    )
}