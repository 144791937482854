import React, { useEffect, useState } from "react";
import "tea-component/dist/tea.css";
import {Layout, Card, ImagePreview, List, Table, Button, message} from "tea-component";
import './index.scss'
import SwitchList from './components/Switch'
import Restore from './components/Restore'
import Dispatch from './components/Dispatch'
import { MyContext } from "../../../context-manger";
import {getSystemInfo, listReserveSceneMenu} from '../../../services/function'
import {switchJmeter, getSwitchJmeter,modifyClbBand} from "../../../services/bookStorage";

const { Header, Body, Sider, Content: LayoutContent } = Layout;

const { groupable } = Table.addons;


const list = [
    {
        name: 'VPC Console',
        src: 'https://console.cloud.tencent.com/vpc/vpc?rid=1',
    },
    {
        name: 'TKE Console',
        src: 'https://console.cloud.tencent.com/tke2/cluster?rid=1',
    },
    {
        name: 'CDB Console',
        src: 'https://console.cloud.tencent.com/cdb?ins=1-cdb-b1sab4tr',
    },
    {
        name: 'CLB Console',
        src: 'https://console.cloud.tencent.com/clb/detail?rid=1&id=lb-dipy3kui',
    },
    {
        name: 'CLS Console',
        src: 'https://console.cloud.tencent.com/cls/overview?region=ap-guangzhou',
    },
    {
        name: 'CVM Console',
        src: 'https://console.cloud.tencent.com/cvm/instance/index?rid=1',
    },
    {
        name: 'Redis Console',
        src: 'https://console.cloud.tencent.com/redis/instance/manage/detail?instanceId=crs-caukn17d&regionId=1',
    },
    {
        name: 'QCBM Monitor',
        src: 'http://106.55.221.6:3000/login',
    },
    {
        name: 'Skywalking',
        src: 'http://42.193.129.86:8080/',
    },

]

export default function Function() {

    let isGetSystemInfoData=-1
    const [systemInfo, setSystemInfo] = useState([])
    const [isLoadingJmeter1, setLoadingJmeter1] = useState(false);
    const [isLoadingJmeter2, setLoadingJmeter2] = useState(false);
    const [isLoadingJmeter3, setLoadingJmeter3] = useState(false);
    const [url,setUrl]=useState('https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/qcbm-flow-normal.gif')
    // 跳转云产品控制台
    const goWeb = (src) => {
        window.open(src)
    }

    // 获取双活状态数据
    const getSystemInfoData = async () => {
        const res = await getSystemInfo()
       
        if (res.data.success === true) {
            
            setSystemInfo(res.data.data)
        }
    }

//    定时轮训表格数据
    const getSystemInfoDataOne=()=>{
        getSystemInfoData()
        isGetSystemInfoData=setInterval(() => {
            if(document.getElementById("functionTable")===null){
                clearInterval(isGetSystemInfoData)
                return
            }
            getSystemInfoData()
        }, 10000);
       
       
    }
    useEffect(() => {
        (async () => {
            const  res = await  listReserveSceneMenu();
            if (res.data.success) {
                let scene1 = document.getElementById('doubleLiveSceneID');
                if(res.data.data.scene1){
                    scene1.style.display="block";
                    getSystemInfoDataOne()
                }
                else if(res.data.data.scene2){
                    scene1.style.display="block";
                    window.location.href="/#/bigPromoteFunction"
                }
                else if(res.data.data.scene3){
                    scene1.style.display="block";
                    window.location.href="/#/realTimeAnalysisFunction"
                }
                else if(res.data.data.scene4){
                    scene1.style.display="block";
                    window.location.href="/#/storeCalcSeparatedFunction"
                }
                else if(res.data.data.scene5){
                    scene1.style.display="block";
                    window.location.href="/#/gpuOptimizeFunction"
                }
                else if(res.data.data.scene6){
                    scene1.style.display="block";
                    window.location.href="/#/acrossCloudDoubleLiveFunction"
                }
                else if(res.data.data.scene7){
                    scene1.style.display="block";
                    window.location.href="/#/microServiceContainerization"
                }
                else if(res.data.data.scene8){
                    scene1.style.display="block";
                    window.location.href="/#/polarisService"
                }
                else if(res.data.data.scene9){
                    scene1.style.display="block";
                    window.location.href="/#/aiGcFunction"
                }
                else if(res.data.data.scene10){
                    scene1.style.display="block";
                    window.location.href="/#/gameDataFunction"
                }
                else if(res.data.data.scene11){
                    scene1.style.display="block";
                    window.location.href="/#/offLineMixingFunction"
                }
                else if(res.data.data.scene12){
                    scene1.style.display="block";
                    window.location.href="/#/questionAnswerFunction"
                }
                else{
                    scene1.style.display="none";
                    window.location.href="/#/reserveSceneFunction"
                }

            }
            if (res.data && res.data > 200 && res.data.indexOf("Please sign in") !== -1) {
                window.location.href = "/login"
            }

        })()
       

       
    }, [])


    const querySwitchJmeter= async() =>{
        setLoadingJmeter1(true)
        const res = await getSwitchJmeter()
        setLoadingJmeter1(false)
        if(res.data.success){
            message.success({
               content:res.data.msg
                //style:{width:'400px', height:'400px',whiteSpace:'pre-line',backgroundColor: '#000000',color:'white'}
            })

        }
        else if(res.data&&res.data.length>200&&res.data.indexOf("Please sign in")!=-1) {
            window.location.href="/login"
        }
        else {
            message.error({
                content:res.data.msg
            })
        }
    }

    const switchOnJmeter= async() =>{
        setLoadingJmeter2(true)
        await   modifyClbBand(5);
        const res = await switchJmeter("on")
        setLoadingJmeter2(false)
        if(res.data.success){
            message.success({

                content:"真实流量开启成功"
            })
        }
        else if(res.data&&res.data.length>200&&res.data.indexOf("Please sign in")!=-1) {
            window.location.href="/login"
        }
        else {
            message.error({
                content:"真实流量开启失败"
            })
        }

    }

    const switchOffJmeter= async() =>{
        setLoadingJmeter3(true)
        await   modifyClbBand(1024);
        const res = await switchJmeter("off")
        setLoadingJmeter3(false)
        if(res.data.success){
            message.success({
                content:"真实流量关闭成功"
            })
        }
        else if(res.data&&res.data.length>200&&res.data.indexOf("Please sign in")!=-1) {
            window.location.href="/login"
        }
        else {
            message.error({
                content:"真实流量关闭失败"
            })
        }

    }

    return (
        <>
            <LayoutContent  id="doubleLiveSceneID" >
                <LayoutContent.Header title='操作'>
                    <Button  onClick={()=>querySwitchJmeter()} type="primary" loading={isLoadingJmeter1}>1.查看真实流量</Button>
                    <Button  onClick={()=>switchOnJmeter()}  type="primary" loading={isLoadingJmeter2}>2.启动真实流量</Button>
                    <Button  onClick={()=>switchOffJmeter()} type="pay" loading={isLoadingJmeter3}>3.停止真实流量</Button>

                </LayoutContent.Header>
                <LayoutContent.Body>
                    <Card>
                        <Card.Body>
                            <div className="table" id='functionTable'>
                                <div className="title">双活状态</div>
                                <Table

                                    bordered='all'
                                    records={systemInfo}
                                    recordKey="az"
                                    columns={[
                                        {
                                            key: "az",
                                            header: "AZ",
                                            align: "center",
                                        },
                                        {
                                            key: "trafficRatio",
                                            header: "当前",
                                            align: "center",
                                            render: function getCurrentOne(systemInfo) {
                                                return (
                                                    <p style={{ color: '#2f70f6' }}>{systemInfo.trafficRatio}</p>
                                                )
                                            }
                                        },
                                        {
                                            key: "defaultTrafficRatio",
                                            header: "缺省",
                                            align: "center",
                                        },
                                        {
                                            key: "mysqlMS",
                                            header: "当前",
                                            align: "center",
                                            render: function getCurrentOne(systemInfo) {
                                                return (
                                                    <p style={{ color: '#2f70f6' }}>{systemInfo.mysqlMS}</p>
                                                )
                                            }
                                        },
                                        {
                                            key: "defaultMysqlMS",
                                            header: "缺省",
                                            align: "center",
                                        },
                                        {
                                            key: "redisMS",
                                            header: "当前",
                                            align: "center",
                                            render: function getCurrentOne(systemInfo) {
                                                return (
                                                    <p style={{ color: '#2f70f6' }}>{systemInfo.redisMS}</p>
                                                )
                                            }
                                        },
                                        {
                                            key: "defaultRedisMS",
                                            header: "缺省",
                                            align: "center",
                                        },
                                    ]}
                                    addons={[
                                        groupable({
                                            headerGroups: [
                                                {
                                                    key: "flow",
                                                    title: "流量",
                                                    align: "center",
                                                    children: ["trafficRatio", "defaultTrafficRatio"],
                                                },
                                                {
                                                    key: "mysql",
                                                    title: "Mysql",
                                                    align: "center",
                                                    children: ["mysqlMS", "defaultMysqlMS"],
                                                },
                                                {
                                                    key: "redis",
                                                    title: "Redis",
                                                    align: "center",
                                                    children: ["redisMS", "defaultRedisMS"],
                                                },
                                            ],
                                        }),
                                    ]}
                                />
                            </div>

                            <div className="img" style={{
                                width: '92%',
                                height: '600px',
                                margin: '0 auto',
                                boxSizing: 'border-box',
                                padding: '10px',
                                border: '1px solid #ccc'

                            }}>
                                <img src={url} alt="" style={{ width: '100%', height: '100%' }} />
                            </div>
                            <MyContext.Provider value={{setUrl}}>
                                <div className="button">
                                    <div className="button_one">
                                        <SwitchList />
                                    </div>
                                    <div className="button_one">
                                        <Restore />
                                    </div>
                                    <div className="button_one">
                                        <Dispatch />
                                    </div>
                                </div>
                            </MyContext.Provider>

                           
                        </Card.Body>
                    </Card>
                </LayoutContent.Body>
            </LayoutContent>
        </>
    );
}
