import React, { useEffect, useState,useContext } from "react";
import "tea-component/dist/tea.css";
import { Tabs, TabPanel, Layout, Card, List, Radio, Button, Modal,message } from "tea-component";

import { MyContext } from "../../../../../context-manger";

import './index.scss'

import {getSwitch,getTaskResult} from '../../../../../services/function'

import {getFormatDate} from '../../../../../utils/getFormatDate'

const { Header, Body, Sider, Content: LayoutContent } = Layout;


function diffSecond(start, end) {
    if(null==end||'undefined'==end){
        return 0;
    }
    if(null==start||'undefined'==start){
        start=getFormatDate();
    }
    const startTime = new Date(start);
    const endTime = new Date(end);
    const second_time = Math.abs(startTime - endTime) / 1000; // 得到相差的秒数
    return second_time;
}


export default function SwitchList() {

    const[list,setList]=useState([
        {
            timeStr:getFormatDate(),
            clbTips:'开始流量调度',
            mySqlTips:'开始 MySQL 主备切换',
            redisTips:'开始 Redis 主备切换',
            timeNum:"0秒",
            timeClbNum:"0秒",
            timeMysqlNum:"0秒",
            timeRedisNum:"0秒",
            clbEndTime:getFormatDate(),
            mySqlEndTime:getFormatDate(),
            redisEndTime:getFormatDate(),
        },
       
    ])
    let pollTaskResultInterval=-1

    const [gender, setGender] = useState('ap-guangzhou-4');
    const [visible, setVisible] = useState(false);
    const [topList,setTopList]=useState({
        clbTips:'开始流量调度',
        mySqlTips:'开始DB切换,该过程较久,可能需要几分钟',
        redisTips:'开始Redis切换,该过程较久,可能需要几分钟。'
    })

    const {setUrl}=useContext(MyContext)

    const close = () => {
        setVisible(false)
        setTopList({
            clbTips:'开始流量调度',
            mySqlTips:'开始DB切换,该过程较久,可能需要几分钟',
            redisTips:'开始Redis切换,该过程较久,可能需要几分钟。'
        })
        setList([
            {
                timeStr:getFormatDate(),
                clbTips:'开始流量调度',
                mySqlTips:'开始 MySQL 主备切换',
                redisTips:'开始 Redis 主备切换'
            },
           
        ])
    };

        // 获取双活切换架构图内容
        const getSwitchData=async ()=>{
            const res=await getSwitch(gender)
            if(res.data.success){
                list[0].timeStr=getFormatDate();
                if(gender==='ap-guangzhou-3'){
                    setUrl('https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/qcbm-flow-az4-fail.gif')
                }
                if(gender==='ap-guangzhou-4'){
                    setUrl('https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/qcbm-flow-az3-fail.gif')
                }
                 setVisible(true)
                 pollTaskResultInterval=setInterval(() => {
                    getTaskResultData(res.data.taskId)
                    
                 }, 1000);
                
            }else{
                message.warning({
                    content: res.data.msg,
                  })
            }
           
        }
        // 获取双活切换任务进度
        const getTaskResultData=async (currentTaskId)=>{
            let listOne=list
            const res=await getTaskResult(currentTaskId)
            if(res.data.data.status===2||res.data.data.status===3){
                clearInterval(pollTaskResultInterval)
                pollTaskResultInterval = -1;
            }
            listOne.push({
                timeStr:res.data.data.timeStr,
                clbEndTime:res.data.data.clbEndTime,
                mySqlEndTime:res.data.data.mySqlEndTime,
                redisEndTime:res.data.data.redisEndTime,
                clbTips:res.data.data.clbTips,
                mySqlTips:res.data.data.mySqlTips,
                redisTips:res.data.data.redisTips,
                timeNum:diffSecond(list[0].timeStr,res.data.data.timeStr)+"秒",
                timeClbNum:diffSecond(list[0].timeStr,res.data.data.clbEndTime)+"秒",
                timeMysqlNum:diffSecond(list[0].timeStr,res.data.data.mySqlEndTime)+"秒",
                timeRedisNum:diffSecond(list[0].timeStr,res.data.data.redisEndTime)+"秒",
            })
            setList(listOne)
            setTopList({
                clbTips:res.data.data.clbTips,
                mySqlTips:res.data.data.mySqlTips,
                redisTips:res.data.data.redisTips,
            })
            let div=document.getElementById('box_black')
            div.scrollTop=div.scrollHeight

        }
    useEffect(()=>{
        
    },[])

    return (
        <>
            <div>
                <p>常用于AZ故障或演练场景，该操作会触发：</p>
                <List type="bullet" style={{ marginLeft: '20px', marginTop: '10px' }}>
                    <List.Item>所有流量调度至目标可用区</List.Item>
                    <List.Item>目标可用区的 DB / Redis 切为 master</List.Item>
                </List>

                <section>
                    <div style={{ marginTop: '20px',marginLeft:'20px' }}>
                        <span>请选择目标可用区：</span>
                        <Radio.Group value={gender} onChange={value => setGender(value)} style={{ marginLeft: '5px' }}>
                            <Radio name="ap-guangzhou-3">广州三区</Radio>
                            <Radio name="ap-guangzhou-4">广州四区</Radio>
                        </Radio.Group>
                    </div>
                </section>
                <Button type="primary" style={{ marginTop: '15px',marginLeft:'20px' }} onClick={getSwitchData}>
                    双活切换
                </Button>
                <Modal visible={visible} caption="双活切换" onClose={close} size='xl' style={{ fontSize: '14px' }}>
                    <Modal.Body>
                        <List type="bullet" style={{ marginLeft: '20px', marginTop: '10px' }}>
                            <List.Item>{topList.clbTips}   &nbsp;   开始时间：{list[0].timeStr} {topList.clbTips.indexOf("完成")!=-1?" 结束时间："+list[list.length-1].clbEndTime  +"    耗时："+list[list.length-1].timeClbNum:""} </List.Item>
                            <List.Item>{topList.mySqlTips} &nbsp; 开始时间：{list[0].timeStr} {topList.mySqlTips.indexOf("完成")!=-1?" 结束时间："+list[list.length-1].mySqlEndTime  +"   耗时："+list[list.length-1].timeMysqlNum:""}</List.Item>
                            <List.Item>{topList.redisTips} &nbsp; 开始时间：{list[0].timeStr}  {topList.redisTips.indexOf("完成")!=-1?" 结束时间："+list[list.length-1].redisEndTime  +"  耗时："+list[list.length-1].timeRedisNum:""}</List.Item>
                        </List>
                        <div className="box" id='box_black'>
                            {
                                list.map((item,index) => {
                                    return (
                                        <div className='box_black'  key={index}>
                                            <div className="left">
                                                <p>{item.timeStr}</p>
                                            </div>
                                            <div className="right">
                                            <p>{item.clbTips}   &nbsp;{ item.clbTips.indexOf("完成")!=-1?" 耗时："+item.timeClbNum:"" }</p>
                                            <p>{item.mySqlTips} &nbsp;{ item.mySqlTips.indexOf("完成")!=-1?" 耗时："+item.timeMysqlNum:"" }</p>
                                            <p>{item.redisTips} &nbsp;{ item.redisTips.indexOf("完成")!=-1? " 耗时："+item.timeRedisNum:"" }</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>


                    </Modal.Body>

                </Modal>
            </div>
        </>
    )
}
