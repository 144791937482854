import { useState, useEffect, useRef } from 'react'
import {
  Button,
  Drawer,
  Form,
  Input,
  InputNumber,
  Select,
  DatePicker,
  Tabs,
  TabPanel,
  Table
} from 'tea-component'
import { useForm, Controller } from 'react-hook-form'
import NodeCol from './nodeCol'
import { colType, colStrategy } from './defaultNode'

const NodeContent = ({ info, onChange }) => {
  const [visible, setVisible] = useState(false)
  const { control, handleSubmit, formState, reset } = useForm({ mode: 'all' })
  const nodeRef = useRef(null)

  const [columns, setColumns] = useState([])

  useEffect(() => {
    const resetInfo = { ...info }
    if (info) {
      setVisible(true)
      setColumns(resetInfo.columns || [])
      reset(resetInfo)
    }
  }, [info])

  async function onSubmit(values) {
    const dataset = { ...values, columns }
    onChange(dataset)
    console.log('dataset', dataset)
    setVisible(false)
  }

  function getStatus(meta) {
    if (!meta.isDirty && !formState.isSubmitted) {
      return null
    }
    return meta.invalid ? 'error' : 'success'
  }

  const tableHtml = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Form>
        <Controller
          name='name'
          control={control}
          rules={{
            required: '请输入表名'
          }}
          render={({ field, fieldState }) => (
            <Form.Item
              label='表名'
              status={getStatus(fieldState)}
              message={fieldState.error?.message}
            >
              <Input
                {...field}
                autoComplete='off'
                maxLength={20}
                placeholder='请输入表名'
              />
            </Form.Item>
          )}
        />

        <Controller
          name='totalLines'
          control={control}
          rules={{
            required: '请输入生成行数'
          }}
          render={({ field, fieldState }) => (
            <Form.Item
              label='生成行数'
              status={getStatus(fieldState)}
              message={fieldState.error?.message}
              extra='-1表示无限制一直生成'
            >
              <InputNumber
                {...field}
                placeholder='-1表示无限制一直生成'
                min={-1}
              />
            </Form.Item>
          )}
        />
        <Controller
          name='partitionNums'
          control={control}
          rules={{
            required: '请输入生成分区数'
          }}
          render={({ field, fieldState }) => (
            <Form.Item
              label='生成分区数'
              status={getStatus(fieldState)}
              message={fieldState.error?.message}
            >
              <InputNumber {...field} placeholder='请输入生成分区数' min={1} />
            </Form.Item>
          )}
        />
        <Controller
          name='bucketNums'
          control={control}
          rules={{
            required: '请输入分桶数'
          }}
          render={({ field, fieldState }) => (
            <Form.Item
              label='分桶数'
              status={getStatus(fieldState)}
              message={fieldState.error?.message}
              extra='一个分桶一输出一个文件使用一个线程进行处理'
            >
              <InputNumber {...field} placeholder='请输入分桶数' min={1} />
            </Form.Item>
          )}
        />
        <Controller
          name='col_null_value'
          control={control}
          render={({ field, fieldState }) => (
            <Form.Item label='空值标识' extra='空值用什么表示,默认为java的null'>
              <Input {...field} placeholder='默认为null' />
            </Form.Item>
          )}
        />
        <Controller
          name='out_format'
          control={control}
          render={({ field, fieldState }) => (
            <Form.Item label='输出内容格式'>
              <Select
                {...field}
                appearance='button'
                options={[
                  { text: '输出json', value: 'json' },
                  { text: '只输出value', value: 'only_v' },
                  { text: 'key加value', value: 'k_and_v' }
                ]}
              />
            </Form.Item>
          )}
        />

        <Controller
          name='col_split'
          control={control}
          render={({ field, fieldState }) => (
            <Form.Item label='列分隔符' extra='列之间的分割符号，默认 `'>
              <Input {...field} maxLength={1} placeholder='默认、' />
            </Form.Item>
          )}
        />

        <Controller
          name='col_kv_split'
          control={control}
          render={({ field, fieldState }) => (
            <Form.Item
              label='key-value分隔符'
              extra='如果[输出格式]为[key加value]是,k和v之间的分割符号。'
            >
              <Input {...field} maxLength={1} placeholder='' />
            </Form.Item>
          )}
        />

        <Controller
          name='output'
          control={control}
          render={({ field, fieldState }) => (
            <Form.Item label='输出目标' extra='输出的目标。默认输出到文件。'>
              <Select
                {...field}
                appearance='button'
                options={[
                  { text: '打印到控制台', value: 'print' },
                  { text: '输出到kafka', value: 'kafka' },
                  {
                    text: '同时出入到文件和kalka',
                    value: 'file_kafka'
                  },
                  { text: '输出到文件', value: 'file' }
                ]}
              ></Select>
            </Form.Item>
          )}
        />

        <Controller
          name='output_kafka_topic'
          control={control}
          render={({ field, fieldState }) => (
            <Form.Item
              label='输出目标kafka'
              extra='当输出目标有kafka时，这个输出kakfa的topic'
            >
              <Input {...field} placeholder='输出目标' />
            </Form.Item>
          )}
        />

        <Controller
          name='partitionUnit'
          control={control}
          render={({ field, fieldState }) => (
            <Form.Item
              label='partitionUnit'
              extra='yyyyMM 表示月为单位;yyyyMMDD 表示天为单位;yyyyMMDDHH 表示小时为单位,now表示当前时间(默认值)'
            >
              <Select
                {...field}
                appearance='button'
                options={[
                  { text: 'yyyyMM', value: 'yyyyMM' },
                  { text: 'yyyyMMDD', value: 'yyyyMMDD' },
                  { text: 'yyyyMMDDHH', value: 'yyyyMMDDHH' },
                  { text: 'now', value: 'now' }
                ]}
              ></Select>
            </Form.Item>
          )}
        />
        <Controller
          name='partitionStart'
          control={control}
          render={({ field, fieldState }) => (
            <Form.Item
              label='partitionStart'
              extra='1、如果partitionUnit 为now时,所有的分区都是生成时候的时间; 
             2、如果非默认值partitionUnit,第一个分区的时间为partitionStart 所在的partitionUnit 开始和结束时间内的任意时间。比如起始时间为 2023-04-23 12:32:34 ，partitionUnit为小时。那么第一个分区内的时间为2023-04-23 12:00:00 - 2023-04-23 13:00:00,那么第一个分区内的时间为2023-04-23 13:00:00 - 2023-04-24 13:00:00 以此类推。 '
            >
              <DatePicker
                {...field}
                format='YYYY-MM-DD HH:mm'
                clearable
                showTime={{ format: 'HH:mm', minuteStep: 60 }}
              />
            </Form.Item>
          )}
        />
      </Form>
    </form>
  )
  const columnHtml = (
    <>
      <Button
        type='primary'
        style={{ marginLeft: '10px' }}
        onClick={() => {
          nodeRef.current.open(columns)
        }}
      >
        配置列
      </Button>
      <Table
        records={columns}
        columns={[
          {
            key: 'index',
            header: '序号',
            width: 44,
            render: (x, key, recordIndex) => recordIndex + 1,
            fixed: 'left'
          },
          {
            key: 'col_name',
            header: () => '列名',
            render: x => x.col_name,
            fixed: 'left'
          },
          {
            key: 'col_type',
            header: '类型',
            width: 70,
            render: (x, key, index) => {
              if (!x.col_type) return ''
              return colType.find(item => item.value === x.col_type).text
            }
          },
          {
            key: 'strategy',
            header: '策略',
            width: 120,
            render: (x, key, index) => {
              if (!x.strategy) return ''
              return colStrategy(text => text).find(
                item => item.value === x.strategy
              ).text
            }
          },
          {
            key: 'null_percent',
            header: 'null',
            width: 50,
            render: x => x.null_percent
          },
          {
            key: 'blank_percent',
            header: 'blank',
            width: 50,
            render: x => x.blank_percent
          },
          {
            key: 'pre',
            header: '内容前缀',
            render: x => x.pre
          }
        ]}
      ></Table>
    </>
  )

  const [activeId, setActiveId] = useState('tableconf')
  return (
    <>
      <Drawer
        visible={visible}
        onClose={() => setVisible(false)}
        className='nodeContent'
        outerClickClosable={false}
        style={{
          width: 600
        }}
        title={
          <Tabs
            tabs={[
              { id: 'tableconf', label: 'Mock表配置' },
              { id: 'column', label: '列配置' }
            ]}
            activeId={activeId}
            onActive={({ id }) => setActiveId(id)}
            className='nodeContent-tabs'
          ></Tabs>
        }
        footer={
          activeId === 'tableconf' ? (
            <>
              <Button
                type='primary'
                loading={formState.isSubmitting}
                onClick={() => {
                  handleSubmit(onSubmit)()
                }}
              >
                保存
              </Button>
              <Button
                type='weak'
                style={{ marginLeft: '10px' }}
                onClick={() => setVisible(false)}
              >
                取消
              </Button>
            </>
          ) : null
        }
      >
        {activeId === 'tableconf' ? tableHtml : null}

        {activeId === 'column' ? columnHtml : null}

        <NodeCol
          ref={nodeRef}
          setData={data => {
            setColumns(data)
            const dataset = { ...info, columns: data }
            onChange(dataset)
          }}
        ></NodeCol>
      </Drawer>
    </>
  )
}
export default NodeContent
