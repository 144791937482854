import React, {useEffect, useState} from "react";
import "tea-component/dist/tea.css";
import {
    Layout,
    Button,
    Table,
    Card,
    Tabs,
    TabPanel, message, Switch, Modal, List,
} from "tea-component";
import './index.scss'
import {
    queryDlcDataList,
    updateDataEngineDlc,
    startEtl,
    startAdhoc,
    queryDlcStatus,
    queryDlcInfo,
    queryDlcLog
} from "../../../services/function"
import {getFormatDate} from "../../../utils/getFormatDate";


const {Content: LayoutContent, Content} = Layout;


let timer
let pollTaskResultInterval=-1
export default function BuringPointFunction() {
    const [dlcList, setDlcList] = useState([])
    const [isLoadingDlc1, setLoadingDlc1] = useState(false)
    const [isLoadingDlc2, setLoadingDlc2] = useState(false)
    const [isLoadingDlc3, setLoadingDlc3] = useState(false)
    const [refreshValue, setRefreshValue] = useState(false)
    const [recoverVisible, setRecoverVisible] = useState(false)
    const [captionName,setCaptionName] = useState("创建集群-详情");
    const [taskEngineValue,setTaskEngineValue] = useState("0");
    const [dlcModelVisible, setDlcModalVisible] = useState(false)
    const [dlcLogList, setDlcLogList] = useState([])

    const tabs = [
        {id: "scene1", label: "非存算分离场景"},
        {id: "scene2", label: "存算分离场景"}
    ];
    const [dlcResourceInfo,setDlcResourceInfo] = useState([])
    const[list,setList]=useState([
        {
            timeStr:getFormatDate(),
            statusTips:'创建无弹性集群,该过程可能需要几分钟，请稍后',
            timeNum:"0秒",
            status:"10",
            endTime:getFormatDate(),
            statusTips1:'创建弹性集群,该过程可能需要几分钟，请稍后',
            timeNum1:"0秒",
            status1:"10",
            endTime1:getFormatDate(),
        },

    ])
    const [topList,setTopList]=useState({
        statusTips:'创建无弹性集群集群,该过程可能需要几分钟，请稍后',
        status:"10",
        statusTips1:'创建弹性集群,该过程可能需要几分钟，请稍后',
        status1:"10"
    })

    const close = () => {
        setRecoverVisible(false)
        setTopList({
            statusTips:'创建无弹性集群,该过程可能需要几分钟，请稍后',
            status:"10",
            statusTips1:'创建弹性集群,该过程可能需要几分钟，请稍后',
            status1:"10"
        })
        setList([
            {
                statusTips:'创建无弹性集群,该过程可能需要几分钟，请稍后',
                status:"10",
                statusTips1:'创建弹性集群,该过程可能需要几分钟，请稍后',
                status1:"10",
            },

        ])
    };
    function diffSecond(start, end) {
        if(null==end||'undefined'==end){
            return 0;
        }
        if(null==start||'undefined'==start){
            start=getFormatDate();
        }
        const startTime = new Date(start);
        const endTime = new Date(end);
        const second_time = Math.abs(startTime - endTime) / 1000; // 得到相差的秒数
        return second_time;
    }

    function  aa(data){
        alert(data);
    }

    //获取状态
    const getTaskResultData=async ()=>{
        let listOne=list
        const res=await queryDlcStatus()
        if(res.data.data.status!==10&&res.data.data.status!==0&&res.data.data.status!=6&&res.data.data.status1!==10&&res.data.data.status1!==0&&res.data.data.status1!=6){
            clearInterval(pollTaskResultInterval)
            pollTaskResultInterval = -1;
            clearInterval(timer)
            setTaskEngineValue("0");
            autoRefreshDlcData(true,"0")
        }
        listOne.push({
            timeStr:res.data.data.timeStr,
            endTime:res.data.data.endTime,
            statusTips:res.data.data.statusTips,
            timeNum:diffSecond(list[0].timeStr,res.data.data.endTime)+"秒",
            endTime1:res.data.data.endTime1,
            statusTips1:res.data.data.statusTips1,
            timeNum1:diffSecond(list[0].timeStr,res.data.data.endTime1)+"秒",

        })
        setList(listOne)
        setTopList({
            statusTips:res.data.data.statusTips,
            status: res.data.data.status,
            statusTips1:res.data.data.statusTips1,
            status1: res.data.data.status1
        })
        let div=document.getElementById('podbox_black')
        if(null!=div){
        div.scrollTop=div.scrollHeight
        }

    }

    const updateDataEngineDlc1 = async () => {
        setLoadingDlc1(true)
        const res = await updateDataEngineDlc("1")
        setLoadingDlc1(false)
        if (res.data.success) {
            list[0].timeStr=getFormatDate();
            setRecoverVisible(true)
            pollTaskResultInterval=setInterval(() => {
                getTaskResultData()

            }, 3000);
            // message.success({
            //     content: res.data.msg
            // })
        } else if (res.data && res.data.length > 200 && res.data.indexOf("Please sign in") != -1) {
            window.location.href = "/login"
        } else {
            message.error({
                content: res.data.msg
            })
        }
        const jobRes = await queryDlcDataList(taskEngineValue)
        if (jobRes.data.success) {
           setDlcList(jobRes.data.data.list);
        }
    }

    const startEtl1 = async (data,task) => {

        setLoadingDlc2(true)
        const res = await startEtl(data)
        setLoadingDlc2(false)
        clearInterval(timer)
        setTaskEngineValue(task);
        autoRefreshDlcData(true,task)
        if (res.data.success) {
            message.success({
                content: res.data.msg
            })
        } else if (res.data && res.data.length > 200 && res.data.indexOf("Please sign in") != -1) {
            window.location.href = "/login"
        } else {
            message.error({
                content: res.data.msg
            })
        }
        const jobRes = await queryDlcDataList(task)
        if (jobRes.data.success) {
           setDlcList(jobRes.data.data.list);
        }

        const  resDlc = await  queryDlcInfo()
        setDlcResourceInfo(resDlc.data.data);
    }


    const startAdhoc1 = async (data,task) => {
        setLoadingDlc3(true)
        const res = await startAdhoc(data)
        setLoadingDlc3(false)
        clearInterval(timer)
        setTaskEngineValue(task);
        autoRefreshDlcData(true,task)
        if (res.data.success) {
            message.success({
                content: res.data.msg
            })
        } else if (res.data && res.data.length > 200 && res.data.indexOf("Please sign in") != -1) {
            window.location.href = "/login"
        } else {
            message.error({
                content: res.data.msg
            })
        }
        const jobRes = await queryDlcDataList(task)
        if (jobRes.data.success) {
            setDlcList(jobRes.data.data.list);
        }
        const  resDlc = await  queryDlcInfo()
        setDlcResourceInfo(resDlc.data.data);
    }

    const autoRefreshDlcData = async (v,taskEngine) => {
        if (v) {
            setRefreshValue(true)
            setTaskEngineValue(taskEngine)
            timer = setInterval(async () => {
                const jobRes = await queryDlcDataList(taskEngine)
                if (jobRes.data.success) {
                     setDlcList(jobRes.data.data.list);
                    if(jobRes.data.data.flag==false){
                        clearInterval(timer)
                        setRefreshValue(false)
                    }
                }
            }, 5000);
        } else {
            console.log("timer=" + timer)
            clearInterval(timer)
            setRefreshValue(false)
        }

    }
    const refreshDlcData = async (taskEngine) => {
        setTaskEngineValue(taskEngine)
        const jobRes = await queryDlcDataList(taskEngine)
        if (jobRes.data.success) {
            setDlcList(jobRes.data.data.list);
        }
        clearInterval(timer)
        autoRefreshDlcData(true,taskEngine)
    }

    const tabsChange =async (tab, event) => {
        let iframeStoreCalc = document.getElementById('refreshIframeStoreCalc');
        if(null!=iframeStoreCalc&&tab.id=="scene1"){
            iframeStoreCalc.src = "http://qcbmm.yunnative.com:3000/d/ZtI4DKmVz/yun-shu-cheng-fei-cun-suan-fen-chi-chang-jing?orgId=1&kiosk=off&refresh=5s";
            setTaskEngineValue("0");
            clearInterval(timer)
            autoRefreshDlcData(true,"0")
            const res = await queryDlcDataList("0")
            if (res.data.success) {
                setDlcList(res.data.data.list);
            }
        }
        if (null!=iframeStoreCalc&&tab.id=="scene2"){
            setTaskEngineValue("1");
            iframeStoreCalc.src = "http://qcbmm.yunnative.com:3000/d/UWqSMzGVz/yun-shu-cheng-cun-suan-fen-chi-chang-jing?orgId=1&kiosk=off&refresh=5s";
            clearInterval(timer)
            setTaskEngineValue("1");
            autoRefreshDlcData(true,"1")
            const res = await queryDlcDataList("1")
            if (res.data.success) {
                setDlcList(res.data.data.list);
            }
        }
    }


    const getDlcLogInfo = async (scence) => {
       setDlcModalVisible(false);
        const res = await queryDlcLog(scence)
        setDlcModalVisible(true)
        if (res.data.success) {
            const resList = res.data.data.events
           setDlcLogList(resList)

        } else if (res.data && res.data.length > 200 && res.data.data.indexOf("Please sign in") != -1) {
            window.location.href = "/login"
        } else {
            message.error({
                content: "日志生成中，请稍后"
            })
        }
        //setLoadingEvent(false)
    }


    useEffect(() => {
        (async () => {
            console.log("quanju:"+taskEngineValue)
            const res = await queryDlcDataList(taskEngineValue)
            if (res.data.success) {
                 setDlcList(res.data.data.list);
                if(!res.data.data.flag){
                    clearInterval(timer)
                }else{
                    clearInterval(timer)
                    autoRefreshDlcData(true,"0")
                }
                setRefreshValue(res.data.data.flag);


            }
            if (res.data && res.data > 200 && res.data.indexOf("Please sign in") != -1) {
                window.location.href = "/login"
            }

            const  resDlc = await  queryDlcInfo()
             setDlcResourceInfo(resDlc.data.data);

        })()

    }, [])
    return (
        <LayoutContent>
            <LayoutContent.Header title='操作'>
                <Button onClick={() => updateDataEngineDlc1()} type="primary" loading={isLoadingDlc1}>创建集群</Button>
                {/*<Button onClick={() => startEtl2()} type="primary" loading={isLoadingDlc2}>2.启动ETL作业 </Button>*/}
                {/*<Button onClick={() => startAdhoc3()} type="primary" loading={isLoadingDlc3}>3.启动Adhoc作业</Button>*/}
                {/*<Button onClick={() => updateDataEngineDlc4()} type="pay" loading={isLoadingDlc4}>4.开启弹性算力</Button>*/}

                <Button  type="weak" style={{border:0}}>
                    <span style={{marginTop:"10px",marginBottom:"10px", marginLeft: "10px" }}> 非存算分离集群：{dlcResourceInfo.nonStoreCalc}&nbsp;&nbsp;|</span>
                    <span style={{marginTop:"10px",marginBottom:"10px", marginLeft: "10px" }}> 存算分离集群：{dlcResourceInfo.storeCalc} &nbsp;</span>

                </Button>
            </LayoutContent.Header>

            <div id="main">
                <div>
                    <div className="tableStoreCal">
                        <div className="title">场景简介</div>
                        <Card>
                            <Card.Body>
                                <Tabs tabs={tabs} onActive={(tab,event)=>tabsChange(tab, event)}>
                                    <TabPanel id="scene1" >
                                        <Card.Body>场景名：非存算分离场景 </Card.Body>
                                        <Card.Body>场景介绍：非存算分离架构下，当ETL任务把计算资源耗尽、调度队列占满后，新增ADHOC任务需等待计算资源被释放后才能开始工作，出现任务排队阻塞的情况。</Card.Body>
                                        <Card.Body>支持队列数：固定（5）</Card.Body>
                                        <Card.Body>启动作业：
                                            <Button
                                                onClick={() => startEtl1("1","0")} loading={isLoadingDlc2} type="primary">启动ETL作业
                                            </Button>
                                            <Button
                                                style={{ marginLeft: "20px"}}
                                                onClick={() => startAdhoc1("1","0")} loading={isLoadingDlc3} type="primary">启动Adhoc作业
                                            </Button>
                                            <Button
                                                style={{ marginLeft: "20px"}}
                                                onClick={() => getDlcLogInfo("1")}  type="pay">查看日志
                                            </Button>
                                        </Card.Body>

                                    </TabPanel>
                                    <TabPanel id="scene2">
                                        <Card.Body>场景名：存算分离场景 </Card.Body>
                                        <Card.Body>场景介绍：非存算分离架构下，当ETL任务把计算资源耗尽、调度队列占满后，新增ADHOC任务需等待计算资源被释放后才能开始工作，出现任务排队阻塞的情况。</Card.Body>
                                        <Card.Body>支持队列数：固定（5）+弹性（5）</Card.Body>
                                        <Card.Body>推荐产品：DLC、WeData</Card.Body>
                                        <Card.Body>启动作业：
                                            <Button
                                                onClick={() => startEtl1("2","1")} loading={isLoadingDlc2} type="primary">启动ETL作业
                                            </Button>
                                            <Button
                                                style={{marginLeft: "20px"}}
                                                onClick={() => startAdhoc1("2","1")} loading={isLoadingDlc3} type="primary">启动Adhoc作业
                                            </Button>
                                            <Button
                                                style={{ marginLeft: "20px"}}
                                                onClick={() => getDlcLogInfo("2")}  type="pay">查看日志
                                            </Button>
                                        </Card.Body>
                                    </TabPanel>
                                </Tabs>
                                <div className="title">数据列表
                                    <Switch id="switchValue" style={{marginTop: "15px", marginBottom: "15px", marginLeft: "15px"}}
                                            onChange={(v) => autoRefreshDlcData(v,taskEngineValue)} value={refreshValue}>
                                        自动刷新(5秒)
                                    </Switch>

                                    <Button style={{marginTop: "10px", marginBottom: "10px", marginLeft: "20px"}}
                                            onClick={() => refreshDlcData(taskEngineValue)}  >刷新</Button></div>

                                <Table
                                    verticalTop
                                    records={
                                        dlcList
                                    }
                                    recordKey="taskId"
                                    bordered='all'
                                    rowClassName={dlcList => {
                                        if(dlcList.sqlType === "ETL"){
                                            return  "bg-gray"
                                        }
                                        if(dlcList.sqlType === "ADHOC"){
                                            return  "bg-blue"
                                        }
                                    }}
                                    columns={[
                                        {
                                            key: "taskId",
                                            header: "任务ID",
                                            align: 'left',
                                            width: '10%'
                                        },
                                        {
                                            key: "taskEngine",
                                            header: "类型",
                                            align: 'left',
                                            width: '10%'
                                        },
                                        {
                                            key: "createTime",
                                            header: "任务创建时间",
                                            align: 'left',
                                            width: '20%'
                                        },
                                        {
                                            key: "endTime",
                                            header: "任务结束时间",
                                            align: 'left',
                                            width: '20%'
                                        },
                                        {
                                            key: "duration",
                                            header: "任务耗时",
                                            align: 'left',
                                            width: '10%'
                                        },
                                        {
                                            key: "sqlType",
                                            header: "任务类型",
                                            align: 'left',
                                            width: '10%'
                                        },
                                        {
                                            key: "taskState",
                                            header: "任务状态",
                                            width: '10%',
                                            align: 'left', render: cvm => {
                                                if (cvm.taskState === "成功") {
                                                    return <span style={{color: "green"}}>{cvm.taskState}</span>;
                                                } else if(cvm.taskState==="执行中"||cvm.taskState==="初始化") {

                                                    return <span style={{color: "blue"}}>{cvm.taskState}</span>;
                                                }else if(cvm.taskState==="排队中"||cvm.taskState==="失败"){
                                                    return <span style={{color: "red"}}>{cvm.taskState}</span>;
                                                }else{
                                                    return <span>{cvm.taskState}</span>;
                                                }
                                            },
                                        }

                                    ]}
                                />
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                <div>
                    <iframe
                        src="http://qcbmm.yunnative.com:3000/d/ZtI4DKmVz/yun-shu-cheng-fei-cun-suan-fen-chi-chang-jing?orgId=1&kiosk=off&refresh=5s"
                        width="100%" height="100%" frameBorder="12" id="refreshIframeStoreCalc"></iframe>
                </div>
            </div>


            <Modal visible={recoverVisible} caption={captionName} onClose={close} size='xl' style={{ fontSize: '14px' }}>
                <Modal.Body>
                    <List type="bullet" style={{ marginLeft: '20px', marginTop: '10px' }}>
                        <List.Item>{topList.statusTips}   &nbsp;   开始时间：{list[0].timeStr} {topList.status!=10&&topList.status!=0?" 结束时间："+list[list.length-1].endTime  +"    耗时："+list[list.length-1].timeNum:""} </List.Item>
                        <List.Item>{topList.statusTips1}   &nbsp;   开始时间：{list[0].timeStr} {topList.status1!=10&&topList.status1!=0?" 结束时间："+list[list.length-1].endTime1  +"    耗时："+list[list.length-1].timeNum1:""} </List.Item>

                    </List>
                    <div className="podbox" id='podbox_black'>
                        {
                            list.map((item,index) => {
                                return (
                                    <div className='podbox_black'  key={index}>
                                        <div className="podleft">
                                            <p>{item.endTime}</p>
                                            <p>{item.endTime1}</p>
                                        </div>
                                        <div className="podright">
                                            <p>{item.statusTips}   &nbsp;{ item.status!=10&&item.status!=0?" 耗时："+item.timeNum:"" }</p>
                                            <p>{item.statusTips1}   &nbsp;{ item.status1!=10&&item.status1!=0?" 耗时："+item.timeNum1:"" }</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </Modal.Body>
            </Modal>


            <Modal visible={dlcModelVisible} onClose={() => setDlcModalVisible(false)} className="popupStoreCalc" caption="查看日志"
                   style={{fontSize: '14px'}}>
                <Modal.Body style={{textAlign: "center", width: '900px', height: '400px'}}>
                  <Table
                                            verticalTop
                                            records={
                                               dlcLogList
                                            }
                                            recordKey="time"
                                            bordered='all'
                                            columns={[
                                                {
                                                    key: "time",
                                                    header: "时间",
                                                    align: 'left',
                                                    width:'20%'
                                                },

                                                {
                                                    key: "eventsAction",
                                                    header: "集群状态",
                                                    align: 'left',
                                                    width:'20%'

                                                },
                                                {
                                                    key: "clusterInfo",
                                                    header: "集群详情",
                                                    align: 'left',
                                                    width:'60%'
                                                }

                                            ]}
                                        />
                </Modal.Body>
            </Modal>
        </LayoutContent>
    );
}
