import React, {useEffect, useState} from "react";
import "tea-component/dist/tea.css";
import {
    Layout,
    Button,
    Table,
    Card,
    Tabs,
    TabPanel,
    ImagePreview, Copy
} from "tea-component";
import './index.scss'
import {getGameDataInfo,querySceneConfig} from "../../../services/function"
//import {getIframeResUrl}  from '../../../services/bookStorage'

const {Content: LayoutContent} = Layout;
export default function GameDataFunction() {
    const [nodeList, setNodeList] = useState([])
    const leftImage = "https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/game_data_analysis/bitmap_left.png";
    const [scene2LeftIframe,setScene2LeftIframe] = useState("https://share.tcbi.qq.com/page/share?pageId=10516767&projectId=11012722&token=31cde1f7-0948-4c9a-be9e-d2c65976e484&scope=page");
    const [scene3LeftIframe,setScene3LeftIframe] =useState( "https://share.tcbi.qq.com/page/share?pageId=10516768&projectId=11012722&token=f07758f8-8276-46d5-ace8-7dff13bd4299&scope=page");
    const [scene4LeftIframe,setScene4LeftIframe] = useState("https://share.tcbi.qq.com/page/share?pageId=10516328&projectId=11012722&token=2039c43e-b964-4afa-87c5-56b18001d115&scope=page");
    const [scene1RightIframe,setScene1RightIframe] = useState("https://workbench.cloud.tencent.com/webshell?regionId=1&instanceId=ins-q7wezi8y&username=root&port=22")
    const scene2RightImage="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/game_data_analysis/game_data_scene2.png";
    const scene3RightImage = "https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/game_data_analysis/game_data_scene3.png";
    const scene4RightImage = "https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/game_data_analysis/game_data_scene4.png";
    const [scene1disable, setScene1Disable] = useState(true)
    const [scene2disable, setScene2Disable] = useState(false)
    const [scene3disable, setScene3Disable] = useState(false)
    const [scene4disable, setScene4Disable] = useState(false)
    //const [scene5disable, setScene5Disable] = useState(false)

    const refreshPage = () => {
        window.location.reload();
    }

    const goTxyLogin = () => {
        const w = window.open('_black') //这里是打开新窗口
        w.location.href = 'https://cloud.tencent.com/login' //这样就可以跳转了
    }

    const tabs = [
        {id: "scene1", label: "数据描述"},
        {id: "scene2", label: "场景描述"},
        // {id:"scene3",label: "登入shell"},
        {id: "scene4", label: "JOIN SQL"},
        {id: "scene5", label: "BitMap SQL"},

    ];

   const  scene1Content="1、ClickHouse查询的数据汇聚和处理是在Coordinator中完成的，但是由于ClickHouse并不支持Shuffle，因此对于Join而言，右表必须为全量数据。无论是普通Join还是GlobalJoin，当右表的数据量较大时，若将数据都放到内存中，会比较容易OOM。\n2、若将数据spill到磁盘，虽然可以解决内存问题，但由于有磁盘IO和数据序列化、反序列化的代价，因此查询的性能会受到影响。Clickhouse的BitMap操作是基于位图的，因此它占用内存小计算速度快，在很多场景下可以使用BitMap代替Join从而获得不错的性能提升。\n" +
       "3、如在游戏场景中使用的较多的天留存率计算，在单表300多亿条数据量情况下获得80%的性能提升。";

   const  scene2Content = "本案例使用了三个数据集。\n" +
       "1、games_users表(用户信息)，从关系型数据中同步到ClickHouse中。数据行数619742993，用户数619742993，时间跨度 ：2021年01月01日至2023年02月06日。\n" +
       "2、games_event_all(用户行为日志),通过flink cdc写入ClickHouse中，数据行34876190565，包含a的所有用户的行为日志。每天数据量 4500多万条，时间跨度 ：2021年01月01日至2023年02月06日。\n" +
       "3、games_play_orders(用户付费记录)，从关系型数据库中同步到ClickHouse中，数据行数1496621583，每天记录数200万左右，时间跨度 ：2021年01月01日至2023年02月06日。";


    // const sqlContext = "sh connect_ck.sh";


    const join_sql = "-- 求次日留存(join方式)\nselect\n" +
        "    count(0) as day0\n" +
        "   ,count(distinct t2.user_key) -1 as day1\n" +
        "   ,day1/day0 as p\n" +
        "from\n" +
        "(\n" +
        "    select\n" +
        "        distinct user_key \n" +
        "    from games.games_event_all where toYYYYMMDD(event_time)='20210101'   and event='user_login'\n" +
        ") t1\n" +
        "left join\n" +
        "(\n" +
        "    select\n" +
        "        distinct user_key \n" +
        "    from games.games_event_all where toYYYYMMDD(event_time)='20210102' and event='user_login'\n" +
        ") t2\n" +
        "on t1.user_key=t2.user_key";

    const bitMap_sql = "-- 求次日留存(bitmap方式)\nSELECT \n" +
        "    bitmapCardinality(\n" +
        "        (SELECT user_bitmap FROM games.games_event_retain WHERE day = 20210101 and event='user_login') \n" +
        "    ) as day0\n" +
        "   ,bitmapAndCardinality(\n" +
        "        (SELECT user_bitmap FROM games.games_event_retain WHERE day = 20210101 and event='user_login'), \n" +
        "        (SELECT user_bitmap FROM games.games_event_retain WHERE day = 20210102 and event='user_login')\n" +
        "    ) as day1\n" +
        "   ,day1/day0 as p\n";

    const [copyContent, setCopyContent] = useState("sh connect_ck.sh")


    const sceneChange = async (scene) => {
        let scene1Id = document.getElementById('scene1Id');
        let scene2Id = document.getElementById('scene2Id');
        let scene3Id = document.getElementById('scene3Id');
        let scene4Id = document.getElementById('scene4Id');
       // let scene5Id = document.getElementById('scene5Id');


        let scene1RightId = document.getElementById('scene1RightId');
        let scene2RightId = document.getElementById('scene2RightId');
        let scene3RightId = document.getElementById('scene3RightId');
        let scene4RightId = document.getElementById('scene4RightId');
        //let scene5RightId = document.getElementById('scene5RightId');
        setScene1Disable(false);
        setScene2Disable(false);
        setScene3Disable(false);
        setScene4Disable(false);
       // setScene5Disable(false);

        if (scene === "scene1") {
            scene1Id.style.display = "block";
            scene2Id.style.display = "none";
            scene3Id.style.display = "none";
            scene4Id.style.display = "none";
            //scene5Id.style.display = "none";
            scene1RightId.style.display = "block";
            scene2RightId.style.display = "none";
            scene3RightId.style.display = "none";
            scene4RightId.style.display = "none";
            //scene5RightId.style.display = "none";
            setScene1Disable(true);
        } else if (scene === "scene2") {
            scene1Id.style.display = "none";
            scene2Id.style.display = "block";
            scene3Id.style.display = "none";
            scene4Id.style.display = "none";
           // scene5Id.style.display = "none";
            scene1RightId.style.display = "none";
            scene2RightId.style.display = "block";
            scene3RightId.style.display = "none";
            scene4RightId.style.display = "none";
            //scene5RightId.style.display = "none";
            setScene2Disable(true);
        } else if (scene === "scene3") {
            scene1Id.style.display = "none";
            scene2Id.style.display = "none";
            scene3Id.style.display = "block";
            scene4Id.style.display = "none";
           // scene5Id.style.display = "none";
            scene1RightId.style.display = "none";
            scene2RightId.style.display = "none";
            scene3RightId.style.display = "block";
            scene4RightId.style.display = "none";
           // scene5RightId.style.display = "none";
            setScene3Disable(true);
        } else if (scene === "scene4") {
            scene1Id.style.display = "none";
            scene2Id.style.display = "none";
            scene3Id.style.display = "none";
            scene4Id.style.display = "block";
           // scene5Id.style.display = "none";
            scene1RightId.style.display = "none";
            scene2RightId.style.display = "none";
            scene3RightId.style.display = "none";
            scene4RightId.style.display = "block";
           // scene5RightId.style.display = "none";
            setScene4Disable(true);
        }
        // else if (scene === "scene5") {
        //     scene1Id.style.display = "none";
        //     scene2Id.style.display = "none";
        //     scene3Id.style.display = "none";
        //     scene4Id.style.display = "none";
        //     scene5Id.style.display = "block";
        //     scene1RightId.style.display = "none";
        //     scene2RightId.style.display = "none";
        //     scene3RightId.style.display = "none";
        //     scene4RightId.style.display = "none";
        //     scene5RightId.style.display = "block";
        //     setScene5Disable(true);
        // }
    }

    const tabsChange = async (tab) => {
        let copyInfoId = document.getElementById('copyInfoId');

        if (tab.id === "scene1") {
            copyInfoId.style.display = "none";
            setCopyContent(scene1Content);
        } else if (tab.id === "scene2") {
            copyInfoId.style.display = "none";
            setCopyContent(scene2Content);
        }
        // else if (tab.id === "scene3") {
        //     copyInfoId.style.display = "block";
        //     setCopyContent(sqlContext);
        // }
        else if (tab.id === "scene4") {
            copyInfoId.style.display = "block";
            setCopyContent(join_sql);
        } else if (tab.id === "scene5") {
            copyInfoId.style.display = "block";
            setCopyContent(bitMap_sql);
        }
    }

    useEffect(() => {
        //
        (async () => {
            const res = await getGameDataInfo()
            if (res.data.success) {
                res && res.data && setNodeList(res.data.data);
            }
            if (res.data && res.data > 200 && res.data.indexOf("Please sign in") !== -1) {
                window.location.href = "/login"
            }
            const sceneRes = await  querySceneConfig(12,3)
            if(sceneRes.data.success){
                let datas = sceneRes.data.data;
               if(null!=datas){
                   datas.map(function (config){
                       if(config.configKey==="game_data_scene1_right_iframe"){
                          setScene1RightIframe(config.configValue);
                       }
                       else if(config.configKey==="game_data_scene2_left_iframe"){
                           setScene2LeftIframe(config.configValue);
                       }
                       else if(config.configKey==="game_data_scene3_left_iframe"){
                           setScene3LeftIframe(config.configValue);
                       }
                       else if(config.configKey==="game_data_scene4_left_iframe"){
                           setScene4LeftIframe(config.configValue);
                       }
                   })
               }
            }
            // if(null!=scene1RightIframe){
            // const iframeRes = await getIframeResUrl(encodeURIComponent(scene1RightIframe));
            // if(iframeRes.data.success){
            //     setScene1RightIframe(iframeRes.data.data);
            // }
            //
            // if (iframeRes.data && iframeRes.data > 200 && iframeRes.data.indexOf("Please sign in") !== -1) {
            //     window.location.href = "/login"
            // }
            // }
        })()

    }, [])
    return (
        <LayoutContent>
            <LayoutContent.Header title='游戏玩家分析演示'>
                <Button type="pay" onClick={() => sceneChange("scene1")} disabled={scene1disable}>BitMap加速</Button>
                <Button type="pay" onClick={() => sceneChange("scene2")} disabled={scene2disable}>人群圈选</Button>
                <Button type="pay" onClick={() => sceneChange("scene3")} disabled={scene3disable}>下钻分析</Button>
                <Button type="pay" onClick={() => sceneChange("scene4")} disabled={scene4disable}>留存分析</Button>
                {/*<Button type="pay" onClick={() => sceneChange("scene5")} disabled={scene5disable}>场景5</Button>*/}
                <Button style={{float: "right"}} onClick={() => goTxyLogin()} type="weak">登录腾讯云</Button>
                <Button style={{float: "right"}} onClick={() => refreshPage()} type="weak">刷新</Button>
            </LayoutContent.Header>


            <div style={{border: "1px solid black",height:'100%'}}>
                <div id="scene1Id"
                     style={{width: "40%", float: "left", height: "100%", overflow: "auto", display: "block"}}>

                    <div className="tableGameData">
                        <div className="tableGameData">
                            <div className="title">ClickHouse配置</div>
                            <Table
                                verticalTop
                                records={
                                    nodeList
                                }
                                recordKey="nodeId"
                                bordered='all'
                                columns={[
                                    {
                                        key: "InstanceId",
                                        header: "ID/名称",
                                        align: 'left',
                                        width: '20%',
                                        render: nodeList => {
                                            return <>
                                                <p>{nodeList.InstanceId}/{nodeList.InstanceName}</p>

                                            </>
                                        },
                                    },
                                    {
                                        key: "status",
                                        header: "状态",
                                        align: 'left',
                                        width: '10%'
                                    },
                                    {
                                        key: "Node",
                                        header: "计算节点",
                                        align: 'left',
                                        width: '20%',
                                        render: nodeList => {
                                            return <>
                                                <p>{nodeList.Node}/  {nodeList.NodeName}</p>

                                            </>
                                        },
                                    },
                                    {
                                        key: "zone",
                                        header: "可用区",
                                        align: 'left',
                                        width: '13%'
                                    },
                                ]}
                            />
                            <div className="title">场景简介
                                <Copy text={copyContent}>
                                    <button id="copyInfoId" type="primary" style={{float: "right", marginRight: "10px" , display: "none"}}>复制</button>
                                </Copy>
                            </div>

                            <Card>
                                <Card.Body>
                                    <Tabs  tabs={tabs} onActive={(tab) => tabsChange(tab)}>

                                        <TabPanel id="scene1">
                                            <Card.Body style={{display: "flex", whiteSpace: "pre-wrap",fontSize: "12px", lineHeight: "24px",height: '180px',overflowX:"hidden",overflowY:"auto"  }}>{scene2Content}</Card.Body>


                                            {/*<Card.Body><span style={{marginLeft: "20px"}}>  ClickHouse查询的数据汇聚和处理是在Coordinator中完成的，但是由于ClickHouse并不支持Shuffle，因此对于Join而言，右表必须为全量数据。无论是普通Join还是GlobalJoin，当右表的数据量较大时，若将数据都放到内存中，会比较容易OOM。若将数据spill到磁盘，虽然可以解决内存问题，但由于有磁盘IO和数据序列化、反序列化的代价，因此查询的性能会受到影响。Clickhouse的BitMap操作是基于位图的，因此它占用内存小计算速度快，在很多场景下可以使用BitMap代替Join从而获得不错的性能提升。*/}
                                            {/*  </span> </Card.Body>*/}
                                            {/*<Card.Body> <span*/}
                                            {/*    style={{marginLeft: "20px"}}>如在游戏场景中使用的较多的天留存率计算，在单表300多亿条数据量情况下获得80%的性能提升。</span>*/}
                                            {/*</Card.Body>*/}
                                        </TabPanel>

                                        <TabPanel id="scene2">
                                            <Card.Body style={{display: "flex", whiteSpace: "pre-wrap", fontSize: "12px", lineHeight: "24px",height: '180px',overflowX:"hidden",overflowY:"auto"  }}>{scene1Content}</Card.Body>

                                            {/*<Card.Body>本案例使用了三个数据集。</Card.Body>*/}
                                            {/*<Card.Body>games_users表(用户信息)，从关系型数据中同步到ClickHouse中。数据行数619742993，用户数619742993，时间跨度*/}
                                            {/*    ：2021年01月01日至2023年02月06日。</Card.Body>*/}
                                            {/*<Card.Body>games_event_all(用户行为日志),通过flink*/}
                                            {/*    cdc写入ClickHouse中，数据行34876190565，包含a的所有用户的行为日志。每天数据量 4500多万条，时间跨度*/}
                                            {/*    ：2021年01月01日至2023年02月06日。*/}
                                            {/*</Card.Body>*/}
                                            {/*<Card.Body>games_play_orders(用户付费记录)，从关系型数据库中同步到ClickHouse中，数据行数1496621583，每天记录数200万左右，时间跨度*/}
                                            {/*    ：2021年01月01日至2023年02月06日。*/}
                                            {/*</Card.Body>*/}

                                        </TabPanel>

                                        {/*<TabPanel id="scene3">*/}
                                        {/*    <Card.Body style={{display: "flex", whiteSpace: "pre-wrap",fontSize: "13px", lineHeight: "24px" ,height: '180px',overflowX:"hidden",overflowY:"auto"  }}>{sqlContext}</Card.Body>*/}
                                        {/*</TabPanel>*/}

                                        <TabPanel id="scene4">
                                            <Card.Body style={{display: "flex", whiteSpace: "pre-wrap",fontSize: "13px", lineHeight: "24px",height: '180px',overflowX:"hidden",overflowY:"auto"   }}>{join_sql}</Card.Body>
                                        </TabPanel>
                                        <TabPanel id="scene5">
                                            <Card.Body style={{display: "flex", whiteSpace: "pre-wrap",fontSize: "13px", lineHeight: "24px",height: '180px',overflowX:"hidden",overflowY:"auto"   }}>{bitMap_sql}</Card.Body>
                                        </TabPanel>

                                    </Tabs>


                                </Card.Body>
                            </Card>

                        </div>

                    </div>

                    <div className="tableGameData">
                        <div className="title">BitMap计算原理</div>
                        <Card>
                               <Card.Body>
                                <ImagePreview
                                    previewSrc={leftImage}
                                    previewTitle="BitMap计算原理">
                                    {open => <a onClick={open}> <img id="picIframeGameData"
                                                                     src={leftImage}
                                                                     alt="" style={{width: '100%', height: '100%'}}/>
                                    </a>}
                                </ImagePreview>

                               </Card.Body>
                        </Card>

                    </div>

                </div>
                <div id="scene2Id"
                     style={{width: "80%", float: "left", height: "100%", overflow: "scroll", display: "none"}}>
                    <iframe src={scene2LeftIframe} width="100%" height="100%" id="refreshIframe1"/>
                </div>
                <div id="scene3Id"
                     style={{width: "80%", float: "left", height: "100%", overflow: "scroll", display: "none"}}>
                    <iframe src={scene3LeftIframe} width="100%" height="100%" id="refreshIframe2"/>
                </div>
                <div id="scene4Id"
                     style={{width: "80%", float: "left", height: "100%", overflow: "scroll", display: "none"}}>
                    <iframe src={scene4LeftIframe} width="100%" height="100%" id="refreshIframe3"/>
                </div>
                {/*<div id="scene5Id"*/}
                {/*     style={{width: "80%", float: "left", height: "100%", overflow: "scroll", display: "none"}}>场景5*/}
                {/*    <iframe src={scene5LeftIframe} width="100%" height="100%" id="refreshIframe4"/></div>*/}
                <div id="scene1RightId"
                     style={{width: "60%", float: "left", height: "100%",  display: "block"}}>
                    <iframe
                        src={scene1RightIframe}
                        width="100%" height="100%" frameBorder="12" id="refreshIframeGpu"/>

                    {/*<div className="podboxGameData" id='podboxGameData_black'>*/}
                    {/*    <p style={{color: "white"}}>进入命令窗口页面</p>*/}
                    {/*</div>*/}

                </div>
                <div id="scene2RightId"
                     style={{width: "20%", float: "left", height: "100%", overflow: "scroll", display: "none"}}>
                    <Card>
                        <Card.Body>
                            <div className='cost-title'><b>场景二：人群圈选</b></div>
                            <div className='cost-content'>
                                <b>场景举例：</b>
                                <br/>
                                <span>圈选广州和深圳地区，通过app store下载游戏并且用iphone激活，在2021年1月1日13点23分至2022年1月1日13点23分之间有付费，氪金数量大于50万年龄在18岁到24岁之间的女性。并且要求提供用户的所有画像信息。</span>
                            </div>
                            <div className='cost-content'>
                                <b>使用说明：</b>
                                <br/>
                                <span> 可以根据筛选器进行各种维度的圈选，特别是在大R场景根据付费金额进行圈选。</span>
                            </div>

                        </Card.Body>
                    </Card>
                    <br/>
                    <br/>
                    <Card>
                        <Card.Body>
                            <div className="cost-title"> 访问示意图</div>
                            <img
                                src={scene2RightImage}
                                alt="" style={{width: '100%', height: '100%'}}/>


                        </Card.Body>
                    </Card>
                    <br/>
                    <br/>

                </div>
                <div id="scene3RightId"
                     style={{width: "20%", float: "left", height: "100%", overflow: "scroll", display: "none"}}>
                    <Card>
                        <Card.Body>
                            <div className='cost-title'><b>场景三：下钻分析</b></div>
                            <div className='cost-content'>
                                <b>场景举例：</b>
                                <br/>
                                <span>按照游戏、渠道、性别进行上钻下钻操作，计算数付费人群和付费总额。</span>
                            </div>
                            <div className='cost-content'>
                                <b>备注：</b>
                                <br/>
                                <span> 有些游戏公司也只关注渠道和游戏两个画像维度即可，可以根据实际情况进行选择。</span>
                            </div>
                        </Card.Body>
                    </Card>
                    <br/>
                    <br/>
                    <Card>
                        <Card.Body>
                            <div className="cost-title"> 访问示意图</div>
                            <img
                                src={scene3RightImage}
                                alt="" style={{width: '100%', height: '100%'}}/>


                        </Card.Body>
                    </Card>
                    <br/>
                    <br/>

                </div>
                <div id="scene4RightId"
                     style={{width: "20%", float: "left", height: "100%", overflow: "scroll", display: "none"}}>
                    <Card>
                        <Card.Body>
                            <div className='cost-title'><b>场景四：留存分析</b></div>
                            <div className='cost-content'>
                                <b>场景举例：</b>
                                <span>计算2021年1月1日广州地区年龄在22到25周岁通过app store下载本游戏用苹果手机登入有登入事件上报的并且在2023年1月1日-2023年1月7日还有登入事件上报的所有女性用户在2023年1月1日-2023年1月7日每一天留存率。</span>
                            </div>
                            <div className='cost-content'>
                                <b>备注：</b>
                                <br/>
                                <span> 在数据量比较大的情况下可以使用Bitmap来加速处理。</span>
                            </div>

                        </Card.Body>
                    </Card>
                    <br/>
                    <br/>
                    <Card>
                        <Card.Body>
                            <div className="cost-title"> 访问示意图</div>
                            <img
                                src={scene4RightImage}
                                alt="" style={{width: '100%', height: '100%'}}/>


                        </Card.Body>
                    </Card>
                    <br/>
                    <br/>
                </div>
                <div id="scene5RightId"
                     style={{width: "20%", float: "left", height: "100%", overflow: "scroll", display: "none"}}>场景5右边
                </div>
            </div>

        </LayoutContent>
    );
}
