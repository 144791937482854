import React, {useEffect, useState} from "react";
import "tea-component/dist/tea.css";
import {
    Layout, Card, ImagePreview, List, Table, Bubble,
    Icon, H4, ExternalLink, Tabs, TabPanel
} from "tea-component";
import './index.scss'
import {Link} from "react-router-dom";
import {querySceneConfig} from "../../../services/function";
const { Body, Sider, Content: LayoutContent } = Layout;

export default function BaseInfo() {
    const [pptUrl,setPptUrl] = useState("https://drive.weixin.qq.com/s?k=AJEAIQdfAAoVHoPN6XAJkAWwanACk");

    useEffect(() => {
        (async () => {
            const sceneRes = await  querySceneConfig(5,3)
            if(sceneRes.data.success){
                let datas = sceneRes.data.data;
                if(null!=datas){
                    datas.map(function (config){
                        if(config.configKey==="deal_time_ppt_url"){
                            setPptUrl(config.configValue);
                        }

                    })
                }
            }

        })()

    }, [])
    return (
        <LayoutContent>
            <LayoutContent.Header title='演示说明'/>
            <div className="presentation">
                <div className="presentation_box">
                    <iframe src={pptUrl} width="100%" height="100%"
                            frameBorder="0"/>
                </div>
            </div>
        </LayoutContent>
    );
}
