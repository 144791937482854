import React, {useEffect, useState} from "react";
import "tea-component/dist/tea.css";
import {
    Layout,
    Button,
    Table,
    Card,
    ImagePreview,
    Select, message
} from "tea-component";
import './index.scss'
import {getAiOffLineMixingClusterInfo, startAiOffLineMixing,querySceneConfig,startAiOffLineMixingScene2,createAiOffLineTask,deleteAiOffLineTask} from "../../../services/function"

const {Content: LayoutContent} = Layout;
export default function OffLineFunction() {
    const [nodeList, setNodeList] = useState([])
    const leftImage = "https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/off_line_mixing/scheduling_diagram.png";
    const [scene1RightIframe,setScene1RightIframe] = useState("https://console.cloud.tencent.com/monitor/dashboard/dashboards/d/s0txhz4ehq9jdcau/zaki-de-dashboard");
    const [scene1disable, setScene1Disable] = useState(true)
    const [scene2disable, setScene2Disable] = useState(false)
    const [isLoadingScene, setIsLoadingScene] = useState(false)
    const [scene,setScene] = useState("1");
    const [isLoadingCreateAiTask, setIsLoadingCreateAiTask] = useState(false)
    const [isLoadingDeleteAiTask, setIsLoadingDeleteAiTask] = useState(false)
    const [isLoadingScene2, setIsLoadingScene2] = useState(false)
    const selectCpu = [
        {value: "1", text: "4核", tooltip: "4核"},
        {value: "2", text: "8核", tooltip: "8核"},
        {value: "3", text: "12核", tooltip: "12核"},
        {value: "4", text: "16核", tooltip: "16核"}
    ];
    const selectMemory = [
        {value: "4Gi", text: "16Gi", tooltip: "16Gi"},
        {value: "8Gi", text: "32Gi", tooltip: "32Gi"},
        {value: "12Gi", text: "48Gi", tooltip: "48Gi"},
        {value: "16Gi", text: "64Gi", tooltip: "64Gi"}
    ];
    const [cpuValue, setCpuValue] = useState("1")
    const [memoryValue, setMemoryValue] = useState("4Gi")
   // const [kdValue, setKdValue] = useState("50%")


    const refreshPage = () => {
        window.location.reload();
    }

    const goTxyLogin = () => {
        const w = window.open('_black') //这里是打开新窗口
        w.location.href = 'https://cloud.tencent.com/login' //这样就可以跳转了
    }

    const [sceneDes, setSceneDes] = useState("场景1：依托腾讯云数据湖分析DLC开箱即用的产品形态，将GPU服务器的CPU资源封装为独立的引擎，通过弹性集群的形式作为DLC常驻集群的资源补充，对外提供透明的资源扩展能力。客户在使用DLC-Spark进行数据分析的时候，底层资源的管理由DLC进行控制，灵活调度产品自身资源和客户供给的资源（GPU服务器容器化的CPU资源总量），实现方案落地对业务无感，零业务改造。");

    const scene1Content = "当前DLC混部在一台28C116G的GPU服务器上，规划混部资源为16C64G。即：cpu任务可用资源上限为16C64G。当混部引擎运行任务时，在spark框架下，整体节点的资源使用率在40%-50%之间。";

    const sceneChange = async (scene) => {
        let effectId1 = document.getElementById('effectId1');
        let effectId2 = document.getElementById('effectId2');
       let  submitInfo1 =document.getElementById("submitInfo1");
        setScene("1");
        if (scene === "scene1") {

            effectId1.style.display = "block"
            effectId2.style.display = "none"
            setScene1Disable(true)
            setScene2Disable(false)
            submitInfo1.style.display = "block";
            setSceneDes("场景1：" + "依托腾讯云数据湖分析DLC开箱即用的产品形态，将GPU服务器的CPU资源封装为独立的引擎，通过弹性集群的形式作为DLC常驻集群的资源补充，对外提供透明的资源扩展能力。客户在使用DLC-Spark进行数据分析的时候，底层资源的管理由DLC进行控制，灵活调度产品自身资源和客户供给的资源（GPU服务器容器化的CPU资源总量），实现方案落地对业务无感，零业务改造。")
        }
       else if (scene === "scene2") {
           setScene("2")
            effectId1.style.display = "none"
            effectId2.style.display = "block"
            setScene1Disable(false)
            setScene2Disable(true)
            setSceneDes("场景2：" + "依托腾讯云数据湖分析DLC开箱即用的产品形态，将GPU服务器的CPU资源封装为独立的引擎，通过弹性集群的形式作为DLC常驻集群的资源补充，对外提供透明的资源扩展能力。客户在使用DLC-Spark进行数据分析的时候，底层资源的管理由DLC进行控制，灵活调度产品自身资源和客户供给的资源（GPU服务器容器化的CPU资源总量），实现方案落地对业务无感，零业务改造。")
            submitInfo1.style.display = "none";
        }
    }

    const startScene = async (scene) => {
        setIsLoadingScene(true)
        const res = await startAiOffLineMixing(scene)
        if (res.data.success) {
            message.success({
                content: res.data.msg
            })
        } else if (res.data && res.data.length > 200 && res.data.indexOf("Please sign in") !== -1) {
            window.location.href = "/login"
        } else {
            message.error({
                content: res.data.msg
            })
        }
        setIsLoadingScene(false);
    }

    const createAiTask = async () => {
        setIsLoadingCreateAiTask(true)
        const res = await createAiOffLineTask(scene)
        if (res.data.success) {
            message.success({
                content: res.data.msg
            })
        } else if (res.data && res.data.length > 200 && res.data.indexOf("Please sign in") !== -1) {
            window.location.href = "/login"
        } else {
            message.error({
                content: res.data.msg
            })
        }
        setIsLoadingCreateAiTask(false);
    }

    const deleteAiTask = async () => {
        setIsLoadingDeleteAiTask(true)
        const res = await deleteAiOffLineTask()
        if (res.data.success) {
            message.success({
                content: res.data.msg
            })
        } else if (res.data && res.data.length > 200 && res.data.indexOf("Please sign in") !== -1) {
            window.location.href = "/login"
        } else {
            message.error({
                content: res.data.msg
            })
        }
        setIsLoadingDeleteAiTask(false);
    }

    const scene2Submit = async(cpu,memory) =>{

       // alert("cpu="+cpu +" memory="+memory);
        setIsLoadingScene2(true)
        const res = await startAiOffLineMixingScene2(cpu,memory)
        if (res.data.success) {
            message.success({
                content: res.data.msg
            })
        } else if (res.data && res.data.length > 200 && res.data.indexOf("Please sign in") !== -1) {
            window.location.href = "/login"
        } else {
            message.error({
                content: res.data.msg
            })
        }
        setIsLoadingScene2(false);
    }


    const showRunInfo = () => {
        const w = window.open('_black') //这里是打开新窗口
        w.location.href = 'https://console.cloud.tencent.com/dlc/history-operation' //这样就可以跳转了
    }

    useEffect(() => {
        //
        (async () => {
            const res = await getAiOffLineMixingClusterInfo()
            if (res.data.success) {
                res && res.data && setNodeList(res.data.data);
            }
            if (res.data && res.data > 200 && res.data.indexOf("Please sign in") !== -1) {
                window.location.href = "/login"
            }
             const sceneRes = await  querySceneConfig(13,3)
            if(sceneRes.data.success){
                let datas = sceneRes.data.data;
               if(null!=datas){
                   datas.map(function (config){
                       if(config.configKey==="ai_off_line_mixing_right_url"){
                          setScene1RightIframe(config.configValue);
                       }
                   })
               }
            }

        })()

    }, [])
    return (
        <LayoutContent>
            <LayoutContent.Header title='AI与大数据混部'>
                {/*<Button type="pay" onClick={() => sceneChange("scene1")} disabled={scene1disable}>场景1</Button>*/}
                {/*<Button type="pay" onClick={() => sceneChange("scene2")} disabled={scene2disable}>场景2</Button>*/}
                <Button style={{float: "right"}} onClick={() => goTxyLogin()} type="weak">登录腾讯云</Button>
                <Button style={{float: "right"}} onClick={() => refreshPage()} type="weak">刷新</Button>
            </LayoutContent.Header>


            <div style={{border: "1px solid black", height: '100%'}}>
                <div id="scene1Id"
                     style={{width: "40%", float: "left", height: "100%", overflow: "auto", display: "block"}}>

                    <div className="tableOffLine">
                            <div className="title">场景描述
                            </div>

                            <Card>
                                <Card.Body>
                                    <Card.Body style={{
                                        display: "flex",
                                        whiteSpace: "pre-wrap",
                                        fontSize: "12px",
                                        lineHeight: "24px",
                                        height: '105px',
                                        overflowX: "hidden",
                                        overflowY: "auto"
                                    }}>{sceneDes}</Card.Body>
                                </Card.Body>
                            </Card>
                            <div className="title">集群配置</div>
                            <Table
                                verticalTop
                                records={
                                    nodeList
                                }
                                recordKey="nodeId"
                                bordered='all'
                                columns={[
                                    {
                                        key: "InstanceId",
                                        header: "GPU实例",
                                        align: 'left',
                                        width: '30%',
                                        render: nodeList => {
                                            return <>
                                                <p>{nodeList.instanceId}</p>
                                                <p>{nodeList.gpuType}</p>
                                            </>
                                        },
                                    },
                                    {
                                        key: "cpu",
                                        header: "CPU",
                                        align: 'left',
                                        width: '10%'
                                    },
                                    {
                                        key: "memory",
                                        header: "内存",
                                        align: 'left',
                                        width: '10%'
                                    },
                                    {
                                        key: "gpu",
                                        header: "GPU",
                                        align: 'left',
                                        width: '13%'
                                    },
                                    {
                                        key: "bandWidth",
                                        header: "内网带宽",
                                        align: 'left',
                                        width: '13%'
                                    },
                                ]}
                            />

                    </div>

                    <div className="tableOffLine">
                        <div className="title">效果说明
                            <Button id="submitInfo2" type="primary"
                                    style={{float: "right", marginRight: "20px",display:"block"}}  onClick={() => showRunInfo()} >查看任务</Button>
                            <Button id="submitInfo1" type="pay"
                                                           style={{float: "right", marginRight: "20px",display:"block"}}  onClick={() => startScene(scene)} loading={isLoadingScene}>任务运行</Button>

                            <Button id="deleteAiTask" type="pay"
                                    style={{float: "right", marginRight: "20px",display:"block"}}  onClick={() => deleteAiTask()} loading={isLoadingDeleteAiTask}>暂停AI任务</Button>

                            <Button id="createAiTask" type="pay"
                                    style={{float: "right", marginRight: "20px",display:"block"}}  onClick={() => createAiTask()} loading={isLoadingCreateAiTask}>启动AI任务</Button>

                        </div>
                        <Card id="effectId1" style={{display: "block",marginTop:"20px",  whiteSpace: "pre-wrap",
                            fontSize: "12px",
                            lineHeight: "24px"}}>
                            <Card.Body>{scene1Content}</Card.Body>
                        </Card>
                        <Card id="effectId2" style={{display: "none"}}>
                            <Card.Body style={{ whiteSpace: "pre-wrap",
                                fontSize: "12px",
                                lineHeight: "24px"}}>该场景与场景一区别：通过原生节点Qos能力，对混部引擎DLC使用的CPU和内存进行限制，避免对同服务器上的其他任务造成资源抢占。例如：设置CPU混部资源分别为：4，8，12，16核，则GPU节点CPU使用率最高不超过：14%，29%，43%，57%
                            </Card.Body>
                            <Card.Body>请输入相关限制：</Card.Body>
                            <Card.Body> <span className="selectFontClass">-CPU：</span> <Select
                                appearance="button"
                                options={selectCpu}
                                value={cpuValue}
                                onChange={value => {
                                    setCpuValue(value)
                                    switch (value){
                                        case '1':
                                            setMemoryValue('4Gi')
                                            break;
                                        case '2':
                                            setMemoryValue('8Gi')
                                            break;
                                        case '3':
                                            setMemoryValue('12Gi')
                                            break;
                                        case '4':
                                            setMemoryValue('16Gi')
                                            break;
                                        default:
                                            setMemoryValue('4Gi')
                                            break;
                                    }

                                   }
                                }
                                size="auto"
                                className="selectButtonClass"/> </Card.Body>
                            <Card.Body> <span className="selectFontClass">-内存：</span> <Select
                                appearance="button"
                                options={selectMemory}
                                value={memoryValue}
                                onChange={value => setMemoryValue(value)}
                                size="auto"
                                className="selectButtonClass"/>
                                <Button type="pay" style={{float: "right", marginRight: "20px"}} onClick={() => scene2Submit(cpuValue,memoryValue)} loading={isLoadingScene2}>任务运行</Button>
                            </Card.Body>
                        </Card>


                    </div>

                    <div className="tableOffLine">
                        <div className="title">调度示意</div>
                        <Card>
                            <Card.Body>
                                <ImagePreview
                                    previewSrc={leftImage}
                                    previewTitle="调度示意">
                                    {open => <a onClick={open}> <img id="picIframeOffLine"
                                                                     src={leftImage}
                                                                     alt="" style={{width: '100%', height: '100%'}}/>
                                    </a>}
                                </ImagePreview>

                            </Card.Body>
                        </Card>

                    </div>

                </div>

                <div id="scene1RightId"
                     style={{width: "60%", float: "left", height: "100%", display: "block"}}>
                    <iframe
                        src={scene1RightIframe}
                        width="100%" height="100%" frameBorder="12" id="refreshIframeGpu"/>
                </div>
            </div>

        </LayoutContent>
    );
}
