import React, { useState,useContext } from "react";
import "tea-component/dist/tea.css";
import { Tabs, TabPanel, Layout, Card, List, Radio, Button, Modal,message } from "tea-component";
import './index.scss'
import { MyContext } from "../../../../../context-manger";

import {getReset,getTaskResult} from '../../../../../services/function'
import {getFormatDate} from '../../../../../utils/getFormatDate'
const { Header, Body, Sider, Content: LayoutContent } = Layout;

function diffSecond(start, end) {
    if(null==end||'undefined'==end){
        return 0;
    }
    if(null==start||'undefined'==start){
        start=getFormatDate();
    }
    const startTime = new Date(start);
    const endTime = new Date(end);
    const second_time = Math.abs(startTime - endTime) / 1000; // 得到相差的秒数
    return second_time;
}

export default function Restore() {
    const [list,setList]=useState([
        {
            timeStr:getFormatDate(),
            clbTips:'开始流量调度',
            mySqlTips:'开始 MySQL 主备切换',
            redisTips:'开始 Redis 主备切换',
            timeNum:"0秒",
            timeClbNum:"0秒",
            timeMysqlNum:"0秒",
            timeRedisNum:"0秒",
            clbEndTime:getFormatDate(),
            mySqlEndTime:getFormatDate(),
            redisEndTime:getFormatDate()
        },
    ])
    let pollTaskResultInterval=-1
    const [visible, setVisible] = useState(false);
    const [topList,setTopList]=useState({
        clbTips:'开始流量调度',
        mySqlTips:'开始DB切换,该过程较久,可能需要几分钟',
        redisTips:'开始Redis切换,该过程较久,可能需要几分钟。'
    })
    const {setUrl}=useContext(MyContext)

    const close = () => {
        setVisible(false)
        setList([
            {
                timeStr:getFormatDate(),
                clbTips:'开始流量调度',
                mySqlTips:'开始 MySQL 主备切换',
                redisTips:'开始 Redis 主备切换'
            },
        ])
        setTopList({
            clbTips:'开始流量调度',
            mySqlTips:'开始DB切换,该过程较久,可能需要几分钟',
            redisTips:'开始Redis切换,该过程较久,可能需要几分钟。'
        })
    };

    // 获取双活复原架构图内容
    const getResetData=async ()=>{
        const res=await getReset()
        if(res.data.success){
            list[0].timeStr=getFormatDate();
            setUrl('https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/qcbm-flow-normal.gif')
            setVisible(true)
            pollTaskResultInterval=setInterval(() => {
                getTCTaskResultData(res.data.taskId)
               
            }, 1000);
        }else{
            message.warning({
                content: res.data.msg,
              })
        }
    }

    // 获取双活复原任务进度
    const getTCTaskResultData=async (currentTaskId)=>{
        let listOne=list
        const res=await getTaskResult(currentTaskId)
        if(res.data.data.status===2||res.data.data.status===3){
            clearInterval(pollTaskResultInterval)
            pollTaskResultInterval = -1;
        }
        listOne.push({
            timeStr:res.data.data.timeStr,
            clbEndTime:res.data.data.clbEndTime,
            mySqlEndTime:res.data.data.mySqlEndTime,
            redisEndTime:res.data.data.redisEndTime,
            clbTips:res.data.data.clbTips,
            mySqlTips:res.data.data.mySqlTips,
            redisTips:res.data.data.redisTips,
            timeNum:diffSecond(list[0].timeStr,res.data.data.timeStr)+"秒",
            timeClbNum:diffSecond(list[0].timeStr,res.data.data.clbEndTime)+"秒",
            timeMysqlNum:diffSecond(list[0].timeStr,res.data.data.mySqlEndTime)+"秒",
            timeRedisNum:diffSecond(list[0].timeStr,res.data.data.redisEndTime)+"秒",
        })
        setList(listOne)
        setTopList({
            clbTips:res.data.data.clbTips,
            mySqlTips:res.data.data.mySqlTips,
            redisTips:res.data.data.redisTips,
        }) 
        let div=document.getElementById('box_black')
            div.scrollTop=div.scrollHeight

    }

    return (
        <>
            <div>
                <p>将系统恢复至故障前的状态：</p>
                <List type="bullet" style={{ marginLeft: '20px', marginTop: '10px' }}>
                    <List.Item>双区流量恢复至缺省比例</List.Item>
                    <List.Item>DB 恢复至原始主备状态</List.Item>
                    <List.Item>Redis 恢复至原始主备状态</List.Item>
                </List>
                <Button type="primary" style={{ marginTop: '15px',marginLeft:'20px' }} onClick={getResetData}>
                    双活复原
                </Button>
                <Modal visible={visible} caption="双活切换" onClose={close} size='xl' style={{ fontSize: '14px' }}>
                    <Modal.Body>
                        <List type="bullet" style={{ marginLeft: '20px', marginTop: '10px' }}>
                            <List.Item>{topList.clbTips}   &nbsp;   开始时间：{list[0].timeStr} {topList.clbTips.indexOf("完成")!=-1?" 结束时间："+list[list.length-1].clbEndTime  +"    耗时："+list[list.length-1].timeClbNum:""} </List.Item>
                            <List.Item>{topList.mySqlTips} &nbsp; 开始时间：{list[0].timeStr} {topList.mySqlTips.indexOf("完成")!=-1?" 结束时间："+list[list.length-1].mySqlEndTime  +"   耗时："+list[list.length-1].timeMysqlNum:""}</List.Item>
                            <List.Item>{topList.redisTips} &nbsp; 开始时间：{list[0].timeStr}  {topList.redisTips.indexOf("完成")!=-1?" 结束时间："+list[list.length-1].redisEndTime  +"  耗时："+list[list.length-1].timeRedisNum:""}</List.Item>
                        </List>
                        <marquee direction='down' behavior="slide" scrollamount="500" loop='-1'>
                        <div className="box" id='box_black'>
                           
                            {
                                list.map((item,index) => {
                                    return (
                                        <div className='box_black' id='box_black' key={index}>
                                            <div className="left">
                                                <p>{item.timeStr}</p>
                                            </div>
                                            <div className="right">
                                                <p>{item.clbTips}   &nbsp;{ item.clbTips.indexOf("完成")!=-1?" 耗时："+item.timeClbNum:"" }</p>
                                                <p>{item.mySqlTips} &nbsp;{ item.mySqlTips.indexOf("完成")!=-1?" 耗时："+item.timeMysqlNum:"" }</p>
                                                <p>{item.redisTips} &nbsp;{ item.redisTips.indexOf("完成")!=-1? " 耗时："+item.timeRedisNum:"" }</p>
                                            </div>
                                            
                                        </div>
                                    )
                                })
                            }
                        </div>
                             </marquee>
                    </Modal.Body>

                </Modal>
            </div>
        </>
    )
}
