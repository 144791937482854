import React, {useEffect, useState} from "react";
import {Button, Card, Layout, message, Modal, Table, TabPanel, Tabs} from "tea-component";
import "./index.scss"
import {queryNacosServices, startTemPts, stopTemPts} from "../../../services/function";
import {Justify} from "tea-component";

const {Content: LayoutContent} = Layout;


export default function MicroServiceContainerization() {

    const tabs = [
        {id: "scene1", label: "VM环境部署"},
        {id: "scene2", label: "容器改造流程"},
        {id: "scene3", label: "TEM环境部署"},
        {id: "scene4", label: "成本对比"},
    ];

    const [des1Visible, setDes1Visible] = useState(false)
    const [des2Visible, setDes2Visible] = useState(false)

    const [job1, setJob1] = useState("Consumer：\n" +
        "spring.application.name=service-consumer\n" +
        "server.port=18082\n" +
        "spring.cloud.nacos.discovery.server-addr=10.1.0.15:8848\n")

    const [job2, setJob2] = useState(
        "Provider：\n" +
        "spring.application.name=service-provider\n" +
        "server.port=18081\n" +
        "spring.cloud.nacos.discovery.server-addr=10.1.0.15:8848\n")

    const [nacosServiceList, setServiceList] = useState([])
    const [columns, setColumns] = useState(nacosServiceList);

    const getNacosServiceList = async () => {
        const resServiceList = await queryNacosServices()
        setServiceList(resServiceList.data.data)
    }

    const startTemPtsTest = async () => {
        const res = await startTemPts()
        if (res.data.success) {
            message.success({
                content: "批量请求任务开启成功"
            })
        } else {
            message.error({
                content: res.data.msg
            })
        }
        if (res.data && res.data.length > 200 && res.data.indexOf("Please sign in") != -1) {
            window.location.href = "/login"
        }
    }

    const stopTemPtsTest = async () => {
        const res = await stopTemPts()
        if (res.data.success) {
            message.success({
                content: "中断请求服务成功"
            })
        } else {
            message.error({
                content: res.data.msg
            })
        }
        if (res.data && res.data.length > 200 && res.data.indexOf("Please sign in") != -1) {
            window.location.href = "/login"
        }
    }

    const toCVM = async () => {
        window.open("http://43.143.208.122:18082/echo-rest/hello-world")
    }

    const toTEM = async () => {
        window.open("http://140.143.181.235/echo-rest/tem-hello-world")
    }

    const goTxyLogin = () => {
        const w = window.open('_black') //这里是打开新窗口
        w.location.href = 'https://cloud.tencent.com/login' //这样就可以跳转了
    }


    const download = (url) => {
        window.location.href = url
    }


    const refreshPage = () => {
        window.location.reload();
    }


    useEffect(() => {
        getNacosServiceList().then(r => {
        })
    }, [])
    return (
        <LayoutContent>
            <LayoutContent.Header title='微服务容器化'>
                <Button type="primary"
                        onClick={() => download("https://johanyliu-1305426035.cos.ap-beijing.myqcloud.com/tem/nacos-service-consumer-0.0.1-SNAPSHOT.jar")}>Consumer下载</Button>
                <Button type="primary"
                        onClick={() => download("https://johanyliu-1305426035.cos.ap-beijing.myqcloud.com/tem/nacos-service-provider-0.0.1-SNAPSHOT.jar")}>Provider下载</Button>
                <Button type="pay" onClick={() => startTemPtsTest()}>批量请求服务</Button>
                <Button type="pay" onClick={() => stopTemPtsTest()}>中断请求服务</Button>
                <Button type="pay" onClick={() => toCVM()}>访问CVM服务</Button>
                <Button type="pay" onClick={() => toTEM()}>访问TEM服务</Button>
                <Button style={{float: "right"}} onClick={() => goTxyLogin()} type="weak">登录腾讯云</Button>
                <Button style={{float: "right"}} onClick={() => refreshPage()} type="weak">刷新</Button>
            </LayoutContent.Header>
            <div style={{border: "1px solid black"}}>
                <div style={{width: "80%", height:"100%",float: "left", overflow: "scroll"}}>
                    {/*<Card style={{height: "860px"}}>*/}
                    {/*    <Card.Body>*/}
                            <Tabs tabs={tabs}>
                                <TabPanel id="scene1">
                                    <Card>
                                    <Card.Body>
                                        <iframe className="iframeMicro"
                                                src="https://workbench.cloud.tencent.com/webshell?regionId=8&instanceId=ins-llrbx3ij&username=root&port=22"
                                                width="100%" height="850px" frameBorder="50"/>
                                    </Card.Body>
                                    </Card>
                                </TabPanel>
                                <TabPanel id="scene2">
                                    <Card>
                                    <Card.Body>
                                        <img
                                            src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/tem/liucheng.png"
                                            alt="" style={{width: '100%', height: '100%'}}/>
                                    </Card.Body>
                                    </Card>
                                </TabPanel>
                                <TabPanel id="scene3">
                                    <Card>
                                    <Card.Body>
                                        <iframe className="iframeMicro"
                                                src="https://console.cloud.tencent.com/tem/application?rid=8&rid=8"
                                                width="100%" height="850px" frameBorder="50"/>
                                    </Card.Body>
                                    </Card>
                                </TabPanel>
                                <TabPanel id="scene4">
                                    <Card>
                                    <Card.Body>
                                        <img
                                            src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/tem/chengben.png"
                                            alt="" style={{width: '100%', height: '100%'}}/>
                                    </Card.Body>
                                    </Card>
                                </TabPanel>
                            </Tabs>
                    {/*    </Card.Body>*/}
                    {/*</Card>*/}
                </div>
                <div style={{width: "19%", float: "right", marginRight: "8px"}}>
                    <br/>
                    <Card>
                        <Card.Body>
                            <div className='cost-title'><b>场景介绍</b></div>
                            <div className='cost-content'>
                                <b>技术栈：</b>
                                <span>Spring Cloud + Nacos</span>
                            </div>
                            <div className='cost-content'>
                                <b>项目: </b>
                                <br/>
                                <span> ECHO示例程序，页面显示URL中输入的字符串</span>
                            </div>
                            <div className='cost-content'>
                                <div>
                                    <b>微服务:</b>
                                </div>
                                <div>
                                    1.Consumer服务--客户请求服务
                                </div>
                                <div>
                                    2.Provider服务--内容提供服务
                                </div>
                                <div>
                                    <br/>
                                    <b >应用配置示例:</b>
                                    <br/>
                                    <button style={{marginTop: "10px", marginBottom: "10px"}}
                                            onClick={() => {
                                                setDes1Visible(true);
                                            }}>Consumer
                                    </button>
                                    <button style={{marginTop: "10px", marginBottom: "10px", marginLeft: "20px"}}
                                            onClick={() => {
                                                setDes2Visible(true);
                                            }}>Provider
                                    </button>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    <br/>
                    <br/>
                    <Card>
                        <Card.Body>
                            <div className="cost-title"> 访问示意图 </div>
                            <img
                                src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/tem/fw.jpg"
                                alt="" style={{width: '100%', height: '100%'}}/>
                        </Card.Body>
                    </Card>
                    <br/>
                    <br/>
                    <Card>
                        <Card.Body>
                            <Table.ActionPanel>
                                <Justify
                                    left={
                                        <>
                                            <div className="cost-title">服务列表</div>
                                        </>
                                    }
                                    right={
                                        <>
                                            <Button onClick={() => {
                                                getNacosServiceList().then(r => {
                                                })
                                            }}>刷新</Button>
                                        </>
                                    }
                                />
                                <Table
                                    verticalTop
                                    records={
                                        nacosServiceList
                                    }
                                    bordered='all'
                                    columns={[
                                        {
                                            key: "name",
                                            header: "服务名",
                                            align: 'center',
                                            width: '10%'
                                        },
                                        {
                                            key: "ipCount",
                                            header: "实例数",
                                            align: 'center',
                                            width: '10%'
                                        }, {
                                            key: "healthyInstanceCount",
                                            header: "健康实例数",
                                            align: 'center',
                                            width: '10%'
                                        }]}/>
                            </Table.ActionPanel>
                        </Card.Body>
                    </Card>
                </div>
            </div>

            <Modal visible={des1Visible} onClose={() => setDes1Visible(false)} className="popupYaml"
                   caption="查看配置文件"
                   style={{fontSize: '18px'}}>
                <Modal.Body style={{
                    textAlign: "left",
                    width: '500px',
                    height: '500px',
                    backgroundColor: 'black',
                    color: 'white'
                }}>
                    <div style={{display: "flex", whiteSpace: "pre-wrap"}}>
                        {job1}
                    </div>
                </Modal.Body>
            </Modal>

            <Modal visible={des2Visible} onClose={() => setDes2Visible(false)} className="popupYaml"
                   caption="查看配置文件"
                   style={{fontSize: '18px'}}>
                <Modal.Body style={{
                    textAlign: "left",
                    width: '500px',
                    height: '500px',
                    backgroundColor: 'black',
                    color: 'white'
                }}>
                    <div style={{display: "flex", whiteSpace: "pre-wrap"}}>
                        {job2}
                    </div>
                </Modal.Body>
            </Modal>
        </LayoutContent>
    );
}