import React, {useEffect, useState} from "react";
import "tea-component/dist/tea.css";
import {Tabs, TabPanel, Layout, Card, Button, message, Modal} from "tea-component";
import './index.scss'
import {getSwitchJmeter, modifyClbBand, switchJmeter} from "../../../services/bookStorage";
import {querySceneConfig} from "../../../services/function";

const {Content: LayoutContent} = Layout;

export default function RealTimeAnalysis() {
    const [logVisible, setLogVisible] = useState(false)
    const [biUrl, setBiUrl] = useState("https://sharegray.tcbi.qq.com/page/share?pageId=10516015&projectId=11010835&token=1a9eea88-a6ae-426c-a818-e40ca4c5b269&scope=page");
    const [scene1Clb, setScene1Clb] = useState("https://console.cloud.tencent.com/clb/detail?rid=1&id=lb-r00vl6p6");
    const [scene1Tke, setScene1Tke] = useState("https://console.cloud.tencent.com/tke2/loglistener/update?rid=1&clusterId=cls-0xcqq9f4&lid=qcbm-cls-ckafka");
    const [scene1Cvm, setScene1Cvm] = useState("https://workbench.cloud.tencent.com/webshell?regionId=1&instanceId=ins-lpy9n8g2&username=root&port=22");
    const [scene1CKafka, setScene1CKafka] = useState("https://console.cloud.tencent.com/ckafka/detail?rid=1&id=ckafka-l738bmge&tab=topic&topicInfo=topic-42k4q9do");
    const [scene1Oceanus, setScene1Oceanus] = useState("https://console.cloud.tencent.com/oceanus/job/detail/sqlJob/ap-guangzhou/space-7n76tbde/cql-lci6dx9q?tab=dev");
    const [scene1Doris, setScene1Doris] = useState("https://console.cloud.tencent.com/cdwdoris/instance/cdwdoris-t28how51?region=ap-guangzhou&tab=basic");
    const [scene2Clb, setScene2Clb] = useState("https://console.cloud.tencent.com/monitor/clb?rid=1&keyword=lb-r00vl6p6");
    const [scene2CKafka, setScene2CKafka] = useState("https://console.cloud.tencent.com/ckafka/detail?rid=1&id=ckafka-l738bmge&tab=monitor&consumerId=ckafka-l738bmge_ckafka-9jnj2a27&topicInfo=topic-42k4q9do&rankType=INSTANCE_EVENT");
    const [scene2Oceanus, setScene2Oceanus] = useState("https://console.cloud.tencent.com/monitor/product/oceanus/tabs/0/1/tjob_id/cql-lci6dx9q");
    const [scene2Doris, setScene2Doris] = useState("https://console.cloud.tencent.com/cdwdoris/instance/cdwdoris-t28how51?region=ap-guangzhou&tab=monitor");
    const [scene3Oceanus, setScene3Oceanus] = useState("https://console.cloud.tencent.com/oceanus/job/detail/sqlJob/ap-guangzhou/space-7n76tbde/cql-lci6dx9q?tab=basic");
    const [scene3Doris, setScene3Doris] = useState("https://console.cloud.tencent.com/cdwdoris/instance/cdwdoris-t28how51?region=ap-guangzhou&tab=param-setting-edit-config");
    const [bookShopUrl, setBookShopUrl] = useState("http://qcbm.yunnative.com/seckill");


    const tabs = [
        {id: "scene1", label: "场景1"},
        {id: "scene2", label: "场景2"},
        {id: "scene3", label: "场景3"},
    ];

    const [dorisVisible, setDorisVisible] = useState(false)


    const refreshPage1 = async () => {
        document.getElementById('scene3IframeId').src = biUrl;
    }
    const tabsChange = async (tab) => {
        let scene1IframeId = document.getElementById('scene1IframeId');
        let scene2IframeId = document.getElementById('scene2IframeId');
        let scene3IframeId = document.getElementById('scene3IframeId');
        //let scene4IframeId = document.getElementById('scene4IframeId');
        if (tab.id === "scene1") {
            scene1IframeId.style.display = "block";
            scene2IframeId.style.display = "none";
            scene3IframeId.style.display = "none";

        } else if (tab.id === "scene2") {
            scene1IframeId.style.display = "none";
            scene2IframeId.style.display = "block";
            scene3IframeId.style.display = "none";

        } else if (tab.id === "scene3") {
            scene1IframeId.style.display = "none";
            scene2IframeId.style.display = "none";
            scene3IframeId.style.display = "block";

        }
    }

    const log = "<root>\n" +
        "    proxy_host = ap-guangzhou.cls.tencentyun.com\n" +
        "    proxy_port = 80\n" +
        "    secret_id = XXX\n" +
        "    secret_key = XXX\n" +
        "    group_ip = 10.0.0.13\n" +
        "    group_label = gameserver001\n" +
        "    instance_id = loglistener-0adfc7a7-d1fc-3d6a-c2df-d59d26d946d1\n" +
        "    pos_file = data/pos.dat\n" +
        "    checkpoint_cache_file = data/checkpoint_cache\n" +
        "    checkpoint_window_size = 1024\n" +
        "    max_file_breakpoints = 8192\n" +
        "    mmap_version_file = data/mmap_version_file.dat\n" +
        "    file_cache = 0\n" +
        "    max_connection = 10\n" +
        "    max_mem = 2097152000\n" +
        "    max_send_rate = 0\n" +
        "    cpu_usage_thres = 0\n" +
        "    max_depth = 10\n" +
        "    request_compression = true\n" +
        "    replace_special_charactors = false\n" +
        "    memory_tight_mode = false\n" +
        "    multi_tenancy = 0\n" +
        "    tenancy_info_path = etc/tenancy.json\n" +
        "    <log>\n" +
        "        level  = INFO\n" +
        "        path   = log/\n" +
        "        name   = loglistener\n" +
        "        size   = 100000000\n" +
        "        num    = 5\n" +
        "    </log>\n" +
        "</root>\n";


    const doris = useState("-- 性能调优\n" +
        "-- 查询变量\n" +
        "-- show variables like '%exec_mem_limit%';\n" +
        "-- 设置比并发数 每个 BE 节点上执行实例的个数\n" +
        "set global parallel_fragment_exec_instance_num = 5;\n" +
        "-- 数据包扫描行数\n" +
        "set global batch_size = 4*1024; \n" +
        "-- 设置为4G，只针对当前session 有效，默认值为2G\n" +
        "set exec_mem_limit=4*1024*1024*1024; \n")


    const [isLoadingJmeter1, setLoadingJmeter1] = useState(false);
    const [isLoadingJmeter2, setLoadingJmeter2] = useState(false);
    const [isLoadingJmeter3, setLoadingJmeter3] = useState(false);
    const toUrl = (data) => {
        let url = scene1Clb;
        if (data === "clb") {
            url = scene1Clb;
        } else if (data === "cKafka") {
            url = scene1CKafka;
        } else if (data === "Oceanus") {
            url = scene1Oceanus;
        } else if (data === "Doris") {
            url = scene1Doris;
        } else if (data === "CVM") {
            url = scene1Cvm;
        } else if (data === "TKE") {
            url = scene1Tke;
        }
        document.getElementById('scene1IframeId').src = url;
    }

    const toUrl2 = (data) => {
        let url = scene2Clb;
        if (data === "clb") {
            url = scene2Clb;
        } else if (data === "cKafka") {
            url = scene2CKafka;
        } else if (data === "Oceanus") {
            url = scene2Oceanus;
        } else if (data === "Doris") {
            url = scene2Doris;
        }
        document.getElementById('scene2IframeId').src = url;
    }

    const toUrl3 = (data) => {
        let url = biUrl
        if (data === "Oceanus") {
            url = scene3Oceanus;
        } else if (data === "Doris") {
            url = scene3Doris;
        }
        document.getElementById('scene3IframeId').src = url;
    }


    const goYunShuCheng = () => {

        const w = window.open('_black') //这里是打开新窗口
        w.location.href = bookShopUrl //这样就可以跳转了
    }

    const querySwitchJmeter = async () => {
        setLoadingJmeter1(true)
        const res = await getSwitchJmeter()
        setLoadingJmeter1(false)
        if (res.data.success) {
            message.success({
                content: res.data.msg
                //style:{width:'400px', height:'400px',whiteSpace:'pre-line',backgroundColor: '#000000',color:'white'}
            })

        } else if (res.data && res.data.length > 200 && res.data.indexOf("Please sign in") !== -1) {
            window.location.href = "/login"
        } else {
            message.error({
                content: res.data.msg
            })
        }
    }

    const switchOnJmeter = async () => {
        setLoadingJmeter2(true)
        await modifyClbBand(5);
        const res = await switchJmeter("on")
        setLoadingJmeter2(false)
        if (res.data.success) {
            message.success({

                content: "真实流量开启成功"
            })
        } else if (res.data && res.data.length > 200 && res.data.indexOf("Please sign in") !== -1) {
            window.location.href = "/login"
        } else {
            message.error({
                content: "真实流量开启失败"
            })
        }

    }

    const switchOffJmeter = async () => {
        setLoadingJmeter3(true)
        await modifyClbBand(1024);
        const res = await switchJmeter("off")
        setLoadingJmeter3(false)
        if (res.data.success) {
            message.success({
                content: "真实流量关闭成功"
            })
        } else if (res.data && res.data.length > 200 && res.data.indexOf("Please sign in") !== -1) {
            window.location.href = "/login"
        } else {
            message.error({
                content: "真实流量关闭失败"
            })
        }

    }

    const refreshPage = () => {
        window.location.reload();
    }

    const goTxyLogin = () => {

        const w = window.open('_black') //这里是打开新窗口
        w.location.href = 'https://cloud.tencent.com/login'//这样就可以跳转了
    }


    useEffect(() => {
        (async () => {
            const sceneRes = await querySceneConfig(5, 3)
            if (sceneRes.data.success) {
                let datas = sceneRes.data.data;
                if (null != datas) {
                    datas.map(function (config) {
                        if (config.configKey === "deal_time_bi_url") {
                            setBiUrl(config.configValue);
                        } else if (config.configKey === "deal_time_scene1_clb") {
                            setScene1Clb(config.configValue);
                        } else if (config.configKey === "deal_time_scene1_tke") {
                            setScene1Tke(config.configValue);
                        } else if (config.configKey === "deal_time_scene1_cvm") {
                            setScene1Cvm(config.configValue);
                        } else if (config.configKey === "deal_time_scene1_ckafka") {
                            setScene1CKafka(config.configValue);
                        } else if (config.configKey === "deal_time_scene1_oceanus") {
                            setScene1Oceanus(config.configValue);
                        } else if (config.configKey === "deal_time_scene1_doris") {
                            setScene1Doris(config.configValue);
                        } else if (config.configKey === "deal_time_scene2_clb") {
                            setScene2Clb(config.configValue);
                        } else if (config.configKey === "deal_time_scene2_ckafka") {
                            setScene2CKafka(config.configValue);
                        } else if (config.configKey === "deal_time_scene2_oceanus") {
                            setScene2Oceanus(config.configValue);
                        } else if (config.configKey === "deal_time_scene2_doris") {
                            setScene2Doris(config.configValue);
                        } else if (config.configKey === "deal_time_scene3_oceanus") {
                            setScene3Oceanus(config.configValue);
                        } else if (config.configKey === "deal_time_scene3_doris") {
                            setScene3Doris(config.configValue);
                        } else if (config.configKey === "deal_time_book_shop_url") {
                            setBookShopUrl(config.configValue);
                        }
                    })
                }
            }

        })()
    }, [])
    return (
        <LayoutContent>
            <LayoutContent.Header title='操作'>
                <Button onClick={() => querySwitchJmeter()} type="primary" loading={isLoadingJmeter1}>1.查看真实流量</Button>
                <Button onClick={() => switchOnJmeter()} type="primary" loading={isLoadingJmeter2}>2.启动真实流量</Button>
                <Button onClick={() => switchOffJmeter()} type="pay" loading={isLoadingJmeter3}>3.停止真实流量</Button>

                <Button style={{float: "right"}} onClick={() => goTxyLogin()} type="weak">登录腾讯云</Button>
                <Button style={{float: "right"}} onClick={() => refreshPage()} type="weak">刷新</Button>
                <Button style={{float: "right"}} onClick={() => goYunShuCheng()} type="weak">跳转云书城</Button>

            </LayoutContent.Header>

            <div id="main">
                <div style={{overflow: "scroll"}}>
                    <div className="tableRealTime">
                        <div className="title">场景简介</div>
                        <Card>
                            <Card.Body>
                                <Tabs tabs={tabs} onActive={(tab) => tabsChange(tab)}>
                                    <TabPanel id="scene1">
                                        <Card.Body>
                                            场景名：初始配置
                                        </Card.Body>
                                        <Card.Body>场景介绍：通过配置CLB、TKE、CVM、CKafka、Oceanus、CDW-Doris完成数据实时采集</Card.Body>
                                        <Card.Body>推荐产品：CLB、TKE、CVM、CKafka、Oceanus、CDW-Doris</Card.Body>

                                        <Card.Body> 查看初始实例配置：
                                            <button style={{marginLeft: "10px"}}
                                                    onClick={() => toUrl("clb")}>CLB配置</button>
                                            <button style={{marginLeft: "10px"}} onClick={() => toUrl("TKE")}>TKE配置
                                            </button>
                                            <button style={{marginLeft: "10px"}} onClick={() => toUrl("CVM")}>CVM配置
                                            </button>
                                            <button style={{marginLeft: "10px"}}
                                                    onClick={() => toUrl("cKafka")}>CKafka配置
                                            </button>


                                        </Card.Body>
                                        <Card.Body>查看配置:
                                            <button style={{marginLeft: "10px"}}
                                                    onClick={() => toUrl("Oceanus")}>Oceanus配置</button>
                                            <button style={{marginLeft: "10px"}}
                                                    onClick={() => toUrl("Doris")}>CDW-Doris配置
                                            </button>
                                            <button style={{marginLeft: "10px"}}
                                                    onClick={() => setLogVisible(true)}>LogListener配置
                                            </button>
                                        </Card.Body>
                                        <Card.Body><img
                                            src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/real_time_analysis/scene1.gif"
                                            alt="初始配置图片" style={{width: '90%', height: '90%'}}/></Card.Body>

                                    </TabPanel>
                                    <TabPanel id="scene2">
                                        <Card.Body>
                                            场景名：实时观测
                                        </Card.Body>
                                        <Card.Body>场景介绍：启动真实流量，观测CLB、Ckafka 、Oceanus应用的实时流量</Card.Body>
                                        <Card.Body>推荐产品：Ckafka、MySQL、Oceanus、CDW-Doris</Card.Body>
                                        <Card.Body>观测各实例监控信息：
                                            <button style={{marginLeft: "10px"}}
                                                    onClick={() => toUrl2("clb")}>CLB监控</button>
                                            <button style={{marginLeft: "10px"}}
                                                    onClick={() => toUrl2("cKafka")}>CKafka监控
                                            </button>
                                            <button style={{marginLeft: "10px"}}
                                                    onClick={() => toUrl2("Oceanus")}>Oceanus监控
                                            </button>
                                            <button style={{marginLeft: "10px"}}
                                                    onClick={() => toUrl2("Doris")}>CDW-Doris监控
                                            </button>

                                        </Card.Body>
                                        <Card.Body><img
                                            src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/real_time_analysis/scene2.gif"
                                            alt="实时采集图片" style={{width: '100%', height: '100%'}}/></Card.Body>
                                    </TabPanel>


                                    <TabPanel id="scene3">
                                        <Card.Body>
                                            场景名：实时分析
                                        </Card.Body>
                                        <Card.Body>场景介绍：使用腾讯云实时计算引擎Oceanus对游戏用户的访问和购买订单等行为进行清洗和计算，实时写入CDW-Doris，业务通过BI对用户行为、平台经营做深度分析</Card.Body>
                                        <Card.Body>推荐产品：Oceanus、CDW-Doris、BI</Card.Body>
                                        <Card.Body>查看配置：
                                            <button style={{marginLeft: "10px"}}
                                                    onClick={() => toUrl3("Oceanus")}>Oceanus配置</button>
                                            <button style={{marginLeft: "10px"}}
                                                    onClick={() => toUrl3("Doris")}>CDW-Doris配置
                                            </button>
                                            <button style={{marginLeft: "10px"}} type="primary"
                                                    onClick={() => refreshPage1()}>刷新BI报表
                                            </button>
                                        </Card.Body>
                                        <Card.Body><img
                                            src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/real_time_analysis/scene3.gif"
                                            alt="实时采集图片" style={{width: '100%', height: '100%'}}/></Card.Body>
                                    </TabPanel>
                                </Tabs>
                            </Card.Body>
                        </Card>
                    </div>


                </div>
                <div>
                    <iframe id="scene1IframeId" style={{display: "block"}}
                            src={scene1Clb}
                            width="100%" height="100%" frameBorder="12"/>

                    <iframe id="scene2IframeId" style={{display: "none"}}
                            src={scene2Clb}
                            width="100%" height="100%" frameBorder="12"/>

                    <iframe id="scene3IframeId" style={{display: "none"}}
                            src={biUrl}
                            width="100%" height="100%" frameBorder="12"/>

                </div>

            </div>


            <Modal visible={logVisible} onClose={() => setLogVisible(false)} className="popup2"
                   caption="查看LogListener配置"
                   style={{fontSize: '14px'}}>
                <Modal.Body style={{
                    textAlign: "left",
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'black',
                    color: 'white'
                }}>
                    <div style={{display: "flex", whiteSpace: "pre-wrap"}}>
                        {log}
                    </div>
                </Modal.Body>
            </Modal>


            <Modal visible={dorisVisible} onClose={() => setDorisVisible(false)} className="popup2"
                   caption="查看Doris配置"
                   style={{fontSize: '14px'}}>
                <Modal.Body style={{
                    textAlign: "left",
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'black',
                    color: 'white'
                }}>
                    <div style={{display: "flex", whiteSpace: "pre-wrap"}}>
                        {doris}
                    </div>
                </Modal.Body>
            </Modal>

        </LayoutContent>
    );
}