import React, { memo } from 'react'
import { Icon, Text } from 'tea-component'

import { Handle, Position } from 'react-flow-renderer'

export default memo(({ data, id, isConnectable }) => {
  // console.log(1, data)
  if (data.isHide) {
    return <></>
  }
  return (
    <div>
      <div className='nodeSelContent' style={data.style}>
        {/* <Icon type='refresh' className='nodeIconRefresh' size={12} /> */}
        <div className='nodelabel'>{data.label}</div>
        <div className='close'>
          <Icon
            type='close'
            onClick={e => {
              e.stopPropagation()
              data.deleteNode(id)
            }}
            className='icon-close'
          />
        </div>
      </div>
      <Handle
        type='target'
        position={Position.Left}
        className='my_handle'
        onConnect={params => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <Handle
        type='source'
        position={Position.Right}
        id='a'
        isConnectable={isConnectable}
      />
    </div>
  )
})
