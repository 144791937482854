import React, {useEffect} from "react";
import "tea-component/dist/tea.css";
import {
    Layout, Card, ImagePreview, List, Table, Bubble,
    Icon, H4, ExternalLink, Tabs, TabPanel
} from "tea-component";
import {Link} from "react-router-dom";
const { Body, Sider, Content: LayoutContent } = Layout;

export default function PolarisExhibition() {

    useEffect(() => {
        // window.open('https://console.cloud.tencent.com/pts/')
    }, [])
    return (
        <LayoutContent>
            <LayoutContent.Header title='演示说明'/>
            <div className="presentation">
                <div className="presentation_box">
                    <iframe src="https://drive.weixin.qq.com/s?k=AJEAIQdfAAopdJWOY8" width="100%" height="100%"
                            frameBorder="0"></iframe>
                </div>
            </div>
        </LayoutContent>
    );
}