import React from "react";
import "tea-component/dist/tea.css";
import {Card, Layout, Table, TabPanel, Tabs} from "tea-component";

const {Body, Sider, Content: LayoutContent} = Layout;

export default function MicroServiceContainerizationBaseInfo() {

    const tabs = [
        {id: "tem", label: "系统架构"},
        {id: "framework", label: "云原生产品和组件"},
        {id: "network", label: "网络规划"},
    ];

    const cvmList = [
        {
            instanceId: "Region",
            instanceName: "华北地区（北京）",
        },
        {
            instanceId: "AZ",
            instanceName: "北京七区，北京六区",
        },
        {
            instanceId: "VPC",
            instanceName: "10.1.0.0/16",
        }]

    const cvmPc = [
        {
            name: "TEM",
            func: "弹性容器计算资源",
            character:"资源弹性伸缩，自动注入微服务组件依赖"
        },
        {
            name: "TCR",
            func: "镜像仓库",
            character:"托管容器镜像服务，全球加速"
        },
        {
            name: "TSE-Nacos",
            func: "服务注册发现",
            character:"托管服务注册中心，跨区高可用，免运维"
        },
        {
            name: "TSE-云原生网关",
            func: "流量治理",
            character:"跨区高可用，一键扩容，免运维"
        },
        {
            name: "CLB",
            func: "外网四层负载均衡",
            character:"跨区高可用，一键部署"
        },
        {
            name: "CLS",
            func: "日志采集与存储",
            character:"一站式日志分析服务"
        },
        {
            name: "APM",
            func: "分布式应用性能分析",
            character:"一站式应用性能分析服务"
        },
        {
            name: "Promethues",
            func: "监控采集与展示",
            character:"托管数据监控服务，跨区高可用"
        },
        {
            name: "CODING",
            func: "代码托管，持续集成，持续交付",
            character:"一站式DevOps开发平台"
        },

    ]
    return (
        <LayoutContent>
            <LayoutContent.Body>
                <Card>
                    <Card.Body>
                        <Tabs tabs={tabs}>
                            <TabPanel id="tem">
                                <img src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/tem/jiagou1.png"
                                     alt="" style={{width: '90%', height: '90%'}}/>
                            </TabPanel>
                            <TabPanel id="framework">
                                <div>
                                    <Table
                                        verticalTop
                                        records={cvmPc}
                                        recordKey="instanceId"
                                        bordered='all'
                                        columns={[
                                            {
                                                key: "name",
                                                header: "产品名称",
                                                align: 'left',
                                                width: '300px'
                                            },
                                            {
                                                key: "func",
                                                header: "产品功能",
                                                align: 'left'
                                            },
                                            {
                                                key: "character",
                                                header: "产品特性",
                                                align: 'left'
                                            }
                                        ]}
                                    />
                                </div>
                            </TabPanel>
                            <TabPanel id="network">
                                <div>
                                    <Table
                                        verticalTop
                                        records={cvmList}
                                        recordKey="instanceId"
                                        bordered='all'
                                        columns={[
                                            {
                                                key: "instanceId",
                                                header: "网络规划",
                                                align: 'left',
                                                width: '300px'
                                            },
                                            {
                                                key: "instanceName",
                                                header: "可用区域",
                                                align: 'left'
                                            },
                                        ]}
                                    />
                                </div>
                            </TabPanel>
                        </Tabs>
                    </Card.Body>
                </Card>
            </LayoutContent.Body>
        </LayoutContent>
    );
}