import React, {useEffect, useState} from "react";
import "tea-component/dist/tea.css";
import {
    Layout,
    Button,
    message,
    Table,
    Modal,
    Card,
    Tabs,
    TabPanel,
    ImagePreview, Form, Copy
} from "tea-component";
import './index.scss'
import {operateGpu, deleteGpu, queryGpuNode, queryGpuJob, queryEvents, queryLogs} from "../../../services/function"

const {Content: LayoutContent, Content} = Layout;

function Title({children}) {
    return <h4 style={{margin: "20px 0"}}>{children}</h4>;
}

let pollTaskResultInterval

export default function BuringPointFunction() {
    const [nodeList, setNodeList] = useState([])
    const [jobList, setJobList] = useState([])
    const [modalVisible, setModalVisible] = useState(false)
    const [logModelVisible, setLogModelVisible] = useState(false)
    const [cardModelVisible, setCardModelVisible] = useState(false)
    const [selectLog1, setSelectLog1] = useState([])
    const [selectEvent1, setSelectEvent1] = useState([])
    const [isLoadingGpu1, setLoadingGpu1] = useState(false)
    const [isLoadingGpu2, setLoadingGpu2] = useState(false)
    const [isLoadingGpu3, setLoadingGpu3] = useState(false)
    const [isLoadingGpu5, setLoadingGpu5] = useState(false)
    const [isLoadingDelGpu4, setLoadingDelGpu4] = useState(false)
    const [isLoadingEvent, setLoadingEvent] = useState(false)
    const [isLoadingLog, setLoadingLog] = useState(false)
    const [isLoadingRefresh, setLoadingRefresh] = useState(false)
    const [isLoadingRefreshLog, setLoadingRefreshLog] = useState(false)
    const [des1Visible, setDes1Visible] = useState(false)
    const [des2Visible, setDes2Visible] = useState(false)
    const [des3Visible, setDes3Visible] = useState(false)
    const [des4Visible, setDes4Visible] = useState(false)
    const [des5Visible, setDes5Visible] = useState(false)
    const [des6Visible, setDes6Visible] = useState(false)
    const [des7Visible, setDes7Visible] = useState(false)
    const [des8Visible, setDes8Visible] = useState(false)
    const [picModelVisible, setPicModelVisible] = useState(false)

    const [selectNode, setSelectNode] = useState("1")

    const [job1, setJob1] = useState("apiVersion: batch/v1\n" +
        "kind: Job\n" +
        "metadata:\n" +
        "  name: scene1-job1\n" +
        "  namespace: qgpu-test\n" +
        "spec:\n" +
        "  template:\n" +
        "    spec:\n" +
        "      nodeSelector:\n" +
        "        gputype: nvidia\n" +
        "      restartPolicy: Never\n" +
        "      containers:\n" +
        "        - name: container1\n" +
        "          image: ccr.ccs.tencentyun.com/qcbm/qgpu-tf-test:21.08-tf1-py3\n" +
        "          command: [ \"/start.sh\", \"50000\" ]\n" +
        "          resources:\n" +
        "            limits:\n" +
        "              nvidia.com/gpu: 1")


    const [job2, setJob2] = useState("apiVersion: batch/v1\n" +
        "kind: Job\n" +
        "metadata:\n" +
        "  name: scene1-job2\n" +
        "  namespace: qgpu-test\n" +
        "spec:\n" +
        "  template:\n" +
        "    spec:\n" +
        "      nodeSelector:\n" +
        "        gputype: nvidia\n" +
        "      restartPolicy: Never\n" +
        "      containers:\n" +
        "        - name: container1\n" +
        "          image: ccr.ccs.tencentyun.com/qcbm/qgpu-tf-test:21.08-tf1-py3\n" +
        "          command: [ \"/start.sh\", \"50000\" ]\n" +
        "          resources:\n" +
        "            limits:\n" +
        "              nvidia.com/gpu: 1")

    const [job3, setJob3] = useState("apiVersion: batch/v1\n" +
        "kind: Job\n" +
        "metadata:\n" +
        "  name: scene2-job1\n" +
        "  namespace: qgpu-test\n" +
        "spec:\n" +
        "  template:\n" +
        "    spec:\n" +
        "      nodeSelector:\n" +
        "        gputype: qgpu\n" +
        "      restartPolicy: Never\n" +
        "      containers:\n" +
        "        - name: container1\n" +
        "          image: ccr.ccs.tencentyun.com/qcbm/qgpu-tf-test:21.08-tf1-py3\n" +
        "          command: [ \"/start.sh\", \"50000\" ]\n" +
        "          resources:\n" +
        "            limits:\n" +
        "              tke.cloud.tencent.com/qgpu-memory: \"5\" #显存大小\n" +
        "              tke.cloud.tencent.com/qgpu-core: \"40\" #显卡比例")

    const [job4, setJob4] = useState("apiVersion: batch/v1\n" +
        "kind: Job\n" +
        "metadata:\n" +
        "  name: scene2-job2\n" +
        "  namespace: qgpu-test\n" +
        "spec:\n" +
        "  template:\n" +
        "    spec:\n" +
        "      nodeSelector:\n" +
        "        gputype: qgpu\n" +
        "      restartPolicy: Never\n" +
        "      containers:\n" +
        "        - name: container1\n" +
        "          image: ccr.ccs.tencentyun.com/qcbm/qgpu-tf-test:21.08-tf1-py3\n" +
        "          command: [ \"/start.sh\", \"50000\" ]\n" +
        "          resources:\n" +
        "            limits:\n" +
        "              tke.cloud.tencent.com/qgpu-memory: \"5\" #显存大小\n" +
        "              tke.cloud.tencent.com/qgpu-core: \"30\" #显卡比例")

    const [job5, setJob5] = useState("apiVersion: batch/v1\n" +
        "kind: Job\n" +
        "metadata:\n" +
        "  name: scene3-job1\n" +
        "  namespace: qgpu-test\n" +
        "spec:\n" +
        "  template:\n" +
        "    spec:\n" +
        "      nodeSelector:\n" +
        "        gputype: qgpu\n" +
        "      restartPolicy: Never\n" +
        "      containers:\n" +
        "        - name: container1\n" +
        "          image: ccr.ccs.tencentyun.com/qcbm/qgpu-tf-test:21.08-tf1-py3\n" +
        "          command: [ \"/start.sh\", \"50000\" ]\n" +
        "          resources:\n" +
        "            limits:\n" +
        "              tke.cloud.tencent.com/qgpu-memory: \"6\" #显存大小\n" +
        "              tke.cloud.tencent.com/qgpu-core: \"60\" #显卡比例")

    const [job6, setJob6] = useState("apiVersion: batch/v1\n" +
        "kind: Job\n" +
        "metadata:\n" +
        "  name: scene3-job2\n" +
        "  namespace: qgpu-test\n" +
        "spec:\n" +
        "  template:\n" +
        "    spec:\n" +
        "      nodeSelector:\n" +
        "        gputype: qgpu\n" +
        "      restartPolicy: Never\n" +
        "      containers:\n" +
        "        - name: container1\n" +
        "          image: ccr.ccs.tencentyun.com/qcbm/qgpu-tf-test:21.08-tf1-py3\n" +
        "          command: [ \"/start.sh\", \"50000\" ]\n" +
        "          resources:\n" +
        "            limits:\n" +
        "              tke.cloud.tencent.com/qgpu-memory: \"3\" #显存大小\n" +
        "              tke.cloud.tencent.com/qgpu-core: \"30\" #显卡比例")

    const [job7, setJob7] = useState("apiVersion: batch/v1\n" +
        "kind: Job\n" +
        "metadata:\n" +
        "  name: scene4-job1\n" +
        "  namespace: qgpu-test\n" +
        "spec:\n" +
        "  template:\n" +
        "    metadata:\n" +
        "      annotations:\n" +
        "        tke.cloud.tencent.com/app-class: online  #在线\n" +
        "    spec:\n" +
        "      nodeSelector:\n" +
        "        gputype: qgpu\n" +
        "      restartPolicy: Never\n" +
        "      containers:\n" +
        "        - name: container1\n" +
        "          image: ccr.ccs.tencentyun.com/qcbm/qgpu-tf-test:21.08-tf1-py3\n" +
        "          command: [ \"bash\", \"-c\", \"while true; do /start.sh 2000 && sleep 90;done\" ]\n" +
        "          resources:\n" +
        "            limits:\n" +
        "              tke.cloud.tencent.com/qgpu-memory: \"7\" #显存大小")

    const [job8, setJob8] = useState("apiVersion: batch/v1\n" +
        "kind: Job\n" +
        "metadata:\n" +
        "  name: scene4-job2\n" +
        "  namespace: qgpu-test\n" +
        "spec:\n" +
        "  template:\n" +
        "    metadata:\n" +
        "      annotations:\n" +
        "        tke.cloud.tencent.com/app-class: offline  #离线\n" +
        "    spec:\n" +
        "      nodeSelector:\n" +
        "        gputype: qgpu\n" +
        "      restartPolicy: Never\n" +
        "      containers:\n" +
        "        - name: container1\n" +
        "          image: ccr.ccs.tencentyun.com/qcbm/qgpu-tf-test:21.08-tf1-py3\n" +
        "          command: [ \"/start.sh\", \"50000\" ]\n" +
        "          resources:\n" +
        "            limits:\n" +
        "              tke.cloud.tencent.com/qgpu-memory: \"7\" #显存大小\n" +
        "              tke.cloud.tencent.com/qgpu-core-greedy: \"60\" #低优资源")



    const onCloseLog = () => {
        //console.log('pollTaskResultInterval', pollTaskResultInterval)
        setLogModelVisible(false)
        clearInterval(pollTaskResultInterval)
        // refreshPage();
    }

    const refreshPage = () => {
        window.location.reload();
    }

    const refreshPod = async () => {
        setLoadingRefresh(true);
        const jobRes = await queryGpuJob()
        if (jobRes.data.success) {
            jobRes && jobRes.data && setJobList(jobRes.data.data);
        }
        setLoadingRefresh(false);
    }


    const goTxyLogin = () => {
        const w = window.open('_black') //这里是打开新窗口
        let url = 'https://cloud.tencent.com/login'
        w.location.href = url //这样就可以跳转了
    }
    const operateGpu1 = async () => {
        setLoadingGpu1(true)
        const res = await operateGpu("1")
        if (res.data.success) {
            setSelectNode("2");
            message.success({
                content: res.data.msg
            })
        } else if (res.data && res.data.length > 200 && res.data.indexOf("Please sign in") != -1) {
            window.location.href = "/login"
        } else {
            message.error({
                content: res.data.msg
            })
        }

        const jobRes = await queryGpuJob()
        if (jobRes.data.success) {
            jobRes && jobRes.data && setJobList(jobRes.data.data);
        }
        setLoadingGpu1(false)
    }
    const operateGpu2 = async () => {
        setLoadingGpu2(true)
        const res = await operateGpu("2")
        if (res.data.success) {
            setSelectNode("3");
            message.success({
                content: res.data.msg
            })
        } else if (res.data && res.data.length > 200 && res.data.indexOf("Please sign in") != -1) {
            window.location.href = "/login"
        } else {
            message.error({
                content: res.data.msg
            })
        }
        const jobRes = await queryGpuJob()
        if (jobRes.data.success) {
            jobRes && jobRes.data && setJobList(jobRes.data.data);
        }
        setLoadingGpu2(false)
    }

    const operateGpu3 = async () => {
        setLoadingGpu3(true)
        const res = await operateGpu("3")
        if (res.data.success) {
            setSelectNode("3");
            message.success({
                content: res.data.msg
            })
        } else if (res.data && res.data.length > 200 && res.data.indexOf("Please sign in") != -1) {
            window.location.href = "/login"
        } else {
            message.error({
                content: res.data.msg
            })
        }
        const jobRes = await queryGpuJob()
        if (jobRes.data.success) {
            jobRes && jobRes.data && setJobList(jobRes.data.data);
        }
        setLoadingGpu3(false)

    }

    const operateGpu4 = async () => {
        setLoadingGpu5(true)
        const res = await operateGpu("4")
        if (res.data.success) {
            setSelectNode("3");
            message.success({
                content: res.data.msg
            })
        } else if (res.data && res.data.length > 200 && res.data.indexOf("Please sign in") != -1) {
            window.location.href = "/login"
        } else {
            message.error({
                content: res.data.msg
            })
        }
        const jobRes = await queryGpuJob()
        if (jobRes.data.success) {
            jobRes && jobRes.data && setJobList(jobRes.data.data);
        }
        setLoadingGpu5(false)

    }

    const delGpu = async () => {
        setLoadingDelGpu4(true)
        const res = await deleteGpu()
        if (res.data.success) {
            setSelectNode("1");
            message.success({
                content: res.data.msg
            })
        } else if (res.data && res.data.length > 200 && res.data.indexOf("Please sign in") != -1) {
            window.location.href = "/login"
        } else {
            message.error({
                content: res.data.msg
            })
        }
        const jobRes = await queryGpuJob()
        if (jobRes.data.success) {
            jobRes && jobRes.data && setJobList(jobRes.data.data);
        }
        setLoadingDelGpu4(false)
    }

    const getEvents = async () => {
        setLoadingEvent(true)
        setModalVisible(false)
        const res = await queryEvents()
        setModalVisible(true)
        if (res.data.success) {
            const resList = res.data.data
            let events = []
            if (resList.length > 0) {
                events = resList.map(i => {
                    return {
                        name: i.name,
                        content: i.content
                    }
                })
                setSelectEvent1(events)
            }
        } else if (res.data && res.data.length > 200 && res.data.data.indexOf("Please sign in") != -1) {
            window.location.href = "/login"
        } else {
            message.error({
                content: "事件生成中，请稍后"
            })
        }
        setLoadingEvent(false)
    }


    const getLog = async () => {
        setLoadingLog(true)
        setLogModelVisible(false)
        const res = await queryLogs()
        const resList = res.data.data
        let logs = []
        if (null != resList && resList.length > 0) {
            logs = resList.map(i => {
                return {
                    name: i.name,
                    content: i.stream
                }
            })
            setSelectLog1(logs)
        }
        if (res.data.success) {
            setLogModelVisible(true)
            pollTaskResultInterval = setInterval(async () => {
                const res1 = await queryLogs()
                const resList1 = res1.data.data
                let logs = []
                if (null != resList1 && resList1.length > 0) {
                    logs = resList1.map(i => {
                        return {
                            name: i.name,
                            content: i.stream
                        }
                    })
                    setSelectLog1(logs)
                }

            }, 3000);
            // console.log(123, pollTaskResultInterval)

        } else if (res.data && res.data.length > 200 && res.data.data.indexOf("Please sign in") != -1) {
            window.location.href = "/login"
        } else {
            message.error({
                content: "日志生成中，请稍后再查"
            })
        }
        setLoadingLog(false)
    }


    const refreshLog = async () => {
        setLoadingRefreshLog(true)
        const res = await queryLogs()
        setLoadingRefreshLog(false);
        if (res.data.success) {
            setLogModelVisible(true)
            const resList = res.data.data
            let logs = []
            if (resList.length > 0) {
                logs = resList.map(i => {
                    return {
                        name: i.name,
                        content: i.stream
                    }
                })
                setSelectLog1(logs)
            }
        } else if (res.data && res.data.length > 200 && res.data.data.indexOf("Please sign in") != -1) {
            window.location.href = "/login"
        } else {
            message.error({
                content: res.data.msg
            })
        }
    }
    const refreshConsole = async (data) => {
        document.getElementById('refreshIframeGpu').src = "https://console.cloud.tencent.com/cvm/instance/detail?rid="+data.rid+"&id=" + data.nodeId + "&tab=monitor";
    }

    const getCardInfo = async (data) => {
        // setCardModelVisible(true)
        // setTimeout(()=>{
        //    // console.log(111, document.getElementById('cardIframeGpu'))
        //     document.getElementById('cardIframeGpu').src = "https://tkecache.cloud.tencent.com/qcloud/ccs/webconsole/index.html#rid=8&clusterId=cls-51llgohf&podId="+data.name+"&containerName=container1&namespace="+data.nameSpace+"&shell=bash";
        //     console.log(2222,document.getElementById('cardIframeGpu').src)
        // },500)

        const w = window.open('_black') //这里是打开新窗口
        let url = "https://tkecache.cloud.tencent.com/qcloud/ccs/webconsole/index.html#rid="+data.rid+"&clusterId="+data.clusterId+"&podId=" + data.name + "&containerName=container1&namespace=" + data.nameSpace + "&shell=bash";
        w.location.href = url //这样就可以跳转了
    }

    const showPic = async () => {
        setPicModelVisible(true)
        setTimeout(() => {
            // console.log(111, document.getElementById('cardIframeGpu'))
            document.getElementById('picIframeGpu').src = "https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/gpu/gpu_pic_big.png";
            console.log(2222, document.getElementById('picIframeGpu').src)
        }, 500)


    }

    const tabs = [
        {id: "scene1", label: "场景1"},
        {id: "scene2", label: "场景2"},
        {id: "scene3", label: "场景3"},
        {id: "scene4", label: "场景4"},
    ];


    useEffect(() => {
        //
        (async () => {
            const res = await queryGpuNode()
            if (res.data.success) {
                res && res.data && setNodeList(res.data.data);
            }
            if (res.data && res.data > 200 && res.data.indexOf("Please sign in") != -1) {
                window.location.href = "/login"
            }
            const jobRes = await queryGpuJob()
            if (jobRes.data.success) {
                jobRes && jobRes.data && setJobList(jobRes.data.data);
                console.log("length=" + jobRes.data.data.length)
                const resList = jobRes.data.data
                let jobs = []
                if (resList.length > 0) {
                    jobs = resList.map(i => {
                        if (i.jobName == "scene1-job1" || i.jobName == "scene1-job2") {
                            setSelectNode("2");
                        } else {
                            setSelectNode("3");
                        }
                    })
                }
            } else {
                setSelectNode("1");
            }

            if (jobRes.data && jobRes.data > 200 && jobRes.data.indexOf("Please sign in") != -1) {
                window.location.href = "/login"
            }
        })()

    }, [])
    return (
        <LayoutContent>
            <LayoutContent.Header title='GPU成本优化'>
                <Button onClick={() => operateGpu1()} type="pay" loading={isLoadingGpu1}>场景1</Button>
                <Button onClick={() => operateGpu2()} type="pay" loading={isLoadingGpu2}>场景2</Button>
                <Button onClick={() => operateGpu3()} type="pay" loading={isLoadingGpu3}>场景3</Button>
                <Button onClick={() => operateGpu4()} type="pay" loading={isLoadingGpu5}>场景4</Button>
                <Button onClick={() => delGpu()} type="primary" loading={isLoadingDelGpu4}>环境恢复</Button>
                <Button style={{float: "right"}} onClick={() => goTxyLogin()} type="weak">登录腾讯云</Button>
                <Button style={{float: "right"}} onClick={() => refreshPage()} type="weak">刷新</Button>
            </LayoutContent.Header>


            <div id="main">
                <div style={{overflow: "scroll"}}>
                    <div className="tableGpu">
                        <div className="title">节点列表</div>
                        <Table
                            verticalTop
                            records={
                                nodeList
                            }
                            recordKey="nodeId"
                            bordered='all'
                            columns={[
                                {
                                    key: "name",
                                    header: "节点名称",
                                    align: 'left',
                                    render: node => {
                                        if (selectNode === "2" && node.type === "nvidia") {
                                            return <span style={{color: "red"}}>{node.name}</span>;
                                        }
                                        if (selectNode === "3" && node.type === "qgpu") {
                                            return <span style={{color: "red"}}>{node.name}</span>;
                                        }
                                        return node.name;
                                    },
                                },
                                {
                                    key: "nodeId",
                                    header: "CVM名称",
                                    align: 'left',
                                    render: node => {
                                        if (selectNode === "2" && node.type === "nvidia") {
                                            return <span style={{color: "red"}}>{node.nodeId}</span>;
                                        }
                                        if (selectNode === "3" && node.type === "qgpu") {
                                            return <span style={{color: "red"}}>{node.nodeId}</span>;
                                        }
                                        return node.nodeId;
                                    },

                                },
                                {
                                    key: "instanceType",
                                    header: "GPU机型",
                                    align: 'left',
                                    render: node => {
                                        if (selectNode === "2" && node.type === "nvidia") {
                                            return <span style={{color: "red"}}>{node.instanceType}</span>;
                                        }
                                        if (selectNode === "3" && node.type === "qgpu") {
                                            return <span style={{color: "red"}}>{node.instanceType}</span>;
                                        }
                                        return node.instanceType;
                                    },
                                },
                                {
                                    key: "qGpuTYpe",
                                    header: "GPU型号",
                                    align: 'left',
                                    render: node => {
                                        if (selectNode === "2" && node.type === "nvidia") {
                                            return <span style={{color: "red"}}>{node.qGpuTYpe}</span>;
                                        }
                                        if (selectNode === "3" && node.type === "qgpu") {
                                            return <span style={{color: "red"}}>{node.qGpuTYpe}</span>;
                                        }
                                        return node.qGpuTYpe;
                                    },
                                },

                                // {
                                //     key: "instanceType",
                                //     header: "类型",
                                //     align: 'left'
                                // },
                                {
                                    key: "type",
                                    header: "节点类型",
                                    align: 'left',
                                    render: node => {
                                        if (selectNode === "2" && node.type === "nvidia") {
                                            return <span style={{color: "red"}}>{node.type}</span>;
                                        }
                                        if (selectNode === "3" && node.type === "qgpu") {
                                            return <span style={{color: "red"}}>{node.type}</span>;
                                        }
                                        return node.type;
                                    },
                                },
                                // {
                                //     key: "status",
                                //     header: "状态",
                                //     align: 'left',
                                //     width:"5%"
                                //
                                // },

                                {
                                    key: "op",
                                    header: "操作",
                                    align: 'left',

                                    render: function getFour(data) {
                                        return <Button onClick={() => refreshConsole(data)} type="pay">查看监控</Button>
                                    }
                                },

                            ]}
                        />
                    </div>
                    <div className="tableGpu">

                        <div className="title">场景简介</div>

                        <Card>
                            <Card.Body>
                                <Tabs tabs={tabs}>
                                    <TabPanel id="scene1">
                                        <Card.Body>场景名：非虚拟化模式部署</Card.Body>
                                        <Card.Body>部署模式：Nvidia非虚拟化GPU节点，1张T4 GPU显卡</Card.Body>
                                        <Card.Body>任务简介：以Job模式启动。2个图片处理训练程序，均请求一张GPU显卡</Card.Body>
                                        <Card.Body>执行预期：只有一个Job可以获取到GPU资源，正常启动；另外一个Job无法获取到GPU资源，无法启动</Card.Body>
                                        <Card.Body>查看任务YAML:
                                            <button
                                                style={{marginTop: "10px", marginBottom: "10px", marginLeft: "20px"}}
                                                onClick={() => {
                                                    setDes1Visible(true);
                                                }}>scene1-job1</button>
                                            <button
                                                style={{marginTop: "10px", marginBottom: "10px", marginLeft: "20px"}}
                                                onClick={() => {
                                                    setDes2Visible(true);
                                                }}>scene1-job2
                                            </button>
                                        </Card.Body>
                                    </TabPanel>


                                    <TabPanel id="scene2">
                                        <Card.Body>场景名：虚拟化模式部署，抢占式策略 </Card.Body>
                                        <Card.Body>部署模式：虚拟化qGPU模式部署，1张T4 GPU显卡，使用Best Effort Policy部署</Card.Body>
                                        <Card.Body>任务简介：以Job模式启动，2个图片处理训练程序，请求一张GPU显卡，各请求5G显存</Card.Body>
                                        <Card.Body>执行预期：两个Job可以同时启动，在保证各5G显存隔离的前提下，抢占剩余显卡资源。因每个训练程序负载都很重，最终平分GPU资源。</Card.Body>
                                        <Card.Body>查看任务YAML:
                                            <button
                                                style={{marginTop: "10px", marginBottom: "10px", marginLeft: "20px"}}
                                                onClick={() => {
                                                    setDes3Visible(true);
                                                }}>scene2-job1</button>
                                            <button
                                                style={{marginTop: "10px", marginBottom: "10px", marginLeft: "20px"}}
                                                onClick={() => {
                                                    setDes4Visible(true);
                                                }}>scene2-job2
                                            </button>
                                        </Card.Body>
                                    </TabPanel>


                                    <TabPanel id="scene3">
                                        <Card.Body>场景名：虚拟化模式部署，隔离式策略 </Card.Body>
                                        <Card.Body>部署模式：虚拟化qGPU模式部署，1张T4 GPU显卡，使用Fixed Share Policy部署</Card.Body>
                                        <Card.Body>任务简介：以Job模式启动。1个图片处理程序请求60%显卡比例，6G显存；另1个程序请求30%显卡比例，3G显存</Card.Body>
                                        <Card.Body>执行预期：两个Job可以同时启动，在保证各5G显存隔离的前提下，按照配置比例严格隔离运行，总体GPU使用在90%左右</Card.Body>
                                        <Card.Body>查看任务YAML:
                                            <button
                                                style={{marginTop: "10px", marginBottom: "10px", marginLeft: "20px"}}
                                                onClick={() => {
                                                    setDes5Visible(true);
                                                }}>scene3-job1
                                            </button>
                                            <button
                                                style={{marginTop: "10px", marginBottom: "10px", marginLeft: "20px"}}
                                                onClick={() => {
                                                    setDes6Visible(true);
                                                }}>scene3-job2
                                            </button>
                                        </Card.Body>
                                    </TabPanel>

                                    <TabPanel id="scene4">

                                        <Card.Body>场景名：虚拟化模式部署，在离线混布 </Card.Body>
                                        <Card.Body>部署模式：虚拟化qGPU模式部署，qGPU节点，1张T4 GPU显卡，在离线混布</Card.Body>
                                        <Card.Body>任务简介：以Job模式启动。1个图片处理程序以Online模式启动，每执行2000次暂停90秒，7G显存；另1个程序以Offline模式启动，进行50000次训练，7G显存</Card.Body>
                                        <Card.Body>执行预期：Online程序负载重，在Online程序执行期间，Offline程序暂停执行；Online程序暂停期间，Offline程序恢复执行。显存部分以隔离方式分配，不发生抢占</Card.Body>
                                        <Card.Body>查看任务YAML:
                                            <button
                                                style={{marginTop: "10px", marginBottom: "10px", marginLeft: "20px"}}
                                                onClick={() => {
                                                    setDes7Visible(true);
                                                }}>scene4-job1
                                            </button>
                                            <button
                                                style={{marginTop: "10px", marginBottom: "10px", marginLeft: "20px"}}
                                                onClick={() => {
                                                    setDes8Visible(true);
                                                }}>scene4-job2
                                            </button>
                                        </Card.Body>
                                    </TabPanel>
                                </Tabs>


                            </Card.Body>
                        </Card>

                    </div>

                    <div className="tableGpu">
                        <Card>
                            <Card.Body>
                                <div className="title1">Pod运行情况
                                    <Button style={{marginLeft: "20px"}} onClick={() => {
                                        setModalVisible(true);
                                        getEvents()
                                    }} type="primary" loading={isLoadingEvent}>查看事件</Button>
                                    <Button style={{marginLeft: "20px"}} onClick={() => {
                                        setLogModelVisible(true);
                                        getLog()
                                    }} type="primary" loading={isLoadingLog}>查看日志</Button>
                                    <Button style={{marginLeft: "20px"}}
                                            onClick={() => refreshPod()} type="primary">刷新</Button>
                                </div>

                                <Table
                                    verticalTop
                                    records={
                                        jobList
                                    }
                                    recordKey="name"
                                    bordered='all'
                                    columns={[
                                        {
                                            key: "nodeName",
                                            header: "节点名称",
                                            align: 'left'

                                        },
                                        {
                                            key: "name",
                                            header: "Pod名称",
                                            align: 'left'
                                        },
                                        {
                                            key: "nameSpace",
                                            header: "命名空间",
                                            align: 'left'

                                        },
                                        {
                                            key: "jobName",
                                            header: "场景名称",
                                            align: 'left'

                                        },

                                        {
                                            key: "status",
                                            header: "状态",
                                            align: 'left'

                                        },
                                        {
                                            key: "op",
                                            header: "操作",
                                            align: 'left',
                                            render: function getFive(data) {
                                                return <Button onClick={() => getCardInfo(data)} type="pay">登录</Button>
                                            }

                                        },
                                    ]}
                                />

                            </Card.Body>
                        </Card>
                    </div>

                    <div className="tableGpu">
                        <div className="title">成本模型</div>
                        <Card>
                            <Card.Body>
                        <ImagePreview
                            previewSrc="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/gpu/gpu_pic_big.png"
                            previewTitle="成本模型图查看">
                            {open => <a onClick={open}> <img id="picIframeGpu"
                                                             src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/gpu/gpu_pic.png"
                                                             alt="" style={{width: '100%', height: '100%'}}/>
                            </a>}
                        </ImagePreview>

                            </Card.Body>
                        </Card>
                        {/*<a onClick={() => showPic()} >*/}
                        {/*<img id="picIframeGpu" src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/gpu/gpu_pic.png" alt="" style={{ width: '90%', height: '180px' }} />*/}
                        {/*</a>*/}
                    </div>

                </div>
                <div>
                    <iframe
                        src="https://console.cloud.tencent.com/cvm/instance/detail?rid=8&id=ins-akfiu8qt&tab=monitor"
                        width="100%" height="100%" frameBorder="12" id="refreshIframeGpu"></iframe>
                </div>
            </div>

            <Modal visible={modalVisible} onClose={() => setModalVisible(false)} className="popup" caption="查看事件"
                   style={{fontSize: '14px'}}>
                <Modal.Body style={{textAlign: "center", width: '1150px', height: '500px', overflow: "scroll"}}>
                    {selectEvent1.length > 0 && (<div style={{display: "flex"}}>
                        {
                            selectEvent1.map((i, idx) => {
                                    return <div key={idx} style={{width: "50%"}}>
                                        <div>
                                            <tilte>{i.name}</tilte>
                                        </div>
                                        <div><Table
                                            verticalTop
                                            records={
                                                i.content
                                            }
                                            recordKey="name"
                                            bordered='all'
                                            columns={[
                                                {
                                                    key: "name",
                                                    header: "名称",
                                                    align: 'left',
                                                    width: "12.5%"
                                                },

                                                {
                                                    key: "lastTransitionTime",
                                                    header: "最后出现时间",
                                                    align: 'left',
                                                    width: "12.5%"

                                                },
                                                {
                                                    key: "message",
                                                    header: "消息",
                                                    align: 'left',
                                                    width: "25%"
                                                }

                                            ]}
                                        />
                                        </div>
                                    </div>
                                }
                            )
                        }
                    </div>)}

                </Modal.Body>
            </Modal>

            <Modal visible={logModelVisible} onClose={() => onCloseLog()} className="popup1"
                   caption="查看日志">

                <Modal.Body style={{textAlign: "left",overflow:"scroll",width:"1150px",height:"600px"}}>
                    <button style={{marginTop: "10px", marginBottom: "10px", marginLeft: "20px"}}
                            onClick={() => {
                                refreshLog();
                            }}>刷新
                    </button>
                    {selectLog1.length > 0 && (<div style={{display: "flex", whiteSpace: "pre-wrap"}}>
                        {
                            selectLog1.map((i, idx) => {
                                    return <div key={idx} style={{width: "50%"}}>
                                        <div style={{color: "red"}}>{i.name}</div>
                                        <div style={{color: "white"}}>{i.content}</div>
                                    </div>
                                }
                            )
                        }

                    </div>)}
                </Modal.Body>
            </Modal>


            <Modal visible={des1Visible} onClose={() => setDes1Visible(false)} className="popup2"
                   caption="查看scene1-job1"
                   style={{fontSize: '14px'}}>
                <Modal.Body style={{
                    textAlign: "left",
                    width: '500px',
                    height: '400px',
                    backgroundColor: 'black',
                    color: 'white'
                }}>
                    {/*<Copy text={job1}>*/}
                    {/*    <a style={{float: "right"}}>复制</a>*/}
                    {/*</Copy>*/}
                    <div style={{display: "flex", whiteSpace: "pre-wrap"}}>
                        {job1}
                    </div>
                </Modal.Body>
            </Modal>

            <Modal visible={des2Visible} onClose={() => setDes2Visible(false)} className="popup2"
                   caption="查看scene1-job2"
                   style={{fontSize: '14px'}}>
                <Modal.Body style={{
                    textAlign: "left",
                    width: '500px',
                    height: '400px',
                    backgroundColor: 'black',
                    color: 'white'
                }}>
                    {/*<Copy text={job2}>*/}
                    {/*    <a style={{float: "right"}}>复制</a>*/}
                    {/*</Copy>*/}
                    <div style={{display: "flex", whiteSpace: "pre-wrap"}}>
                        {job2}
                    </div>
                </Modal.Body>
            </Modal>

            <Modal visible={des3Visible} onClose={() => setDes3Visible(false)} className="popup2"
                   caption="查看scene2-job1"
                   style={{fontSize: '14px'}}>
                <Modal.Body style={{
                    textAlign: "left",
                    width: '500px',
                    height: '400px',
                    backgroundColor: 'black',
                    color: 'white'
                }}>
                    {/*<Copy text={job3}>*/}
                    {/*    <a style={{float: "right"}}>复制</a>*/}
                    {/*</Copy>*/}
                    <div style={{display: "flex", whiteSpace: "pre-wrap"}}>
                        {job3}
                    </div>
                </Modal.Body>
            </Modal>

            <Modal visible={des4Visible} onClose={() => setDes4Visible(false)} className="popup2"
                   caption="查看scene2-job2"
                   style={{fontSize: '14px'}}>
                <Modal.Body style={{
                    textAlign: "left",
                    width: '500px',
                    height: '400px',
                    backgroundColor: 'black',
                    color: 'white'
                }}>
                    {/*<Copy text={job4}>*/}
                    {/*    <a style={{float: "right"}}>复制</a>*/}
                    {/*</Copy>*/}
                    <div style={{display: "flex", whiteSpace: "pre-wrap"}}>
                        {job4}
                    </div>
                </Modal.Body>
            </Modal>

            <Modal visible={des5Visible} onClose={() => setDes5Visible(false)} className="popup2"
                   caption="查看scene3-job1"
                   style={{fontSize: '14px'}}>
                <Modal.Body style={{
                    textAlign: "left",
                    width: '500px',
                    height: '400px',
                    backgroundColor: 'black',
                    color: 'white'
                }}>
                    {/*<Copy text={job5}>*/}
                    {/*    <a style={{float: "right"}}>复制</a>*/}
                    {/*</Copy>*/}
                    <div style={{display: "flex", whiteSpace: "pre-wrap"}}>
                        {job5}
                    </div>
                </Modal.Body>
            </Modal>


            <Modal visible={des6Visible} onClose={() => setDes6Visible(false)} className="popup2"
                   caption="查看scene3-job2"
                   style={{fontSize: '14px'}}>
                <Modal.Body style={{
                    textAlign: "left",
                    width: '500px',
                    height: '400px',
                    backgroundColor: 'black',
                    color: 'white'
                }}>
                    {/*<Copy text={job6}>*/}
                    {/*    <a style={{float: "right"}}>复制</a>*/}
                    {/*</Copy>*/}
                    <div style={{display: "flex", whiteSpace: "pre-wrap"}}>
                        {job6}
                    </div>
                </Modal.Body>
            </Modal>

            <Modal visible={des7Visible} onClose={() => setDes7Visible(false)} className="popup2"
                   caption="查看scene4-job1"
                   style={{fontSize: '14px'}}>
                <Modal.Body style={{
                    textAlign: "left",
                    width: '500px',
                    height: '500px',
                    backgroundColor: 'black',
                    color: 'white'
                }}>
                    {/*<Copy text={job7}>*/}
                    {/*    <a style={{float: "right"}}>复制</a>*/}
                    {/*</Copy>*/}
                    <div style={{display: "flex", whiteSpace: "pre-wrap"}}>
                        {job7}
                    </div>
                </Modal.Body>
            </Modal>


            <Modal visible={des8Visible} onClose={() => setDes8Visible(false)} className="popup2"
                   caption="查看scene4-job2"
                   style={{fontSize: '14px'}}>
                <Modal.Body style={{
                    textAlign: "left",
                    width: '500px',
                    height: '500px',
                    backgroundColor: 'black',
                    color: 'white'
                }}>
                    {/*<Copy text={job8}>*/}
                    {/*    <a style={{float: "right"}}>复制</a>*/}
                    {/*</Copy>*/}
                    <div style={{display: "flex", whiteSpace: "pre-wrap"}}>
                        {job8}
                    </div>
                </Modal.Body>
            </Modal>

            <Modal visible={cardModelVisible} onClose={() => setCardModelVisible(false)} className="popupLoginConsole"
                   caption="控制台"
                   style={{fontSize: '14px'}}>
                <Modal.Body>
                    <div style={{width: "100%", height: "600px"}}>
                        <iframe
                            src="https://tkecache.cloud.tencent.com/qcloud/ccs/webconsole/index.html#rid=8&clusterId=cls-51llgohf&podId=qgpu-test4--1-s8mjx&containerName=container1&namespace=qgpu-test&shell=bash"
                            width="100%" height="100%" frameBorder="12" id="cardIframeGpu">

                        </iframe>
                    </div>
                </Modal.Body>
            </Modal>


            <Modal visible={picModelVisible} onClose={() => setPicModelVisible(false)} caption="查看成本模型"
                   style={{fontSize: '14px', width: "1200px", height: "600px"}}>
                <Modal.Body>
                    <div style={{width: "1000px", height: "600px"}}>
                        <iframe
                            src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/gpu/gpu_pic_big.png"
                            width="100%" height="100%" frameBorder="12" id="cardIframeGpu">

                        </iframe>
                    </div>
                </Modal.Body>
            </Modal>


        </LayoutContent>
    );
}
