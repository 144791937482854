import React, {useEffect, useState} from "react";
import "tea-component/dist/tea.css";
import {
    Layout,
    Button,
    Card,
    Tabs,
    TabPanel, Input, Table, message, Bubble,
    Icon, Checkbox, Modal
} from "tea-component";
import './indexAcrossCloudFunciton.scss'
import {
    queryAcrossCloudDoubleLive,
    purchaseAcrossCloud,
    updateDNSPod,
    updateKong,
    queryAcrossDoubleLiveMysqlDataLog,
    queryAcrossDoubleLiveMysqlDataLogTable,
    queryClsLogData,
    insertMysqlData,
    queryAcrossDoubleLiveKongClsLog, querySceneConfig
} from "../../../services/function";


const {Content: LayoutContent} = Layout;
const {mergeable} = Table.addons;

export default function BuringPointFunction() {
    const [books, setBooks] = useState([]);
    const [doubleLiveList, setDoubleLiveList] = useState([])
    const [sceneImageUrl, setSceneImageUrl] = useState("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/across_cloud_double_live/latest/crosscloud-doublelive-run.gif")
    const [isLoadingFault, setLoadingFault] = useState(false)
    const [isLoadingSwitch, setLoadingSwitch] = useState(false)
    const [isLoadingRecover, setLoadingRecover] = useState(false)
    const [isLoadingSceneRecover, setLoadingSceneRecover] = useState(false)
    const [isLoadingPurchase, setLoadingPurchase] = useState(false)
    const [isLoadingDataRecover, setLoadingDataRecover] = useState(false)
    const [isLoadingKongLog, setLoadingKongLog] = useState(false)
    const [isLoadingClsLog, setLoadingClsLog] = useState(false)
    const [isLoadingMysqlLog, setLoadingMysqlLog] = useState(false)
    const [doubleLiveStatus, setDoubleLiveStatus] = useState("")
    const [logModelVisible, setLogModelVisible] = useState(false)
    const [dnsStatus, setDnsStatus] = useState("未开启")
    const [logClsKongContent, setLogClsKongContent] = useState([])
    const [logClsKongContent1, setLogClsKongContent1] = useState([])
    const [logMysqlDataVisible, setLogMysqlDataModelVisible] = useState(false)
    const [logDnsPodVisible, setLogDnsPodModelVisible] = useState(false)
    const [shTableDataList, setShTableDataList] = useState([]);
    const [gzTableDataList, setGzTableDataList] = useState([]);
    const [mysqlDataGzLog, setMysqlDataGzLog] = useState("");
    const [mysqlDataShLog, setMysqlDataShLog] = useState("");
    const [mysqlDataGzLog1, setMysqlDataGzLog1] = useState("");
    const [mysqlDataShLog1, setMysqlDataShLog1] = useState("");
    const [logClsModelVisible, setLogClsModelVisible] = useState(false);
    const [logCls, setLogCls] = useState([]);
    const [logCls1, setLogCls1] = useState([]);
    const [dnsPodUrl, setDnsPodUrl] = useState("https://console.cloud.tencent.com/cns/detail/yunnative.com/records/0");
    const [shKongUrl, setShKongUrl] = useState("https://console.cloud.tencent.com/tse/cnapigw-detail?rid=1&id=gateway-2b4c3a0d");
    const [gzKongUrl, setGzKongUrl] = useState("https://console.cloud.tencent.com/tse/cnapigw-detail?rid=1&id=gateway-036d5b87");
    const [shMysqlUrl, setShMysqlUrl] = useState("https://console.cloud.tencent.com/dts/replication/detail?rid=1&jobId=sync-fv88vpfo");
    const [gzMysqlUrl, setGzMysqlUrl] = useState("https://console.cloud.tencent.com/dts/replication/detail?rid=4&jobId=sync-mbmt49pp");
    const [scene3RightUrl, setScene3RightUrl] = useState("https://console.cloud.tencent.com/tdcc/cluster");
    const [scene4RightUrl, setScene4RightUrl] = useState("http://qcbmm.yunnative.com:3000/d/y_5idHI4k/yun-shu-cheng-kua-yun-shuang-huo?kiosk=off");


    const [scene4ClsConsoleUrl, setScene4ClsConsoleUrl] = useState("https://console.cloud.tencent.com/cls/topic/detail?region=ap-guangzhou&id=bf92106e-a602-4608-aef9-be6c4bd51c03&searchParams=cmVnaW9uPWFwLWd1YW5nemhvdQ");
    const [scene4ClsKongUrl, setScene4ClsKongUrl] = useState("https://console.cloud.tencent.com/cls/topic/detail?region=ap-guangzhou&id=8b5fd5bd-b1a4-4370-bc73-ca181c8872a9&searchParams=cmVnaW9uPWFwLWd1YW5nemhvdQ");
    const [scene4PrometheusUrl, setScene4PrometheusUrl] = useState("https://console.cloud.tencent.com/monitor/prometheus/detail/prom-kp3jntpy?rid=1");
    const [scene4BookShopUrl, setScene4BookShopUrl] = useState("http://qcbmm.yunnative.com:3000/d/y_5idHI4k/yun-shu-cheng-kua-yun-shuang-huo?orgId=1");

    const [scene6ShConflictUrl, setScene6ShConflictUrl] = useState("http://qcbmm.yunnative.com:3000/d/y_5idHI4k/yun-shu-cheng-kua-yun-shuang-huo?orgId=1");
    const [scene6GzConflictUrl, setScene6GzConflictUrl] = useState("http://qcbmm.yunnative.com:3000/d/y_5idHI4k/yun-shu-cheng-kua-yun-shuang-huo?orgId=1");


    const [detailList, setDetailList] = useState([

        {
            num: 1,
            description: "DNSPod设置两地域的系统权重均为50%，将请求按照权重分配至任一地域的云原生网关。",

        },
        {
            num: 2,
            description: "云原生网关根据用户ID为奇数将该请求路由至部署在上海地域的Q云书城应用。",
        },
        {
            num: 3,
            description: "部署在上海地域的Q云书城应用执行该请求，并写入本地域的MySQL数据库。",
        },
        {
            num: 4,
            description: "DTS将相关的数据库记录从上海地域的MySQL数据库同步至广州地域的MySQL数据库。",
        },

    ])
    const [dnsPodLog, setDnsPodLog] = useState("{\n" +
        "  \"code\": 10000,\n" +
        "  \"msg\": \"下单成功\",\n" +
        "  \"data\": \"下单成功\",\n" +
        "  \"success\": true,\n" +
        "  \"fail\": false\n" +
        "}")
    const tabs = [
        {id: "scene1", label: "场景1"},
        {id: "scene2", label: "场景2"},
        {id: "scene3", label: "场景3"},
        {id: "scene4", label: "场景4"},
        {id: "scene5", label: "场景5"},
        {id: "scene6", label: "场景6"}
    ];

    const tabs1 = [
        {id: "scene6_1", label: "冲突忽略"},
        {id: "scene6_2", label: "冲突覆盖"}
    ];


    const scene5Data = [

        {
            conflictType: "数据1",
            shData: "id:123\n" +
                "userid:1\n" +
                "user name:admin\n" +
                "isbn:9787121198854",
            gzData: "id:123\n" +
                "userid:2\n" +
                "user name:guest\n" +
                "isbn:9787115551382",
        },
        {
            conflictType: "数据2",
            shData: "id:123\n" +
                "userid:1\n" +
                "user name:上海用户\n" +
                "isbn:9787121198854",
            gzData: "id:123\n" +
                "userid:2\n" +
                "user name:广州用户\n" +
                "isbn:9787115551382",
        }
    ]


    const scene6IgnoreData = [

        {
            conflictType: "冲突忽略",

            gzData: "id:123\n" +
                "userid:2\n" +
                "user name:广州用户\n" +
                "isbn:9787115551382",
            shData: "id:123\n" +
                "userid:1\n" +
                "user name:上海用户\n" +
                "isbn:9787121198854",
        }
    ]

    const scene6CoverData = [

        {
            conflictType: "冲突覆盖",
            shData: "id:123\n" +
                "userid:1\n" +
                "user name:admin\n" +
                "isbn:9787121198854",
            gzData: "id:123\n" +
                "userid:2\n" +
                "user name:guest\n" +
                "isbn:9787115551382",
        }
    ]


    const scene6RightBData = [
        {
            opType: "insert",
            confType: "忽略",
            oLibrary: "全部存在主键",
            sqlDetail:"insert ignore",
            detail: "预期影响行数1，实际影响行数0",
            result: "告警"
        },
        {
            opType: "insert",
            confType: "忽略",
            oLibrary: "全部不存在",
            sqlDetail:"insert ignore",
            detail: "预期影响行数1，实际影响行数1",
            result: "不告警"
        },
        {
            opType: "insert",
            confType: "忽略",
            oLibrary: "存在部分主键",
            sqlDetail:"insert ignore",
            detail: "预期影响行数1，实际影响行数1",
            result: "告警"
        }, {
            opType: "update",
            confType: "忽略",
            oLibrary: "全部存在主键",
            sqlDetail:"不改写",
            detail: "预期影响行数1，实际影响行数1",
            result: "不告警"
        },
        {
            opType: "update",
            confType: "忽略",
            oLibrary: "全部不存在",
            sqlDetail:"不改写",
            detail: "预期影响行数1，实际影响行数0",
            result: "告警"
        },
        {
            opType: "delete",
            confType: "忽略",
            oLibrary: "全部存在主键",
            sqlDetail:"不改写",
            detail: "预期影响行数1，实际影响行数1",
            result: "不告警"
        },
        {
            opType: "delete",
            confType: "忽略",
            oLibrary: "全部不存在",
            sqlDetail:"不改写",
            detail: "预期影响行数1，实际影响行数0",
            result: "告警"
        },
        {
            opType: "insert",
            confType: "覆盖",
            oLibrary: "全部存在主键",
            sqlDetail:"replace into",
            detail: "预期影响行数1，实际影响行数2",
            result: "告警"
        },
        {
            opType: "insert",
            confType: "覆盖",
            oLibrary: "全部不存在",
            sqlDetail:"replace into",
            detail: "预期影响行数1，实际影响行数1",
            result: "不告警"
        },
        {
            opType: "insert",
            confType: "覆盖",
            oLibrary: "存在部分主键",
            sqlDetail:"replace into",
            detail: "预期影响行数1，实际影响行数1",
            result: "不告警"
        },
        {
            opType: "update",
            confType: "覆盖",
            oLibrary: "全部存在主键",
            sqlDetail:"delete + replace into",
            detail: "预期影响行数1，实际影响行数1",
            result: "不告警"
        },
        {
            opType: "update",
            confType: "覆盖",
            oLibrary: "全部不存在",
            sqlDetail:"delete + replace into",
            detail: "预期影响行数1，实际delete影响行数0，实际replaceinto影响行数1",
            result: "告警（delete操作）"
        },

        {
            opType: "delete",
            confType: "覆盖",
            oLibrary: "全部存在主键",
            sqlDetail:"不改写",
            detail: "预期影响行数1，实际影响行数1",
            result: "不告警"
        },
        {
            opType: "delete",
            confType: "覆盖",
            oLibrary: "全部不存在",
            sqlDetail:"不改写",
            detail: "预期影响行数1，实际影响行数0",
            result: "告警"
        }

    ]

    const logData = (status,value) => {
        const dataLogList1 = [
            {
                num: 1,
                description: "由于上海地域的Q云书城应用出现故障无法访问，该请求返回错误",

            }];
        const dataLogList = [
            {
                num: 1,
                description: "DNSPod设置两地域的系统权重均为50%，将请求按照权重分配至任一地域的云原生网关。",

            },
            {
                num: 2,
                description: "云原生网关根据用户ID为奇数将该请求路由至部署在上海地域的Q云书城应用。",
            },
            {
                num: 3,
                description: "部署在上海地域的Q云书城应用执行该请求，并写入本地域的MySQL数据库。",
            },
            {
                num: 4,
                description: "DTS将相关的数据库记录从上海地域的MySQL数据库同步至广州地域的MySQL数据库。",
            }];
        if (status === 11) {
            dataLogList[0].num = 1;
            dataLogList[0].description = "因为用户ID "+value+"为奇数，所以DNSPod将shapi.yunnative.com的记录值81.69.182.71，即上海云原生网关的访问地址返回给客户端。"

            dataLogList[1].num = 2;
            dataLogList[1].description = "云原生网关根据用户ID "+value+"为奇数将该请求路由至部署在上海地域的Q云书城应用。"

            dataLogList[2].num = 3;
            dataLogList[2].description = "部署在上海地域的Q云书城应用执行该请求，并写入本地域的MySQL数据库。"

            dataLogList[3].num = 4;
            dataLogList[3].description = "DTS将相关的数据库记录从上海地域的MySQL数据库同步至广州地域的MySQL数据库。"
            setDetailList(dataLogList)
        } else if (status === 12) {
            dataLogList[0].num = 1;
            dataLogList[0].description = "因为用户ID "+value+"为偶数，所以DNSPod将gzapi.yunnative.com的记录值129.204.97.166，即广州云原生网关的访问地址返回给客户端。"

            dataLogList[1].num = 2;
            dataLogList[1].description = "云原生网关根据用户ID "+value+"为偶数将该请求路由至部署在广州地域的Q云书城应用。"

            dataLogList[2].num = 3;
            dataLogList[2].description = "部署在广州地域的Q云书城应用执行该请求，并写入本地域的MySQL数据库。"

            dataLogList[3].num = 4;
            dataLogList[3].description = "DTS将相关的数据库记录从广州地域的MySQL数据库同步至上海地域的MySQL数据库。"
            setDetailList(dataLogList)
        } else if (status === 21) {
            setDetailList(dataLogList1)
        } else if (status === 22) {
            dataLogList[0].num = 1;
            dataLogList[0].description = "因为用户ID "+value+"为偶数，所以DNSPod将gzapi.yunnative.com的记录值129.204.97.166，即广州云原生网关的访问地址返回给客户端。"

            dataLogList[1].num = 2;
            dataLogList[1].description = "云原生网关根据用户ID "+value+"为偶数将该请求路由至部署在广州地域的Q云书城应用。"

            dataLogList[2].num = 3;
            dataLogList[2].description = "部署在广州地域的Q云书城应用执行该请求，并写入本地域的MySQL数据库。"

            dataLogList[3].num = 4;
            dataLogList[3].description = "DTS将相关的数据库记录从广州地域的MySQL数据库同步至上海地域的MySQL数据库。"
            setDetailList(dataLogList)
        } else if (status === 31) {
            dataLogList[0].num = 1;
            dataLogList[0].description = "双活切换后，无论用户ID为奇偶，客户端均访问gzapi.yunnative.com，DNSPod将gzapi.yunnative.com的记录值129.204.97.166，即广州云原生网关的访问地址返回给客户端。"

            dataLogList[1].num = 2;
            dataLogList[1].description = "云原生网关中的路由策略已经更新，所有请求都路由至广州地域的Q云书城应用。"

            dataLogList[2].num = 3;
            dataLogList[2].description = "部署在广州地域的Q云书城应用执行该请求，并写入本地域的MySQL数据库。"

            dataLogList[3].num = 4;
            dataLogList[3].description = "DTS停止数据同步。"
            setDetailList(dataLogList)
        } else if (status === 32) {
            dataLogList[0].num = 1;
            dataLogList[0].description = "双活切换后，无论用户ID为奇偶，客户端均访问gzapi.yunnative.com，DNSPod将gzapi.yunnative.com的记录值129.204.97.166，即广州云原生网关的访问地址返回给客户端。"

            dataLogList[1].num = 2;
            dataLogList[1].description = "云原生网关中的路由策略已经更新，所有请求都路由至广州地域的Q云书城应用。"

            dataLogList[2].num = 3;
            dataLogList[2].description = "部署在广州地域的Q云书城应用执行该请求，并写入本地域的MySQL数据库。"

            dataLogList[3].num = 4;
            dataLogList[3].description = "DTS停止数据同步。"
            setDetailList(dataLogList)
        }

        if (status === 11 || status === 12 || status === 21 || status === 22 || status === 31 || status === 32) {
            document.getElementById('acrossCloudRoadId').style.display = "block";
        }
    }

    const purchase = async (books) => {
        setLoadingPurchase(true)
        let r = /^[0-9]*[1-9][0-9]*$/
        let value = document.getElementById("acrossCloudUserId").value;
        if (value === null || value === 'undefined' || value === "" || !r.test(value) || value <= 0) {
            message.error({
                content: "用户ID输入错误，请输入大于0的数字"
            })
            setLoadingPurchase(false)
            return;
        }

        if (books.length <= 0) {
            message.error({
                content: "请选择下单的书本"
            })
            setLoadingPurchase(false)
            return;
        }
        let status = 1;
        const resStatus = await queryAcrossCloudDoubleLive()
        if (resStatus.data.success) {
            if (resStatus.data.taskId === 1 || resStatus.data.taskId === 11 || resStatus.data.taskId === 12) {
                setDoubleLiveStatus("双活运行")
                if (value % 2 === 0) {
                    status = 12;
                } else {
                    status = 11;
                }
            } else if (resStatus.data.taskId === 2 || resStatus.data.taskId === 21 || resStatus.data.taskId === 22) {
                setDoubleLiveStatus("故障中")
                if (value % 2 === 0) {
                    status = 22;
                } else {
                    status = 21;
                }
            } else if (resStatus.data.taskId === 3 || resStatus.data.taskId === 31 || resStatus.data.taskId === 32) {
                setDoubleLiveStatus("双活切换")
                if (value % 2 === 0) {
                    status = 32;
                } else {
                    status = 31;
                }
            } else {
                status = resStatus.data.taskId;
            }
        } else if (resStatus.data && resStatus.data.length > 200 && resStatus.data.indexOf("Please sign in") !== -1) {
            window.location.href = "/login"
        }
        const res = await purchaseAcrossCloud(value, status, books);

        setLoadingPurchase(false)
        //if (res.data.success || !res.data.success) {
        if (res.data.success ) {
            const doubleLiveStatus = await queryAcrossCloudDoubleLive()
            if (doubleLiveStatus.data.success) {
                doubleLiveStatus && doubleLiveStatus.data && setDoubleLiveList(doubleLiveStatus.data.data);
                logData(doubleLiveStatus.data.taskId,value);
                if (doubleLiveStatus.data.taskId === 11) {
                    setDoubleLiveStatus("双活运行");
                    setSceneImageUrl("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/across_cloud_double_live/latest/crosscloud-doublelive-odd.gif")
                } else if (doubleLiveStatus.data.taskId === 12) {
                    setDoubleLiveStatus("双活运行");
                    setSceneImageUrl("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/across_cloud_double_live/latest/crosscloud-doublelive-even.gif")
                } else if (doubleLiveStatus.data.taskId === 2) {
                    setDoubleLiveStatus("故障中");
                    setSceneImageUrl("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/across_cloud_double_live/latest/crosscloud-failure-run.gif")
                } else if (doubleLiveStatus.data.taskId === 21) {
                    setDoubleLiveStatus("故障中");
                    setSceneImageUrl("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/across_cloud_double_live/latest/crosscloud-failure-odd.gif")
                } else if (doubleLiveStatus.data.taskId === 22) {
                    setDoubleLiveStatus("故障中");
                    setSceneImageUrl("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/across_cloud_double_live/latest/crosscloud-failure-even.gif")
                } else if (doubleLiveStatus.data.taskId === 3) {
                    setDoubleLiveStatus("双活切换");
                    setSceneImageUrl("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/across_cloud_double_live/latest/crosscloud-failover-run.gif")
                } else if (doubleLiveStatus.data.taskId === 31) {
                    setDoubleLiveStatus("双活切换");
                    setSceneImageUrl("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/across_cloud_double_live/latest/crosscloud-failover-odd.gif")
                } else if (doubleLiveStatus.data.taskId === 32) {
                    setDoubleLiveStatus("双活切换");
                    setSceneImageUrl("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/across_cloud_double_live/latest/crosscloud-failover-even.gif")
                }
            }
            document.getElementById('acrossCloudRoadId').style.display = "block";
            if (res.data.success) {
                message.success({
                    content: res.data.msg
                })
            } else {
                message.error({
                    content: res.data.msg
                })
            }


        } else if (res.data && res.data.length > 200 && res.data.indexOf("Please sign in") !== -1) {
            window.location.href = "/login"
        } else {
            message.error({
                content: res.data.msg
            })
        }
        // document.getElementById('acrossCloudRoadId').style.display = "block";
        setLoadingPurchase(false)

    }

    const querySh = (data) => {
        let url = dnsPodUrl
        if (data === "Kong网关") {
            //url = 'https://console.cloud.tencent.com/tse/kong-detail?rid=4&id=gateway-2b4c3a0d';
            url = shKongUrl;
        } else if (data === 'MySQL数据库同步') {
            url = shMysqlUrl;
        }

        const w = window.open('_black') //这里是打开新窗口

        w.location.href = url //这样就可以跳转了
    }

    const queryGz = (data) => {
        let url = dnsPodUrl
        if (data === 'Kong网关') {
           // url = 'https://console.cloud.tencent.com/tse/kong-detail?rid=1&id=gateway-036d5b87';
            url= gzKongUrl;
        } else if (data === 'MySQL数据库同步') {
            // url = "https://console.cloud.tencent.com/dts/replication/detail/info?rid=4&jobId=sync-piac2wy7";
            url = gzMysqlUrl;
        }
        const w = window.open('_black') //这里是打开新窗口
        w.location.href = url //这样就可以跳转了
    }

    const recoverAcrossCloud = async () => {
        setSceneImageUrl("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/across_cloud_double_live/latest/crosscloud-doublelive-run.gif")
        document.getElementById('acrossCloudRoadId').style.display = "none";
        setLoadingSceneRecover(true)
        const res = await updateDNSPod("3");
        setLoadingSceneRecover(false)
        if (res.data.success) {
            setDoubleLiveStatus("双活运行");
            message.success({
                content: res.data.msg
            })
        } else if (res.data && res.data.length > 200 && res.data.indexOf("Please sign in") !== -1) {
            window.location.href = "/login"
        } else {
            message.error({
                content: res.data.msg
            })
        }

        //空网关关掉 广州和上海
        const resKong = await updateKong("all", "on");
        if (resKong.data.success) {
            message.success({
                content: resKong.data.msg
            })
        } else if (resKong.data && resKong.data.length > 200 && resKong.data.indexOf("Please sign in") !== -1) {
            window.location.href = "/login"
        } else {
            message.error({
                content: resKong.data.msg
            })
        }

        const queryRes = await queryAcrossCloudDoubleLive()
        if (queryRes.data.success) {
            queryRes && queryRes.data && setDoubleLiveList(queryRes.data.data);
        }

    }

    const faultShow = async () => {

        document.getElementById('acrossCloudRoadId').style.display = "none";
        setLoadingFault(true)
        const res = await updateDNSPod("1");
        setLoadingFault(false)
        if (res.data.success) {
            setDoubleLiveStatus("故障中");
            setSceneImageUrl("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/across_cloud_double_live/latest/crosscloud-failure-run.gif")
            message.success({
                content: res.data.msg
            })
        } else if (res.data && res.data.length > 200 && res.data.indexOf("Please sign in") !== -1) {
            window.location.href = "/login"
        } else {
            message.error({
                content: res.data.msg
            })
        }
        const queryRes = await queryAcrossCloudDoubleLive()
        if (queryRes.data.success) {
            queryRes && queryRes.data && setDoubleLiveList(queryRes.data.data);
        }
    }
    //双活切换
    const switchDouble = async () => {
        document.getElementById('acrossCloudRoadId').style.display = "none";
        setLoadingSwitch(true)
        setSceneImageUrl("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/across_cloud_double_live/latest/crosscloud-failover-run.gif")
        const res = await updateDNSPod("2");
        setLoadingSwitch(false)
        if (res.data.success) {
            setDoubleLiveStatus("双活切换");
            message.success({
                content: res.data.msg
            })
        } else if (res.data && res.data.length > 200 && res.data.indexOf("Please sign in") !== -1) {
            window.location.href = "/login"
        } else {
            message.error({
                content: res.data.msg
            })
        }

        //空网关关掉 广州和上海
        const resKong = await updateKong("all", "off");
        if (resKong.data.success) {
            message.success({
                content: resKong.data.msg
            })
        } else if (resKong.data && resKong.data.length > 200 && resKong.data.indexOf("Please sign in") !== -1) {
            window.location.href = "/login"
        } else {
            message.error({
                content: resKong.data.msg
            })
        }


        const queryRes = await queryAcrossCloudDoubleLive()
        if (queryRes.data.success) {
            queryRes && queryRes.data && setDoubleLiveList(queryRes.data.data);
        }

    }


    //双活复原
    const recoverDouble = async () => {
        document.getElementById('acrossCloudRoadId').style.display = "none";
        setLoadingRecover(true)
        setSceneImageUrl("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/across_cloud_double_live/latest/crosscloud-doublelive-run.gif")
        const res = await updateDNSPod("3");
        setLoadingRecover(false)
        if (res.data.success) {
            setDoubleLiveStatus("双活运行");
            message.success({
                content: res.data.msg
            })
        } else if (res.data && res.data.length > 200 && res.data.indexOf("Please sign in") !== -1) {
            window.location.href = "/login"
        } else {
            message.error({
                content: res.data.msg
            })
        }

        //空网关关掉 广州和上海
        const resKong = await updateKong("all", "on");
        if (resKong.data.success) {
            message.success({
                content: resKong.data.msg
            })
        } else if (resKong.data && resKong.data.length > 200 && resKong.data.indexOf("Please sign in") !== -1) {
            window.location.href = "/login"
        } else {
            message.error({
                content: resKong.data.msg
            })
        }

        const queryRes = await queryAcrossCloudDoubleLive()
        if (queryRes.data.success) {
            queryRes && queryRes.data && setDoubleLiveList(queryRes.data.data);
        }

    }
    const goYunShuCheng = () => {

        const w = window.open('_black') //这里是打开新窗口
        w.location.href = 'http://crossclouddemo-1305426035.cos-website.ap-shanghai.myqcloud.com' //这样就可以跳转了
    }


    //切换Tab
    const tabsChange = async (tab) => {
        document.getElementById('acrossCloudRoadId').style.display = "none";

        let iframeScene3Id = document.getElementById('iframeScene3Id');
        let scene12ImageId = document.getElementById('scene12ImageId');
        let iframeScene4Id1 = document.getElementById('iframeScene4Id1');
        let rightFrameId = document.getElementById('rightFrameId');
        let leftDivId = document.getElementById("leftDivId");
        let leftCardId = document.getElementById("leftCardId");
        let scene5RightId = document.getElementById("scene5RightId");
        let scene6RightId = document.getElementById("scene6RightId");

        if ( (tab.id === "scene1" || tab.id === "scene2")) {
            scene12ImageId.style.display = "block";
            iframeScene3Id.style.display = "none";
            iframeScene4Id1.style.display = "none";
            leftDivId.style.height = "720px";
            leftCardId.style.height = "100%"
            rightFrameId.style.height = "720px";
            rightFrameId.style.backgroundColor = "#f3f4f7"
            scene5RightId.style.display = "none";
            scene6RightId.style.display = "none";
             leftDivId.style.overflow = "hidden";
            //leftDivId.style.overflow = "scroll"
            await initData();
        } else if (null != iframeScene3Id && tab.id === "scene3") {
            iframeScene3Id.style.display = "block";
            scene12ImageId.style.display = "none";
            iframeScene4Id1.style.display = "none";
            leftDivId.style.height = "800px";
            leftCardId.style.height = "750px"
            rightFrameId.style.height = "750px";
            rightFrameId.style.backgroundColor = "#f3f4f7"
            scene5RightId.style.display = "none";
            scene6RightId.style.display = "none";
            leftDivId.style.overflow = "hidden";
        } else if (tab.id === "scene4") {
            iframeScene3Id.style.display = "none";
            scene12ImageId.style.display = "none";
            iframeScene4Id1.style.display = "block";
            leftDivId.style.height = "910px";
            leftCardId.style.height = "100%"
            rightFrameId.style.height = "920px";
            rightFrameId.style.backgroundColor = "#f3f4f7"
            scene5RightId.style.display = "none";
            scene6RightId.style.display = "none";
            leftDivId.style.overflow = "hidden";
        } else if (tab.id === "scene5") {
            iframeScene3Id.style.display = "none";
            scene12ImageId.style.display = "none";
            iframeScene4Id1.style.display = "none";
            rightFrameId.style.height = "775px";
            leftDivId.style.height = "800px";
            leftCardId.style.height = "750px"
            scene5RightId.style.display = "block";
            scene5RightId.style.height = "775px";
            scene6RightId.style.display = "none";
            leftDivId.style.overflow = "hidden";
            const res = await queryAcrossDoubleLiveMysqlDataLogTable();
            if (res.data.success) {
                setShTableDataList(res.data.data.shList);
                setGzTableDataList(res.data.data.gzList);

            }
        } else if (tab.id === "scene6") {
            iframeScene3Id.style.display = "none";
            scene12ImageId.style.display = "none";
            iframeScene4Id1.style.display = "none";
            rightFrameId.style.height = "775px";
            leftDivId.style.height = "800px";
            leftCardId.style.height = "750px";
            //leftDivId.style.overflow = "scroll"
            scene6RightId.style.display = "block";
            scene6RightId.style.height = "775px";
            scene5RightId.style.display = "none";
            const res = await queryAcrossDoubleLiveMysqlDataLogTable();
            if (res.data.success) {
                setShTableDataList(res.data.data.shList);
                setGzTableDataList(res.data.data.gzList);

            }
        }
    }

    const onCloseLog = () => {
        setLogModelVisible(false)
    }
    const onCloseDnsPodLog = () => {
        setLogDnsPodModelVisible(false)
    }


    const getLog = async () => {

        setLoadingKongLog(true);
        const res = await queryAcrossDoubleLiveKongClsLog(books.length);
        if (res.data.success) {
            setLogClsKongContent(res.data.data.listF)
            setLogClsKongContent1(res.data.data.listL)
        } else if (res.data && res.data.length > 200 && res.data.data.indexOf("Please sign in") !== -1) {
            window.location.href = "/login"
        } else {
            message.error({
                content: "日志生成中，请稍后再查"
            })
        }
        setLoadingKongLog(false);
        setLogModelVisible(true);
    }

    /**
     * 获取DNSPod日志
     */
    const getDnsPodLog = async () => {
        setLogDnsPodModelVisible(true);
        if ("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/across_cloud_double_live/latest/crosscloud-failure-odd.gif" === sceneImageUrl) {
            // alert(sceneImageUrl)
            setDnsPodLog("{\n" +
                "  \"code\": 10001,\n" +
                "  \"msg\": \"下单失败，系统状态处于故障中，请稍后再试\",\n" +
                "  \"data\": \"下单失败：I/O error on POST request for \"http://apigateway.yunnative.com/api/book/purchase\": connect timed out; nested exception is java.net.SocketTimeoutException: connect timed out\",\n" +
                "  \"success\": false,\n" +
                "  \"fail\": true\n" +
                "}");
        } else {
            setDnsPodLog("{\n" +
                "  \"code\": 10000,\n" +
                "  \"msg\": \"下单成功\",\n" +
                "  \"data\": \"下单成功\",\n" +
                "  \"success\": true,\n" +
                "  \"fail\": false\n" +
                "}");
        }

    }

    const getMysqlDataLog = async () => {
        setLoadingMysqlLog(true);
        const res = await queryAcrossDoubleLiveMysqlDataLog(books.length);
        if (res.data.success) {
            setMysqlDataShLog(res.data.data.shSql1)
            setMysqlDataGzLog(res.data.data.gzSql1)
            setMysqlDataShLog1(res.data.data.shSql2)
            setMysqlDataGzLog1(res.data.data.gzSql2)

        } else if (res.data && res.data.length > 200 && res.data.indexOf("Please sign in") !== -1) {
            window.location.href = "/login"
        }
        setLoadingMysqlLog(false);
        setLogMysqlDataModelVisible(true);

    }

    const queryClsLog = async () => {
        setLoadingClsLog(true);
        const res = await queryClsLogData(books.length);
        if (res.data.success) {
            setLogCls(res.data.data.listF)
            setLogCls1(res.data.data.listL)
        } else if (res.data && res.data.length > 200 && res.data.indexOf("Please sign in") !== -1) {
            window.location.href = "/login"
        }
        setLogClsModelVisible(true);
        setLoadingClsLog(false);
    }

    const initData = async () => {
        const res = await queryAcrossCloudDoubleLive()
        if (res.data.success) {
            setDnsStatus("未开启");//未实现
            res && res.data && setDoubleLiveList(res.data.data);
            if (res.data.taskId === 1) {
                setDoubleLiveStatus("双活运行")
                setSceneImageUrl("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/across_cloud_double_live/latest/crosscloud-doublelive-run.gif")
            } else if (res.data.taskId === 11) {
                setDoubleLiveStatus("双活运行")
                setSceneImageUrl("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/across_cloud_double_live/latest/crosscloud-doublelive-odd.gif")
            } else if (res.data.taskId === 12) {
                setDoubleLiveStatus("双活运行")
                setSceneImageUrl("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/across_cloud_double_live/latest/crosscloud-doublelive-even.gif")
            } else if (res.data.taskId === 2) {
                setDoubleLiveStatus("故障中")
                setSceneImageUrl("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/across_cloud_double_live/latest/crosscloud-failure-run.gif")
            } else if (res.data.taskId === 21) {
                setDoubleLiveStatus("故障中")
                setSceneImageUrl("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/across_cloud_double_live/latest/crosscloud-failure-odd.gif")
            } else if (res.data.taskId === 22) {
                setDoubleLiveStatus("故障中")
                setSceneImageUrl("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/across_cloud_double_live/latest/crosscloud-failure-even.gif")
            } else if (res.data.taskId === 3) {
                setDoubleLiveStatus("双活切换")
                setSceneImageUrl("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/across_cloud_double_live/latest/crosscloud-failover-run.gif")
            } else if (res.data.taskId === 31) {
                setDoubleLiveStatus("双活切换")
                setSceneImageUrl("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/across_cloud_double_live/latest/crosscloud-failover-odd.gif")
            } else if (res.data.taskId === 32) {
                setDoubleLiveStatus("双活切换")
                setSceneImageUrl("https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/across_cloud_double_live/latest/crosscloud-failover-even.gif")
            }
        } else if (res.data && res.data.length > 200 && res.data.indexOf("Please sign in") !== -1) {
            window.location.href = "/login"
        }
    }


    const refreshPage = () => {
        window.location.reload();
    }

    const refreshMysqlData = async () => {
        const queryRes = await queryAcrossDoubleLiveMysqlDataLogTable()
        if (queryRes.data.success) {
            setShTableDataList(queryRes.data.data.shList)
            setGzTableDataList(queryRes.data.data.gzList)

        } else if (queryRes.data && queryRes.data.length > 200 && queryRes.data.indexOf("Please sign in") !== -1) {
            window.location.href = "/login"
        }
    }

    const goTxyLogin = () => {
        const w = window.open('_black') //这里是打开新窗口
        w.location.href = 'https://cloud.tencent.com/login' //跳转到腾讯页面
    }

    const goConsolePage = (data) => {
        const w = window.open('_black') //这里是打开新窗口
        if (data === "gz") {
            w.location.href = scene6GzConflictUrl; //跳转到广州监控页面
        } else if (data === "sh") {
           //w.location.href = 'https://console.cloud.tencent.com/dts/replication/detail/conflict_alert?rid=1&jobId=sync-2kr97ikm'
            w.location.href = scene6ShConflictUrl;
        }else if(data==="cls"){
            w.location.href=scene4ClsConsoleUrl;
        }else if(data==='prometheus'){
            w.location.href=scene4PrometheusUrl;
        }else if(data==='clsKong'){
            w.location.href=scene4ClsKongUrl;
        }else if(data==='grafana'){
            w.location.href=scene4BookShopUrl;

        }

    }

    const writeMysqlData = async (regionType, scene) => {
        if("deleteData"===regionType){
            setLoadingDataRecover(true);
        }
        const res = await insertMysqlData(regionType, scene);
        if (res.data.success) {
            message.success({
                content: res.data.msg
            })
        } else if (res.data && res.data.length > 200 && res.data.indexOf("Please sign in") !== -1) {
            window.location.href = "/login"
        } else {
            message.error({
                content: res.data.msg
            })
        }
        if("deleteData"===regionType){
            setLoadingDataRecover(false);
        }
        const queryRes = await queryAcrossDoubleLiveMysqlDataLogTable()
        if (queryRes.data.success) {
            setShTableDataList(queryRes.data.data.shList)
            setGzTableDataList(queryRes.data.data.gzList)

        }
    }

    useEffect(() => {
        (async () => {
            await initData();

            const res = await queryAcrossDoubleLiveMysqlDataLogTable();
            if (res.data.success) {
                setShTableDataList(res.data.data.shList);
                setGzTableDataList(res.data.data.gzList);

            } else if (res.data && res.data.length > 200 && res.data.indexOf("Please sign in") !== -1) {
                window.location.href = "/login"
            }

            const sceneRes = await  querySceneConfig(8,3)
            if(sceneRes.data.success){
                let datas = sceneRes.data.data;
                if(null!=datas){
                    datas.map(function (config){
                        if(config.configKey==="across_cloud_dns_pod_url"){
                            setDnsPodUrl(config.configValue);
                        }
                        else if(config.configKey==="across_cloud_sh_kong_url"){
                            setShKongUrl(config.configValue);
                        }
                        else if(config.configKey==="across_cloud_gz_kong_url"){
                            setGzKongUrl(config.configValue);
                        }
                        else if(config.configKey==="across_cloud_sh_mysql_url"){
                            setShMysqlUrl(config.configValue);
                        }
                        else if(config.configKey==="across_cloud_gz_mysql_url"){
                            setGzMysqlUrl(config.configValue);
                        }
                        else if(config.configKey==="across_cloud_scene3_right_url"){
                            setScene3RightUrl(config.configValue);
                        }
                        else if(config.configKey==="across_cloud_scene4_right_url"){
                            setScene4RightUrl(config.configValue);
                        }
                        else if(config.configKey==="across_cloud_scene4_cls_console_url"){
                            setScene4ClsConsoleUrl(config.configValue);
                        }
                        else if(config.configKey==="across_cloud_scene4_cls_kong_url"){
                            setScene4ClsKongUrl(config.configValue);
                        }
                        else if(config.configKey==="across_cloud_scene4_prometheus_url"){
                            setScene4PrometheusUrl(config.configValue);
                        }
                        else if(config.configKey==="across_cloud_scene4_book_shop_url"){
                            setScene4BookShopUrl(config.configValue);
                        }
                        else if(config.configKey==="across_cloud_scene6_gz_url"){
                            setScene6GzConflictUrl(config.configValue);
                        }
                        else if(config.configKey==="across_cloud_scene6_sh_url"){
                            setScene6ShConflictUrl(config.configValue);
                        }
                    })
                }
            }

        })()

    }, [])
    return (
        <LayoutContent>
            <LayoutContent.Header title='跨云双活操作'>
                <Button type="pay" onClick={() => recoverAcrossCloud()} loading={isLoadingSceneRecover}>场景复原</Button>
                <Button type="pay" onClick={() => writeMysqlData("deleteData")}
                        loading={isLoadingDataRecover}>数据恢复</Button>
                <Button type="weak" style={{border: 0}}>
                    <span style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        marginLeft: "10px"
                    }}> 系统状态：{doubleLiveStatus}&nbsp;|</span>
                    <span style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        marginLeft: "10px"
                    }}> D监控：{dnsStatus} &nbsp;</span>
                </Button>
                <Button style={{float: "right"}} onClick={() => goTxyLogin()} type="weak">登录腾讯云</Button>
                <Button style={{float: "right"}} onClick={() => refreshPage()} type="weak">刷新</Button>
                <Button style={{float: "right"}} onClick={() => goYunShuCheng()}
                        type="weak">跳转云书城</Button>
            </LayoutContent.Header>

            <div style={{width: "100%", height: "100%"}}>

                    <div id="leftDivId" style={{width: "38%", float: "left", height: "720px",overflow: "hidden"}}>
                        <div className="title"
                             style={{marginLeft: "15px", fontWeight: "bold", marginBottom: "5px"}}>场景简介
                        </div>
                        <Card id="leftCardId" style={{height: "750px", marginLeft: "5px"}}>
                            <Card.Body>
                                <Tabs tabs={tabs} onActive={(tab) => tabsChange(tab)}>

                                    <TabPanel id="scene1" >
                                        <Card.Body>场景名：双活运行</Card.Body>
                                        <Card.Body>场景简介：演示双活运行的正常流程。业务请求通过DNSPOD和云原生网关，根据user id的奇偶分发至两个地域的集群上，每个地域的应用闭环处理该业务请求，处理过程中读写本地域的Redis缓存和MySQL数据库；双地域上的MySQL数据库通过DTS双向同步数据。
                                        </Card.Body>

                                        <Card.Body style={{fontWeight: "bold"}}>双活状态</Card.Body>
                                        <Card.Body>
                                            <div className="acrossCloudDoubleLiveTable">

                                                <Table
                                                    verticalTop
                                                    records={
                                                        doubleLiveList
                                                    }
                                                    recordKey="productName"
                                                    bordered='all'
                                                    rowClassName={doubleLiveList => {
                                                        if (doubleLiveList.valueType === "当前") {
                                                            return "bg-gray"
                                                        }
                                                    }}
                                                    columns={[
                                                        {
                                                            key: "productName",
                                                            header: "地域",
                                                            align: 'center',
                                                            width: "20%",
                                                            render: doubleLive => {
                                                                if (doubleLive.productName === "MySQL数据库同步") {
                                                                    return <>
                                                                        <p>MySQL</p>
                                                                        <p>数据库同步</p>
                                                                    </>
                                                                } else {
                                                                    return doubleLive.productName
                                                                }
                                                            }
                                                        },
                                                        {
                                                            key: "valueType",
                                                            header: "类型",
                                                            align: 'center',
                                                            width: "10%"
                                                        },
                                                        {
                                                            key: "shValue",
                                                            header: "腾讯云上海",
                                                            align: 'center',
                                                            width: "25%",
                                                            render: doubleLive => {
                                                                if (doubleLive.productName === "Kong网关") {
                                                                    if (doubleLive.valueType === "默认") {
                                                                        return <span>开启路由策略
                                                                          <Bubble content={doubleLive.shValue}
                                                                                  type="primary">
                                                                           <Icon type="info"/>
                                                                             </Bubble></span>;

                                                                    } else {
                                                                        return <span>{doubleLive.shValue}
                                                                            <Bubble content={doubleLive.shContent}>
                                                                           <Icon type="info"/>
                                                                             </Bubble></span>;
                                                                    }

                                                                }
                                                                // else if(doubleLive.productName === "MySQL数据库同步"){
                                                                //     return <span>{doubleLive.shValue}
                                                                //         <Bubble content="如果数据库无故障，数据同步继续；如果数据库有故障中断，数据同步将中断，待数据库恢复后数据同步将自动恢复。">
                                                                //            <Icon type="info"/>
                                                                //              </Bubble></span>;
                                                                // }

                                                                return doubleLive.shValue;
                                                            }
                                                        },


                                                        {
                                                            key: "gzValue",
                                                            header: "腾讯云广州",
                                                            align: 'center',
                                                            width: "25%",
                                                            render: doubleLive => {
                                                                if (doubleLive.productName === "Kong网关") {
                                                                    if (doubleLive.valueType === "默认") {
                                                                        return <span>开启路由策略
                                                                          <Bubble content={doubleLive.gzValue}>
                                                                           <Icon type="info"/>
                                                                             </Bubble></span>;

                                                                    } else {
                                                                        return <span>{doubleLive.gzValue}
                                                                            <Bubble content={doubleLive.gzContent}>
                                                                           <Icon type="info"/>
                                                                             </Bubble></span>;
                                                                    }
                                                                }
                                                                // else if(doubleLive.productName === "MySQL数据库同步"){
                                                                //     return <span>{doubleLive.gzValue}
                                                                //         <Bubble content="如果数据库无故障，数据同步继续；如果数据库有故障中断，数据同步将中断，待数据库恢复后数据同步将自动恢复。">
                                                                //            <Icon type="info"/>
                                                                //              </Bubble></span>;
                                                                // }

                                                                return doubleLive.gzValue;
                                                            }
                                                        },
                                                        {
                                                            key: "setting",
                                                            header: "查看配置",
                                                            align: 'center',
                                                            width: "25%",
                                                            render: function getFour(data) {
                                                                return (
                                                                    <>

                                                                        <button style={{
                                                                            float: "center",
                                                                            marginLeft: "5px",
                                                                            marginTop: "5px"
                                                                        }} onClick={() => querySh(data.productName)}
                                                                                type="primary">上海
                                                                        </button>
                                                                        <button style={{
                                                                            float: "center",
                                                                            marginLeft: "5px",
                                                                            marginTop: "5px"
                                                                        }} onClick={() => queryGz(data.productName)}
                                                                                type="primary">广州
                                                                        </button>
                                                                    </>
                                                                )

                                                            }
                                                        },
                                                    ]}

                                                    addons={[
                                                        mergeable({

                                                            headColSpan: columnIndex => {
                                                                switch (columnIndex) {
                                                                    case 0:
                                                                        return 2;
                                                                    case 1:
                                                                        return 0;
                                                                }
                                                                return 1;
                                                            },
                                                            rowSpan: (columnIndex, recordIndex) => {
                                                                if (columnIndex === 0 || columnIndex === 4) {
                                                                    if (recordIndex === 0 || recordIndex === 2 || recordIndex === 4) {
                                                                        return 2;
                                                                    }
                                                                    return 0;
                                                                }
                                                                return 1;
                                                            },
                                                        }),
                                                    ]}
                                                />
                                            </div>
                                        </Card.Body>
                                        <Card.Body style={{fontWeight: "bold"}}>用户下单</Card.Body>
                                        <Card.Body>

                                            <Card>
                                                <Card.Body>
                                                    <div style={{marginBottom: "10px"}}>
                                                        <span>设置用户ID：</span>
                                                        <Input
                                                            placeholder="请输入用户ID"
                                                            maxLength="11" size="s"
                                                            id="acrossCloudUserId"
                                                            name="acrossCloudUserId"/>

                                                        <Button onClick={() => purchase(books)} style={{marginLeft: "10px"}}
                                                                type="pay" loading={isLoadingPurchase}>下单</Button>
                                                    </div>
                                                </Card.Body>
                                                <Card.Body>选择书本：</Card.Body>
                                                <Card.Body>
                                                    <section>
                                                        <Checkbox.Group value={books} onChange={value => setBooks(value)}>

                                                        <div style={{width:"25%",float:"left"}}>

                                                        <Checkbox name="高性能MySQL" defaultValue={"true"} style={{marginLeft: "20px"}}>
                                                            <img style={{width: "100%", height: "100%"}}
                                                                 src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/qcbm-front/books/9787121198854/cover.jpeg"
                                                                 alt={"9787121198854"}/></Checkbox>
                                                        </div>
                                                        <div style={{width:"25%",float:"left"}}>
                                                        <Checkbox name="React全家桶" style={{marginLeft: "20px"}}><img
                                                            style={{width: "100%", height: "100%"}}
                                                            src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/qcbm-front/books/9787115551382/cover.jpeg"
                                                            alt={"9787115551382"}/></Checkbox>
                                                        </div>
                                                        <div style={{width:"25%",float:"left"}}>
                                                        <Checkbox name="深入浅出Node.js" style={{marginLeft: "10px"}}><img
                                                            style={{width: "100%", height: "100%"}}
                                                            src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/qcbm-front/books/9787115335500/cover.jpeg"
                                                            alt={"9787115335500"}/></Checkbox>
                                                        </div>
                                                        <div style={{width:"25%",float:"left"}}>
                                                        <Checkbox name="Redis 实战" style={{marginLeft: "20px"}}><img
                                                            style={{width: "100%", height: "100%"}}
                                                            src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/qcbm-front/books/9787115402844/cover.jpeg"
                                                            alt={"9787115402844"}/></Checkbox>
                                                        </div>
                                                        {/*<Checkbox name="Kafka技术内幕" style={{marginLeft: "20px"}}><img*/}
                                                        {/*    style={{width: "80px", height: "100px"}}*/}
                                                        {/*    src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/qcbm-front/books/9787115469380/cover.jpeg"*/}
                                                        {/*    alt={"9787115469380"}/></Checkbox>*/}
                                                        {/*<Checkbox name="Nginx高性能Web服务器详解" style={{marginLeft: "20px"}}><img*/}
                                                        {/*    style={{width: "80px", height: "100px"}}*/}
                                                        {/*    src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/qcbm-front/books/9787121215186/cover.jpeg"*/}
                                                        {/*    alt={"9787121215186"}/></Checkbox>*/}
                                                    </Checkbox.Group>
                                                        <p style={{marginBottom: "10px", marginTop: "5px"}}>
                                                            已选中 {books.length} 本书：{books.join(", ")}

                                                        </p>

                                                    </section>


                                                </Card.Body>
                                            </Card>
                                        </Card.Body>

                                    </TabPanel>
                                    <TabPanel id="scene2">
                                        <Card.Body>场景名：模拟故障和双活切换 </Card.Body>
                                        <Card.Body>场景简介：演示模拟腾讯云上海出现故障，进行双活切换后，所有的业务请求均调度至腾讯云广州，所有数据均写入腾讯云广州的数据库。</Card.Body>

                                        <Card.Body style={{fontWeight: "bold"}}>双活状态</Card.Body>
                                        <Card.Body>
                                            <div className="acrossCloudDoubleLiveTable">

                                                <Table
                                                    verticalTop
                                                    records={
                                                        doubleLiveList
                                                    }
                                                    recordKey="productName"
                                                    bordered='all'
                                                    rowClassName={doubleLiveList => {
                                                        if (doubleLiveList.valueType === "当前") {
                                                            return "bg-gray"
                                                        }
                                                    }}
                                                    columns={[
                                                        {
                                                            key: "productName",
                                                            header: "地域",
                                                            align: 'center',
                                                            width: "20%",
                                                            render: doubleLive => {
                                                                if (doubleLive.productName === "MySQL数据库同步") {
                                                                    return <>
                                                                        <p>MySQL</p>
                                                                        <p>数据库同步</p>
                                                                    </>
                                                                } else {
                                                                    return doubleLive.productName
                                                                }
                                                            }
                                                        },
                                                        {
                                                            key: "valueType",
                                                            header: "类型",
                                                            align: 'center',
                                                            width: "10%"
                                                        },
                                                        {
                                                            key: "shValue",
                                                            header: "腾讯云上海",
                                                            align: 'center',
                                                            width: "25%",
                                                            render: doubleLive => {
                                                                if (doubleLive.productName === "Kong网关") {
                                                                    if (doubleLive.valueType === "默认") {
                                                                        return <span>开启路由策略
                                                                          <Bubble content={doubleLive.shValue}>
                                                                           <Icon type="info"/>
                                                                             </Bubble></span>;

                                                                    } else {
                                                                        return <span>{doubleLive.shValue}
                                                                            <Bubble content={doubleLive.shContent}>
                                                                           <Icon type="info"/>
                                                                             </Bubble></span>;
                                                                    }

                                                                }
                                                                else if(doubleLive.productName === "MySQL数据库同步" && doubleLive.valueType === "当前"){
                                                                    if(doubleLiveStatus==="双活切换"){
                                                                    return <span>{doubleLive.shValue}
                                                                        <Bubble content="如果数据库无故障，数据同步继续；如果数据库有故障中断，数据同步将中断，待数据库恢复后数据同步将自动恢复。">
                                                                           <Icon type="info"/>
                                                                             </Bubble></span>;
                                                                    }

                                                                }

                                                                return doubleLive.shValue;
                                                            }
                                                        },


                                                        {
                                                            key: "gzValue",
                                                            header: "腾讯云广州",
                                                            align: 'center',
                                                            width: "25%",
                                                            render: doubleLive => {
                                                                if (doubleLive.productName === "Kong网关") {
                                                                    if (doubleLive.valueType === "默认") {
                                                                        return <span>开启路由策略
                                                                          <Bubble content={doubleLive.gzValue}>
                                                                           <Icon type="info"/>
                                                                             </Bubble></span>;

                                                                    } else {
                                                                        return <span>{doubleLive.gzValue}
                                                                            <Bubble content={doubleLive.gzContent}>
                                                                           <Icon type="info"/>
                                                                             </Bubble></span>;
                                                                    }
                                                                }
                                                                else if(doubleLive.productName === "MySQL数据库同步" && doubleLive.valueType === "当前"){
                                                                    if(doubleLiveStatus==="双活切换") {
                                                                        return <span>{doubleLive.gzValue}
                                                                            <Bubble
                                                                                content="如果数据库无故障，数据同步继续；如果数据库有故障中断，数据同步将中断，待数据库恢复后数据同步将自动恢复。">
                                                                           <Icon type="info"/>
                                                                             </Bubble></span>;
                                                                    }
                                                                }

                                                                return doubleLive.gzValue;
                                                            }
                                                        },
                                                        {
                                                            key: "setting",
                                                            header: "查看配置",
                                                            align: 'center',
                                                            width: "25%",
                                                            render: function getFour(data) {
                                                                return (
                                                                    <>

                                                                        <button style={{
                                                                            float: "center",
                                                                            marginLeft: "5px",
                                                                            marginTop: "5px"
                                                                        }} onClick={() => querySh(data.productName)}
                                                                                type="primary">上海
                                                                        </button>
                                                                        <button style={{
                                                                            float: "center",
                                                                            marginLeft: "5px",
                                                                            marginTop: "5px"
                                                                        }} onClick={() => queryGz(data.productName)}
                                                                                type="primary">广州
                                                                        </button>
                                                                    </>
                                                                )

                                                            }
                                                        },
                                                    ]}

                                                    addons={[
                                                        mergeable({

                                                            headColSpan: columnIndex => {
                                                                switch (columnIndex) {
                                                                    case 0:
                                                                        return 2;
                                                                    case 1:
                                                                        return 0;
                                                                }
                                                                return 1;
                                                            },
                                                            rowSpan: (columnIndex, recordIndex) => {
                                                                if (columnIndex === 0 || columnIndex === 4) {
                                                                    if (recordIndex === 0 || recordIndex === 2 || recordIndex === 4) {
                                                                        return 2;
                                                                    }
                                                                    return 0;
                                                                }
                                                                return 1;
                                                            },
                                                        }),
                                                    ]}
                                                />
                                            </div>
                                        </Card.Body>
                                        <Card.Body>
                                            <Button  type="primary"
                                                    onClick={() => faultShow()} loading={isLoadingFault}>模拟故障</Button>
                                            <Button style={{marginLeft: "20px"}} type="primary"
                                                    onClick={() => switchDouble()}
                                                    loading={isLoadingSwitch}>双活切换</Button>
                                            <Button style={{marginLeft: "20px"}}
                                                    type="primary" onClick={() => recoverDouble()}
                                                    loading={isLoadingRecover}>双活复原</Button></Card.Body>


                                        <Card.Body>
                                            <Card.Body style={{fontWeight: "bold"}}>用户下单</Card.Body>
                                           <Card>

                                                <Card.Body>
                                                    <div>

                                                        <span>设置用户ID：</span>
                                                        <Input
                                                            placeholder="请输入用户ID"
                                                            maxLength="11" size="s"
                                                            id="acrossCloudUserId"
                                                            name="acrossCloudUserId"/>

                                                        <Button onClick={() => purchase(books)} style={{marginLeft: "10px"}}
                                                                type="pay" loading={isLoadingPurchase}>下单</Button>
                                                    </div>
                                                </Card.Body>
                                                <Card.Body>选择书本：</Card.Body>
                                                <Card.Body>
                                                    <section>
                                                        <Checkbox.Group value={books} onChange={value => setBooks(value)}>

                                                            <div style={{width:"25%",float:"left"}}>

                                                                <Checkbox name="高性能MySQL" defaultValue={"true"} style={{marginLeft: "20px"}}>
                                                                    <img style={{width: "100%", height: "100%"}}
                                                                         src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/qcbm-front/books/9787121198854/cover.jpeg"
                                                                         alt={"9787121198854"}/></Checkbox>
                                                            </div>
                                                            <div style={{width:"25%",float:"left"}}>
                                                                <Checkbox name="React全家桶" style={{marginLeft: "20px"}}><img
                                                                    style={{width: "100%", height: "100%"}}
                                                                    src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/qcbm-front/books/9787115551382/cover.jpeg"
                                                                    alt={"9787115551382"}/></Checkbox>
                                                            </div>
                                                            <div style={{width:"25%",float:"left"}}>
                                                                <Checkbox name="深入浅出Node.js" style={{marginLeft: "10px"}}><img
                                                                    style={{width: "100%", height: "100%"}}
                                                                    src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/qcbm-front/books/9787115335500/cover.jpeg"
                                                                    alt={"9787115335500"}/></Checkbox>
                                                            </div>
                                                            <div style={{width:"25%",float:"left"}}>
                                                                <Checkbox name="Redis 实战" style={{marginLeft: "20px"}}><img
                                                                    style={{width: "100%", height: "100%"}}
                                                                    src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/qcbm-front/books/9787115402844/cover.jpeg"
                                                                    alt={"9787115402844"}/></Checkbox>
                                                            </div>
                                                            {/*<Checkbox name="Kafka技术内幕" style={{marginLeft: "20px"}}><img*/}
                                                            {/*    style={{width: "80px", height: "100px"}}*/}
                                                            {/*    src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/qcbm-front/books/9787115469380/cover.jpeg"*/}
                                                            {/*    alt={"9787115469380"}/></Checkbox>*/}
                                                            {/*<Checkbox name="Nginx高性能Web服务器详解" style={{marginLeft: "20px"}}><img*/}
                                                            {/*    style={{width: "80px", height: "100px"}}*/}
                                                            {/*    src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/qcbm-front/books/9787121215186/cover.jpeg"*/}
                                                            {/*    alt={"9787121215186"}/></Checkbox>*/}
                                                        </Checkbox.Group>
                                                        <p style={{marginBottom: "10px", marginTop: "5px"}}>
                                                            已选中 {books.length} 本书：{books.join(", ")}

                                                        </p>

                                                    </section>


                                                </Card.Body>
                                           </Card>
                                        </Card.Body>
                                    </TabPanel>
                                    <TabPanel id="scene3">
                                        <Card.Body>场景名：双集群管理和应用双发布 </Card.Body>
                                        <Card.Body>场景简介：运行在腾讯云上海的自建K8S集群和腾讯云广州的TKE集群统一注册至腾讯云云原生分布式中心TDCC。 TDCC指定分发策略，将Q云书城应用分发和部署在两个集群上。原先用户需要调用每个集群的K8S API管理集群，现在仅需统一调用TDCC的K8S API，即可一次性管理两个集群。</Card.Body>
                                        <Card.Body style={{marginTop:"50px"}}>
                                            <img alt="场景1动图" style={{
                                                marginTop: "40px",
                                                marginBottom: "40px",
                                                width: "100%",
                                                height: "100%"
                                            }}
                                                // src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/across_cloud_double_live/latest/across_cloud_scene1.gif"
                                                // src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/across_cloud_double_live/latest/across_cloud_scene1.png"
                                                 src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/across_cloud_double_live/latest/scene1_left.png"
                                            />
                                        </Card.Body>

                                    </TabPanel>
                                    <TabPanel id="scene4" >
                                        <Card.Body>场景名：统一监控运维 </Card.Body>
                                        <Card.Body>场景简介：演示为双集群开启监控、日志、审计等功能，实现双集群统一监控运维。</Card.Body>
                                        <Card.Body>跨云统一日志采集：基于腾讯云原生日志服务，部署在双集群上的Q云书城应用将日志统一投递至腾讯云CLS的一个日志主题下，实现跨云跨地域的日志采集、投递、转储分析。</Card.Body>
                                        <Card.Body>跨云统一监控：基于腾讯云原生监控服务，实现多集群的统一监控，完全兼容原生Prometheus配置和接口。统一的云原生Prometheus实例监控双集群，并且通过Agent监控两个云原生Kong网关；关联多个集群，通过全局标记，对不同集群指标进行标识；通过Grafana统一展示监控数据。</Card.Body>
                                        <Card.Body><img alt="场景4动图"
                                                        style={{marginTop: "30px", width: "100%", height: "100%"}}
                                                        src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/across_cloud_double_live/latest/across_cloud_scene2.gif"/></Card.Body>


                                        <Card.Body >
                                            <button style={{marginLeft: "10px",marginTop: "30px"}} onClick={() => goConsolePage("cls")}>CLS控制台（应用）</button>
                                            <button style={{marginLeft: "10px",marginTop: "30px"}} onClick={() => goConsolePage("clsKong")}>CLS控制台（云原生网关）</button>
                                            <button style={{marginLeft: "10px",marginTop: "30px"}} onClick={() => goConsolePage("prometheus")}>Promethus控制台</button>
                                            <button style={{marginLeft: "10px",marginTop: "30px"}} onClick={() => goConsolePage("grafana")}>云书城监控视图
                                            </button>
                                        </Card.Body>

                                    </TabPanel>
                                    <TabPanel id="scene5">
                                        <Card.Body>场景名：数据双向同步 </Card.Body>
                                        <Card.Body>场景简介：上海地域的自建MySQL数据库与广州地域的腾讯云CDB for
                                            MySQL数据库之间采用DTS构建双向同步。双向同步通过创建两个单向同步任务来构建双向的拓扑，支持在同步过程中，两个数据库实例同时写入数据。为了避免数据重复，需要同步的表具有主键或者非空唯一键。两个源端负责更新（增、删、改）不同主键的数据。</Card.Body>
                                        <Card.Body>演示效果：当前场景演示双向同步的order表，order表采用id作为主键。当一侧数据库已经写入某个id的order数据后，向另一侧数据库写入同样id的order数据将会显示错误。</Card.Body>
                                        <Card.Body><img alt="场景5图片" style={{width: "100%", height: "100%"}}
                                                        src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/across_cloud_double_live/latest/s5.png"/></Card.Body>

                                        <Card.Body>

                                            <div className="acrossCloudScene5Table"
                                                 style={{display: "flex", whiteSpace: "pre-wrap"}}>

                                                <Table
                                                    verticalTop
                                                    records={
                                                        scene5Data
                                                    }
                                                    recordKey="productName"
                                                    bordered='all'
                                                    columns={[
                                                        {
                                                            key: "conflictType",
                                                            header: "",
                                                            align: 'center',
                                                            width: '15%',
                                                            render: scene5Data => {
                                                                return <>
                                                                    <br/>
                                                                    <p>{scene5Data.conflictType}</p>
                                                                    <br/>
                                                                </>
                                                            }

                                                        },
                                                        {
                                                            key: "shData",
                                                            header: () => (
                                                                <>
                                                                    <span style={{marginRight: "20px"}}>上海数据库</span>
                                                                    {/*<button*/}
                                                                    {/*    onClick={() => goConsolePage("sh")}>展示冲突告警*/}
                                                                    {/*</button>*/}
                                                                </>),
                                                            align: 'left',
                                                            width: '40%',
                                                            render: scene5Data => {
                                                                if (scene5Data.conflictType === '数据1') {
                                                                    return <>
                                                                        <p>订单ID: 11</p>
                                                                        <p>图书名称:Redis实战</p>
                                                                        <p>用户名: 用户A
                                                                        <button
                                                                            style={{marginLeft: "5px"}}
                                                                            onClick={() => writeMysqlData("sh1", "5")}>写入</button>
                                                                        </p>
                                                                    </>
                                                                } else if(scene5Data.conflictType === '数据2') {
                                                                    return <>
                                                                        <p>订单ID: 22</p>
                                                                        <p>图书名称:Redis实战</p>
                                                                        <p>用户名: 用户B

                                                                            <button style={{marginLeft: "5px"}}
                                                                                    onClick={() => writeMysqlData("sh2", "5")}>写入
                                                                            </button>
                                                                        </p>
                                                                    </>
                                                                }

                                                            }
                                                        },
                                                        {
                                                            key: "gzData",
                                                            header: () => (
                                                                <>
                                                                    <span style={{marginRight: "20px"}}>广州数据库</span>
                                                                    {/*<button*/}
                                                                    {/*    onClick={() => goConsolePage("gz")}>展示冲突告警*/}
                                                                    {/*</button>*/}
                                                                </>),
                                                            align: 'left',
                                                            width: '40%',
                                                            render: scene5Data => {
                                                                if (scene5Data.conflictType === '数据1') {
                                                                    return <>
                                                                        <p>订单ID: 11</p>
                                                                        <p>图书名称：Kafka技术内幕</p>
                                                                        <p>用户名:用户A
                                                                            <button
                                                                                style={{marginLeft: "5px"}}
                                                                                onClick={() => writeMysqlData("gz1", "5")}>写入</button>
                                                                        </p>
                                                                    </>
                                                                } else if(scene5Data.conflictType === '数据2') {
                                                                    return <>
                                                                        <p>订单ID：22</p>
                                                                        <p>图书名称：Kafka技术内幕</p>
                                                                        <p>用户名: 用户B
                                                                            <button style={{marginLeft: "5px"}}
                                                                                    onClick={() => writeMysqlData("gz2", "5")}>写入
                                                                            </button>
                                                                        </p>
                                                                    </>
                                                                }
                                                            }
                                                        }
                                                    ]}


                                                />
                                            </div>

                                        </Card.Body>
                                    </TabPanel>
                                    <TabPanel id="scene6">

                                        <Card.Body>场景名：数据冲突 </Card.Body>
                                        <Card.Body>场景简介：演示在数据双向同步的场景下，数据冲突的处理和告警。在双向同步的场景下，可能因为两侧应用的异常导致数据冲突，运营人员需要针对该异常进行冲突处理。</Card.Body>
                                        <Card.Body>演示效果：数据冲突时DTS可以告警和处理。</Card.Body>
                                        {/*<Card.Body>演示效果：在跨云双活场景下，偶发由于网络延迟或中断导致的单向DTS链路中断，可能导致应用向两侧数据库写入相同id的数据，反向DTS链路需要针对此数据冲突现象告警及处理。</Card.Body>*/}


                                        <Card.Body>
                                            <Tabs tabs={tabs1}>
                                                <TabPanel id="scene6_1">
                                                    <Card.Body>冲突忽略：暂时中断广州到上海的DTS链路，广州库先写入id=123的购买数据，上海库后写入id=123但是内容不同的购买数据，查看数据库发现广州库忽略了上海库的数据，两边数据不一致。</Card.Body>
                                                    <Card.Body> <img alt="场景6-1图"
                                                                     style={{width: "100%", height: "100%"}}
                                                                     src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/across_cloud_double_live/latest/scene6_001.png"/></Card.Body>
                                                    <Card.Body>
                                                        <div className="acrossCloudScene5Table"
                                                             style={{
                                                                 display: "flex",
                                                                 whiteSpace: "pre-wrap",
                                                                 marginBottom: "15px"
                                                             }}>
                                                            <Table
                                                                verticalTop
                                                                records={
                                                                    scene6IgnoreData
                                                                }
                                                                recordKey="productName"
                                                                bordered='all'

                                                                columns={[
                                                                    {
                                                                        key: "conflictType",
                                                                        header: "冲突类型",
                                                                        align: 'center',
                                                                        width: '15%',
                                                                        render: scene6IgnoreData => {
                                                                            return <>
                                                                                <br/>
                                                                                <p>{scene6IgnoreData.conflictType}</p>
                                                                                <br/>
                                                                            </>
                                                                        }

                                                                    },

                                                                    {
                                                                        key: "gzData",
                                                                        header: () => (
                                                                            <>
                                                                            <span
                                                                                style={{marginRight: "20px"}}>广州数据库</span>
                                                                                {/*<button*/}
                                                                                {/*    onClick={() => goConsolePage("gz")}>展示冲突告警*/}
                                                                                {/*</button>*/}
                                                                            </>),
                                                                        align: 'left',
                                                                        width: '42%',
                                                                        render: () => {
                                                                            return <>
                                                                                <p>订单ID: 123</p>
                                                                                <p>图书名称: React全家桶</p>
                                                                                <p>用户名: 书城用户
                                                                                    <button style={{marginLeft:"5px"}}
                                                                                        onClick={() => writeMysqlData("gzIgnore", "6")}>先写入
                                                                                    </button>
                                                                                </p>
                                                                            </>

                                                                        }
                                                                    }, {
                                                                        key: "shData",
                                                                        header: () => (
                                                                            <>
                                                                            <span
                                                                                style={{marginRight: "20px"}}>上海数据库</span>
                                                                                <button
                                                                                    onClick={() => goConsolePage("sh")}>展示冲突告警
                                                                                </button>
                                                                            </>
                                                                        ),
                                                                        align: 'left',
                                                                        width: '43%',
                                                                        render: () => {
                                                                            return <>
                                                                                <p>订单ID: 123</p>
                                                                                <p>图书名称: 高性能MySQL</p>
                                                                                <p>用户名: 书城用户
                                                                                    <button style={{marginLeft:"5px"}}
                                                                                        onClick={() => writeMysqlData("shIgnore", "6")}>后写入
                                                                                    </button>
                                                                                </p>
                                                                            </>
                                                                        }
                                                                    }
                                                                ]}
                                                            />
                                                        </div>

                                                    </Card.Body>


                                                </TabPanel>
                                                <TabPanel id="scene6_2">
                                                    <Card.Body>冲突覆盖：暂时中断上海到广州的DTS链路，上海库先写入id=1234的购买数据，广州库后写入id=1234但是内容不同的购买数据，查看数据库发现广州库的数据覆盖了上海库的数据，两边数据一致。</Card.Body>
                                                    <Card.Body> <img alt="场景6-2图"
                                                                     style={{width: "100%", height: "100%"}}
                                                                     src="https://qcbm-1305426035.cos.ap-guangzhou.myqcloud.com/console/across_cloud_double_live/latest/scene6_002.png"/></Card.Body>

                                                    <Card.Body>
                                                        <div className="acrossCloudScene5Table"
                                                             style={{
                                                                 display: "flex",
                                                                 whiteSpace: "pre-wrap",
                                                                 marginBottom: "15px"
                                                             }}>
                                                            <Table
                                                                verticalTop
                                                                records={
                                                                    scene6CoverData
                                                                }
                                                                recordKey="productName"
                                                                bordered='all'

                                                                columns={[
                                                                    {
                                                                        key: "conflictType",
                                                                        header: "冲突类型",
                                                                        align: 'center',
                                                                        width: '15%',
                                                                        render: scene6CoverData => {
                                                                            return <>
                                                                                <br/>
                                                                                <p>{scene6CoverData.conflictType}</p>
                                                                                <br/>
                                                                            </>
                                                                        }

                                                                    },
                                                                    {
                                                                        key: "shData",
                                                                        header: () => (
                                                                            <>
                                                                                <span
                                                                                    style={{marginRight: "20px"}}>上海数据库</span>
                                                                                {/*<button*/}
                                                                                {/*    onClick={() => goConsolePage("sh")}>展示冲突告警*/}
                                                                                {/*</button>*/}
                                                                            </>
                                                                        ),
                                                                        align: 'left',
                                                                        width: '42%',
                                                                        render: () => {

                                                                            return <>
                                                                                <p>订单ID: 1234</p>
                                                                                <p>图书名称：高性能MySQL</p>

                                                                                    <p>用户名: 书城用户
                                                                                    <button style={{marginLeft:"5px"}}
                                                                                        onClick={() => writeMysqlData("shCover", "6")}>先写入
                                                                                    </button>
                                                                                </p>
                                                                            </>
                                                                        }
                                                                    },
                                                                    {
                                                                        key: "gzData",
                                                                        header: () => (
                                                                            <>
                                                                                <span
                                                                                    style={{marginRight: "20px"}}>广州数据库</span>
                                                                                <button
                                                                                    onClick={() => goConsolePage("gz")}>展示冲突告警
                                                                                </button>
                                                                            </>),
                                                                        align: 'left',
                                                                        width: '43%',
                                                                        render: () => {
                                                                            return <>
                                                                                <p>订单ID: 1234</p>
                                                                                <p>图书名称：React全家桶</p>
                                                                                <p>用户名: 书城用户
                                                                                    <button style={{marginLeft:"5px"}}
                                                                                        onClick={() => writeMysqlData("gzCover", "6")}>后写入
                                                                                    </button>
                                                                                </p>
                                                                            </>
                                                                        }
                                                                    }

                                                                ]}
                                                            />
                                                        </div>

                                                    </Card.Body>


                                                </TabPanel>
                                            </Tabs>
                                        </Card.Body>
                                    </TabPanel>
                                </Tabs>

                            </Card.Body>
                        </Card>
                    </div>
                    <div id="rightFrameId" style={{
                        width: "60%",
                        marginLeft: "5px",
                        float: "left",
                        height: "720px",
                        backgroundColor: "#f3f4f7"
                    }}>
                        <Card style={{height:"695px", display: "block",marginTop:"25px"}} id="scene12ImageId">
                            <Card.Body >
                                <img
                                    src={sceneImageUrl}
                                    alt="" style={{ width: '100%', height: '100%',marginTop:"30px"}}
                                    />

                            </Card.Body>


                        </Card>

                        <iframe style={{marginTop: "25px", display: "none"}}
                                src={scene3RightUrl}
                                width="100%" height="100%" frameBorder="12" id="iframeScene3Id"/>

                        <iframe style={{display: "none"}}
                                src={scene4RightUrl}
                                width="100%" height="100%%" frameBorder="12" id="iframeScene4Id1"/>


                        <div id="scene5RightId" style={{display: "none", overflow: "scroll"}}>
                            <p style={{
                                width: "100%",
                                fontWeight: "bold",
                                marginTop: "25px",
                                marginBottom: "17px"
                            }}>查看MySQL数据库详细数据<Button onClick={() => refreshMysqlData()} type="primary" style={{
                                float: "right",
                                marginBottom: "5px",
                                marginRight: "15px"
                            }}>刷新</Button></p>
                            <div style={{width: "49%", float: "left"}}>

                                <div className="iframeAcrossCloudTitle">
                                    上海数据库
                                </div>
                                <div>
                                    <Table
                                        verticalTop
                                        records={
                                            shTableDataList
                                        }
                                        // rowClassName={shTableDataList => {
                                        //     if (shTableDataList.id === "123" || shTableDataList.id === "1234") {
                                        //         return "bg-green"
                                        //     }
                                        // }}
                                        recordKey="id"
                                        bordered='all'
                                        columns={[
                                            {
                                                key: "id",
                                                header: "订单ID",
                                                align: 'center',
                                                width: "22%",
                                                render: shTableDataList => {
                                                    if (shTableDataList.id === "11" || shTableDataList.id === "22") {
                                                        return <span className="font-color">{shTableDataList.id}</span>
                                                    } else {
                                                        return <span>{shTableDataList.id}</span>
                                                    }
                                                }
                                            },
                                            {
                                                key: "userName",
                                                header: "用户名",
                                                align: 'center',
                                                width: "15%",
                                                render: shTableDataList => {
                                                    if (shTableDataList.id === "11" || shTableDataList.id === "22") {
                                                        return <span
                                                            className="font-color">{shTableDataList.userName}</span>
                                                    } else {
                                                        return <span>{shTableDataList.userName}</span>
                                                    }
                                                }
                                            },
                                            {
                                                key: "bookTitle",
                                                header: "图书名称",
                                                align: 'center',
                                                width: "25%",
                                                render: shTableDataList => {
                                                    if (shTableDataList.id === "11" || shTableDataList.id === "22") {
                                                        return <span
                                                            className="font-color">{shTableDataList.bookTitle}</span>
                                                    } else {
                                                        return <span>{shTableDataList.bookTitle}</span>
                                                    }
                                                }
                                            },


                                            {
                                                key: "purchaseDate",
                                                header: "购买时间",
                                                align: 'center',
                                                width: "33%",
                                                render: shTableDataList => {
                                                    if (shTableDataList.id === "11" || shTableDataList.id === "22") {
                                                        return <span
                                                            className="font-color">{shTableDataList.purchaseDate}</span>
                                                    } else {
                                                        return <span>{shTableDataList.purchaseDate}</span>
                                                    }
                                                }
                                            }

                                        ]}
                                    />
                                </div>
                            </div>
                            <div style={{width: "49%", float: "left", marginLeft: "5px"}}>

                                <div className="iframeAcrossCloudTitle">
                                    广州数据库
                                </div>
                                <div>
                                    <Table
                                        verticalTop
                                        records={
                                            gzTableDataList
                                        }
                                        // rowClassName={shTableDataList => {
                                        //     if (shTableDataList.id === "123" || shTableDataList.id === "1234") {
                                        //         return "bg-green"
                                        //     }
                                        // }}
                                        recordKey="id"
                                        bordered='all'
                                        columns={[
                                            {
                                                key: "id",
                                                header: "订单ID",
                                                align: 'center',
                                                width: "22%",
                                                render: shTableDataList => {
                                                    if (shTableDataList.id === "11" || shTableDataList.id === "22") {
                                                        return <span
                                                            className="font-color">{shTableDataList.id}</span>
                                                    } else {
                                                        return <span>{shTableDataList.id}</span>
                                                    }
                                                }
                                            },
                                            {
                                                key: "userName",
                                                header: "用户名",
                                                align: 'center',
                                                width: "15%",
                                                render: shTableDataList => {
                                                    if (shTableDataList.id === "11" || shTableDataList.id === "22") {
                                                        return <span
                                                            className="font-color">{shTableDataList.userName}</span>
                                                    } else {
                                                        return <span>{shTableDataList.userName}</span>
                                                    }
                                                }
                                            },
                                            {
                                                key: "bookTitle",
                                                header: "图书名称",
                                                align: 'center',
                                                width: "25%",
                                                render: shTableDataList => {
                                                    if (shTableDataList.id === "11" || shTableDataList.id === "22") {
                                                        return <span
                                                            className="font-color">{shTableDataList.bookTitle}</span>
                                                    } else {
                                                        return <span>{shTableDataList.bookTitle}</span>
                                                    }
                                                }
                                            },


                                            {
                                                key: "purchaseDate",
                                                header: "购买时间",
                                                align: 'center',
                                                width: "33%",
                                                render: shTableDataList => {
                                                    if (shTableDataList.id === "11" || shTableDataList.id === "22") {
                                                        return <span
                                                            className="font-color">{shTableDataList.purchaseDate}</span>
                                                    } else {
                                                        return <span>{shTableDataList.purchaseDate}</span>
                                                    }
                                                }
                                            }

                                        ]}
                                    />
                                </div>
                            </div>
                        </div>


                        <div id="scene6RightId" style={{display: "none", overflow: "scroll"}}>

                                    <p style={{
                                        width: "100%",
                                        fontWeight: "bold",
                                        marginTop: "25px",
                                        marginBottom: "17px"
                                    }}>查看MySQL数据库详细数据<Button onClick={() => refreshMysqlData()} type="primary" style={{
                                        float: "right",
                                        marginBottom: "5px",
                                        marginRight: "15px"
                                    }}>刷新</Button></p>

                                    <Card.Body>
                                    <div style={{width: "49.5%", float: "left"}}>

                                        <div className="iframeAcrossCloudTitle">
                                            上海数据库
                                        </div>
                                        <div>
                                            <Table
                                                verticalTop
                                                records={
                                                    shTableDataList
                                                }
                                                recordKey="id"
                                                bordered='all'
                                                columns={[
                                                    {
                                                        key: "id",
                                                        header: "订单ID",
                                                        align: 'center',
                                                        width: "22%",
                                                        render: shTableDataList => {
                                                            if (shTableDataList.id === "123" || shTableDataList.id === "1234") {
                                                                return <span
                                                                    className="font-color">{shTableDataList.id}</span>
                                                            } else {
                                                                return <span>{shTableDataList.id}</span>
                                                            }
                                                        }
                                                    },
                                                    {
                                                        key: "userName",
                                                        header: "用户名",
                                                        align: 'center',
                                                        width: "15%",
                                                        render: shTableDataList => {
                                                            if (shTableDataList.id === "123" || shTableDataList.id === "1234") {
                                                                return <span
                                                                    className="font-color">{shTableDataList.userName}</span>
                                                            } else {
                                                                return <span>{shTableDataList.userName}</span>
                                                            }
                                                        }
                                                    },
                                                    {
                                                        key: "bookTitle",
                                                        header: "图书名称",
                                                        align: 'center',
                                                        width: "25%",
                                                        render: shTableDataList => {
                                                            if (shTableDataList.id === "123" || shTableDataList.id === "1234") {
                                                                return <span
                                                                    className="font-color">{shTableDataList.bookTitle}</span>
                                                            } else {
                                                                return <span>{shTableDataList.bookTitle}</span>
                                                            }
                                                        }
                                                    },


                                                    {
                                                        key: "purchaseDate",
                                                        header: "购买时间",
                                                        align: 'center',
                                                        width: "33%",
                                                        render: shTableDataList => {
                                                            if (shTableDataList.id === "123" || shTableDataList.id === "1234") {
                                                                return <span
                                                                    className="font-color">{shTableDataList.purchaseDate}</span>
                                                            } else {
                                                                return <span>{shTableDataList.purchaseDate}</span>
                                                            }
                                                        }
                                                    }

                                                ]}
                                            />
                                        </div>
                                    </div>
                                    <div style={{width: "49.5%", float: "left", marginLeft: "0.5%"}}>

                                        <div className="iframeAcrossCloudTitle">
                                            广州数据库
                                        </div>
                                        <div>
                                            <Table
                                                verticalTop
                                                records={
                                                    gzTableDataList
                                                }
                                                // rowClassName={shTableDataList => {
                                                //     if (shTableDataList.id === "123" || shTableDataList.id === "1234") {
                                                //         return "bg-green"
                                                //     }
                                                // }}
                                                recordKey="id"
                                                bordered='all'
                                                columns={[
                                                    {
                                                        key: "id",
                                                        header: "订单ID",
                                                        align: 'center',
                                                        width: "22%",
                                                        render: shTableDataList => {
                                                            if (shTableDataList.id === "123" || shTableDataList.id === "1234") {
                                                                return <span
                                                                    className="font-color">{shTableDataList.id}</span>
                                                            } else {
                                                                return <span>{shTableDataList.id}</span>
                                                            }
                                                        }
                                                    },
                                                    {
                                                        key: "userName",
                                                        header: "用户名",
                                                        align: 'center',
                                                        width: "15%",
                                                        render: shTableDataList => {
                                                            if (shTableDataList.id === "123" || shTableDataList.id === "1234") {
                                                                return <span
                                                                    className="font-color">{shTableDataList.userName}</span>
                                                            } else {
                                                                return <span>{shTableDataList.userName}</span>
                                                            }
                                                        }
                                                    },
                                                    {
                                                        key: "bookTitle",
                                                        header: "图书名称",
                                                        align: 'center',
                                                        width: "25%",
                                                        render: shTableDataList => {
                                                            if (shTableDataList.id === "123" || shTableDataList.id === "1234") {
                                                                return <span
                                                                    className="font-color">{shTableDataList.bookTitle}</span>
                                                            } else {
                                                                return <span>{shTableDataList.bookTitle}</span>
                                                            }
                                                        }
                                                    },


                                                    {
                                                        key: "purchaseDate",
                                                        header: "购买时间",
                                                        align: 'center',
                                                        width: "33%",
                                                        render: shTableDataList => {
                                                            if (shTableDataList.id === "123" || shTableDataList.id === "1234") {
                                                                return <span
                                                                    className="font-color">{shTableDataList.purchaseDate}</span>
                                                            } else {
                                                                return <span>{shTableDataList.purchaseDate}</span>
                                                            }
                                                        }
                                                    }

                                                ]}
                                            />
                                        </div>
                                    </div>
                                    </Card.Body>



                                   <div style={{width:"99%",float:"left"}}>

                                       <p style={{
                                           width: "100%",
                                           fontWeight: "bold",
                                           marginTop: "25px",
                                           marginBottom: "17px"
                                       }}>同步冲突规则示例</p>
                                    <Table
                                        verticalTop
                                        records={
                                            scene6RightBData
                                        }
                                        rowClassName={scene6RightBData => {
                                            if (scene6RightBData.opType === "update") {
                                                return "bg-gray"
                                            } else if (scene6RightBData.opType === "delete") {
                                                return "bg-ghostwhite"
                                            }
                                        }}
                                        recordKey="opType"
                                        bordered='all'
                                        columns={[
                                            {
                                                key: "opType",
                                                header: "数据库操作类型",
                                                align: 'center',
                                                width: "15%",
                                                render: scene6RightBData => {
                                                    return <span>{scene6RightBData.opType}</span>
                                                }
                                            },
                                            {
                                                key: "confType",
                                                header: "同步冲突策略",
                                                align: 'center',
                                                width: "12%",
                                                render: scene6RightBData => {
                                                    return <span>{scene6RightBData.confType}</span>
                                                }
                                            },
                                            {
                                                key: "oLibrary",
                                                header: "目标库情况",
                                                align: 'center',
                                                width: "13%",
                                                render: scene6RightBData => {
                                                    return <span>{scene6RightBData.oLibrary}</span>
                                                }
                                            },
                                            {
                                                key: "sqlDetail",
                                                header: "SQL命令改写",
                                                align: 'center',
                                                width: "20%",
                                                render: scene6RightBData => {
                                                    return <span>{scene6RightBData.sqlDetail}</span>
                                                }
                                            },

                                            {
                                                key: "detail",
                                                header: "实际情况",
                                                align: 'center',
                                                width: "30%",
                                                render: scene6RightBData => {
                                                    return <span>{scene6RightBData.detail}</span>
                                                }
                                            },
                                            {
                                                key: "result",
                                                header: "结果",
                                                align: 'center',
                                                width: "10%",
                                                render: scene6RightBData => {
                                                    return <span>{scene6RightBData.result}</span>
                                                }
                                            }
                                        ]}
                                    />
                                   </div>


                        </div>

                    </div>

                <div className="tableAcrossCloud" id="acrossCloudRoadId" style={{display: "none"}}>
                    <div style={{fontWeight: "bold"}}>路径追踪</div>
                    <Table
                        verticalTop
                        records={
                            detailList
                        }
                        recordKey="num"
                        bordered='all'
                        columns={[
                            {
                                key: "num",
                                header: "步骤",
                                align: 'center',
                                width: "10%"
                            },
                            {
                                key: "description",
                                header: "描述",
                                align: 'left',
                                width: "80%"
                            },

                            {
                                key: "op",
                                header: "原始日志",
                                align: 'center',
                                width: "10%",
                                render: data => {
                                    //alert(data.num)
                                    if (data.num === 1) {
                                        return <Button type="primary" onClick={() => getDnsPodLog()}>查看下单</Button>
                                    } else if (data.num === 2) {
                                        return <Button type="primary" onClick={() => getLog()} loading={isLoadingKongLog}>查看日志</Button>
                                    } else if (data.num === 3) {return <Button type="primary" onClick={() => queryClsLog()} loading={isLoadingClsLog}>查看日志</Button>
                                    } else if (data.num === 4) {
                                        return <Button type="primary" onClick={() =>
                                            getMysqlDataLog()
                                        } loading={isLoadingMysqlLog}>查看数据</Button>
                                    } else {
                                        return <Button type="primary">查看日志</Button>
                                    }

                                }
                            },
                        ]}
                    />
                </div>

            </div>


            <Modal visible={logModelVisible} onClose={() => onCloseLog()} className="popup1"
                   caption="查看云原生网关日志">
                <Button
                    onClick={() => getLog()} type="primary" style={{marginBottom: "5px"}}>刷新
                </Button>

                <Modal.Body style={{
                    textAlign: "left",
                    overflow: "scroll",
                    width: "1150px",
                    height: "590px",
                    backgroundColor: 'black',
                    color: 'white'
                }}>
                    <div style={{whiteSpace: "pre-wrap"}}>
                        <p>{logClsKongContent}</p>
                        <p style={{color: "green", fontWeight: "bold"}}>{logClsKongContent1}</p>
                    </div>
                </Modal.Body>

            </Modal>


            <Modal visible={logMysqlDataVisible} onClose={() => setLogMysqlDataModelVisible(false)} className="popup1"
                   caption="查看MySQL数据库详细数据">
                <Modal.Body style={{
                    textAlign: "left", overflow: "scroll", width: "1150px", height: "590px", backgroundColor: 'black',
                    color: 'white'
                }}>
                    <div id="main">
                        <div>


                            <Card.Body className="iframeAcrossCloudTitle">
                                上海数据库
                            </Card.Body>
                            <div style={{whiteSpace: "pre-wrap"}}>
                                <p style={{color: "green", fontWeight: "bold"}}>{mysqlDataShLog}</p>
                                <p>{mysqlDataShLog1}</p>
                            </div>

                        </div>
                        <div style={{marginLeft: "10px"}}>

                            <Card.Body className="iframeAcrossCloudTitle">
                                广州数据库
                            </Card.Body>
                            <div style={{whiteSpace: "pre-wrap"}}>
                                <p style={{color: "green", fontWeight: "bold"}}>{mysqlDataGzLog}</p>
                                <p>{mysqlDataGzLog1}</p>
                            </div>

                        </div>

                    </div>

                </Modal.Body>
            </Modal>


            <Modal visible={logDnsPodVisible} onClose={() => onCloseDnsPodLog()} className="popup1"
                   caption="查看图书下单请求返回">
                <Modal.Body style={{
                    textAlign: "left", overflow: "scroll", width: "1150px", height: "590px", backgroundColor: 'black',
                    color: 'white'
                }}>
                    <div style={{display: "flex", whiteSpace: "pre-wrap"}}>
                        {dnsPodLog}
                    </div>
                </Modal.Body>
            </Modal>


            <Modal visible={logClsModelVisible} onClose={() => setLogClsModelVisible(false)} className="popup1"
                   caption="查看Q云书城应用日志">
                <Button
                    onClick={() => queryClsLog()} type="primary" style={{marginBottom: "5px"}}>刷新
                </Button>
                <Modal.Body style={{
                    textAlign: "left",
                    overflow: "scroll",
                    width: "1150px",
                    height: "590px",
                    backgroundColor: 'black',
                    color: 'white'
                }}>
                    <div style={{whiteSpace: "pre-wrap"}}>
                        <p>{logCls}</p>
                        <p style={{color: "green", fontWeight: "bold"}}>{logCls1}</p>
                    </div>
                </Modal.Body>
            </Modal>

        </LayoutContent>
    );
}
