import React, { useState, useEffect } from "react";
import "tea-component/dist/tea.css";
import { DatePicker, Select, Form } from "tea-component";
import moment from "moment";
import "moment/locale/zh-cn";
import { Tabs, Table,Input, TabPanel, Layout, Card, List, Radio, Button, Modal, message } from "tea-component";
import './index.scss'
import { getBookList, modifyBookstorage} from '../../../services/bookStorage'

const { Header, Body, Sider, Content: LayoutContent } = Layout;
const color = ['#006eff', '#0abf5b', '#ff9d00', '#e54545', '#262626', '#bbbbbb', '#f1ccb9', '#b9f2ec', '#d8b9f2', '#a9c186']

const { RangePicker } = DatePicker;

export default function BookStorage() {
    const [orderList, setOrderList] = useState([])
    const [selectOrder, setSelectOrder] = useState([])
    const [modalVisible, setModalVisible] = useState(false)
    useEffect(()=>{
        (async () => {
            const res = await getBookList()
            if(res.data&& res.data.length<=200){
                res && res.data && setOrderList(res.data);
            }
            if(res.data&&res.data.length>200&&res.data.indexOf("Please sign in")!=-1){
               window.location.href="/login"
           }
        })()
    }, [])
    const changeSelectOrder = (value,item)=>{
        let temp = {}
        selectOrder[item] = value
        for(let i in selectOrder) {
            temp[i] = selectOrder[i]
        }
        setSelectOrder(temp)
    }


    const changeSelectOrders = (value,item,value1,item1)=>{
        selectOrder[item] = moment(value).format('YYYY-MM-DD HH:mm:ss');
        selectOrder[item1] = moment(value1).format('YYYY-MM-DD HH:mm:ss');
    }
    const submitChange = async () => {
        const res = await modifyBookstorage(selectOrder)
        console.log("res.status="+res.status )
        if(res){
            if(res.status==200 && res.data.success==true){
                const refresh = await getBookList()
                refresh && refresh.data && setOrderList(refresh.data);
                setModalVisible(false)
                message.success({
                    content:res.data.msg
                })}
            else if(res.data.success==false){
                setModalVisible(true)
                message.error({
                    content:res.data.msg
                })}
            else{
                setModalVisible(false)
                if(res.data&&res.data.length>200&&res.data.indexOf("Please sign in")!=-1) {
                    window.location.href="/login"
                }
                message.error({
                    content:"修改失败,请稍后再试"
                 })
                //const w=window.open('about:blank');

            }
        }else{
            setModalVisible(false)
            if(res.data&&res.data.length>200&&res.data.indexOf("Please sign in")!=-1) {
                window.location.href="/login"
            }
            message.error({
                content:"修改失败，请稍后再试"
            })

        }
    }

    const refreshPage = async () => {
        const refresh1 = await getBookList()
        console.log(refresh1.data.length)
        if(refresh1.data && refresh1.data.length<=200){
            refresh1 && refresh1.data && setOrderList(refresh1.data);
        }
        else if(refresh1.data&&refresh1.data.length>200&&refresh1.data.indexOf("Please sign in")!=-1){
            window.location.href="/login"
        }
        else {
           // window.location.href="/login"
            message.error({
                  content:"刷新失败，请稍后再试"
             })
        }
    }
    const goBackPage = () => {
        window.history.back()
    }

    return (
        <LayoutContent>
            <LayoutContent.Header title='秒杀配置'  >
                <Button style={{marginTop:"10px",marginBottom:"10px",marginLeft:"20px"}} onClick={()=>refreshPage()} type="primary">刷新</Button>
                <Button style={{marginTop:"10px",marginBottom:"10px",marginLeft:"20px"}} onClick={()=>goBackPage()} type="primary">返回</Button>
            </LayoutContent.Header>
            <div className="presentation">
                <div className="bookstorage_box" id="bookstorageId">
                    <Table
                        verticalTop
                        records={
                            orderList
                        }
                        recordKey="id"
                        bordered='all'
                        columns={[
                            {
                                key: "isbn",
                                header: "isbn",
                                align: 'left',
                            },
                            {
                                key: "title",
                                header: "书本名称",
                                align: 'left'
                            },
                            {
                                key: "storageNum",
                                header: "库存数",
                                align: 'left'
                            },
                            {
                                key: "startTime",
                                header: "秒杀开始时间",
                                align: 'left'
                            },
                            {
                                key: "endTime",
                                header: "秒杀结束时间",
                                align: 'left'
                            },
                            {
                                key:"op",
                                header: "操作",
                                align: 'left',
                                render:function getFour(data) {
                                    return <Button onClick={()=>{setModalVisible(true);setSelectOrder(data)}}>修改</Button>
                                }
                            },
                        ]}
                    />
                </div>
            </div>
            <Modal visible={modalVisible} onClose={()=>setModalVisible(false)} caption="修改库存信息" style={{ fontSize: '14px' }}>
                <Modal.Body style={{textAlign:"center"}}>
                    {/* <List type="bullet" style={{ marginLeft: '20px', marginTop: '10px' }}>
                            <List.Item>{orderList.clbTips}</List.Item>

                        </List> */}
                    {
                        <div style={{display:'flex'}} key={0}>
                            <div className="left" style={{width:"30%"}}>
                                <p>ID</p>
                            </div>
                            <div className="right" style={{width:"70%"}}>
                                <Input   type="text" value={selectOrder['id']} onChange={value => changeSelectOrder(value,'id')} readOnly={true} />
                            </div>
                        </div>}
                    {
                        <div style={{display:'flex'}} key={1}>
                            <div className="left" style={{width:"30%"}}>
                                <p>isbn</p>
                            </div>
                            <div className="right" style={{width:"70%"}}>
                                <Input type="text" value={selectOrder['isbn']} onChange={value => changeSelectOrder(value,'isbn')} readOnly={true}/>
                            </div>
                        </div>

                    }
                    {
                        <div style={{display:'flex'}} key={2}>
                            <div className="left" style={{width:"30%"}}>
                                <p>书标题</p>
                            </div>
                            <div className="right" style={{width:"70%"}}>
                                <Input type="text" value={selectOrder['title']} onChange={value => changeSelectOrder(value,'title')} readOnly={true}/>
                            </div>
                        </div>

                    }
                    {
                        <div style={{display:'flex'}} key={3}>
                            <div className="left" style={{width:"30%"}}>
                                <p>库存数</p>
                            </div>
                            <div className="right" style={{width:"70%"}}>
                                <Input type="text" value={selectOrder['storageNum']} onChange={value => changeSelectOrder(value,'storageNum')} />
                            </div>
                        </div>

                    }
                    {
                        <div style={{display:'flex'}} key={5}>
                            <div className="left" style={{width:"30%"}}>
                                <p>开始至结束时间</p>
                            </div>
                            <div className="right" style={{width:"70%"}}>
                                <RangePicker
                                    separator="至"
                                    format="YYYY-MM-DD HH:mm:ss"
                                    showTime={{ format: "HH:mm:ss"}}
                                    defaultValue={[
                                        moment(selectOrder['startTime']),
                                        moment(selectOrder['endTime']),
                                    ]}
                                    onChange={value => changeSelectOrders(value[0],'startTime',value[1],'endTime')}
                                />

                            </div>
                        </div>
                    }
                    <Button style={{marginTop:"20px"}} onClick={()=>submitChange()} type="primary">修改</Button>
                </Modal.Body>
            </Modal>

        </LayoutContent>
    );
}
