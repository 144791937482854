import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { Modal, Button, Text, message } from 'tea-component'

export default forwardRef(({}, ref) => {
  const [visible, setVisible] = useState(false)
  const [dataJson, setDataJson] = useState({})

  const close = () => setVisible(false)

  useImperativeHandle(ref, () => ({
    open: data => {
      setDataJson(data)
      setVisible(true)
    }
  }))

  return (
    <>
      <Modal visible={visible} caption='Json结果' onClose={close}>
        <Modal.Body>
          <pre
            style={{
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
              maxHeight: '500px',
              overflowY: 'auto',
              backgroundColor: '#f3f4f7',
              padding: '10px'
            }}
          >
            <Text copyable>{dataJson}</Text>
          </pre>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type='primary'
            onClick={() => {
              navigator.clipboard.writeText(dataJson)
              message.success({
                content: '复制成功!'
              })
            }}
          >
            复制
          </Button>
          <Button type='weak' onClick={close}>
            关闭
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
})
